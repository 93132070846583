import styled from "styled-components";

export const AnswerFormStyled = styled.div`
  .answer_row {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }
  .btn_add_lv3 {
    margin-bottom: 90px;
    border-radius: 18px;
    padding: 8px 32px;
    background: ${({ theme }) => theme.COLORS.BLUE_6};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    cursor: pointer;
  }
  .btn_delete {
    cursor: pointer;
  }
`;
