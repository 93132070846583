export const ACTIVITY_FORM_RENDER_STATUS = (type) => {
  switch (type) {
    case "1":
      return {
        label: "รอประกาศผล",
        bg: "#FFB341",
      };
    case "2":
      return {
        label: "ปฏิเสธ",
        bg: "#FFB341",
      };
    case "3":
      return {
        label: "ถูกยกเลิกโดยระบบ",
        bg: "#FFB341",
      };
    case "4":
      return {
        label: "สำเร็จ",
        bg: "#008000",
      };
    default:
      return {
        label: "-",
        bg: "#008000",
      };
  }
};
