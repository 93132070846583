import styled from 'styled-components';

export const ProductWarrantyFormStyled = styled.div`
  .input_wrap {
    width: 50%;
  }
  .action_row {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
    .w150 {
      width: 150px;
    }
  }
  .box_reason {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    &.mt {
      margin-top: 0rem;
      margin-bottom: 1rem;
    }
    &.center {
      align-items: center;
    }
    .blank {
      width: 20%;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
      color: ${({ theme }) => theme.COLORS.BLUE_2};
    }
    .show_reason {
      width: 80%;
      .w50 {
        width: 50%;
      }
    }
  }
`;
