import styled from "styled-components";

export const ShowrromQrCodeContainerStyled = styled.div`
  border-radius: 1rem;
  padding: 1.25rem 1rem;
  width: 250px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .qr_code_wrap {
    margin-bottom: 30px;
    text-align: center;
  }
`;
