import React from "react";
import { connect } from "react-redux";
import { CorperateContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import { MdAdd, MdAssignmentAdd } from "react-icons/md";
import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from "utils/functions/string";
import moment from "moment";
import * as XLSX from "xlsx";
import queryString from "query-string";
import { Buttons, Inputs, Displays, Tables } from "components";

class CorperateContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    perPage: 10,
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    const query =
      this.props.router.location.search || `?limit=${perPage}&is_delete=false`;
    this.fetchData(query);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_ORGANIZATION_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        isLoading: false,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}&is_delete=false`
      : `?limit=${per_page}&page=${page}&is_delete=false`;
    this.setState({
      isLoading: true,
    });
    this.props.router.navigate(`${ROUTE_PATH.CORPERATE}${joinQuery}`);
    this.fetchData(joinQuery);
  };

  handleExport = async (permission) => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false&is_delete=false`
      : `?pagination=false&is_delete=false`;
    let res = await portalService.GET_ORGANIZATION_LIST(joinQuery);
    if (res && res.status === 200) {
      let wb = XLSX.utils.book_new();
      let ws_name = "SheetJS";
      /* make worksheet */
      let ws_data = res.data.docs.reduce((result, e, i) => {
        result.push([
          i + 1,
          `${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: e.name,
            action: "export",
          })}`,
          e.corporate_id,
          e.province,
          RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: e.phone_number,
            type: "phone",
            action: "export",
          }),
          e.email,
          e.member_list.length > 1 ? e.member_list[0].name : "",
          e.member_list.length >= 2 ? e.member_list[1].name : "",
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        "No",
        "Name",
        "Corperate no",
        "Province",
        "Phone",
        "Email",
        "Contact1",
        "Contact2",
      ]);
      let ws = XLSX.utils.aoa_to_sheet(ws_data);
      let wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `coperate-report-${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    }
  };

  handleClickCreate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.CORPERATE}/${e}`);
  };

  handleClickInfoDetail = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.CORPERATE}/info/${e}`);
  };

  render() {
    const { authenRedux, router } = this.props;
    const { isLoading, isBgLoading, pagination, data } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "customer");
    return (
      <CorperateContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {permission && permission.is_create && (
          <div className="btn_create">
            <div className="w120">
              <Buttons.BgIconPrefix
                label="New"
                icon={<MdAdd size={18} />}
                onClick={() => this.handleClickCreate("create")}
              />
            </div>
          </div>
        )}
        <Inputs.InputFilter
          theme_standard_input_filter
          dataFilter={dataFilter(router.location?.search)}
          btnLabel="ค้นหา"
          onQueryFilter={this.handleFilterKeyword}
          onExport={
            permission &&
            (permission.is_export || permission.is_export_condition)
              ? () => this.handleExport(permission)
              : false
          }
        />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <>
            <div className="body_table">
              <Tables.Standard
                columns={columns({
                  permission: permission,
                  handleClickDetail: this.handleClickCreate,
                  handleClickInfoDetail: this.handleClickInfoDetail,
                })}
                data={data}
                showPagination
                pagination={pagination}
                handlePageClick={this.handlePageClick}
              />
            </div>
          </>
        )}
      </CorperateContainerStyled>
    );
  }
}

const dataFilter = (query) => {
  let initialValues = queryString.parse(query);
  return [
    {
      accessor: "name",
      length: "311px",
      filter: {
        fieldQuery: "name",
        defaultValue: initialValues["name"],
        type: "text",
        label: "Name",
        placeholder: "Name",
      },
    },
    {
      accessor: "phone_number",
      length: "311px",
      filter: {
        fieldQuery: "phone_number",
        defaultValue: initialValues["phone_number"],
        type: "text",
        label: "Phone number",
        placeholder: "Phone number",
      },
    },
  ];
};

const columns = ({ permission, handleClickDetail, handleClickInfoDetail }) => {
  return [
    {
      Header: <div style={{ textAlign: "left" }}>ชื่อองค์กร</div>,
      accessor: "name",
      Cell: (props) => (
        <div onClick={() => handleClickDetail(props.row.original._id)}>
          {props.value}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>เลขนิติ</div>,
      accessor: "corporate_id",
      Cell: (props) => (
        <div onClick={() => handleClickDetail(props.row.original._id)}>
          {props.value}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>จังหวัด</div>,
      accessor: "province",
      Cell: (props) => (
        <div onClick={() => handleClickDetail(props.row.original._id)}>
          {props.value}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>เบอร์</div>,
      accessor: "phone_number",
      Cell: (props) => (
        <div onClick={() => handleClickDetail(props.row.original._id)}>
          {RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: props.value,
            type: "phone",
          })}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>อีเมล</div>,
      accessor: "email",
      Cell: (props) => (
        <div onClick={() => handleClickDetail(props.row.original._id)}>
          {RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: props.value,
          })}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>ชื่อผู้ติดต่อ1</div>,
      accessor: "person_contact_1",
      Cell: (props) => (
        <div onClick={() => handleClickDetail(props.row.original._id)}>
          {props.row.original.member_list &&
          props.row.original.member_list.length > 0
            ? props.row.original.member_list[0].name
            : ""}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>ชื่อผู้ติดต่อ2</div>,
      accessor: "person_contact_2",
      Cell: (props) => (
        <div onClick={() => handleClickDetail(props.row.original._id)}>
          {props.row.original.member_list &&
          props.row.original.member_list.length > 1
            ? props.row.original.member_list[1].name
            : ""}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Action</div>,
      accessor: "action",
      Cell: (props) => (
        <div onClick={() => handleClickInfoDetail(props.row.original._id)}>
          <MdAssignmentAdd size={22} color="#4b1e78" />
        </div>
      ),
    },
  ];
};

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CorperateContainer));
