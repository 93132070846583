import styled from "styled-components";

export const ShowRoomFormStyled = styled.div`
  padding: 20px;
  .row_contain {
    display: flex;
    flex-direction: row;
    width: 100%;
    .row_left_contain {
      width: 50%;
      .input_row {
        margin-bottom: 20px;
        display: flex;
        width: 100%;
        .f_left_col {
          width: 30%;
          display: flex;
          align-items: center;
        }

        .f_right_col {
          width: 70%;
          .detail {
            width: 100%;
            text-align: left;
          }
        }

        .f_right_col2 {
          width: 70%;
          display: flex;
          flex-direction: row;
          .detail {
            width: 100%;
            text-align: left;
          }
          .f_sub_left_col {
            padding: 10px;
            width: fit-content;
          }
          .f_sub_right_col {
            width: 40%;
          }
        }
      }
    }
    .row_right_contain {
      width: 50%;
      display: flex;
      justify-content: center;
      .right_col {
        width: 50%;
        .title {
          font-size: 22px;
        }
        .row_detail {
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-bottom: 10px;
          .left_detail {
            width: 50%;
          }
          .right_detail {
            width: 80%;
          }
        }
      }
    }
  }
  .action_row {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
  }
`;
