import apiService from "../apiService";

const apiPortalPath = "/api/v1";

export const portalService = {
  POST_LOGIN: (params) => {
    return apiService.post(`${apiPortalPath}/admin-login`, params);
  },
  POST_CHECK_TOKEN: (params) => {
    return apiService.post(`${apiPortalPath}/admin-login/check_token`, params);
  },
  GET_MY_PROFILE: () => {
    return apiService.get(`${apiPortalPath}/admin/myProfile`);
  },
  POST_RESET_PASSWORD: (params) => {
    return apiService.post(`${apiPortalPath}/admin/reset-password`, params);
  },

  // ADMIN PERMISSION
  GET_ADMIN_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/admin${stringQuery ? stringQuery : ""}`
    );
  },
  GET_ADMIN_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/admin/${id}`);
  },
  PUT_ADMIN_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/admin/${id}`, params);
  },
  POST_ADMIN_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/admin`, params);
  },
  PUT_ADMIN_PASSWORD_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/admin/password/${id}`, params);
  },
  DELETE_ADMIN_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/admin/${id}`);
  },

  // ACTIVITY FORM HISTORY
  GET_ACTIVITY_FORM_HISTORY_BY_ACTIVITY_LIST: (id, stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/activity-form-history/activity/${id}${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  PUT_ACTIVITY_FORM_APPROVE_: (id, params) => {
    return apiService.put(
      `${apiPortalPath}/activity-form-history/approve/${id}`,
      params
    );
  },
  POST_ACTIVITY_FORM_CERTIFICATE_UPLOAD: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/activity-form-history/upload-certificate`,
      params
    );
  },

  // ACTIVITY FORM
  GET_ACTIVITY_FORM_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/activity-form${stringQuery ? stringQuery : ""}`
    );
  },
  GET_ACTIVITY_FORM_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/activity-form/${id}`);
  },
  POST_ACTIVITY_FORM_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/activity-form`, params);
  },
  POST_ACTIVITY_FORM_UPLOAD: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/activity-form/upload`,
      params
    );
  },
  POST_ACTIVITY_FORM_UPLOAD_DELETE: (params) => {
    return apiService.post(
      `${apiPortalPath}/activity-form/upload/delete`,
      params
    );
  },
  POST_ACTIVITY_FORM_QUESTION_UPLOAD: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/activity-form/upload/question`,
      params
    );
  },
  POST_ACTIVITY_FORM_QUESTION_MEDIA_DELETE: (params) => {
    return apiService.post(`${apiPortalPath}/activity/question/delete`, params);
  },
  PUT_ACTIVITY_FORM_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/activity-form/${id}`, params);
  },
  DELETE_ACTIVITY_FORM_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/activity-form/${id}`);
  },

  // ACTIVITY
  GET_ACTIVITY_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/activity${stringQuery ? stringQuery : ""}`
    );
  },
  GET_ACTIVITY_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/activity/${id}`);
  },
  POST_ACTIVITY_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/activity`, params);
  },
  POST_ACTIVITY_UPLOAD: (params) => {
    return apiService.post_formdata(`${apiPortalPath}/activity/upload`, params);
  },
  POST_ACTIVITY_UPLOAD_DELETE: (params) => {
    return apiService.post(`${apiPortalPath}/activity/upload/delete`, params);
  },
  POST_ACTIVITY_QUESTION_UPLOAD: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/activity/upload/question`,
      params
    );
  },
  POST_ACTIVITY_QUESTION_MEDIA_DELETE: (params) => {
    return apiService.post(`${apiPortalPath}/activity/question/delete`, params);
  },
  PUT_ACTIVITY_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/activity/${id}`, params);
  },
  DELETE_ACTIVITY_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/activity/${id}`);
  },

  // ACTIVITY HISTORY
  GET_ACTIVITY_HISTORY_BY_ACTIVITY_LIST: (id, stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/activity-history/activity/${id}${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_ACTIVITY_BY_CUSTOMER_LIST: (id, stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/activity-history/customer/${id}${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  PUT_ACTIVITY_HITORY: (id, params) => {
    return apiService.put(`${apiPortalPath}/activity-history/${id}`, params);
  },
  POST_ACTIVITY_HITORY_SCAN: (params) => {
    return apiService.post(
      `${apiPortalPath}/activity-history/scan/admin`,
      params
    );
  },
  POST_ACTIVITY_ANNOUNCEMEN_DETAIL: (params) => {
    return apiService.post(
      `${apiPortalPath}/activity-history/announcemen`,
      params
    );
  },
  POST_ACTIVITY_HISTORY_IMPORT: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/activity-history/import`,
      params
    );
  },
  PUT_ACTIVITY_NOMAL_FORM_APPROVE: (id, params) => {
    return apiService.put(
      `${apiPortalPath}/activity-history/approve-normal-form/${id}`,
      params
    );
  },
  POST_ACTIVITY_QUESTION_EDIT: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/activity-history/me/question`,
      params
    );
  },

  // REWARD HISTORY activity
  GET_REWARD_HISTORY_BY_REWARD_LIST: (id, stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/reward-history/reward/${id}${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_REWARD_BY_CUSTOMER_LIST: (id, stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/reward-history/customer/${id}${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  POST_REWARD_HITORY_SCAN: (params) => {
    return apiService.post(
      `${apiPortalPath}/reward-history/scan/admin`,
      params
    );
  },

  // ASSESSMENT
  GET_ASSESSMENT_BY_QUESTION_LIST: (id, stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/assessment/question/${id}${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_ASSESSMENT_BY_QUESTION_ADMIN_LIST: (id, stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/assessment/question/admin/${id}${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  // CUSTOMER
  GET_CUSTOMER_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/customer${stringQuery ? stringQuery : ""}`
    );
  },
  GET_CUSTOMER_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/customer/${id}`);
  },
  DELETE_CUSTOMER_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/customer/${id}`);
  },

  // NEW
  GET_NEW_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/new${stringQuery ? stringQuery : ""}`
    );
  },
  GET_NEW_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/new/${id}`);
  },
  POST_NEW_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/new`, params);
  },
  POST_NEW_UPLOAD: (params) => {
    return apiService.post_formdata(`${apiPortalPath}/new/upload`, params);
  },
  PUT_NEW_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/new/${id}`, params);
  },
  DELETE_NEW_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/new/${id}`);
  },

  // BUSINESS_UNIT
  GET_BUSINESS_UNIT_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/business-unit${stringQuery ? stringQuery : ""}`
    );
  },
  GET_BUSINESS_UNIT_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/business-unit/${id}`);
  },
  POST_BUSINESS_UNIT_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/business-unit`, params);
  },
  PUT_BUSINESS_UNIT_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/business-unit/${id}`, params);
  },
  DELETE_BUSINESS_UNIT_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/business-unit/${id}`);
  },

  // BRANCH
  GET_BRANCH_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/branch${stringQuery ? stringQuery : ""}`
    );
  },
  GET_BRANCH_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/branch/${id}`);
  },
  POST_BRANCH_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/branch`, params);
  },
  PUT_BRANCH_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/branch/${id}`, params);
  },
  DELETE_BRANCH_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/branch/${id}`);
  },

  // PRODUCT WARRANTEE
  GET_PRODUCT_WARRANTEE_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/product-warrantee${stringQuery ? stringQuery : ""}`
    );
  },
  GET_PRODUCT_WARRANTEE_BY_CUSTOMER_LIST: (id, stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/product-warrantee/customer/${id}${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_PRODUCT_WARRANTEE_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/product-warrantee/${id}`);
  },
  POST_PRODUCT_WARRANTEE_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/product-warrantee`, params);
  },
  POST_PRODUCT_WARRANTEE_APPROVAL: (params) => {
    return apiService.post(
      `${apiPortalPath}/product-warrantee/approval`,
      params
    );
  },
  POST_PRODUCT_WARRANTEE_IMPORT_JSON: (params) => {
    return apiService.post(
      `${apiPortalPath}/product-warrantee/import-json`,
      params
    );
  },
  POST_PRODUCT_WARRANTEE_UPLOAD: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/product-warrantee/import-json-file`,
      params
    );
  },
  POST_PRODUCT_WARRANTEE_UPLOAD_IMAGE: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/product-warrantee/upload/admin`,
      params
    );
  },
  POST_PRODUCT_WARRANTEE_REGIS_UPLOAD_IMAGE: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/product-warrantee/upload/member`,
      params
    );
  },
  PUT_PRODUCT_WARRANTEE_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/product-warrantee/${id}`, params);
  },
  DELETE_PRODUCT_WARRANTEE_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/product-warrantee/${id}`);
  },

  GET_PRODUCT_DEP_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/product-dep${stringQuery ? stringQuery : ""}`
    );
  },

  // QUESTION
  GET_QUESTION_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/question${stringQuery ? stringQuery : ""}`
    );
  },
  GET_QUESTION_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/question/${id}`);
  },
  POST_QUESTION_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/question`, params);
  },
  POST_QUESTION_UPLOAD: (params) => {
    return apiService.post_formdata(`${apiPortalPath}/question/upload`, params);
  },
  PUT_QUESTION_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/question/${id}`, params);
  },
  DELETE_QUESTION_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/question/${id}`);
  },

  // REWARD
  GET_REWARD_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/reward${stringQuery ? stringQuery : ""}`
    );
  },
  GET_REWARD_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/reward/${id}`);
  },
  POST_REWARD_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/reward`, params);
  },
  POST_REWARD_UPLOAD: (params) => {
    return apiService.post_formdata(`${apiPortalPath}/reward/upload`, params);
  },
  PUT_REWARD_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/reward/${id}`, params);
  },
  DELETE_REWARD_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/reward/${id}`);
  },

  // REWARD HISTORY
  GET_REWARD_HISTORY_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/reward-history/${stringQuery ? stringQuery : ""}`
    );
  },
  GET_REWARD_HISTORY_BY_CUSTOMER_LIST: (id, stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/reward-history/customer/${id}${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  PUT_REWARD_HISTORY_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/reward-history/${id}`, params);
  },

  // LUCKY DRAW
  GET_LUCKY_DRAW_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/luckydraw-reward${stringQuery ? stringQuery : ""}`
    );
  },
  GET_LUCKY_DRAW_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/luckydraw-reward/${id}`);
  },
  POST_LUCKY_DRAW_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/luckydraw-reward`, params);
  },
  POST_LUCKY_DRAW_UPLOAD: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/luckydraw-reward/upload`,
      params
    );
  },
  PUT_LUCKY_DRAW_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/luckydraw-reward/${id}`, params);
  },
  DELETE_LUCKY_DRAW_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/luckydraw-reward/${id}`);
  },

  // LUCKY DRAW History
  GET_LUCKY_DRAW_HISTORY_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/luckydraw-reward-history${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  // STATISTIC
  GET_SYSTEM_OVERVIEW: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/statistic/overview/system${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_SYSTEM_OVERVIEW_PERIOD: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/statistic/overview/system/period${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_CUSTOMER_OVERVIEW: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/statistic/overview/customer${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_PRODUCT_WARRANTY_OVERVIEW: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/statistic/overview/product-warranty${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_ACTIVITY_HISTORY_OVERVIEW: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/statistic/overview/activity-history${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_REWARD_HISTORY_OVERVIEW: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/statistic/overview/reward-history${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_E_WARRANTY_OVERVIEW_STATUS: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/statistic/overview/e-warranty/status${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_E_WARRANTY_OVERVIEW_DEALER: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/statistic/overview/e-warranty/dealer${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_E_WARRANTY_OVERVIEW_DEALER_PRODUCT_DEP: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/statistic/overview/e-warranty/dealer/product-dep${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_RELINK_OVERVIEW: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/statistic/overview/relink${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  // PDPA
  GET_PDPA_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/pdpa${stringQuery ? stringQuery : ""}`
    );
  },
  GET_PDPA_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/pdpa/${id}`);
  },
  POST_PDPA_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/pdpa`, params);
  },
  PUT_PDPA_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/pdpa/${id}`, params);
  },
  DELETE_PDPA_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/pdpa/${id}`);
  },

  // PDPA_HISTORY
  GET_PDPA_HISTORY_LIST_BY_PDPA_ID: (id, stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/pdpa-history/pdpa/${id}${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_PDPA_HISTORY_LIST_BY_CUSTOMER_ID: (id, stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/pdpa-history/customer/${id}${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  // PERMISSION
  GET_PERMISSION_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/permission${stringQuery ? stringQuery : ""}`
    );
  },
  GET_MY_PERMISSION_DETAIL: () => {
    return apiService.get(`${apiPortalPath}/permission/me`);
  },
  GET_PERMISSION_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/permission/${id}`);
  },
  POST_PERMISSION_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/permission`, params);
  },
  PUT_PERMISSION_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/permission/${id}`, params);
  },
  DELETE_PERMISSION_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/permission/${id}`);
  },

  // NOTIFICATION
  POST_NOTIFICATION_SENT: (params) => {
    return apiService.post(
      `${apiPortalPath}/notification-history/send`,
      params
    );
  },
  POST_POPUP_SENT: (params) => {
    return apiService.post(`${apiPortalPath}/popup-history/send`, params);
  },

  // ORGANIZATION
  GET_ORGANIZATION_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/organization${stringQuery ? stringQuery : ""}`
    );
  },
  GET_ORGANIZATION_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/organization/${id}`);
  },
  POST_ORGANIZATION_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/organization`, params);
  },
  PUT_ORGANIZATION_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/organization/${id}`, params);
  },
  DELETE_ORGANIZATION_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/organization/${id}`);
  },

  // PRODUCT WARRANTY SET
  GET_PRODUCT_WARRANTY_SET_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/product-warranty-set${stringQuery ? stringQuery : ""}`
    );
  },
  GET_PRODUCT_WARRANTY_SET_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/product-warranty-set/${id}`);
  },
  POST_PRODUCT_WARRANTY_SET_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/product-warranty-set`, params);
  },
  POST_PRODUCT_SET_REGIS_UPLOAD: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/product-warranty-set/upload`,
      params
    );
  },
  PUT_PRODUCT_WARRANTY_SET_UPDATE: (id, params) => {
    return apiService.put(
      `${apiPortalPath}/product-warranty-set/${id}`,
      params
    );
  },
  PUT_PRODUCT_WARRANTY_SET_ACTIVATE: (id, params) => {
    return apiService.put(
      `${apiPortalPath}/product-warranty-set/activate/${id}`,
      params
    );
  },
  DELETE_PRODUCT_WARRANTY_SET_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/product-warranty-set/${id}`);
  },

  POST_CUSTOMER_UNLINK: (params) => {
    return apiService.post(
      `${apiPortalPath}/customer/sync/unlink-line`,
      params
    );
  },

  // BOOKING
  GET_BOOKING_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/booking${stringQuery ? stringQuery : ""}`
    );
  },
  GET_BOOKING_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/booking/${id}`);
  },
  POST_BOOKING_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/booking`, params);
  },
  PUT_BOOKING_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/booking/${id}`, params);
  },
  DELETE_BOOKING_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/booking/${id}`);
  },

  // HOLIDAY
  GET_HOLIDAY_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/holiday${stringQuery ? stringQuery : ""}`
    );
  },
  GET_HOLIDAY_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/holiday/${id}`);
  },
  POST_HOLIDAY_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/holiday`, params);
  },
  PUT_HOLIDAY_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/holiday/${id}`, params);
  },
  DELETE_HOLIDAY_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/holiday/${id}`);
  },

  // TIMESLOT
  GET_TIME_SLOT_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/time-slot${stringQuery ? stringQuery : ""}`
    );
  },
  GET_TIME_SLOT_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/time-slot/${id}`);
  },
  POST_TIME_SLOT_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/time-slot`, params);
  },
  POST_TIME_SLOT_BOOKING: (params) => {
    return apiService.post(`${apiPortalPath}/time-slot/booking`, params);
  },
  PUT_TIME_SLOT_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/time-slot/${id}`, params);
  },
  DELETE_TIME_SLOT_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/time-slot/${id}`);
  },

  // SHOWROOM
  GET_SHOWROOM_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/showroom-visit${stringQuery ? stringQuery : ""}`
    );
  },
  GET_SHOWROOM_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/showroom-visit/${id}`);
  },
  POST_SHOWROOM_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/showroom-visit`, params);
  },
  PUT_SHOWROOM_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/showroom-visit/${id}`, params);
  },
  DELETE_SHOWROOM_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/showroom-visit/${id}`);
  },

  // BOOKING EMAIL CONFIG
  GET_BOOKING_EMAIL_CONFIG_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/booking-email-config${stringQuery ? stringQuery : ""}`
    );
  },
  GET_BOOKING_EMAIL_CONFIG_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/booking-email-config/${id}`);
  },
  POST_BOOKING_EMAIL_CONFIG_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/booking-email-config`, params);
  },
  PUT_BOOKING_EMAIL_CONFIG_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/booking-email-config/${id}`, params);
  },
  DELETE_BOOKING_EMAIL_CONFIG_DETAIL: (id) => {
    return apiService.delete(`${apiPortalPath}/booking-email-config/${id}`);
  },
};
