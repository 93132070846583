import React from "react";
import { connect } from "react-redux";
import { NewDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { AlertControl } from "components/Modals/Alert";
import { Displays, Forms, Modals } from "components";

class NewDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    scrollToTopPageFN();
    if (this.props.router.params.id !== "create") {
      this.fetchData();
    } else {
      this.setState({
        isLoading: false,
        isBgLoading: false,
      });
    }
  }

  fetchData = async () => {
    let res = await portalService.GET_NEW_DETAIL(this.props.router.params.id);
    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  // FLOW DELETE
  // =============================
  // =============================
  handleDelete = () => {
    this.setState({
      renderModal: (
        <Displays.CofirmBox
          label="คุณต้องการลบใช่หรือไม่ ?"
          labelBtn1="Confirm"
          labelBtn2="Cancel"
          onAction1={this.deleteAction}
          onAction2={this.handleCloseModal}
        />
      ),
    });
  };
  deleteAction = async () => {
    this.setState({
      isBgLoading: true,
    });
    let res = await portalService.DELETE_NEW_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      toast.success("ลบสำเร็จ");
      this.handleNextStep();
    } else {
      toast.error("ลบไม่สำรเร็จ");
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  // FLOW SUBMIT
  // =============================
  // =============================
  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    let res =
      this.props.router.params.id === "create"
        ? await portalService.POST_NEW_DETAIL(params)
        : await portalService.PUT_NEW_UPDATE(
            this.props.router.params.id,
            params
          );
    if (res && res.status === 200) {
      let filesTemp = [];
      if (values.front_img_file) {
        filesTemp = [
          ...filesTemp,
          ...values.front_img_file.filter((e) => e.isNewUpload),
        ];
      }
      if (values.inside_img_file) {
        filesTemp = [
          ...filesTemp,
          ...values.inside_img_file.filter((e) => e.isNewUpload),
        ];
      }
      if (filesTemp.length === 0) {
        toast.success("อัพเดตสำเร็จ");
        this.handleNextStep();
      } else {
        this.handleUploadContentMedia(res.data._id, filesTemp);
      }
    } else {
      toast.error("อัพเดตไม่สำเร็จ");
    }
  };
  handleUploadContentMedia = (newID, files) => {
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set("new_id", newID);
            bodyFormData.set("type", e.fieldName);
            bodyFormData.append("image", e);
            let res = await portalService.POST_NEW_UPLOAD(bodyFormData);
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      this.setState({
        isBgLoading: false,
      });
      toast.success("อัพเดจสำเร็จ");
      this.handleNextStep();
    });
  };

  handleCancel = () => {
    this.props.router.navigate(-1);
  };

  handleSent = async () => {
    AlertControl.show({
      title: "ยืนยันการส่ง",
      description: "คุณต้องการจะส่งแจ้งเตือนใช่หรือไม่",
      btnLabel1: "ยกเลิก",
      btnLabel2: "ยืนยัน",
      onEvent2: async () => {
        const { initialValues } = this.state;
        this.setState({
          isBgLoading: true,
        });
        let paramsPopup = {
          feature_id: initialValues._id,
          img_path: initialValues.front_img,
          feature_type: "1",
        };
        let paramsNoti = {
          feature_id: initialValues._id,
          title: initialValues.title,
          message: initialValues.description,
          feature_type: "1",
        };
        let res = await Promise.all([
          portalService.POST_POPUP_SENT(paramsPopup),
          portalService.POST_NOTIFICATION_SENT(paramsNoti),
          portalService.PUT_NEW_UPDATE(this.props.router.params.id, {
            is_popup_activate: true,
            is_notification_activate: true,
          }),
        ]);
        if (res) {
          this.fetchData();
        }
      },
    });
  };

  handleNextStep = () => {
    this.props.router.navigate(ROUTE_PATH.NEW);
  };

  render() {
    const { authenRedux } = this.props;
    const { isLoading, initialValues, isBgLoading, renderModal } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "new");
    return (
      <NewDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading theme_standard_loading />
          </div>
        ) : (
          <Forms.NewForm
            initialValues={initialValues}
            permission={permission}
            onCancel={this.handleCancel}
            onDelete={this.handleDelete}
            onSent={this.handleSent}
            onSubmit={this.handleSubmit}
          />
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </NewDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewDetailContainer));
