export const ACTIVITY_RENDER_TYPE = (type) => {
  switch (type) {
    case "1":
      return "Online";
    case "2":
      return "Offline";
    case "3":
      return "Normal";
    default:
      break;
  }
};

export const ACTIVITY_RENDER_STATUS = (type) => {
  switch (type) {
    case "1":
      return {
        label: "ดำเนินการ",
        bg: "#FFB341",
      };
    case "2":
      return {
        label: "สำเร็จ",
        bg: "#008000",
      };
    case "3":
      return {
        label: "รอประกาศผล",
        bg: "#FFB341",
      };
    case "4":
      return {
        label: "ปฏิเสธ",
        bg: "#FFB341",
      };
    case "5":
      return {
        label: "ถูกยกเลิกโดยระบบ",
        bg: "#FFB341",
      };
    default:
      break;
  }
};
