import styled from 'styled-components';

export const ProductWarrantySetFormStyled = styled.div`
  .input_row {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    .f_left_col {
      width: 20%;
      display: flex;
      align-items: center;
    }
    .f_right_col {
      width: 40%;
    }
    .contact_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 1rem;
      .ci_col {
        flex: 1;
      }
      .btn_delete {
        cursor: pointer;
      }
    }
  }
  .btn_add {
    border-radius: 1rem;
    cursor: pointer;
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .action_row {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
  }
`;
