import React from 'react';
import { connect } from 'react-redux';
import { ProductSetDetailContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { portalService } from 'apiServices';
import { AlertControl } from 'components/Modals/Alert';
import { toast } from 'react-toastify';
import { Displays, Forms } from 'components';

class ProductSetDetailContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    if (this.props.router.params.id !== 'create') {
      this.fetchData();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  fetchData = async () => {
    let res = await portalService.GET_PRODUCT_WARRANTY_SET_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      this.setState({
        initialValues: { ...res.data },
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleClickDelete = async () => {
    AlertControl.show({
      title: 'ยืนยันการลบ',
      description: 'คุณต้องการจะลบใช่หรือไม่',
      btnLabel1: 'ยกเลิก ',
      btnLabel2: 'ยืนยัน',
      onEvent2: async () => {
        this.setState({
          isBgLoading: true,
        });
        let res = await portalService.DELETE_PRODUCT_WARRANTY_SET_DETAIL(
          this.props.router.params.id
        );
        if (res && res.status === 200) {
          toast.success('ลบสำเร็จ');
          this.setState({
            isBgLoading: false,
          });
          this.props.router.navigate(-1);
        } else {
          toast.error('ลบไม่สำเร็จ');
        }
      },
    });
  };

  handleSubmit = async (values) => {
    const { deleteTemp } = this.state;
    const fileUploadNew = values.slip_img.filter((e) => e.isNewUpload);
    const bodyFormData = new FormData();
    bodyFormData.append('code', values.code);
    bodyFormData.append('name', values.name);
    bodyFormData.append('organization_ref_id', values.organization._id);
    bodyFormData.append('organization_name', values.organization.name);
    bodyFormData.append(
      'organization_coporate_id',
      values.organization.coporate_id
    );
    let product_warranty_temp = [];
    if (
      values.product_warranty_list &&
      values.product_warranty_list.length > 0
    ) {
      values.product_warranty_list.forEach((e) => {
        product_warranty_temp.push({
          product_warranty_id: e.info._id,
          name: e.info.serial_no + '-' + e.info.item_no,
          isNew: e.isNew,
        });
      });
    }
    if (deleteTemp && deleteTemp.length > 0) {
      if (!product_warranty_temp || product_warranty_temp.length === 0) {
        deleteTemp.forEach((e) =>
          product_warranty_temp.push({
            product_warranty_id: e.info.product_warranty_id,
            name: e.info.serial_no + '-' + e.info.item_no,
            isDelete: e.isDelete,
          })
        );
      } else {
        deleteTemp.forEach((e) => {
          product_warranty_temp.push({
            product_warranty_id: e.info.product_warranty_id,
            name: e.info.serial_no + '-' + e.info.item_no,
            isDelete: e.isDelete,
          });
        });
      }
    }
    if (product_warranty_temp && product_warranty_temp.length > 0) {
      bodyFormData.append(
        'product_warranty_list',
        JSON.stringify(product_warranty_temp)
      );
    }
    if (fileUploadNew && fileUploadNew.length > 0) {
      bodyFormData.append('image', fileUploadNew[0]);
    }
    if (this.props.router.params.id !== 'create') {
      bodyFormData.append(
        'product_warranty_set_id',
        this.props.router.params.id
      );
    }
    let res = await portalService.POST_PRODUCT_SET_REGIS_UPLOAD(bodyFormData);
    if (res && res.status === 200) {
      toast.success('อัพเดตสำเร็จ');
      this.props.router.navigate(-1);
    } else {
      toast.error('อัพเดตไม่สำเร็จ');
    }
  };

  handleDeleteItem = (e) => {
    const { deleteTemp } = this.state;
    if (!e.isNew) {
      if (!deleteTemp) {
        this.setState({
          deleteTemp: [{ ...e, isDelete: true }],
        });
      } else {
        let temp = deleteTemp;
        temp.push({ ...e, isDelete: true });
        this.setState({
          deleteTemp: [...temp],
        });
      }
    }
  };

  handleClickBack = () => {
    this.props.router.navigate(-1);
  };

  handleActive = () => {
    const { initialValues } = this.state;
    AlertControl.show({
      title: 'ยืนยันการทำงาน',
      description: 'เมื่อคุณกดยืนยันแล้วจะไม่สามารถย้อนการทำงานได้',
      btnLabel1: 'ยกเลิก ',
      btnLabel2: 'ยืนยัน',
      onEvent2: async () => {
        this.setState({
          isBgLoading: true,
        });
        let params = {
          organization_id: initialValues.organization_ref_id,
        };
        let res = await portalService.PUT_PRODUCT_WARRANTY_SET_ACTIVATE(
          this.props.router.params.id,
          params
        );
        if (res && res.status === 200) {
          toast.success('ยินยันสำเร็จ');
          this.fetchData();
        } else {
          toast.error('ยืนยันไม่สำเร็จ');
        }
      },
    });
  };

  render() {
    const { authenRedux, router } = this.props;
    const { isBgLoading, isLoading, initialValues } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === 'product_warranty');
    return (
      <ProductSetDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <Forms.ProductWarrantySetForm
            isCreate={router.params.id === 'create'}
            initialValues={initialValues}
            permission={permission}
            onActive={this.handleActive}
            onCancel={this.handleClickBack}
            onDelete={this.handleClickDelete}
            onDeleteItem={this.handleDeleteItem}
            onSubmit={this.handleSubmit}
          />
        )}
      </ProductSetDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductSetDetailContainer));
