import React, { useState, useEffect } from "react";
import { BranchFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import address from "utils/jsons/thailandAddress/address.json";
import { Buttons, Inputs } from "components";

const BranchForm = ({
  initialValues,
  permission,
  onCancel,
  onDelete,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    phone_number: yup.string().required("Phone is required"),
    province: yup.object().required("Please select province"),
    district: yup.object().required("Please select district"),
    sub_district: yup.object().required("Please select sub district"),
    zipcode: yup.string().required("Please enter zipcode"),
    address: yup.string().required("Adress is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [_districtOption, _setDistrictOption] = useState([]);
  const [_subdistrictOption, _setSubdistrictOption] = useState([]);

  useEffect(() => {
    if (initialValues) {
      let params = { ...initialValues };
      if (initialValues.province) {
        params.province = {
          label: initialValues.province,
          value: initialValues.province,
        };
      }
      if (initialValues.district) {
        params.district = {
          label: initialValues.district,
          value: initialValues.district,
        };
      }
      if (initialValues.sub_district) {
        params.sub_district = {
          label: initialValues.sub_district,
          value: initialValues.sub_district,
        };
      }
      reset(params);
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleChangeProvince = (e) => {
    setValue("district", "");
    setValue("sub_district", "");
    setValue("zipcode", "");
    _setDistrictOption(
      address.reduce((result, data, i) => {
        if (data.province === e.value) {
          let find = result.find((f) => f.value === data.amphoe);
          if (!find) {
            result.push({
              label: data.amphoe,
              value: data.amphoe,
            });
          }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeDistrict = (e) => {
    setValue("sub_district", "");
    setValue("zipcode", "");
    _setSubdistrictOption(
      address.reduce((result, data, i) => {
        if (data.amphoe === e.value) {
          let find = result.find((f) => f.value === data.district);
          if (!find) {
            result.push({
              label: data.district,
              value: data.district,
              zipcode: data.zipcode,
            });
          }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeSubDistrict = (e) => {
    let find = _subdistrictOption.find((f) => f.value === e.value);
    if (find) {
      setValue("zipcode", find.zipcode);
    }
  };

  return (
    <BranchFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_row">
          <div className="f_left_col">Name</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.name?.message}
                />
              )}
              name="name"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Phone number</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.phone_number?.message}
                />
              )}
              name="phone_number"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Province</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputMultiSelect
                  {...field}
                  options={address.reduce((result, data, i) => {
                    if (i === 0) {
                      result.push({
                        label: data.province,
                        value: data.province,
                      });
                    } else {
                      let find = result.find((e) => e.value === data.province);
                      if (!find) {
                        result.push({
                          label: data.province,
                          value: data.province,
                        });
                      }
                    }
                    return result;
                  }, [])}
                  placeholder="Province"
                  errors={errors.province?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    _handleChangeProvince(e);
                  }}
                />
              )}
              name="province"
              defaultValue=""
            />
          </div>
        </div>

        <div className="input_row">
          <div className="f_left_col">District</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputMultiSelect
                  {...field}
                  isSplitLabel
                  options={_districtOption}
                  placeholder="District"
                  errors={errors.district?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    _handleChangeDistrict(e);
                  }}
                />
              )}
              name="district"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Sub district</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputMultiSelect
                  {...field}
                  disabled
                  options={_subdistrictOption}
                  placeholder="Sub district"
                  errors={errors.sub_district?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    _handleChangeSubDistrict(e);
                  }}
                />
              )}
              name="sub_district"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Zipcode</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.zipcode?.message}
                />
              )}
              name="zipcode"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Address</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextArea
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.address?.message}
                />
              )}
              name="address"
              defaultValue=""
            />
          </div>
        </div>
        {/* <div className="input_row">
          <div className="f_left_col">Is Center</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  errors={errors.is_center?.message}
                />
              )}
              name="is_center"
              defaultValue={false}
            />
          </div>
        </div> */}
        <div className="action_row">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
          {permission && (
            <>
              {initialValues && permission.is_delete && (
                <div className="w120">
                  <Buttons.BgStandard
                    theme_red
                    label="Delete"
                    disabled={initialValues && initialValues.is_center}
                    onClick={onDelete}
                  />
                </div>
              )}
              {initialValues && permission.is_update ? (
                <div className="w120">
                  <Buttons.BgStandard
                    type="submit"
                    label="Update"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              ) : (
                permission.is_create && (
                  <div className="w120">
                    <Buttons.BgStandard
                      type="submit"
                      label="Save"
                      // disabled={initialValues && initialValues.is_center}
                    />
                  </div>
                )
              )}
            </>
          )}
        </div>
      </form>
    </BranchFormStyled>
  );
};

BranchForm.propTypes = {};

export default BranchForm;
