import React from "react";
import { ActQuestionContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";

import { toast } from "react-toastify";
import { Displays, Forms } from "components";

class ActQuestionContainer extends React.Component {
  state = {};

  handleSubmit = async (values) => {
    const { initialValues, onQuestionUpdateFail, onQuestionUpdateSuccess } =
      this.props;
    this.setState({
      isBgLoading: true,
    });
    const bodyFormData = new FormData();
    bodyFormData.append("activity_id", initialValues._id);
    bodyFormData.set(
      "question_list",
      JSON.stringify(
        values.question_list.map((e, i) => {
          let render = { ...e };
          if (e.type === "3" && typeof render.answer !== "string") {
            delete render.answer;
          }
          if (
            e.answer === "Other" ||
            e.answer === "อื่นๆ" ||
            e.answer === "อื่นๆ:"
          ) {
            render.answer = e.other;
          }
          return render;
        })
      )
    );
    values.question_list.forEach((e, i) => {
      bodyFormData.append(`file[]`, e.answer[0]);
    });
    let res = await portalService.POST_ACTIVITY_QUESTION_EDIT(bodyFormData);
    if (res && res.status === 200) {
      toast.success("Successfully edited");
      onQuestionUpdateSuccess && onQuestionUpdateSuccess();
      this.setState({
        isBgLoading: false,
      });
    } else {
      onQuestionUpdateFail && onQuestionUpdateFail();
      toast.error("Edited fail");
      this.setState({
        isBgLoading: false,
      });
    }
  };

  render() {
    const { initialValues, isBgLoading, permission, onBack } = this.props;
    return (
      <ActQuestionContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Forms.ActivityQuestionform
          isEdit
          initialValues={initialValues}
          permission={permission}
          onBack={onBack}
          onSubmit={this.handleSubmit}
        />
      </ActQuestionContainerStyled>
    );
  }
}

export default withRouter(ActQuestionContainer);
