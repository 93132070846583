import React from "react";
import cx from "classnames";
import { MdEdit } from "react-icons/md";
// import PropTypes from 'prop-types'
import { StatusLabelStyled } from "./styled";

const StatusLabel = ({ theme_standard, bg, label }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <StatusLabelStyled bg={bg}>
      <div className={customClass}>
        {label && (
          <div className="status_label_container">
            {label}
            <MdEdit />
          </div>
        )}
      </div>
    </StatusLabelStyled>
  );
};

StatusLabel.propTypes = {};

export default StatusLabel;
