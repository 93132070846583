import React from "react";
import { AnswerFormStyled } from "./styled";
import { Controller, useFieldArray } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import { AlertControl } from "components/Modals/Alert";
import { Inputs } from "components";

const AnswerForm = ({ nestIndex, nestIndexLv2, control, errors }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `question_list.${nestIndex}.questions.${nestIndexLv2}.answer_list`,
  });

  return (
    <AnswerFormStyled>
      {fields.map((item, index) => {
        return (
          <div key={item.id} className="answer_row">
            <Controller
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  // placeholder={t('test')}
                  // errors={errors.title?.message}
                />
              )}
              // name={`question_list.${nestIndex}.answer_list[${index}]`}
              name={`question_list.${nestIndex}.questions.${nestIndexLv2}.answer_list[${index}]`}
              control={control}
            />
            <div
              className="btn_delete"
              onClick={() => {
                AlertControl.show({
                  title: "ยืนยันการลบ",
                  description: "คุณต้องการจะลบใช่หรือไม่",
                  btnLabel1: "ยกเลิก ",
                  btnLabel2: "ยืนยัน",
                  onEvent2: async () => {
                    remove(index);
                  },
                });
              }}
            >
              <MdDelete fontSize={25} color={"red"} />
            </div>
          </div>
        );
      })}
      <button type="button" className="btn_add_lv3" onClick={() => append()}>
        + Add Choice
      </button>
    </AnswerFormStyled>
  );
};

AnswerForm.propTypes = {};

export default AnswerForm;
