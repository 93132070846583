import React, { useEffect } from "react";
import { PDPAFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const PDPAForm = ({
  initialValues,
  permission,
  onCancel,
  onDelete,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    release_date: yup.string().when("type", {
      is: (value) => value === "1",
      then: (schema) => schema.required("Public date  is required"),
    }),
    type: yup.string().required("PDPA type is required"),
    pdpa_code: yup.string().required("PDPA code is required"),
    privacy_title: yup.string().required("Head is required"),
    privacy_body: yup.string().required("Body is required"),
    privacy_priority_type: yup.string().when("type", {
      is: (value) => value === "1",
      then: (schema) => schema.required("Condition Type is required"),
    }),
    product_warranty_title: yup.string().when("type", {
      is: (value) => value === "1",
      then: (schema) => schema.required("Head is required"),
    }),
    product_warranty_body: yup.string().when("type", {
      is: (value) => value === "1",
      then: (schema) => schema.required("Body is required"),
    }),
    product_warranty_priority_type: yup.string().when("type", {
      is: (value) => value === "1",
      then: (schema) => schema.required("Condition Type is required"),
    }),
    certified_true_info_title: yup.string().when("type", {
      is: (value) => value === "1",
      then: (schema) => schema.required("Head is required"),
    }),
    certified_true_info_body: yup.string().when("type", {
      is: (value) => value === "1",
      then: (schema) => schema.required("Body is required"),
    }),
    certified_true_info_priority_type: yup.string().when("type", {
      is: (value) => value === "1",
      then: (schema) => schema.required("Condition Type is required"),
    }),
    marketing_title: yup.string().when("type", {
      is: (value) => value === "1",
      then: (schema) => schema.required("Head is required"),
    }),
    marketing_body: yup.string().when("type", {
      is: (value) => value === "1",
      then: (schema) => schema.required("Body is required"),
    }),
    marketing_priority_type: yup.string().when("type", {
      is: (value) => value === "1",
      then: (schema) => schema.required("Condition Type is required"),
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const watchField = watch();

  return (
    <PDPAFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_row">
          <div className="f_left_col">Create Date</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  disabled={true}
                  errors={errors.created_at?.message}
                  showYearDropdown
                />
              )}
              name="created_at"
              defaultValue={new Date()}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Type</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={[
                    {
                      label: "Application",
                      value: "1",
                    },
                    {
                      label: "Activity Form",
                      value: "2",
                    },
                  ]}
                  errors={errors.type?.message}
                />
              )}
              name="type"
              defaultValue={new Date()}
            />
          </div>
        </div>
        {watchField && watchField.type === "1" && (
          <div className="input_row">
            <div className="f_left_col">Public Date</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.DateTime
                    {...field}
                    placeholder={"Start Date"}
                    errors={errors.release_date?.message}
                    showYearDropdown
                  />
                )}
                name="release_date"
                // defaultValue={new Date()}
              />
            </div>
          </div>
        )}
        <div className="input_row">
          <div className="f_left_col">PDPA Code</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  errors={errors.pdpa_code?.message}
                />
              )}
              name="pdpa_code"
              defaultValue=""
            />
          </div>
        </div>
        {watchField && watchField.type === "1" && (
          <div className="input_row">
            <div className="f_left_col">Active</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputSwitch
                    {...field}
                    errors={errors.is_active?.message}
                  />
                )}
                name="is_active"
                defaultValue={false}
              />
            </div>
          </div>
        )}
        <div className="input_group_container">
          {watchField && watchField.type === "1" && (
            <div className="input_group_title">General Privacy</div>
          )}
          <div className="input_row">
            <div className="f_left_col">Head</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    errors={errors.privacy_title?.message}
                  />
                )}
                name="privacy_title"
                defaultValue=""
              />
            </div>
          </div>
          <div className="input_row">
            <div className="f_left_col">Body</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputWysiwyg
                    {...field}
                    errors={errors.privacy_body?.message}
                  />
                )}
                name="privacy_body"
                defaultValue=""
              />
            </div>
          </div>
          {watchField && watchField.type === "2" && (
            <>
              <div className="input_row">
                <div className="f_left_col">Button Accept Label</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextField
                        {...field}
                        errors={errors.privacy_accept_label?.message}
                      />
                    )}
                    name="privacy_accept_label"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="input_row">
                <div className="f_left_col">Button Decline Label</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextField
                        {...field}
                        errors={errors.privacy_decline_label?.message}
                      />
                    )}
                    name="privacy_decline_label"
                    defaultValue=""
                  />
                </div>
              </div>
            </>
          )}
          {watchField && watchField.type === "1" && (
            <>
              <div className="input_row">
                <div className="f_left_col">Waning message</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextField
                        {...field}
                        errors={errors.privacy_warning_msg?.message}
                      />
                    )}
                    name="privacy_warning_msg"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="input_row">
                <div className="f_left_col">Condition Type</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Dropdown
                        {...field}
                        options={[
                          {
                            value: "1",
                            label: "Accept only",
                          },
                          {
                            value: "2",
                            label: "Optional",
                          },
                        ]}
                        errors={errors.privacy_priority_type?.message}
                      />
                    )}
                    name="privacy_priority_type"
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {watchField && watchField.type === "1" && (
          <>
            <div className="input_group_container">
              <div className="input_group_title">Product warranty</div>
              <div className="input_row">
                <div className="f_left_col">Head</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextField
                        {...field}
                        errors={errors.product_warranty_title?.message}
                      />
                    )}
                    name="product_warranty_title"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="input_row">
                <div className="f_left_col">Body</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputWysiwyg
                        {...field}
                        errors={errors.product_warranty_body?.message}
                      />
                    )}
                    name="product_warranty_body"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="input_row">
                <div className="f_left_col">Waning message</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextField
                        {...field}
                        errors={errors.product_warranty_warning_msg?.message}
                      />
                    )}
                    name="product_warranty_warning_msg"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="input_row">
                <div className="f_left_col">Condition Type</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Dropdown
                        {...field}
                        options={[
                          {
                            value: "1",
                            label: "Accept only",
                          },
                          {
                            value: "2",
                            label: "Optional",
                          },
                        ]}
                        errors={errors.product_warranty_priority_type?.message}
                      />
                    )}
                    name="product_warranty_priority_type"
                  />
                </div>
              </div>
            </div>
            <div className="input_group_container">
              <div className="input_group_title">
                Certified true information
              </div>
              <div className="input_row">
                <div className="f_left_col">Head</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextField
                        {...field}
                        errors={errors.certified_true_info_title?.message}
                      />
                    )}
                    name="certified_true_info_title"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="input_row">
                <div className="f_left_col">Body</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputWysiwyg
                        {...field}
                        errors={errors.certified_true_info_body?.message}
                      />
                    )}
                    name="certified_true_info_body"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="input_row">
                <div className="f_left_col">Waning message</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextField
                        {...field}
                        errors={errors.certified_true_info_warning_msg?.message}
                      />
                    )}
                    name="certified_true_info_warning_msg"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="input_row">
                <div className="f_left_col">Condition Type</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Dropdown
                        {...field}
                        options={[
                          {
                            value: "1",
                            label: "Accept only",
                          },
                          {
                            value: "2",
                            label: "Optional",
                          },
                        ]}
                        errors={
                          errors.certified_true_info_priority_type?.message
                        }
                      />
                    )}
                    name="certified_true_info_priority_type"
                  />
                </div>
              </div>
            </div>
            <div className="input_group_container">
              <div className="input_group_title">Marketing</div>
              <div className="input_row">
                <div className="f_left_col">Head</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextField
                        {...field}
                        errors={errors.marketing_title?.message}
                      />
                    )}
                    name="marketing_title"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="input_row">
                <div className="f_left_col">Body</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputWysiwyg
                        {...field}
                        errors={errors.marketing_body?.message}
                      />
                    )}
                    name="marketing_body"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="input_row">
                <div className="f_left_col">Waning message</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextField
                        {...field}
                        errors={errors.marketing_warning_msg?.message}
                      />
                    )}
                    name="marketing_warning_msg"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="input_row">
                <div className="f_left_col">Condition Type</div>
                <div className="f_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Dropdown
                        {...field}
                        options={[
                          {
                            value: "1",
                            label: "Accept only",
                          },
                          {
                            value: "2",
                            label: "Optional",
                          },
                        ]}
                        errors={errors.marketing_priority_type?.message}
                      />
                    )}
                    name="marketing_priority_type"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="action_row">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
          {permission && (
            <>
              {initialValues && permission.is_delete && (
                <div className="w120">
                  <Buttons.BgStandard
                    theme_red
                    label="Delete"
                    disabled={initialValues && initialValues.is_center}
                    onClick={onDelete}
                  />
                </div>
              )}
              {initialValues && permission.is_update ? (
                <div className="w120">
                  <Buttons.BgStandard
                    type="submit"
                    label="Update"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              ) : (
                permission.is_create && (
                  <div className="w120">
                    <Buttons.BgStandard
                      type="submit"
                      label="Save"
                      // disabled={initialValues && initialValues.is_center}
                    />
                  </div>
                )
              )}
            </>
          )}
        </div>
      </form>
    </PDPAFormStyled>
  );
};

PDPAForm.propTypes = {};

export default PDPAForm;
