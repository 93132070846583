export const CUSTOMER_TYPE = [
  {
    label: "Customer",
    value: "1",
  },
  {
    label: "Visitor",
    value: "2",
  },
];

export const RENDER_CUSTOMER_TYPE = (key) => {
  switch (key) {
    case "1":
      return {
        label: "Customer",
      };
    case "2":
      return {
        label: "Visitor",
      };
    default:
      return {
        label: "",
      };
  }
};

export const RACE = [
  {
    label: "Thai",
    value: "1",
  },
  {
    label: "Foreigner",
    value: "2",
  },
];

export const RENDER_RACE = (key) => {
  switch (key) {
    case "1":
      return {
        label: "Thai",
      };
    case "2":
      return {
        label: "Foreigner",
      };
    default:
      return {
        label: "",
      };
  }
};

export const BOOKING_METHOD = [
  {
    label: "Walk in - member",
    value: "1",
  },
  {
    label: "Walk in - apply member",
    value: "2",
  },
  {
    label: "Walk in - no apply",
    value: "3",
  },
];

export const RENDER_BOOKIN_METHOD = (key) => {
  switch (key) {
    case "1":
      return {
        label: "Walk in - member",
      };
    case "2":
      return {
        label: "Walk in - apply member",
      };
    case "3":
      return {
        label: "Walk in - no apply",
      };
    default:
      return {
        label: "",
      };
  }
};

export const ENTRY_TYPE = [
  {
    label: "Walk in",
    value: "1",
  },
  {
    label: "Dealers",
    value: "2",
  },
];

export const RENDER_ENTRY_TYPE = (key) => {
  switch (key) {
    case "1":
      return {
        label: "Walk in",
      };
    case "2":
      return {
        label: "Dealers",
      };
    default:
      return {
        label: "",
      };
  }
};

export const PURPOSE_OPTION = [
  {
    label: "Education",
    value: "1",
  },
  {
    label: "Hobby",
    value: "2",
  },
  {
    label: "Business",
    value: "3",
  },
  {
    label: "Work",
    value: "4",
  },
];

export const RENDER_PURPOSE_OPTION = (key) => {
  switch (key) {
    case "1":
      return {
        label: "Education",
      };
    case "2":
      return {
        label: "Hobby",
      };
    case "3":
      return {
        label: "Business",
      };
    case "4":
      return {
        label: "Work",
      };
    default:
      return {
        label: "",
      };
  }
};

export const RENDER_TIME_OPTION = (startTime, endTime) => {
  const value = `${startTime},${endTime}`;
  const option = TIME_OPTION.find((item) => item.value === value);
  return option ? option.label : "";
};

export const TIME_OPTION = [
  { label: "10.00 - 11.00", value: "10.00,11.00" },
  { label: "11.00 - 12.00", value: "11.00,12.00" },
  { label: "13.00 - 14.00", value: "13.00,14.00" },
  { label: "14.00 - 15.00", value: "14.00,15.00" },
  { label: "15.00 - 16.00", value: "15.00,16.00" },
  { label: "16.00 - 17.00", value: "16.00,17.00" },
];

export const AGE_RANGE_OPTION = [
  {
    label: "10 - 20",
    value: "1",
  },
  {
    label: "21 - 30",
    value: "2",
  },
  {
    label: "31 - 40",
    value: "3",
  },
  {
    label: "41 - 50",
    value: "4",
  },
  {
    label: "51 - 60",
    value: "5",
  },
  {
    label: "60 and more",
    value: "6",
  },
];

export const RENDER_AGE_RANGE_OPTION = (key) => {
  switch (key) {
    case "1":
      return {
        label: "10 - 20",
      };
    case "2":
      return {
        label: "21 - 30",
      };
    case "3":
      return {
        label: "31 - 40",
      };
    case "4":
      return {
        label: "41 - 50",
      };
    case "5":
      return {
        label: "51 - 60",
      };
    case "6":
      return {
        label: "60 and more",
      };
    default:
      return {
        label: "",
      };
  }
};
