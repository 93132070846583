import styled from "styled-components";

export const ActivityParticipateStyled = styled.div`
  border-radius: 1rem;
  padding: 1.25rem 1rem;
  width: 100%;
  max-width: 80vw;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .ip_row {
    margin-bottom: 1.75rem;
    max-width: 540px;
    display: flex;
    column-gap: 1rem;
    .ip_btn_wrap {
      width: 200px;
    }
  }
  .ip_table_sec {
    margin-bottom: 1rem;
  }
`;
