import styled from "styled-components";

export const DateTimeStyled = styled.div`
  .label {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .date_container {
    border-radius: 1rem;
    border: 0.0625rem solid ${({ theme }) => theme.COLORS.GRAY_1};
    padding: 0.3125rem 1.25rem;
    width: 100%;
    min-width: 200px;
    height: 44px;
    background: transparent;
    text-align: left;
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    &.disabled {
      background: ${({ theme }) => theme.COLORS.GRAY_1};
    }
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }

  .theme_filter {
    .label {
      color: ${({ theme }) => theme.COLORS.BLUE_2};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    }
  }
`;
