import styled from "styled-components";

export const ActivityQuestionformStyled = styled.div`
  .input_wrap {
    margin-bottom: 8px;
    .f_question_img {
      margin-bottom: 1rem;
      width: 100%;
      height: auto;
    }
  }
  .passed_label {
    margin-bottom: 0.5rem;
    text-align: center;
  }
  .action_row {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
  }
`;
