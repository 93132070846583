import styled from "styled-components";

export const BookingFormStyled = styled.div`
  padding: 20px;
  .row_contain {
    display: flex;
    flex-direction: row;
    width: 100%;
    .row_right_contain {
      width: 100%;
      display: flex;
      .right_col {
        width: 50%;
        .title {
          font-size: 22px;
          margin-bottom: 20px;
        }
        .row_detail {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;
          align-items: center;
          .left_detail {
            width: 40%;
          }
          .right_detail {
            flex: 1;
          }
          .right_detail2 {
            display: flex;
            flex: 1;
            flex-direction: row;
            column-gap: 10px;
            align-items: center;
          }
        }
        .linear {
          height: 2px;
          width: 100%;
          background-color: gray;
          margin-bottom: 10px;
          border: none;
        }
      }
    }
  }
  .action_row {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
  }
`;
