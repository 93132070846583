export const RENDER_STATUS_PRODUCT_WARRANTY_SET = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'ready to link',
        bg: '#4b1e78',
      };
    case '2':
      return {
        label: 'linked',
        bg: '#008000',
      };
    default:
      break;
  }
};
