import React, { useState, forwardRef, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { DateTimeStyled } from "./styled";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import moment from 'moment';

const DateTime = ({
  theme_standard,
  theme_filter,
  errors,
  value,
  onChange,
  label,
  showYearDropdown,
  dateFormat = "dd/MM/yyyy",
  showMonthYearPicker,
  showYearPicker,
  disabled,
  placeholder,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_filter: theme_filter,
  });

  const [startDate, setStartDate] = useState();

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className={`date_container ${disabled ? "disabled" : ""}`}
      onClick={onClick}
      ref={ref}
    >
      {value || placeholder}
    </button>
  ));

  useEffect(() => {
    if (value) {
      setStartDate(new Date(value));
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleChange = (e) => {
    setStartDate(e);
    onChange(e);
  };

  return (
    <DateTimeStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <DatePicker
          {...props}
          disabled={disabled}
          dateFormat={dateFormat}
          showMonthYearPicker={showMonthYearPicker}
          showYearPicker={showYearPicker}
          selected={startDate}
          onChange={(date) => _handleChange(date)}
          customInput={<ExampleCustomInput />}
          showYearDropdown={showYearDropdown}
          dropdownMode="select"
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </DateTimeStyled>
  );
};

DateTime.propTypes = {};

export default DateTime;
