import styled from "styled-components";

export const CertificateFormStyled = styled.div`
  border-radius: 1rem;
  padding: 1.5rem 1rem;
  min-width: 500px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .form_section {
    margin-bottom: 60px;
    .form_title {
      margin-bottom: 1.5rem;
      text-align: left;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_SEMIBOLD};
    }
    .input_row {
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      .f_left_col {
        width: 20%;
        display: flex;
        align-items: center;
      }
      .f_right_col {
        width: 40%;
        .dl_simple_btn {
          width: 156px;
        }
      }
    }
    .input_wrap {
      margin-bottom: 1rem;
    }

    .certificate_canva {
      margin-bottom: 20px;
      display: ${({ isShowCanva }) => (isShowCanva ? "block" : "none")};
    }
    .btn_download {
      width: 182px;
    }
  }

  .cf_action_row {
    margin-top: 1.5rem;
    display: flex;
    justify-content: right;
    column-gap: 1rem;
    .cf_action_item {
      width: 100%;
      max-width: 160px;
    }
  }
`;
