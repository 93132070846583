import React, { useEffect } from "react";
import { ActivityFormStyled } from "./styled";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MdDelete } from "react-icons/md";
import AnswerForm from "./AnswerForm";
import { Buttons, Cards, Inputs } from "components";
import { toast } from "react-toastify";

const ActivityForm = ({
  initialValues,
  permission,
  onCancel,
  onDelete,
  onDeleteQuestion,
  onSent,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    type: yup.string().required("Activity type is required"),
    // condition_type: yup.string().required("Condition type is required"),
    // gift_type: yup.string().required("Gift type is required"),
    code: yup.string().required("Activity Code is required"),
    name: yup.string().required("Activity name is required"),
    description: yup.string().required("Description is required"),
    // point: yup.string().required("Point name is required"),
    // permission_type: yup.string().required("Required"),
    // last_date: yup.string().required("Required"),
    // announcement_date: yup.string().required("Required"),
    // qrcode_amount: yup.string().required('Qrcode amount is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "question_list",
  });

  const watchType = watch("type");
  const watchConditionType = watch("condition_type");
  const watchUseQRcodeSpecial = watch("is_used_qrcode_special");
  const watchPermissionType = watch("permission_type");
  const watchIsCondition = watch("is_condition");
  const watchAnnouncementType = watch("announcement_type");
  const watchQuestionList = watch("question_list");

  useEffect(() => {
    if (initialValues) {
      let params = {
        ...initialValues,
        start_date:
          initialValues.start_date && new Date(initialValues.start_date),
        end_date: initialValues.end_date && new Date(initialValues.end_date),
        img_file: initialValues.img ? [{ media_uri: initialValues.img }] : [],
        img_file2: initialValues.img2
          ? [{ media_uri: initialValues.img2 }]
          : [],
      };
      if (initialValues.question_list) {
        params.question_list = initialValues.question_list.map((e) => ({
          ...e,
          image: e.img_path ? [{ media_uri: e.img_path }] : [],
        }));
      }
      reset({
        ...params,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const _onSubmit = (values) => {
    let isError = false;
    if (values.type !== "3") {
      if (!values.permission_type) {
        isError = true;
        setError("permission_type", {
          type: "custom",
          message: "Permission type is required",
        });
      }
      if (!values.point) {
        isError = true;
        setError("point", {
          type: "custom",
          message: "Point is required",
        });
      }
      if (!values.condition_type) {
        isError = true;
        setError("condition_type", {
          type: "custom",
          message: "Condition type is required",
        });
      }
      if (!values.gift_type) {
        isError = true;
        setError("gift_type", {
          type: "custom",
          message: "Gift type is required",
        });
      }
    }
    if (values.type === "1" && watchConditionType !== "2") {
      if (!values.qrcode_amount) {
        isError = true;
        setError("qrcode_amount", {
          type: "custom",
          message: "Qrcode amount is required",
        });
      }
      if (!values.qrcode_passed_amount) {
        isError = true;
        setError("qrcode_passed_amount", {
          type: "custom",
          message: "Qrcode passed amount is required",
        });
      }
      if (values.is_used_qrcode_special && !values.qrcode_special_point) {
        isError = true;
        setError("qrcode_special_point", {
          type: "custom",
          message: "Qrcode special point is required",
        });
      }
    }
    if (values.permission_type === "2") {
      if (!values.permission_main_quotar) {
        isError = true;
        setError("permission_main_quotar", {
          type: "custom",
          message: "Required",
        });
      }
      if (
        values.announcement_type === "2" &&
        !values.permission_reserve_quotar
      ) {
        isError = true;
        setError("permission_reserve_quotar", {
          type: "custom",
          message: "Required",
        });
      }
      if (!values.announcement_type) {
        isError = true;
        setError("announcement_type", {
          type: "custom",
          message: "Required",
        });
      }
      if (values.announcement_type === "2" && !values.announcement_date) {
        isError = true;
        setError("announcement_date", {
          type: "custom",
          message: "Required",
        });
      }
      if (!values.last_date) {
        isError = true;
        setError("last_date", {
          type: "custom",
          message: "Required",
        });
      }
    }
    if (!isError) {
      onSubmit(values);
    } else {
      toast.error("กรุณากรอกเงื่อนไขให้ครบ");
    }
  };

  return (
    <ActivityFormStyled>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="input_row">
          <div className="f_left_col">Activity Type</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={[
                    {
                      value: "1",
                      label: "Online",
                    },
                    {
                      value: "2",
                      label: "Offline",
                    },
                    {
                      value: "3",
                      label: "ฟอร์มทั่วไป",
                    },
                  ]}
                  errors={errors.type?.message}
                  disabled={initialValues}
                />
              )}
              name="type"
              // defaultValue={{ label: '1', value: '1' }}
            />
          </div>
        </div>
        {watchType !== "3" && (
          <div className="input_row">
            <div className="f_left_col">Condition Type</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={[
                      {
                        value: "1",
                        label: "ทำตามเงื่อนไข scan qrcode",
                      },
                      {
                        value: "2",
                        label: "ลงทะเบียนได้รับรางวัลทันที",
                      },
                    ]}
                    errors={errors.condition_type?.message}
                    // disabled={initialValues}
                  />
                )}
                name="condition_type"
                // defaultValue={{ label: '1', value: '1' }}
              />
            </div>
          </div>
        )}
        {watchType !== "3" && (
          <div className="input_row">
            <div className="f_left_col">Gift Type</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={[
                      {
                        value: "1",
                        label: "คะแนน",
                      },
                      {
                        value: "2",
                        label: "เหรียญเล่นเกมส์",
                      },
                    ]}
                    errors={errors.gift_type?.message}
                    // disabled={initialValues}
                  />
                )}
                name="gift_type"
                // defaultValue={{ label: '1', value: '1' }}
              />
            </div>
          </div>
        )}
        <div className="input_row">
          <div className="f_left_col">Code</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField {...field} errors={errors.code?.message} />
              )}
              name="code"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Name</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.name?.message}
                />
              )}
              name="name"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Description</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputWysiwyg
                  {...field}
                  errors={errors.description?.message}
                />
              )}
              name="description"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Start Date</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  placeholder={"Start Date"}
                  errors={errors.start_date?.message}
                  showYearDropdown
                />
              )}
              name="start_date"
              defaultValue={new Date()}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">End Date</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  placeholder={"End Date"}
                  errors={errors.end_date?.message}
                  showYearDropdown
                />
              )}
              name="end_date"
              defaultValue={new Date()}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Picture</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  {...field}
                  theme_upload_image
                  isDelete
                  fieldName="img"
                  acceptType={"cover"}
                  scaleLabel="*** รูปขนาดกว้าง 720px สูง 720px"
                  errors={errors.img_file?.message}
                />
              )}
              name="img_file"
              defaultValue={[]}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Picture2</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  {...field}
                  isDelete
                  fieldName="img2"
                  theme_upload_image
                  acceptType={"cover"}
                  scaleLabel="*** รูปขนาดกว้าง 720px สูง 720px"
                  errors={errors.img_file?.message}
                />
              )}
              name="img_file2"
              defaultValue={[]}
            />
          </div>
        </div>
        {watchType !== "3" && (
          <div className="input_row">
            <div className="f_left_col">Point</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    type="number"
                    // placeholder={t('test')}
                    errors={errors.point?.message}
                  />
                )}
                name="point"
                defaultValue=""
              />
            </div>
          </div>
        )}
        {watchType === "1" && watchConditionType !== "2" && (
          <>
            <div className="input_row">
              <div className="f_left_col">QRCode Amount</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      type="number"
                      min={
                        !initialValues ? 0 : initialValues.qrcode_list.length
                      }
                      // placeholder={t('test')}
                      errors={errors.qrcode_amount?.message}
                    />
                  )}
                  name="qrcode_amount"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">QRCode Passed</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      type="number"
                      // placeholder={t('test')}
                      errors={errors.qrcode_passed_amount?.message}
                    />
                  )}
                  name="qrcode_passed_amount"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Use special point</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputSwitch
                      {...field}
                      errors={errors.is_used_qrcode_special?.message}
                    />
                  )}
                  name="is_used_qrcode_special"
                  defaultValue={false}
                />
              </div>
            </div>
          </>
        )}
        {watchUseQRcodeSpecial && (
          <>
            <div className="input_row">
              <div className="f_left_col">QRCode special point</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      type="number"
                      // placeholder={t('test')}
                      errors={errors.qrcode_special_point?.message}
                    />
                  )}
                  name="qrcode_special_point"
                  defaultValue=""
                />
              </div>
            </div>
          </>
        )}
        {watchType !== "3" && (
          <div className="input_row">
            <div className="f_left_col">Number of participate</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={[
                      {
                        value: "1",
                        label: "ไม่จำกัดสิทธิ์",
                      },
                      {
                        value: "2",
                        label: "จำกัดสิทธิ์",
                      },
                    ]}
                    errors={errors.permission_type?.message}
                    disabled={initialValues}
                  />
                )}
                name="permission_type"
                // defaultValue={{ label: '1', value: '1' }}
              />
            </div>
          </div>
        )}
        {watchPermissionType && watchPermissionType === "2" && (
          <>
            <div className="input_row">
              <div className="f_left_col">Announcement type</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={[
                        {
                          label: "Run by Que",
                          value: "1",
                        },
                        {
                          label: "Select by Admin",
                          value: "2",
                        },
                      ]}
                      errors={errors.announcement_type?.message}
                      disabled={
                        initialValues &&
                        (initialValues.status === "2" ||
                          initialValues.status === "3")
                      }
                    />
                  )}
                  name="announcement_type"
                  // defaultValue={{ label: '1', value: '1' }}
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Fix</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      type="number"
                      min={
                        initialValues ? initialValues.permission_main_active : 1
                      }
                      // max={50}
                      // placeholder={t('test')}
                      errors={errors.permission_main_quotar?.message}
                    />
                  )}
                  name="permission_main_quotar"
                  defaultValue=""
                />
              </div>
            </div>
            {watchAnnouncementType === "2" && (
              <>
                <div className="input_row">
                  <div className="f_left_col">Sub</div>
                  <div className="f_right_col">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.TextField
                          {...field}
                          type="number"
                          min={
                            initialValues
                              ? initialValues.permission_reserve_active
                              : 1
                          }
                          // max={10}
                          // placeholder={t('test')}
                          errors={errors.permission_reserve_quotar?.message}
                        />
                      )}
                      name="permission_reserve_quotar"
                      defaultValue=""
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <div className="input_row">
          <div className="f_left_col">Last date register</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  placeholder={"Last date"}
                  errors={errors.last_date?.message}
                  showYearDropdown
                />
              )}
              name="last_date"
              // defaultValue={new Date()}
            />
          </div>
        </div>
        {watchAnnouncementType === "2" && (
          <div className="input_row">
            <div className="f_left_col">Announcement date</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.DateTime
                    {...field}
                    placeholder={"Announcement date"}
                    errors={errors.announcement_date?.message}
                    showYearDropdown
                  />
                )}
                name="announcement_date"
                // defaultValue={new Date()}
              />
            </div>
          </div>
        )}
        <div className="input_row">
          <div className="f_left_col">One time popup</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  disabled={true}
                  errors={errors.is_popup_activate?.message}
                />
              )}
              name="is_popup_activate"
              // defaultValue={new Date()}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">One time Notification</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  disabled={true}
                  errors={errors.is_notification_activate?.message}
                />
              )}
              name="is_notification_activate"
              defaultValue={false}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Question status</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  errors={errors.is_condition?.message}
                />
              )}
              name="is_condition"
              defaultValue={false}
            />
          </div>
        </div>
        {watchIsCondition === true && (
          <>
            {fields.map((item, index) => (
              <div key={item.id} className="question_block">
                <div className="question_row">
                  <div className="question_left_col">
                    <div className="question_input_item">
                      <Controller
                        render={({ field }) => (
                          <Inputs.InputWysiwyg
                            {...field}
                            label={`คำถามข้อที่ ${index + 1}`}
                          />
                        )}
                        name={`question_list.${index}.question`}
                        control={control}
                      />
                    </div>
                    <div className="question_upload">
                      <Controller
                        control={control}
                        render={({ field }) => (
                          <Inputs.UploadImage
                            {...field}
                            theme_upload_image
                            fieldName="question"
                            acceptType={"cover"}
                            label={"สามารถใส่รูปภาพประกอบได้"}
                            // scaleLabel="*** รูปขนาดกว้าง 720px สูง 720px"
                            // errors={errors.img_file?.message}
                          />
                        )}
                        name={`question_list.${index}.image`}
                        defaultValue={[]}
                      />
                    </div>
                  </div>
                  <div
                    className="btn_delete"
                    onClick={() => {
                      onDeleteQuestion && onDeleteQuestion(item);
                      remove(index);
                    }}
                  >
                    <MdDelete fontSize={25} color={"red"} />
                  </div>
                </div>
                <div className="question_type_wrap">
                  <Controller
                    render={({ field }) => (
                      <Inputs.Dropdown
                        {...field}
                        options={[
                          {
                            label: "Choice",
                            value: "1",
                          },
                          {
                            label: "Freetext",
                            value: "2",
                          },
                          {
                            label: "Upload",
                            value: "3",
                          },
                        ]}
                        errors={errors.type?.message}
                      />
                    )}
                    name={`question_list.${index}.type`}
                    control={control}
                  />
                </div>
                {watchQuestionList &&
                  watchQuestionList[index] &&
                  watchQuestionList[index].type === "1" && (
                    <div className="answer_block">
                      <AnswerForm nestIndex={index} {...{ control, errors }} />
                    </div>
                  )}
              </div>
            ))}
            <button type="button" className="btn_add" onClick={() => append()}>
              เพิ่มคำถาม
            </button>
          </>
        )}
        <div className="input_row">
          <div className="f_left_col">Active</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  errors={errors.is_active?.message}
                />
              )}
              name="is_active"
              defaultValue={false}
            />
          </div>
        </div>
        {initialValues && initialValues.qrcode_list.length > 0 && (
          <div className="qr_code_block">
            <div className="f_title">QRCode normal</div>
            <div className="qrcode_row">
              {initialValues.qrcode_list.map((e, i) => (
                <div key={i}>
                  <Cards.QrCodeItem code={e} />
                </div>
              ))}
            </div>
          </div>
        )}
        {initialValues && initialValues.is_used_qrcode_special && (
          <div className="qr_code_block">
            <div className="f_title">QRCode special</div>
            <div className="qrcode_row">
              <Cards.QrCodeItem code={initialValues.qrcode_special} />
            </div>
          </div>
        )}
        {permission && (
          <div className="action_row">
            <div className="w120">
              <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
            </div>
            {initialValues && permission.is_delete && (
              <div className="w120">
                <Buttons.BgStandard
                  theme_red
                  label="Delete"
                  onClick={onDelete}
                />
              </div>
            )}
            {initialValues && permission.is_update ? (
              <>
                {!initialValues.is_popup_activate && (
                  <div className="w120">
                    <Buttons.BgStandard
                      theme_green
                      label="Sent popup"
                      // disabled={initialValues && initialValues.is_center}
                      onClick={onSent}
                    />
                  </div>
                )}
                <div className="w120">
                  <Buttons.BgStandard
                    type="submit"
                    label="Update"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              </>
            ) : (
              permission.is_create && (
                <div className="w120">
                  <Buttons.BgStandard
                    type="submit"
                    label="Save"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              )
            )}
          </div>
        )}
      </form>
    </ActivityFormStyled>
  );
};

ActivityForm.propTypes = {};

export default ActivityForm;
