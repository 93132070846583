import React from "react";
import { connect } from "react-redux";
import { ProductWarranteeContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import momentTZ from "moment-timezone";
import moment from "moment";
import queryString from "query-string";
import { portalService } from "apiServices";
import {
  CHECK_WARRANTY,
  RENDER_WARRANTY_STATUS,
  RENDER_STATUS_PRODUCT_WARRANTY,
} from "utils/functions/productWarranty";
import { RENDER_UNIT } from "utils/functions/unit";
import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from "utils/functions/string";
import { RENDER_GENDER_TYPE } from "utils/functions/customer";
import * as XLSX from "xlsx";
import { Inputs, Displays, Tables } from "components";

class ProductWarranteeContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    perPage: 10,
    productDepOptions: [],
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    const query = this.props.router.location.search || `?limit=${perPage}`;
    this.fetchData(query);
    this.fetchOptions();
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_PRODUCT_WARRANTEE_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        isLoading: false,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
      });
    }
  };

  fetchOptions = async () => {
    let res = await portalService.GET_PRODUCT_DEP_LIST(`?pagination=false`);
    if (res && res.status === 200) {
      this.setState({
        productDepOptions: res.data.docs.map((e) => ({
          label: e.code,
          value: e.code,
        })),
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleClickCreate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.PRODUCT_WARRANTY}/${e}`);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoading: true,
    });
    this.props.router.navigate(`${ROUTE_PATH.PRODUCT_WARRANTY}${joinQuery}`);
    this.fetchData(joinQuery);
  };

  handleExport = async (permission) => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false`
      : `?pagination=false`;
    // let joinQuery = `?pagination=false&status=2`;
    let res = await portalService.GET_PRODUCT_WARRANTEE_LIST(joinQuery);
    if (res && res.status === 200) {
      let wb = XLSX.utils.book_new();
      let ws_name = "SheetJS";
      /* make worksheet */
      let ws_data = res.data.docs.reduce((result, e, i) => {
        let renderStatus = RENDER_STATUS_PRODUCT_WARRANTY(e.status);
        const renderWarranty = RENDER_WARRANTY_STATUS(
          e.status,
          CHECK_WARRANTY(
            e.approved_warranty_date,
            e.warranty_period,
            e.warranty_unit,
            e.extension_period,
            e.extension_unit
          )
        );
        result.push([
          i + 1,
          momentTZ(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY"),
          e.serial_no,
          e.item_no,
          `${e.description} ${
            !e.description_line_2 || e.description_line_2 === null
              ? ""
              : e.description_line_2
          }`,
          e.dealer_code,
          e.dealer_name,
          e.invoice_no,
          e.invoice_type,
          momentTZ(e.invoice_date).tz("Asia/Bangkok").format("DD/MM/YYYY"),
          !e.registed_at
            ? ""
            : momentTZ(e.registed_at).tz("Asia/Bangkok").format("DD/MM/YYYY"),
          renderStatus?.label,
          renderWarranty?.label,
          !e.warranty_period
            ? "-"
            : e.warranty_period + " " + RENDER_UNIT(e.warranty_unit)?.labelTH,
          !e.extension_period
            ? "-"
            : e.extension_period + " " + RENDER_UNIT(e.extension_unit)?.labelTH,
          e.warranty_description_th,
          !e.first_name
            ? ""
            : RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                ...permission,
                inputString: e.first_name,
                action: "export",
              }) +
              " " +
              RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                ...permission,
                inputString: e.last_name,
                action: "export",
              }),
          !e.phone_number
            ? ""
            : RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                ...permission,
                inputString: e.phone_number,
                type: "phone",
                action: "export",
              }),
          !e.gender ? "" : RENDER_GENDER_TYPE(e.gender)?.labelTh,
          !e.province ? "" : e.province,
          e.pd_dep_code,
          e.pd_dep_desc,
          e.pd_cat02_code,
          e.pd_cat02_desc,
          e.pd_cat03_code,
          e.pd_cat03_desc,
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        "No",
        "Create Date",
        "Sn",
        "Item no",
        "Description",
        "Dealer Code",
        "Dealer Name",
        "Invoice no",
        "Invoice type",
        "Invoice date",
        "Registed at",
        "Status",
        "Warranty",
        "Warranty Period",
        "Warranty Extension Period",
        "Condition",
        "Name",
        "Phone number",
        "Gender",
        "Province",
        "PD dep code",
        "PD dep desc",
        "PD cat02 code",
        "PD cat02 desc",
        "PD cat03 code",
        "PD cat03 desc",
      ]);
      let ws = XLSX.utils.aoa_to_sheet(ws_data);
      let wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `product-warrantee-report-${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    }
  };

  render() {
    const { authenRedux, router } = this.props;
    const { isLoading, isBgLoading, pagination, data, productDepOptions } =
      this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "product_warranty");
    return (
      <ProductWarranteeContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Inputs.InputFilter
          theme_standard_input_filter
          dataFilter={dataFilter(router.location?.search, productDepOptions)}
          permission={permission}
          btnLabel="ค้นหา"
          onQueryFilter={this.handleFilterKeyword}
          onExport={
            permission &&
            (permission.is_export || permission.is_export_condition)
              ? () => this.handleExport(permission)
              : false
          }
        />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <>
            <div className="body_table  ">
              <Tables.Standard
                columns={columns({
                  handleClickDetail: this.handleClickCreate,
                })}
                data={data}
                showPagination
                pagination={pagination}
                handlePageClick={this.handlePageClick}
              />
            </div>
          </>
        )}
      </ProductWarranteeContainerStyled>
    );
  }
}

const dataFilter = (query, productDepOptions) => {
  let initialValues = queryString.parse(query);
  return [
    {
      accessor: "serial_no",
      length: "311px",
      filter: {
        fieldQuery: "serial_no",
        defaultValue: initialValues["serial_no"],
        type: "text",
        label: "SN search",
        placeholder: "Serial no",
      },
    },
    {
      accessor: "status",
      length: "311px",
      filter: {
        fieldQuery: "status",
        defaultValue: initialValues["status"],
        type: "dropdown",
        label: "Status",
        placeholder: "",
        options: [
          {
            label: "All",
            value: "",
          },
          {
            label: "Ready",
            value: "1",
          },
          {
            label: "Wait Approve",
            value: "2",
          },
          {
            label: "Approved",
            value: "3",
          },
          // {
          //   label: 'Expired',
          //   value: '4',
          // },
          {
            label: "Rejected",
            value: "5",
          },
        ],
      },
    },
    {
      accessor: "pd_dep_code",
      length: "311px",
      filter: {
        fieldQuery: "pd_dep_code",
        defaultValue: initialValues["pd_dep_code"],
        type: "dropdown",
        label: "Product Dep.",
        placeholder: "",
        options: productDepOptions,
      },
    },
    {
      accessor: "is_member",
      length: "311px",
      filter: {
        fieldQuery: "is_member",
        defaultValue: initialValues["is_member"],
        type: "dropdown",
        label: "ประเภทการลงทะเบียน",
        placeholder: "",
        options: [
          {
            label: "All",
            value: "",
          },
          {
            label: "แบบเป็นสมาชิก",
            value: "true",
          },
          {
            label: "แบบไม่เป็นสมาชิก",
            value: "false",
          },
        ],
      },
    },
    {
      accessor: "created_at",
      length: "311px",
      filter: {
        fieldQuery: "created_at",
        defaultValue:
          initialValues["start_date"] && initialValues["end_date"]
            ? {
                startDate: new Date(initialValues["start_date"]),
                endDate: new Date(initialValues["end_date"]),
              }
            : false,
        type: "dateRank",
        placeholder: "เลือกวันที่",
      },
    },
  ];
};

const columns = ({ handleClickDetail }) => [
  {
    Header: <div style={{ textAlign: "left" }}>Create Date</div>,
    accessor: "created_at",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Sn</div>,
    accessor: "serial_no",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Item no</div>,
    accessor: "item_no",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Description</div>,
    accessor: "description",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}{" "}
        {props.row.original.description_line_2 === null
          ? ""
          : props.row.original.description_line_2}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Dealer Code</div>,
    accessor: "dealer_code",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Dealer Name</div>,
    accessor: "dealer_name",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Invoice date</div>,
    accessor: "invoice_date",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value && `${moment(props.value).format("DD/MM/YYYY")}`}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Registed at</div>,
    accessor: "registed_at",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value && `${moment(props.value).format("DD/MM/YYYY")}`}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "center" }}>Status</div>,
    accessor: "status",
    Cell: (props) => {
      const renderStatus = RENDER_STATUS_PRODUCT_WARRANTY(props.value);
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Displays.StatusLabel
            bg={renderStatus?.bg}
            label={renderStatus?.label}
          />
        </div>
      );
    },
  },
  {
    Header: <div style={{ textAlign: "left" }}>Warranty</div>,
    accessor: "warranty",
    Cell: (props) => {
      const renderStatus = RENDER_WARRANTY_STATUS(
        props.row.original.status,
        CHECK_WARRANTY(
          props.row.original.approved_warranty_date,
          props.row.original.warranty_period,
          props.row.original.warranty_unit,
          props.row.original.extension_period,
          props.row.original.extension_unit
        )
      );
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Displays.StatusLabel
            bg={renderStatus?.bg}
            label={renderStatus?.label}
          />
        </div>
      );
    },
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductWarranteeContainer));
