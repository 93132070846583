import React from "react";
import { connect } from "react-redux";
import { CustomerDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import momentTZ from "moment-timezone";
import moment from "moment";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import {
  ACTIVITY_RENDER_TYPE,
  ACTIVITY_RENDER_STATUS,
} from "utils/functions/activity";
import { RENDER_STATUS_PRODUCT_WARRANTY } from "utils/functions/productWarranty";
import { RENDER_GENDER_TYPE } from "utils/functions/customer";
import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from "utils/functions/string";
import { AlertControl } from "components/Modals/Alert";
import { toast } from "react-toastify";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Displays, Tables, Buttons } from "components";

class CustomerDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
    this.fetchProductWarranteeData();
  }

  fetchData = async () => {
    let resArr = await Promise.all([
      this.fetchCustomerDetail(),
      this.fetchActivityData(),
      this.fetchProductWarranteeData(),
      this.fetchRewardData(),
      this.fetchPDPA(),
    ]);
    if (resArr) {
      let findPDPALatest = resArr[4].find(
        (e) => e.pdpa_ref_id === resArr[0].pdpa_accepted_latest_ref_id
      );
      let resOrganization = !resArr[0].organization_ref_id
        ? false
        : await portalService.GET_ORGANIZATION_DETAIL(
            resArr[0].organization_ref_id
          );
      this.setState({
        data: resArr[0],
        organizationData: !resOrganization
          ? resOrganization
          : resOrganization.data,
        activityHistoryData: resArr[1],
        productWarranteeData: resArr[2],
        rewardHistoryData: resArr[3],
        pdpaLatest: findPDPALatest,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  fetchCustomerDetail = async () => {
    let res = await portalService.GET_CUSTOMER_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };
  fetchProductWarranteeData = async () => {
    let res = await portalService.GET_PRODUCT_WARRANTEE_BY_CUSTOMER_LIST(
      this.props.router.params.id,
      `?pagination=false`
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };
  fetchActivityData = async () => {
    let res = await portalService.GET_ACTIVITY_BY_CUSTOMER_LIST(
      this.props.router.params.id,
      `?pagination=false`
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };
  fetchRewardData = async () => {
    let res = await portalService.GET_REWARD_HISTORY_BY_CUSTOMER_LIST(
      this.props.router.params.id,
      `?pagination=false`
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };
  fetchPDPA = async () => {
    let res = await portalService.GET_PDPA_HISTORY_LIST_BY_CUSTOMER_ID(
      this.props.router.params.id,
      `?pagination=false`
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };

  handleClickBack = (e) => {
    this.props.router.navigate(-1);
  };

  handleClickDelete = async () => {
    const { data } = this.state;
    AlertControl.show({
      title: "ยืนยันการลบ",
      description: "คุณต้องการจะลบใช่หรือไม่",
      btnLabel1: "ยกเลิก ",
      btnLabel2: "ยืนยัน",
      onEvent2: async () => {
        this.setState({
          isBgLoading: true,
        });
        let res = await portalService.DELETE_CUSTOMER_DETAIL(data._id);
        if (res && res.status === 200) {
          toast.success("ลบสำเร็จ");
          this.setState({
            isBgLoading: false,
          });
          this.props.router.navigate(-1);
        } else {
          toast.error("ลบไม่สำเร็จ");
        }
      },
    });
  };

  handleAddOrganization = () => {
    this.props.router.navigate(`${ROUTE_PATH.CORPERATE}`);
  };

  handleClickOrganizationDetail = () => {
    const { organizationData } = this.state;
    this.props.router.navigate(
      `${ROUTE_PATH.CORPERATE}/${organizationData._id}`
    );
  };

  handleClickProductWarrantyDetail = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.PRODUCT_WARRANTY}/${e._id}`);
  };

  handleRelink = (customer_id) => {
    AlertControl.show({
      title: "ยืนยันการรีเซ็ตไลน์",
      description: "คุณต้องการจะรีเซ็ตไลน์ใช่หรือไม่",
      btnLabel1: "ยกเลิก",
      btnLabel2: "ยืนยัน",
      onEvent2: async () => {
        this.setState({
          isBgLoading: true,
        });
        let params = {
          customer_id: customer_id,
        };
        let res = await portalService.POST_CUSTOMER_UNLINK(params);
        if (res && res.status === 200) {
          toast.success("ปลดล็อคไลน์สำเร็จ");
          this.fetchData();
        } else {
          this.setState({
            isBgLoading: false,
          });
          toast.error("ปลดล็อคไลน์ไม่สำเร็จ");
        }
      },
    });
  };

  render() {
    const { authenRedux } = this.props;
    const {
      isLoading,
      isBgLoading,
      data,
      activityHistoryData,
      productWarranteeData,
      pdpaLatest,
      organizationData,
      // rewardHistoryData,
    } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "customer");
    return (
      <CustomerDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <div className="block_section">
              <div className="title">รายละเอียดลูกค้า</div>
              <div className="cus_info_row">
                <div className="ci_left_col">
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={"ชื่อ"}
                      value={`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                        ...permission,
                        inputString: data.first_name,
                      })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                        ...permission,
                        inputString: data.last_name,
                      })}`}
                    />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={"เบอร์โทร"}
                      value={RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                        ...permission,
                        inputString: data.phone_number,
                        type: "phone",
                      })}
                    />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={"เพศ"}
                      value={RENDER_GENDER_TYPE(data.gender).labelTh}
                    />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={"จังหวัด"}
                      value={`${data.province}`}
                    />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={"วันเกิด"}
                      value={momentTZ(data.birthday)
                        .tz("Asia/Bangkok")
                        .format("DD/MM/YYYY")}
                    />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue label={"อีเมล"} value={data.email} />
                  </div>
                  {(data.register_type === "2" || data.register_type === "4") &&
                    permission &&
                    permission.is_delete && (
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          label={"รีเซ็ตไลน์ไอดี"}
                          value={
                            <button
                              type="button"
                              className="btn_delete red_head"
                              onClick={() => this.handleRelink(data._id)}
                            >
                              Unlink ระบบ Line OA
                            </button>
                          }
                        />
                      </div>
                    )}
                </div>
                <div className="ci_right_col">
                  {pdpaLatest && (
                    <>
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          label={"PDPA code"}
                          value={`${pdpaLatest.pdpa_code}`}
                        />
                      </div>
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          label={"ยอมรับวันที่"}
                          value={`${moment(pdpaLatest.accepted_at).format(
                            "DD/MM/YYYY"
                          )}`}
                        />
                      </div>
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          label={"Privacy"}
                          value={`${
                            pdpaLatest.is_accepted_privacy_pdpa ? "Yes" : "No"
                          }`}
                        />
                      </div>
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          label={"Warranty"}
                          value={`${
                            pdpaLatest.is_accepted_product_warranty_pdpa
                              ? "Yes"
                              : "No"
                          }`}
                        />
                      </div>
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          label={"True info"}
                          value={`${
                            pdpaLatest.is_accepted_certified_true_info_pdpa
                              ? "Yes"
                              : "No"
                          }`}
                        />
                      </div>
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          label={"Maketing"}
                          value={`${
                            pdpaLatest.is_accepted_marketing_pdpa ? "Yes" : "No"
                          }`}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="block_section">
              <div className="title">รายละเอียดองค์กร</div>
              <div className="cus_info_row">
                <div className="ci_left_col">
                  {!data.organization_ref_id ? (
                    <button
                      type="button"
                      className="btn_add"
                      onClick={this.handleAddOrganization}
                    >
                      เพิ่มองค์กร
                    </button>
                  ) : (
                    <>
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          label={"ชื่อองค์กร"}
                          value={organizationData.name}
                        />
                      </div>
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          label={"เลขนิติ"}
                          value={organizationData.corporate_id}
                        />
                      </div>
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          label={"จังหวัด"}
                          value={organizationData.province}
                        />
                      </div>
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          label={"เบอร์โทรศัพท์"}
                          value={organizationData.phone_number}
                        />
                      </div>
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          label={"อีเมล"}
                          value={organizationData.email}
                        />
                      </div>
                      {permission && permission.is_update && (
                        <div className="value_item_wrap">
                          <Displays.LabelValue
                            value={
                              <button
                                type="button"
                                className="btn_delete"
                                onClick={this.handleClickOrganizationDetail}
                              >
                                จัดการองค์กร
                              </button>
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="ci_right_col"></div>
              </div>
            </div>
            <div className="block_section">
              <div className="title">การลงทะเบียนประกันสินค้า</div>
              <div className="body">
                <Tables.Standard
                  columns={productWaranteeColumns({
                    handleClickProductWarrantyDetail:
                      this.handleClickProductWarrantyDetail,
                  })}
                  data={productWarranteeData.docs}
                />
              </div>
            </div>
            <div className="block_section">
              <div className="title">การลงทะเบียนกิจกรรม</div>
              <div className="body">
                <Tables.Standard
                  columns={activityHistoryColumns({
                    // handleClickDetail: this.handleClickCreate,
                    // handleClickOfflineActivity: this.handleClickOfflineActivity,
                  })}
                  data={activityHistoryData.docs}
                />
              </div>
            </div>
            {/* <div className="block_section">
              <div className="title">ประวัติการแลกรางวัล</div>
              <div className="body"></div>
            </div> */}
            <div className="box_back">
              <div className="w150">
                <Buttons.BgStandard
                  theme_gray
                  label="Back"
                  onClick={this.handleClickBack}
                />
              </div>
              {permission && permission.is_delete && (
                <div className="w150">
                  <Buttons.BgStandard
                    theme_red
                    label="Delete"
                    onClick={this.handleClickDelete}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </CustomerDetailContainerStyled>
    );
  }
}

const productWaranteeColumns = ({ handleClickProductWarrantyDetail }) => [
  {
    Header: <div style={{ textAlign: "left" }}>Create Date</div>,
    accessor: "created_at",
    Cell: (props) => (
      <div onClick={() => handleClickProductWarrantyDetail(props.row.original)}>
        {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Sn</div>,
    accessor: "serial_no",
    Cell: (props) => (
      <div onClick={() => handleClickProductWarrantyDetail(props.row.original)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Item no</div>,
    accessor: "item_no",
    Cell: (props) => (
      <div onClick={() => handleClickProductWarrantyDetail(props.row.original)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Dealer Code</div>,
    accessor: "dealer_code",
    Cell: (props) => (
      <div onClick={() => handleClickProductWarrantyDetail(props.row.original)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Dealer Name</div>,
    accessor: "dealer_name",
    Cell: (props) => (
      <div onClick={() => handleClickProductWarrantyDetail(props.row.original)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "center" }}>Status</div>,
    accessor: "status",
    Cell: (props) => {
      const renderStatus = RENDER_STATUS_PRODUCT_WARRANTY(props.value);
      return (
        <div
          style={{ display: "flex", justifyContent: "center" }}
          onClick={() => handleClickProductWarrantyDetail(props.row.original)}
        >
          <Displays.StatusLabel
            bg={renderStatus?.bg}
            label={renderStatus?.label}
          />
        </div>
      );
    },
  },
];

const activityHistoryColumns = ({
  handleClickDetail,
  handleClickOfflineActivity,
}) => [
  {
    Header: <div style={{ textAlign: "left" }}>Create Date</div>,
    accessor: "created_at",
    Cell: (props) => (
      <div>
        {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Code</div>,
    accessor: "activity_code",
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Name</div>,
    accessor: "activity_name",
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>ชนิด</div>,
    accessor: "activity_type",
    Cell: (props) => <div>{ACTIVITY_RENDER_TYPE(props.value)}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>คะแนน</div>,
    accessor: "activity_point",
    Cell: (props) => <div>{RenderCommaMoney(props.value, true)}</div>,
  },
  {
    Header: <div style={{ textAlign: "center" }}>Status</div>,
    accessor: "status",
    Cell: (props) => {
      const renderStatus = ACTIVITY_RENDER_STATUS(props.value);
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Displays.StatusLabel
            bg={renderStatus?.bg}
            label={renderStatus?.label}
          />
        </div>
      );
    },
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomerDetailContainer));
