import styled from "styled-components";

export const InputWysiwygStyled = styled.div`
  .label {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .editor_className {
    border-radius: 0.125rem;
    border: 0.0625rem solid ${({ theme }) => theme.COLORS.GRAY_1};
    width: 100%;
    padding: 20px;
    height: ${({ height }) => (height ? height : "100%")};
    font-family: prompt;
  }
  .error {
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_3};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    padding-top: 2px;
  }

  .theme_standard {
  }
`;
