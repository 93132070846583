import React from "react";
import { connect } from "react-redux";
import { ActivityFormContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import { MdAdd, MdAssignmentAdd } from "react-icons/md";
import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from "utils/functions/string";
import * as XLSX from "xlsx";
import moment from "moment";
import momentTZ from "moment-timezone";
import queryString from "query-string";
import { Buttons, Inputs, Displays, Tables } from "components";

class ActivityFormContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    perPage: 10,
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    const query = this.props.router.location.search || `?limit=${perPage}`;
    this.fetchData(query);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_ACTIVITY_FORM_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        isLoading: false,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoading: true,
    });
    this.props.router.navigate(`${ROUTE_PATH.ACTIVITY_FORM}${joinQuery}`);
    this.fetchData(joinQuery);
  };

  handleClickCreate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.ACTIVITY_FORM}/${e}`);
  };

  handleClickOfflineActivity = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.ACTIVITY_FORM}/offline/${e}`);
  };

  handleExport = async (permission) => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false`
      : `?pagination=false`;
    let res = await portalService.GET_ACTIVITY_FORM_LIST(joinQuery);
    if (res && res.status === 200) {
      let wb = XLSX.utils.book_new();
      let ws_name = "SheetJS";
      /* make worksheet */
      let ws_data = res.data.docs.reduce((result, e, i) => {
        result.push([
          i + 1,
          momentTZ(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY"),
          e.code,
          RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: e.name,
            action: "export",
          }),
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, ["No", "Create Date", "Code", "Name"]);
      let ws = XLSX.utils.aoa_to_sheet(ws_data);
      let wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 30 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `activity-report-${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    }
  };

  render() {
    const { authenRedux, router } = this.props;
    const { isLoading, isBgLoading, pagination, data } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "activity-form");
    return (
      <ActivityFormContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {permission && permission.is_create && (
          <div className="btn_create">
            <div className="w120">
              <Buttons.BgIconPrefix
                label="New"
                icon={<MdAdd size={18} />}
                onClick={() => this.handleClickCreate("create")}
              />
            </div>
          </div>
        )}
        <Inputs.InputFilter
          theme_standard_input_filter
          dataFilter={dataFilter(router.location?.search)}
          btnLabel="ค้นหา"
          onQueryFilter={this.handleFilterKeyword}
          onExport={
            permission &&
            (permission.is_export || permission.is_export_condition)
              ? () => this.handleExport(permission)
              : false
          }
        />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <>
            <div className="body_table  ">
              <Tables.Standard
                columns={columns({
                  handleClickDetail: this.handleClickCreate,
                  handleClickOfflineActivity: this.handleClickOfflineActivity,
                })}
                data={data}
                showPagination
                pagination={pagination}
                handlePageClick={this.handlePageClick}
              />
            </div>
          </>
        )}
      </ActivityFormContainerStyled>
    );
  }
}

const dataFilter = (query) => {
  let initialValues = queryString.parse(query);
  return [
    {
      accessor: "code",
      length: "311px",
      filter: {
        fieldQuery: "code",
        defaultValue: initialValues["code"],
        type: "text",
        label: "Code search",
        placeholder: "Code name",
      },
    },
    {
      accessor: "type",
      length: "311px",
      filter: {
        fieldQuery: "type",
        defaultValue: initialValues["type"],
        type: "dropdown",
        label: "Type",
        placeholder: "",
        options: [
          {
            label: "Online",
            value: "1",
          },
          {
            label: "Offline",
            value: "2",
          },
        ],
      },
    },
  ];
};

const columns = ({ handleClickDetail, handleClickOfflineActivity }) => [
  {
    Header: <div style={{ textAlign: "left" }}>Create Date</div>,
    accessor: "created_at",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Code</div>,
    accessor: "code",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Name</div>,
    accessor: "name",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Action</div>,
    accessor: "action",
    Cell: (props) => (
      <div onClick={() => handleClickOfflineActivity(props.row.original._id)}>
        <MdAssignmentAdd size={22} color="#4b1e78" />
      </div>
    ),
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivityFormContainer));
