import React from "react";
import { RewardHistoryOverviewContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import moment from "moment";
import { ColumnChartWidget, PieDonutWidget } from "widgets";
import { Cards, Displays, Inputs } from "components";

class RewardHistoryOverviewContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData(
      `?pagination=false&start_date=${moment()
        .startOf("year")
        .format("YYYY-MM-DD")}&end_date=${moment()
        .endOf("year")
        .format("YYYY-MM-DD")}`
    );
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_REWARD_HISTORY_OVERVIEW(stringQuery);
    if (res && res.status === 200) {
      const provinceRender = res.data.provinceList
        .sort((a, b) => b.count - a.count)
        .reduce((result, data, i) => {
          if (i < 11) {
            result.push(data);
          } else {
            result[10].province = "จังหวัดที่เหลือ";
            result[10].count += data.count;
          }
          return result;
        }, []);
      this.setState({
        data: res.data,
        agePieDonutGraphData: {
          name: "จำนวนคน",
          suffixValue: "คน",
          labels: ["0_18", "19_24", "25_34", "35_44", "45_54", "55_64", "65_+"],
          series: [
            res.data.ageList["0_18"],
            res.data.ageList["19_24"],
            res.data.ageList["25_34"],
            res.data.ageList["35_44"],
            res.data.ageList["45_54"],
            res.data.ageList["55_64"],
            res.data.ageList["65_+"],
          ],
        },
        provincePieDonutGraphData: {
          labels: res.data.provinceList.map((e) => e.province),
          series: res.data.provinceList.map((e) => e.count),
        },
        provinceChartGraphData: {
          name: "จำนวนคน",
          suffixValue: "คน",
          labels: provinceRender.map((e) => e.province),
          series: provinceRender.map((e) => e.count),
        },
        genderGraphData: {
          labels: ["male", "famale", "notSpecific"],
          series: [
            res.data.genderList["male"],
            res.data.genderList["famale"],
            // res.data.genderList['lgbtqia'],
            // res.data.genderList['other'],
            res.data.genderList["notSpecific"],
          ],
        },
        isLoading: false,
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleJoinQuery = (query) => {
    let joinQuery = query ? `${query}&pagination=false` : `?pagination=false`;
    this.setState({
      isLoading: true,
    });
    this.fetchData(joinQuery);
  };

  render() {
    const {
      isLoading,
      data,
      agePieDonutGraphData,
      provincePieDonutGraphData,
      provinceChartGraphData,
      genderGraphData,
    } = this.state;
    return (
      <RewardHistoryOverviewContainerStyled>
        <div className="filter_layout">
          <Inputs.InputFilter
            theme_standard_input_filter
            dataFilter={dataFilter}
            btnLabel="ค้นหา"
            onQueryFilter={this.handleFilterKeyword}
          />
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <div className="body_layout">
              <div className="score_row">
                <Cards.ScoreBlock
                  label="MEMBERS"
                  value={data.ageList.totalCount}
                />
              </div>
              <div className="bl_row">
                <div className="bl_col">
                  <div className="graph_label">AGE</div>
                  <PieDonutWidget data={agePieDonutGraphData} />
                </div>
                <div className="bl_col">
                  <div className="graph_label">PROVINCE</div>
                  <PieDonutWidget data={provincePieDonutGraphData} />
                </div>
              </div>
              <div className="bl_row">
                <div className="bl_col">
                  <div className="graph_label">GENDER</div>
                  <PieDonutWidget data={genderGraphData} />
                </div>
                <div className="bl_col"></div>
              </div>
              <div className="bl_row">
                <div className="bl_col">
                  <div className="graph_label">AGE CHART</div>
                  <ColumnChartWidget data={agePieDonutGraphData} />
                </div>
                <div className="bl_col">
                  <div className="graph_label">Province CHART</div>
                  <ColumnChartWidget data={provinceChartGraphData} />
                </div>
              </div>
            </div>
          </>
        )}
      </RewardHistoryOverviewContainerStyled>
    );
  }
}

const dataFilter = [
  {
    accessor: "created_at",
    length: "311px",
    filter: {
      defaultValue: {
        startDate: new Date(moment().startOf("year").format("YYYY-MM-DD")),
        endDate: new Date(moment().endOf("year").format("YYYY-MM-DD")),
      },
      fieldQuery: "created_at",
      type: "dateRank",
      placeholder: "เลือกวันที่",
    },
  },
];

export default withRouter(RewardHistoryOverviewContainer);
