export const RENDER_CHARACTER_REPLACE = ({
  number = 3,
  inputString,
  character = "x",
}) => {
  let firstThreeCharacters = inputString.slice(0, number);
  let replacedString =
    firstThreeCharacters +
    character.repeat(
      inputString.length - number < 0 ? 0 : inputString.length - number
    );
  return replacedString;
};

export const RENDER_PHONE_NUMBER_REPLACE = ({
  inputString,
  replacementString = "xxxx",
  number = 4,
}) => {
  if (inputString.length < number) {
    return replacementString.repeat(number);
  } else {
    let replacedString = inputString.slice(0, -number) + replacementString;
    return replacedString;
  }
};

export const RENDER_CHARACTER_REPLACE_BY_PERMISSION = ({
  inputString,
  is_read_condition,
  is_export_condition,
  is_export,
  number = 3,
  type = "word",
  action = "view",
}) => {
  if (!inputString) {
    return "";
  } else {
    switch (action) {
      case "view":
        if (is_read_condition) {
          return type === "phone"
            ? RENDER_PHONE_NUMBER_REPLACE({
                inputString,
              })
            : RENDER_CHARACTER_REPLACE({ inputString, number });
        } else {
          return inputString;
        }
      case "export":
        if (is_export_condition) {
          return type === "phone"
            ? RENDER_PHONE_NUMBER_REPLACE({
                inputString,
              })
            : RENDER_CHARACTER_REPLACE({ inputString, number });
        } else {
          if (!is_export) {
            return "";
          } else {
            return inputString;
          }
        }
      default:
        break;
    }
    // if (is_read_condition || is_export_condition) {
    //   return type === "phone"
    //     ? RENDER_PHONE_NUMBER_REPLACE({
    //         inputString,
    //       })
    //     : RENDER_CHARACTER_REPLACE({ inputString, number });
    // } else {
    //   if (!is_export) {
    //     return "";
    //   } else {
    //     return inputString;
    //   }
    // }
  }
};
