import styled from "styled-components";

export const StatusLabelStyled = styled.div`
  .status_label_container {
    border-radius: 1rem;
    padding: 0 0.5rem;
    width: fit-content;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3px;
    background: ${({ bg }) => bg};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
  }

  .theme_standard {
  }
`;
