import styled from "styled-components";

export const InputMutiCheckboxStyled = styled.div`
  .input_checkbox_container {
    .input_checkbox_item {
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      cursor: pointer;
    }
  }

  .theme_flex {
    .input_checkbox_container {
      display: flex;

      .input_checkbox_item {
      }
    }
  }

  .theme_standard {
  }
`;
