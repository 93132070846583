import styled from "styled-components";

export const SchedulerStyled = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .btn_day_week {
      padding-left: 10%;
      display: flex;
      gap: 10px;
      .btn {
        border: none;
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        padding: 10px 20px;
        transition: background-color 0.3s;
        &.active {
          background: ${({ theme }) => theme.COLORS.GRAY_2};
          color: ${({ theme }) => theme.COLORS.WHITE};
        }
      }
    }

    .year_right_sec {
      display: flex;
      flex-direction: row;
      column-gap: 30px;
      .year_selector {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        .btn_next_prev {
          background: none;
          border: none;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .date-picker {
        width: 150px;
      }
    }
  }

  .table_container {
    border-radius: 20px;
    display: flex;
    .time_column {
      width: 150px;
      .time_column_label {
        font-weight: bold;
        padding: 10px;
        text-align: center;
      }
      .time_row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 60px;
        padding-left: 10px;
        border-right: 1px solid #ccc;
        border-bottom: none;
        input[type="checkbox"] {
          margin-right: 8px;
        }
        .time_text {
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .scheduler_table {
      flex: 1;
      display: flex;
      width: 100%;
      flex-direction: column;
      .main_header {
        display: flex;
        flex-direction: row;
        .table_header {
          flex: 1;
          border-bottom: 1px solid #ccc;
          .header_item {
            padding: 15px;
            font-weight: bold;
            text-align: center;
            border-right: 1px solid #ccc;
            background-color: #f5f5f5;
          }
        }
      }
      .table_body {
        display: flex;
        flex-direction: column;
        .row {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .cell {
            width: 14.28%;
            height: 60px;
            overflow: hidden;
          }
        }
      }
    }
  }
  .btn_update {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 30px;
    .w120 {
      width: 220px;
    }
    .status {
      display: flex;
      .status-indicator {
        width: 16px;
        height: 16px;
        background-color: green;
        border: 1px solid #333;
        margin-right: 8px;
        &.orange {
          background-color: orange;
        }
        &.red {
          background-color: red;
        }
        &.gray {
          background-color: ${({ theme }) => theme.COLORS.GRAY_2};
        }
      }

      .status-text {
        font-size: 16px;
        color: black;
      }
      &.left_200 {
        margin-left: 200px;
      }
    }
  }
`;
