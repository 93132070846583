import React from "react";
import { connect } from "react-redux";
import { ProductWarrantyRegisterContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import { Displays, Forms } from "components";
import { toast } from "react-toastify";

class ProductWarrantyRegisterContainer extends React.Component {
  state = {};

  handleSubmit = async (values) => {
    const { onRegistedSuccess } = this.props;
    this.setState({
      isBgLoading: true,
    });
    const bodyFormData = new FormData();
    bodyFormData.set("product_warrantee_id", this.props.router.params.id);
    bodyFormData.set("regis_type", "2");
    bodyFormData.set("customer_id", values.member._id);
    bodyFormData.set("member_type", values.member_type);
    bodyFormData.append("image", values.slip_img[0]);
    let res = await portalService.POST_PRODUCT_WARRANTEE_REGIS_UPLOAD_IMAGE(
      bodyFormData
    );
    if (res && res.status === 200) {
      this.setState({
        isBgLoading: false,
      });
      onRegistedSuccess && onRegistedSuccess();
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error("ลงทะเบียนไม่สำเร็จ");
    }
  };

  handleUploadContentMedia = (product_warrantee_id, files) => {
    const { onRegistedSuccess } = this.props;
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set("product_warrantee_id", product_warrantee_id);
            bodyFormData.set("type", e.fieldName);
            bodyFormData.set("regis_type", "2");
            bodyFormData.set("customer_id", "2");
            bodyFormData.append("image", e);
            let res =
              await portalService.POST_PRODUCT_WARRANTEE_REGIS_UPLOAD_IMAGE(
                bodyFormData
              );
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      toast.success("อัพเดจสำเร็จ");
      onRegistedSuccess && onRegistedSuccess();
    });
  };

  render() {
    const { authenRedux, onCancel } = this.props;
    const { isBgLoading, initialValues } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "product_warranty");
    return (
      <ProductWarrantyRegisterContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Forms.ProductWarrantyRegisterForm
          permission={permission}
          initialValues={initialValues}
          onCancel={onCancel}
          onSubmit={this.handleSubmit}
        />
      </ProductWarrantyRegisterContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductWarrantyRegisterContainer));
