import React, { useEffect } from 'react';
import { EditSendCompanyFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';

const EditSendCompanyForm = ({ onSubmit, onCancel, initialValues }) => {
  const schema = yup.object().shape({
    service_shipping: yup.string().required('Shipping service is required'),
    code_shipping: yup.string().required('Shipping code is required'),
    date_shipping: yup.string().required('Shipping date is required'),
  });

  const {
    control,
    // watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const watchCampaignType = watch('type');

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        date_shipping: initialValues.date_shipping
          ? new Date(initialValues.date_shipping)
          : new Date(),
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <EditSendCompanyFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="body_form_campaign">
          <div className="input_type_wrap">
            <div className="itw_left_box">ผู้ที่ได้รับรางวัล :</div>
            <div className="itw_right_box">
              {`${initialValues?.member_detail.first_name} ${initialValues?.member_detail.last_name}`}
            </div>
          </div>
          <div className="input_type_wrap">
            <div className="itw_left_box">รางวัล :</div>
            <div className="itw_right_box">{initialValues?.reward_name}</div>
          </div>
          <div className="input_type_wrap">
            <div className="itw_left_box">บริษัที่จัดส่ง :</div>
            <div className="itw_right_box">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={[
                      {
                        label: 'Kerry',
                        value: '1',
                      },
                      {
                        label: 'Flash',
                        value: '2',
                      },
                      {
                        label: 'SGC',
                        value: '3',
                      },
                    ]}
                    errors={errors.service_shipping?.message}
                  />
                )}
                name="service_shipping"
                // defaultValue={{ label: 'Kerry', value: '1' }}
              />
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">เลขที่ส่ง :</div>
            <div className="right_box">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    errors={errors.code_shipping?.message}
                  />
                )}
                name="code_shipping"
                defaultValue=""
              />
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">วันที่ส่ง :</div>
            <div className="right_box">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.DateTime
                    {...field}
                    placeholder={'วันที่สิ้นสุด'}
                    errors={errors.date_shipping?.message}
                    showYearDropdown
                  />
                )}
                name="date_shipping"
                defaultValue={new Date()}
              />
            </div>
          </div>
          {initialValues.status === '3' && (
            <div className="btn_zone">
              <div className="w120">
                <Buttons.BgStandard
                  theme_gray
                  label="Back"
                  onClick={onCancel}
                />
              </div>
              <div className="w120">
                <Buttons.BgStandard
                  type="submit"
                  label="Save"
                  // disabled={initialValues && !initialValues.is_edit}
                />
              </div>
            </div>
          )}
        </div>
      </form>
    </EditSendCompanyFormStyled>
  );
};

EditSendCompanyForm.propTypes = {};

export default EditSendCompanyForm;
