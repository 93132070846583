import React from "react";
import { connect } from "react-redux";
import { BookingSettingContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { MdDelete } from "react-icons/md";
import { portalService } from "apiServices";
import moment from "moment";
import { Displays, Forms, Tables } from "components";
import { AlertControl } from "components/Modals/Alert";
import { toast } from "react-toastify";

class BookingSettingContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(
      `?pagination=false&start_date${moment().format(
        "YYYY-MM-DD"
      )}&end_date=${moment().add(1, "month").format("YYYY-MM-DD")}`
    );
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_HOLIDAY_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    let res = await portalService.POST_HOLIDAY_DETAIL(params);
    if (res && res.status === 200) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.fetchData(
            `?pagination=false&start_date${moment().format(
              "YYYY-MM-DD"
            )}&end_date=${moment().add(1, "month").format("YYYY-MM-DD")}`
          );
        }
      );
    } else {
      this.setState({
        isBgLoading: false,
      });
      AlertControl.show({
        title: "สร้างไม่สำเร็จ",
        description: "กรุณายกเลิกการจองของลูกค้า ในวันที่ต้องการสร้างวันหยุด",
        btnLabel2: "ตกลง",
      });
    }
  };

  handleClickDelete = async (e) => {
    AlertControl.show({
      title: "ยืนยันการลบ",
      description: "คุณต้องการจะลบใช่หรือไม่",
      btnLabel1: "ยกเลิก ",
      btnLabel2: "ยืนยัน",
      onEvent2: async () => {
        this.setState({
          isBgLoading: true,
        });
        let res = await portalService.DELETE_HOLIDAY_DETAIL(e);
        if (res && res.status === 200) {
          this.fetchData();
          toast.success("ลบสำเร็จ");
        } else {
          this.setState({
            isBgLoading: false,
          });
          toast.error("ลบไม่สำเร็จ");
        }
      },
    });
  };

  render() {
    const { authenRedux } = this.props;
    const { isBgLoading, isLoading, data } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "booking");
    return (
      <BookingSettingContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <>
            <Forms.HolidayForm
              permission={permission}
              onSubmit={this.handleSubmit}
            />
            <div className="body_table  ">
              <Tables.Standard
                columns={columns({
                  handleClickDetail: this.handleClickCreate,
                  onClickDelete: this.handleClickDelete,
                })}
                data={data}
              />
            </div>
          </>
        )}
      </BookingSettingContainerStyled>
    );
  }
}

const columns = ({ onClickDelete }) => [
  {
    Header: <div style={{ textAlign: "left" }}>รายการวันหยุด</div>,
    accessor: "date",
    Cell: (props) =>
      moment(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY"),
  },
  {
    Header: <div style={{ textAlign: "left" }}></div>,
    accessor: "action",
    Cell: (props) => (
      <div onClick={() => onClickDelete(props.row.original._id)}>
        <MdDelete size={22} color="#4b1e78" />
      </div>
    ),
  },
];

// const data = [
//   { _id: "1", date: "2024-01-01" },
//   { _id: "2", date: "2024-04-13" },
//   { _id: "3", date: "2024-05-01" },
//   { _id: "4", date: "2024-12-05" },
//   { _id: "5", date: "2024-08-12" },
// ];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BookingSettingContainer));
