import styled from "styled-components";

export const CertificateGenerateFormStyled = styled.div`
  border-radius: 1rem;
  padding: 1.25rem 1rem;
  width: 100%;
  max-width: 50vw;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .cgf_title {
    margin-bottom: 30px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.BLACK_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
  }
  .input_wrap {
    &.mw_400 {
      max-width: 400px;
    }
  }
  .action_row {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
  }
`;
