import React, { useEffect } from "react";
import { ShowRoomFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  GENDER_TYPE_OPTION,
  RENDER_GENDER_TYPE,
} from "utils/functions/customer";
import {
  CUSTOMER_TYPE,
  RACE,
  AGE_RANGE_OPTION,
  TIME_OPTION,
  PURPOSE_OPTION,
  ENTRY_TYPE,
  BOOKING_METHOD,
} from "utils/functions/showroom";
import { DIVISION_OPTIONS } from "utils/functions/division";
import moment from "moment";
import { RENDER_AGE_RANGE } from "utils/functions/booking";
import { PRODUCT_BOOKING_OPTIONS } from "utils/functions/productBooking";
import { Buttons, Inputs } from "components";

const ShowRoomForm = ({ initialValues, permission, onCancel, onSubmit }) => {
  const schema = yup.object().shape({
    visit_date: yup.string().required("required"),
    time: yup.string().required("required"),
    customer_type: yup.string().required("required"),
    // gender: yup.string().required("required"),
    race: yup.string().required("required"),
    age: yup.string().required("required"),
    division: yup.string().required("required"),
    product: yup.object().nullable().required("required"),
    product_amount: yup.string().required("required"),
    product_price: yup.string().required("required"),
    purpose: yup.string().required("required"),
    customer_first_name: yup.string().required("required"),
    customer_last_name: yup.string().required("required"),
    customer_phone_number: yup.string().required("required"),
    customer_email: yup.string().required("required"),
    customer_gender: yup.string().required("required"),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      let params = {
        ...initialValues,
      };
      if (initialValues.product_code) {
        params.product = {
          labelEn: initialValues.product_name_en,
          labelTh: initialValues.product_name_th,
          label: initialValues.product_name_th,
          value: initialValues.product_code,
        };
      }
      if (initialValues.start_time && initialValues.end_time) {
        params.time = `${initialValues.start_time}-${initialValues.end_time}`;
      }
      reset(params);
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const visitDateWatch = watch("visit_date");

  return (
    <ShowRoomFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row_contain">
          <div className="row_left_contain">
            <div className="input_row">
              <div className="f_left_col">Day</div>
              <div className="f_right_col">
                <p className="detail left">
                  {visitDateWatch ? moment(visitDateWatch).format("dddd") : "-"}
                </p>
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Visit Date</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.DateTime
                      {...field}
                      minDate={new Date(moment().format("YYYY-MM-DD"))}
                      placeholder={"Visit Date"}
                      errors={errors.visit_date?.message}
                    />
                  )}
                  name="visit_date"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Time</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={TIME_OPTION}
                      errors={errors.time?.message}
                    />
                  )}
                  name="time"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Status</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={CUSTOMER_TYPE}
                      errors={errors.customer_type?.message}
                    />
                  )}
                  name="customer_type"
                  defaultValue=""
                />
              </div>
            </div>
            {/* <div className="input_row">
              <div className="f_left_col">Gender</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={GENDER_TYPE_OPTION}
                      errors={errors.gender?.message}
                    />
                  )}
                  name="gender"
                  defaultValue=""
                />
              </div>
            </div> */}
            <div className="input_row">
              <div className="f_left_col">Race</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={RACE}
                      errors={errors.race?.message}
                    />
                  )}
                  name="race"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Age range</div>
              <div className="f_right_col">
                {initialValues?.age_range
                  ? RENDER_AGE_RANGE(initialValues.age_range).label
                  : "-"}{" "}
                ปี
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Age</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      options={AGE_RANGE_OPTION}
                      errors={errors.age?.message}
                    />
                  )}
                  name="age"
                  defaultValue=""
                />
                {/* <div className="f_sub_left_col">Or</div>
                <div className="f_sub_right_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Dropdown
                        {...field}
                        options={[
                          {
                            value: "1",
                            label: "ยืนยัน",
                          },
                        ]}
                        errors={errors.type?.message}
                        disabled={initialValues}
                      />
                    )}
                    name="race"
                    defaultValue="1"
                  />
                </div> */}
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Kid 1</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={GENDER_TYPE_OPTION}
                      errors={errors.kid_gender_1?.message}
                    />
                  )}
                  name="kid_gender_1"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Kid 2</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={GENDER_TYPE_OPTION}
                      errors={errors.kid_gender_2?.message}
                    />
                  )}
                  name="kid_gender_2"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Product detail</div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Division</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={DIVISION_OPTIONS}
                      errors={errors.division?.message}
                    />
                  )}
                  name="division"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Price Info</div>
              <div className="f_right_col">
                <p className="detail">
                  {initialValues?.product_price_range_min
                    ? initialValues?.product_price_range_min +
                      "-" +
                      initialValues?.product_price_range_max
                    : "-"}{" "}
                  บาท
                </p>
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Product Info</div>
              <div className="f_right_col">
                <p className="detail">
                  {initialValues?.product_info_name_th
                    ? initialValues.product_info_name_th
                    : "-"}
                </p>
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Product</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputMultiSelect
                      {...field}
                      options={PRODUCT_BOOKING_OPTIONS.map((e) => ({
                        ...e,
                        label: e.labelTh,
                        value: e.value,
                      }))}
                      placeholder=""
                      errors={errors.product?.message}
                    />
                  )}
                  name="product"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Amount</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      errors={errors.product_amount?.message}
                    />
                  )}
                  name="product_amount"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Price</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      errors={errors.product_price?.message}
                    />
                  )}
                  name="product_price"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Purpose</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={PURPOSE_OPTION}
                      placeholder=""
                      errors={errors.purpose?.message}
                    />
                  )}
                  name="purpose"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Customer Type</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={ENTRY_TYPE}
                      placeholder=""
                      errors={errors.entry_type?.message}
                    />
                  )}
                  name="entry_type"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Customer Type2</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={BOOKING_METHOD}
                      placeholder=""
                      errors={errors.booking_method?.message}
                    />
                  )}
                  name="booking_method"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">Remark</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextArea
                      {...field}
                      // placeholder={t('test')}
                      errors={errors.remark?.message}
                    />
                  )}
                  name="remark"
                  defaultValue=""
                />
              </div>
            </div>
            {/* <div className="input_row">
          <div className="f_left_col">Is Center</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  errors={errors.is_center?.message}
                />
              )}
              name="is_center"
              defaultValue={false}
            />
          </div>
        </div> */}
          </div>
          <div className="row_right_contain">
            <div className="right_col">
              <h3 className="title">รายละเอียดลูกค้า</h3>
              <div className="row_detail">
                <div className="left_detail">ชื่อ</div>
                <div className="right_detail">
                  {initialValues?.customer_ref_id ? (
                    initialValues.customer_first_name
                  ) : (
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.TextField
                          {...field}
                          placeholder={"ชื่อ"}
                          errors={errors.customer_first_name?.message}
                        />
                      )}
                      name="customer_first_name"
                      defaultValue=""
                    />
                  )}
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">นามสกุล</div>
                <div className="right_detail">
                  {initialValues?.customer_ref_id ? (
                    initialValues.customer_last_name
                  ) : (
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.TextField
                          {...field}
                          placeholder={"นามสกุล"}
                          errors={errors.customer_last_name?.message}
                        />
                      )}
                      name="customer_last_name"
                      defaultValue=""
                    />
                  )}
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">เบอร์โทร</div>
                <div className="right_detail">
                  {initialValues?.customer_ref_id ? (
                    initialValues.customer_phone_number
                  ) : (
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.TextField
                          {...field}
                          placeholder={"เบอร์โทร"}
                          errors={errors.customer_phone_number?.message}
                        />
                      )}
                      name="customer_phone_number"
                      defaultValue=""
                    />
                  )}
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">อีเมล</div>
                <div className="right_detail">
                  {initialValues?.customer_ref_id ? (
                    initialValues.customer_email
                  ) : (
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.TextField
                          {...field}
                          placeholder={"อีเมล"}
                          errors={errors.customer_email?.message}
                        />
                      )}
                      name="customer_email"
                      defaultValue=""
                    />
                  )}
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">เพศ</div>
                <div className="right_detail">
                  {initialValues?.customer_ref_id ? (
                    RENDER_GENDER_TYPE(initialValues.customer_gender).labelTh
                  ) : (
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.Dropdown
                          {...field}
                          placeholder={"เพศ"}
                          options={GENDER_TYPE_OPTION}
                          errors={errors.customer_gender?.message}
                        />
                      )}
                      name="customer_gender"
                      defaultValue=""
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="action_row">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
          {permission && (
            <>
              {initialValues && permission.is_update ? (
                <div className="w120">
                  <Buttons.BgStandard type="submit" label="Update" />
                </div>
              ) : (
                permission.is_create && (
                  <div className="w120">
                    <Buttons.BgStandard type="submit" label="Save" />
                  </div>
                )
              )}
            </>
          )}
        </div>
      </form>
    </ShowRoomFormStyled>
  );
};

ShowRoomForm.propTypes = {};

export default ShowRoomForm;
