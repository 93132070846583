import styled from 'styled-components';

export const RewardHistoryOverviewContainerStyled = styled.div`
  .filter_layout {
    margin-bottom: 2rem;
  }
  .body_layout {
    .score_row {
      margin-bottom: 2rem;
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.5rem;
    }
    .bl_row {
      display: flex;
      column-gap: 1.25rem;
      .bl_col {
        flex-basis: 50%;
      }
    }
  }
`;
