import { PieDonutWidgetStyled } from './styled';
import ReactApexChart from 'react-apexcharts';

const PieDonutWidget = ({ data, graphType }) => {
  const renderColor = () => {
    let tempColor = []
    
    switch (graphType) {
      case 'overview':
        tempColor = [
          '#0166ff',
          '#fb0268',
          '#00cc99',
          '#ff6600',
        ]
        break;

      case 'age':
        tempColor = [
          '#0166ff',
          '#ff0066',
          '#00cc99',
          '#ff6600',
          '#9734ff',
          '#996533',
          '#660066',
        ]
        break;

      case 'gender':
        tempColor = [
          '#0166ff',
          '#ff0066',
          '#00cc99',
          '#ff6600',
          '#9734ff',
        ]
        break;

      case 'province':
        tempColor = [
          '#0166ff',
          '#ff0066',
          '#00cc99',
          '#ff6600',
          '#9734ff',
          "#996533",
          "#660066",
          '#00FF00',
          "#7FFFD4",
          "#FFFF00",
          '#34282C',
          '#C9C0BB',
          '#CFECEC',
          '#728C00',
          '#F5F5DC',
          "#FDD017",
          "#C2B280",
          '#786D5F',
          "#C35817",
          "#7E354D",
          '#EDC9AF',
          '#F8B88B',
          '#E75480',
          '#E238EC',
          '#5865F2',
          "#CCCCFF",
          "#EDE6D6",
          '#800000',
          "#808000",
          "#FFC0CB",
          '#504A4B',
          '#6D7B8D',
          '#123456',
          '#B0CFDE',
          '#4EE2EC',
          "#5E7D7E",
          "#4B5320",
          '#254117',
          "#5FFB17",
          "#FBF6D9",
          '#FFE87C',
          '#FFA600',
          '#C68E17',
          '#827839',
          '#49413F',
          "#835C3B",
          "#E66C2C",
          '#FF6347',
          "#560319",
          "#C5908E",
          '#FFDDCA',
          '#FEA3AA',
          '#E759AC',
          '#A74AC7',
          '#6F2DA8',
          "#86608E",
          "#C8A2C8",
          '#FEF0E3',
          "#FFFEFA",
          "#A52A2A",
          '#565051',
          '#DCDCDC',
          '#4863A0',
          '#1569C7',
          '#F0F8FF',
          "#46C7C7",
          "#4C787E",
          '#3CB371',
          "#6AA121",
          "#CCFB5D",
          '#FFE5B4',
          '#FFEF00',
          '#B8860B',
          '#704214',
          '#F88158',
          "#3F000F",
          "#550A35",
          '#F778A1',
          "#C12283",
          "#6A5ACD",
        ]
        break

      default:
        tempColor = [
          '#0166ff',
          '#ff0066',
          '#00cc99',
          '#ff6600',
          '#9734ff',
          "#996533",
          "#660066",
        ]
        break;
    }


    return tempColor
  }

  return (
    <PieDonutWidgetStyled>
      {data && (
        <ReactApexChart
          options={{
            // labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            labels: data.labels,
            options: {
              chart: {
                width: 480,
                type: 'pie',
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
              ],
            },
            colors: renderColor()
          }}
          // series: [44, 55, 13, 43, 22],
          series={data.series}
          type="pie"
          width={480}
        />
      )}
    </PieDonutWidgetStyled>
  );
};

export default PieDonutWidget;
