import React from "react";
import { connect } from "react-redux";
import { VisitorBookingDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Displays, Forms } from "components";

class VisitorBookingDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    if (this.props.router.params.id !== "create") {
      this.fetchData();
    } else {
      this.setState({
        isLoading: false,
        isBgLoading: false,
      });
    }
  }

  fetchData = async () => {
    let res = await portalService.GET_SHOWROOM_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  // FLOW SUBMIT
  // =============================
  // =============================
  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let timeSplit = values.time.split(",");
    let params = {
      ...values,
      start_time: timeSplit[0],
      end_time: timeSplit[1],
      product_code: values.product.value,
      product_name_en: values.product.labelEn,
      product_name_th: values.product.labelTh,
    };
    let res =
      this.props.router.params.id === "create"
        ? await portalService.POST_SHOWROOM_DETAIL(params)
        : await portalService.PUT_SHOWROOM_UPDATE(
            this.props.router.params.id,
            params
          );
    if (res && res.status === 200) {
      toast.success("อัพเดตสำเร็จ");
      this.handleNextStep();
    } else {
      toast.error("อัพเดตไม่สำเร็จ");
    }
  };

  handleNextStep = () => {
    this.props.router.navigate(ROUTE_PATH.SHOW_ROOM);
  };

  render() {
    const { authenRedux } = this.props;
    const { isLoading, initialValues, isBgLoading } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "booking");
    return (
      <VisitorBookingDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <Forms.ShowRoomForm
            initialValues={initialValues}
            permission={permission}
            onCancel={this.handleNextStep}
            onSubmit={this.handleSubmit}
          />
        )}
      </VisitorBookingDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VisitorBookingDetailContainer));
