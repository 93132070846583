import styled from "styled-components";

export const CalendarDayItemStyled = styled.div`
  position: relative;
  border: 1px solid #ccc;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  background-color: ${({ isHoliday, theme }) =>
    isHoliday ? theme.COLORS.GRAY_2 : theme.COLORS.WHITE_1};
  .status-indicator {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 8px;
    background-color: ${({ indicatorColor }) =>
      indicatorColor ? indicatorColor : "transparent"};
  }
  .cld_row {
    padding: 0 12px;
    display: flex;
    .cld_left_col {
      flex-basis: 36px;
      .cld_name {
        margin-top: -5px;
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      }
    }
    .cld_right_col {
      flex: 1;
      line-height: 16px;
      .cld_phone {
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      }
      .cld_product {
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
      }
    }
  }

  .theme_standard {
  }
`;
