import styled from "styled-components";

export const EWarrantyFilterWidgetStyled = styled.div`
  margin-bottom: 17px;
  display: flex;
  column-gap: 18px;
  .mf_item {
    border-radius: 10px;
    padding: 11px;
    min-width: 212px;
    background: ${({ theme }) => `${theme.COLORS.PURPLE_1}42`};
    text-align: center;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_BOLD};
    cursor: pointer;
    &.active {
      background: ${({ theme }) => theme.COLORS.PURPLE_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }
`;
