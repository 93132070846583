import React from "react";
import { connect } from "react-redux";
import { ProductWarranteeImportContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { Buttons, Displays, Inputs } from "components";

class ProductWarranteeImportContainer extends React.Component {
  state = {};

  componentDidMount() {
    scrollToTopPageFN();
  }

  handleChangeFile = (e) => {
    this.setState({
      fileCurrent: e,
    });
  };

  handleUpload = async () => {
    const { fileCurrent } = this.state;
    if (!fileCurrent) {
      toast.error("กรุณาอัพโหลดไฟล์");
    } else {
      this.setState({
        isBgLoading: true,
      });
      const fileReader = new FileReader();
      fileReader.readAsText(fileCurrent[0], "UTF-8");
      fileReader.onload = async (e) => {
        const perChunk = 50;
        const arrayChunk = JSON.parse(e.target.result).reduce(
          (resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk);
            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = []; // start a new chunk
            }
            resultArray[chunkIndex].push(item);
            return resultArray;
          },
          []
        );
        let resProcess = await Promise.all(
          arrayChunk.map(
            (e) =>
              new Promise(async (resolve) => {
                let res =
                  await portalService.POST_PRODUCT_WARRANTEE_IMPORT_JSON(e);
                if (res && res.status === 200) {
                  resolve(res);
                }
              })
          )
        );
        if (resProcess) {
          this.setState({
            isBgLoading: false,
            fileCurrent: false,
          });
          toast.success("อัพโหลดสำเร็จ");
        } else {
          toast.error("อัพโหลดไม่สำเร็จ");
        }
      };
    }
  };

  render() {
    const { authenRedux } = this.props;
    const { isBgLoading, fileCurrent } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "product_warranty");
    return (
      <ProductWarranteeImportContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {!permission || !permission?.is_create ? (
          <div>บัญชีของคุณไม่มีสิทธิ์ใช้งานฟังก์ชันนี้</div>
        ) : (
          <div className="input_upload_wrap">
            <div className="upload_block">
              <Inputs.UploadImage
                theme_upload_image
                value={fileCurrent}
                acceptType="json"
                onChange={this.handleChangeFile}
              />
            </div>
            <div className="btn_action_filter">
              <Buttons.BgStandard
                label={"Upload"}
                onClick={this.handleUpload}
              />
            </div>
          </div>
        )}
      </ProductWarranteeImportContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductWarranteeImportContainer));
