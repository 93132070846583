import styled from "styled-components";

export const CustomerDetailContainerStyled = styled.div`
  border-radius: 1rem;
  padding: 1.25rem;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .block_section {
    margin-bottom: 2rem;
    .title {
      margin-bottom: 0.25rem;
    }
    .cus_info_row {
      display: flex;
      .ci_left_col {
        flex: 1;
      }
      .ci_right_col {
        flex: 1;
      }
    }
    .value_item_wrap {
    }
    .btn_delete {
      margin-top: 8px;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: ${({ theme }) => theme.COLORS.GREEN_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      &.purple {
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
      }
      &.red_head {
        padding: 0.25rem 1rem;
        background: ${({ theme }) => theme.COLORS.RED_1};
      }
    }
    .btn_add {
      border-radius: 1rem;
      cursor: pointer;
      color: ${({ theme }) => theme.COLORS.BLUE_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    }
  }
  .box_back {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
    .w150 {
      width: 150px;
    }
  }
`;
