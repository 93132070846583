import React from "react";
import { ShowrromQrCodeContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import QRCode from "react-qr-code";
import { Buttons } from "components";

class ShowrromQrCodeContainer extends React.Component {
  render() {
    const { onCloseModal } = this.props;
    return (
      <ShowrromQrCodeContainerStyled>
        <div className="qr_code_wrap">
          <QRCode
            title="Yamaha activity qrcode"
            id="QRCode"
            value={"/showroom"}
            size={156}
          />
        </div>
        <Buttons.BgStandard label={"ปิด"} onClick={onCloseModal} />
      </ShowrromQrCodeContainerStyled>
    );
  }
}

export default withRouter(ShowrromQrCodeContainer);
