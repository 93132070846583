import React, { useEffect } from "react";
import { SchedulerStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import moment from "moment";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Buttons, Cards, Inputs } from "components";

const Scheduler = ({
  columns,
  customerData,
  dateFilterValue,
  filterValue,
  holidayData,
  permission,
  timeSlotData,
  onChangeFilter,
  onDateFilter,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    // test: yup.string().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (timeSlotData) {
      let timeSlotFilter = timeSlotData.filter(
        (e) =>
          moment(e.date).format("YYYY-MM-DD") ===
            moment(dateFilterValue).format("YYYY-MM-DD") && e.status === "4"
      );
      if (timeSlotFilter && timeSlotFilter.length > 0) {
        let params = {};
        timeSlotFilter.forEach((e) => {
          params[
            `${e.start_time.replace(".", "_")}-${e.end_time.replace(".", "_")}`
          ] = true;
        });
        reset({ ...params });
      }
    }
  }, [timeSlotData]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigate = useNavigate();

  // DAY FILTER
  // =======================================
  // =======================================
  const _renderCutomer = (e) => {
    if (holidayData.includes(moment(dateFilterValue).format("YYYY-MM-DD"))) {
      let customerList = [];
      for (let i = 0; i < 6; i++) {
        customerList.push(<Cards.CalendarDayItem isHoliday={true} />);
      }
      return customerList;
    } else {
      // let findTimeSlot = timeSlotData.find(
      //   (f) =>
      //     moment(f.date).format("YYYY-MM-DD") ===
      //       moment(dateFilterValue).format("YYYY-MM-DD") &&
      //     f.start_time === e.value.startTime &&
      //     f.end_time === e.value.endTime
      // );
      // if (findTimeSlot && findTimeSlot.status === "4") {
      //   let customerList = [];
      //   for (let i = 0; i < 6; i++) {
      //     customerList.push(<Cards.CalendarDayItem isHoliday={true} />);
      //   }
      //   return customerList;
      // } else {
      let findTimeSlot = timeSlotData.find(
        (f) =>
          moment(f.date).format("YYYY-MM-DD") ===
            moment(dateFilterValue).format("YYYY-MM-DD") &&
          f.start_time === e.value.startTime &&
          f.end_time === e.value.endTime
      );

      let customerList = [];
      let masterData = customerData.filter(
        (f) =>
          moment(f.date).format("YYYY-MM-DD") ===
            moment(dateFilterValue).format("YYYY-MM-DD") &&
          f.start_time === e.value.startTime &&
          f.end_time === e.value.endTime
      );
      for (let i = 0; i < 6; i++) {
        if (masterData[i]) {
          customerList.push(
            <div
              style={{ flex: 1, cursor: masterData[i] ? "pointer" : "unset" }}
              className="cell"
              onClick={() => {
                if (masterData[i]) {
                  navigate(`${ROUTE_PATH.BOOKING}/${masterData[i]._id}`);
                }
              }}
            >
              <Cards.CalendarDayItem
                division={masterData[i]?.division}
                name={masterData[i]?.customer_first_name}
                product={masterData[i]?.product}
                status={masterData[i]?.status}
              />
            </div>
          );
        } else {
          if (findTimeSlot && findTimeSlot.status === "4") {
            customerList.push(<Cards.CalendarDayItem isHoliday={true} />);
          } else {
            customerList.push(
              <div
                style={{ flex: 1, cursor: masterData[i] ? "pointer" : "unset" }}
                onClick={() => {
                  if (masterData[i]) {
                    navigate(`${ROUTE_PATH.BOOKING}/${masterData[i]._id}`);
                  }
                }}
              >
                <Cards.CalendarDayItem
                  division={masterData[i]?.division}
                  name={masterData[i]?.customer_first_name}
                  product={masterData[i]?.product}
                  status={masterData[i]?.status}
                />
              </div>
            );
          }
        }
      }
      return customerList;
      // }
    }
  };

  // WEEK FILTER
  // =======================================
  // =======================================
  const _renderDay = () => {
    const startOfWeek = moment(dateFilterValue, "YYYY-MM-DD").startOf("week");
    let days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="table_header">
          <div className="header_item">
            <div>{startOfWeek.format("ddd")}</div>
            <div>{startOfWeek.format("D")}</div>
          </div>
        </div>
      );
      startOfWeek.add(1, "day");
    }
    return days;
  };

  const _renderCalendar = () => {
    let days = [];
    TIME_DATA.forEach((e) => {
      const startOfWeek = moment(dateFilterValue, "YYYY-MM-DD").startOf("week");
      for (let i = 0; i < 7; i++) {
        // HOLIDAY
        if (holidayData.includes(startOfWeek.format("YYYY-MM-DD"))) {
          days.push(
            <div className="cell">
              <Cards.CalendarWeekItem isHoliday />
            </div>
          );
        } else {
          let masterData = customerData.filter(
            (f) =>
              moment(f.date).format("YYYY-MM-DD") ===
                startOfWeek.format("YYYY-MM-DD") &&
              f.start_time === e.value.startTime &&
              f.end_time === e.value.endTime &&
              f.status === "2"
          );
          if (masterData && masterData.length > 0) {
            days.push(
              <div className="cell">
                <Cards.CalendarWeekItem status="2" data={masterData} />
              </div>
            );
          } else {
            days.push(
              <div className="cell">
                <Cards.CalendarWeekItem />
              </div>
            );
          }
        }
        startOfWeek.add(1, "day");
      }
    });

    return days;
  };

  const isHoliday = holidayData.includes(
    moment(dateFilterValue).format("YYYY-MM-DD")
  );
  return (
    <SchedulerStyled columns={columns}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="header">
          <div className="btn_day_week">
            <Buttons.BgIconPrefix
              className={`btn ${filterValue === "day" ? "active" : ""}`}
              label="Day"
              onClick={() => onChangeFilter("day")}
            />
            <Buttons.BgIconPrefix
              className={`btn ${filterValue === "week" ? "active" : ""}`}
              label="Week"
              onClick={() => onChangeFilter("week")}
            />
          </div>
          <div className="year_right_sec">
            <div className="year_selector">
              <button
                type="button"
                className="btn_next_prev"
                onClick={() => onDateFilter("prev")}
              >
                &lt;
              </button>
              <span>
                {filterValue === "day"
                  ? moment(dateFilterValue).format("DD MMMM YYYY")
                  : `${moment(dateFilterValue)
                      .startOf("week")
                      .format("DD MMM YYYY")} - ${moment(dateFilterValue)
                      .endOf("week")
                      .format("DD MMM YYYY")}`}
              </span>
              <button
                type="button"
                className="btn_next_prev"
                onClick={() => onDateFilter("next")}
              >
                &gt;
              </button>
            </div>
            <Inputs.DateTime
              type="datetime-local"
              value={new Date(dateFilterValue)}
              onChange={(e) => {
                onDateFilter("select", e);
              }}
              className="date-picker"
            />
          </div>
        </div>
        <div className="table_container">
          <div className="time_column">
            <div className="time_column_label">เซ็ทเป็นไม่ว่าง</div>
            {TIME_DATA.map((e, i) => (
              <div key={i} className="time_row">
                {!isHoliday && filterValue === "day" ? (
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputCheckBox
                        {...field}
                        checked={field.value}
                        label={`${e.value.startTime} - ${e.value.endTime}`}
                        onChange={(e) => field.onChange({ ...e, isEdit: true })}
                      />
                    )}
                    name={`${e.value.startTime.replace(
                      ".",
                      "_"
                    )}-${e.value.endTime.replace(".", "_")}`}
                    defaultValue=""
                  />
                ) : (
                  `${e.value.startTime} - ${e.value.endTime}`
                )}
              </div>
            ))}
          </div>
          <div className="scheduler_table">
            <div className="main_header">
              {filterValue === "day"
                ? columns.map((col, index) => (
                    <div className="table_header">
                      <div key={index} className="header_item">
                        {col}
                      </div>
                    </div>
                  ))
                : _renderDay()}
            </div>
            <div className="table_body">
              {filterValue === "week" ? (
                <div className="row">{_renderCalendar()}</div>
              ) : (
                TIME_DATA.map((e, i) => (
                  <div key={i} className="row">
                    {_renderCutomer(e)}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        {filterValue === "day" ? (
          <div className="btn_update">
            {permission.is_create && (
              <div className="w120">
                <Buttons.BgStandard
                  type="submit"
                  label="Update Unavailable slot"
                  disabled={isHoliday}
                />
              </div>
            )}
            <div className="status">
              <span className="status-indicator"></span>
              <span className="status-text">ยืนยันนัด</span>
            </div>
            <div className="status">
              <span className="status-indicator orange"></span>
              <span className="status-text">รอยืนยัน</span>
            </div>
            <div className="status">
              <span className="status-indicator red"></span>
              <span className="status-text">ยกเลิก</span>
            </div>
            <div className="status">
              <span className="status-indicator gray"></span>
              <span className="status-text">วันหยุด</span>
            </div>
          </div>
        ) : (
          <div className="btn_update">
            <div className="status left_200">
              <span className="status-indicator green"></span>
              <span className="status-text">Only Comfirmed booking</span>
            </div>
            <div className="status">
              <span className="status-indicator gray"></span>
              <span className="status-text">วันหยุด</span>
            </div>
          </div>
        )}
      </form>
    </SchedulerStyled>
  );
};

const TIME_DATA = [
  // { label: "09.00\n10.00", value: { startTime: "09.00", endTime: "10.00" } },
  { label: "10.00\n11.00", value: { startTime: "10.00", endTime: "11.00" } },
  { label: "11.00\n12.00", value: { startTime: "11.00", endTime: "12.00" } },
  { label: "13.00\n14.00", value: { startTime: "13.00", endTime: "14.00" } },
  { label: "14.00\n15.00", value: { startTime: "14.00", endTime: "15.00" } },
  { label: "15.00\n16.00", value: { startTime: "15.00", endTime: "16.00" } },
  { label: "16.00\n17.00", value: { startTime: "16.00", endTime: "17.00" } },
  { label: "17.00\n18.00", value: { startTime: "17.00", endTime: "18.00" } },
];

export default Scheduler;
