import React, { useEffect } from 'react';
import { ProductWarrantySetFormStyled } from './styled';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MdDelete } from 'react-icons/md';
import { portalService } from 'apiServices';
import { Buttons, Inputs } from 'components';

const ProductWarrantySetForm = ({
  initialValues,
  isCreate,
  permission,
  onActive,
  onCancel,
  onDeleteItem,
  onDelete,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    code: yup.string().required('Code is required'),
    name: yup.string().required('Name is required'),
    organization: yup.object().required('Organization is required'),
    product_warranty_list: yup.array().of(
      yup.object().shape({
        info: yup
          .object()
          .nullable()
          .required('Member is required')
          .shape({
            name: yup.string().required('Member is required'),
          }),
      })
    ),
    slip_img: yup.array().required('Slip is required'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'product_warranty_list',
  });

  useEffect(() => {
    if (initialValues) {
      let params = initialValues;
      if (initialValues.slip_img) {
        params.slip_img = [{ media_uri: initialValues.slip_img }];
      }
      if (initialValues.organization_name) {
        params.organization = {
          _id: initialValues.organization_ref_id,
          name: initialValues.organization_name,
        };
      }
      if (initialValues.product_warranty_list) {
        params.product_warranty_list = initialValues.product_warranty_list.map(
          (e) => ({
            info: {
              ...e,
              name: e.name,
              serial_no: e.name.split('-')[0],
              item_no: e.name.split('-')[1],
            },
          })
        );
      }
      reset({
        ...params,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const isFormDisabled = !isCreate && !initialValues?.can_update;
  return (
    <ProductWarrantySetFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_row">
          <div className="f_left_col">Set Code</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  disabled={isFormDisabled}
                  errors={errors.code?.message}
                />
              )}
              name="code"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Set Name</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  disabled={isFormDisabled}
                  errors={errors.name?.message}
                />
              )}
              name="name"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">ค้นหาองค์กร</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputCustomerAutoSuggestion
                  {...field}
                  apiService={portalService.GET_ORGANIZATION_LIST}
                  fieldRender={'name'}
                  query={'?name'}
                  disabled={isFormDisabled}
                  errors={errors.organization?.message}
                />
              )}
              name="organization"
              // defaultValue={[]}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">อัพโหลดสลิป</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  {...field}
                  theme_upload_image
                  acceptType={'cover'}
                  scaleLabel="*** รูปขนาดไม่เกิน 8 MB"
                  disabled={isFormDisabled}
                  errors={errors.slip_img?.message}
                />
              )}
              name="slip_img"
              // defaultValue={[]}
            />
          </div>
        </div>
        {fields.map((item, index) => (
          <div key={item.id} className="input_row">
            <div className="f_left_col">Item {index + 1}</div>
            <div className="f_right_col">
              <div className="contact_item">
                <div className="ci_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputCustomerAutoSuggestion
                        {...field}
                        fieldRender={'serial_no'}
                        desc="กรุณากรอก serial no"
                        apiService={portalService.GET_PRODUCT_WARRANTEE_LIST}
                        query="?status=1&serial_no"
                        disabled={isFormDisabled}
                        errors={
                          errors.product_warranty_list &&
                          // errors?.product_warranty_list[index]?.member?.message &&
                          errors?.product_warranty_list[index]?.info?.name
                            ?.message
                        }
                      />
                    )}
                    name={`product_warranty_list.${index}.info`}
                    // defaultValue=""
                  />
                </div>
                {!isFormDisabled && (
                  <div
                    className="btn_delete"
                    onClick={() => {
                      onDeleteItem && onDeleteItem(item);
                      remove(index);
                    }}
                  >
                    <MdDelete fontSize={25} color={'red'} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        {!isFormDisabled && (
          <button
            type="button"
            className="btn_add"
            onClick={() =>
              append({
                info: {
                  name: '',
                },
                isNew: true,
              })
            }
          >
            Add Item
          </button>
        )}
        <div className="action_row">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
          {permission && (
            <>
              {initialValues && permission.is_delete && !isFormDisabled && (
                <div className="w120">
                  <Buttons.BgStandard
                    theme_red
                    label="Delete"
                    disabled={initialValues && initialValues.is_center}
                    onClick={onDelete}
                  />
                </div>
              )}
              {initialValues && permission.is_update && !isFormDisabled ? (
                <>
                  <div className="w120">
                    <Buttons.BgStandard
                      type="submit"
                      label="Update"
                      // disabled={initialValues && initialValues.is_center}
                    />
                  </div>
                  <div className="w120">
                    <Buttons.BgStandard
                      theme_green
                      label="Active"
                      // disabled={initialValues && initialValues.is_center}
                      onClick={onActive}
                    />
                  </div>
                </>
              ) : (
                permission.is_create &&
                !isFormDisabled && (
                  <div className="w120">
                    <Buttons.BgStandard
                      type="submit"
                      label="Save"
                      // disabled={initialValues && initialValues.is_center}
                    />
                  </div>
                )
              )}
            </>
          )}
        </div>
      </form>
    </ProductWarrantySetFormStyled>
  );
};

ProductWarrantySetForm.propTypes = {};

export default ProductWarrantySetForm;
