import React from "react";
import { connect } from "react-redux";
import { ActivityParticipateStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { AlertControl } from "components/Modals/Alert";
import { Buttons, Displays, Inputs, Tables } from "components";

class ActivityParticipate extends React.Component {
  state = {};

  componentDidMount() {}

  handleChangeFile = (e) => {
    this.setState({
      fileValue: e,
    });
  };

  handleConfirmImport = () => {
    AlertControl.show({
      title: "ยืนยันการอนุมัติ",
      description: "คุณต้องการจะอนุมัติใช่หรือไม่",
      btnLabel1: "ยกเลิก ",
      btnLabel2: "ยืนยัน",
      onEvent2: () => {
        this.handleImport();
      },
    });
  };

  handleImport = async () => {
    const { onImportSuccess } = this.props;
    const { fileValue } = this.state;
    this.setState({
      isLoading: true,
    });
    const bodyFormData = new FormData();
    bodyFormData.append("file", fileValue[0]);
    let res = await portalService.POST_ACTIVITY_HISTORY_IMPORT(bodyFormData);
    if (res && res.status === 200) {
      toast.success("อัพโหลดไฟล์สำเร็จ");
      if (res.data.err.length > 0) {
        this.setState({
          isLoading: false,
          dataFail: res.data.err,
        });
      } else {
        onImportSuccess && onImportSuccess();
      }
    } else {
      toast.error("อัพโหลดไฟล์ไม่สำเร็จ");
      this.setState({
        isLoading: false,
        dataFail: res.data.err,
      });
    }
  };

  render() {
    const { authenRedux } = this.props;
    const { isLoading, dataSuccess, dataFail, fileValue } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "activity");
    return (
      <ActivityParticipateStyled>
        {!permission || !permission.is_create ? (
          "คุณไม่มีสิทธิ์ในการใช้ฟังกชันนี้"
        ) : (
          <div className="ip_row">
            <Inputs.UploadImage
              theme_upload_image
              value={fileValue}
              acceptType="csv"
              onChange={this.handleChangeFile}
            />
            <div className="ip_btn_wrap">
              <Buttons.BgStandard
                label={"Upload"}
                disabled={isLoading}
                onClick={this.handleConfirmImport}
              />
            </div>
          </div>
        )}
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          (dataSuccess || dataFail) && (
            <>
              <div className="ip_table_sec">
                <div className="ip_title_table">ข้อมูลที่ไม่สำเร็จ</div>
                <Tables.Standard columns={columns} data={dataFail} />
              </div>
              {/* <div className="ip_table_sec">
                <div className="ip_title_table">ข้อมูลที่สำเร็จ</div>
                <Tables.Standard columns={columns} data={dataSuccess} />
              </div> */}
            </>
          )
        )}
      </ActivityParticipateStyled>
    );
  }
}

const columns = [
  {
    Header: <div style={{ textAlign: "left" }}>ID</div>,
    accessor: "ID",
    Cell: (props) => props.value,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Customer ID</div>,
    accessor: "Customer ID",
    Cell: (props) => props.value,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Activity code</div>,
    accessor: "Activity code",
    Cell: (props) => props.value,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Name</div>,
    accessor: "Name",
    Cell: (props) => props.value,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Province</div>,
    accessor: "Province",
    Cell: (props) => props.value,
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivityParticipate));
