import React from "react";
import { connect } from "react-redux";
import { BranchDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { Displays, Forms, Modals } from "components";

class BranchDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    scrollToTopPageFN();
    if (this.props.router.params.id !== "create") {
      this.fetchData();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  fetchData = async () => {
    let res = await portalService.GET_BRANCH_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  // FLOW DELETE
  // =============================
  // =============================
  handleDelete = () => {
    this.setState({
      renderModal: (
        <Displays.CofirmBox
          label="คุณต้องการลบใช่หรือไม่ ?"
          labelBtn1="Confirm"
          labelBtn2="Cancel"
          onAction1={this.deleteAction}
          onAction2={this.handleCloseModal}
        />
      ),
    });
  };
  deleteAction = async () => {
    this.setState({
      isBgLoading: true,
    });
    let res = await portalService.DELETE_BRANCH_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      toast.success("ลบสำเร็จ");
      this.handleNextStep();
    } else {
      toast.error("ลบไม่สำรเร็จ");
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  // FLOW SUBMIT
  // =============================
  // =============================
  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
      province: values.province.value,
      district: values.district.value,
      sub_district: values.sub_district.value,
    };
    let res =
      this.props.router.params.id === "create"
        ? await portalService.POST_BRANCH_DETAIL(params)
        : await portalService.PUT_BRANCH_UPDATE(
            this.props.router.params.id,
            params
          );
    if (res && res.status === 200) {
      toast.success("อัพเดตสำเร็จ");
      this.handleNextStep();
    } else {
      toast.error("อัพเดตไม่สำเร็จ");
    }
  };

  handleCancel = () => {
    this.props.router.navigate(-1);
  };

  handleNextStep = () => {
    this.props.router.navigate(ROUTE_PATH.BRANCH);
  };

  render() {
    const { authenRedux } = this.props;
    const { isLoading, initialValues, isBgLoading, renderModal } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "business_unit");
    return (
      <BranchDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading theme_standard_loading />
          </div>
        ) : (
          <Forms.BranchForm
            initialValues={initialValues && initialValues}
            permission={permission}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            onDelete={this.handleDelete}
          />
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </BranchDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BranchDetailContainer));
