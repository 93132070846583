import { useEffect, useState } from "react";
import { SidebarWidgetStyled } from "./styled";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { RENDER_ROUTE_PERMISSION } from "utils/functions/config";

const SidebarWidget = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authenRedux = useSelector((state) => state.authenRedux);

  const [_active, _setActive] = useState();

  useEffect(() => {
    if (location) {
      const splitPathname = location.pathname.split("/");
      _setActive(splitPathname[2]);
    }
  }, [location]);

  const _handleClickMenu = (e) => {
    navigate(e.path);
  };

  return (
    <SidebarWidgetStyled>
      <div className="s_logo_sec">
        <img
          className="sidebar_logo"
          alt="MiSUMi logo"
          src="/assets/images/logos/logo.png"
        />
      </div>
      <ul className="s_menu_sec">
        {authenRedux &&
          RENDER_ROUTE_PERMISSION(authenRedux?.permission)
            .filter((f) => !f.notShowSidebar)
            .map((e, i) => (
              <li
                key={i}
                className="s_menu_item"
                onClick={() => _handleClickMenu(e)}
              >
                {e.icon && e.icon(_active === e.key ? true : false)}
                {e.name}
              </li>
            ))}
      </ul>
    </SidebarWidgetStyled>
  );
};

export default SidebarWidget;
