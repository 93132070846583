import styled from "styled-components";

export const VisitorContainerStyled = styled.div`
  .header {
    display: flex;
    flex-direction: column-reverse;
    .btn_right {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      .btn_create {
        display: flex;
        flex-direction: column;
        .w120 {
          margin-left: 8px;
          width: 200px;
          margin-bottom: 10px;
        }
      }
    }
    .input_body {
      width: 100%;
    }
  }
  .body_table {
    margin-top: 20px;
    border-radius: 1rem;
    padding: 1.25rem;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    margin-bottom: 50px;
  }
`;
