import styled from "styled-components";

export const ProductWarranteeDetailContainerStyled = styled.div`
  .block_section {
    margin-bottom: 1rem;
    .title {
      margin-bottom: 0.25rem;
    }
    .body {
      .value_item_wrap {
      }
      .slip_img {
        width: 300px;
      }
    }
  }
  .btn_regis_wrap {
    max-width: 190px;
  }
  .button_wrap {
    max-width: 150px;
    margin-left: auto;
    margin-right: 0px;
  }
`;
