import React from "react";
import { connect } from "react-redux";
import { EWarrantyDelaerDepCodeContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import moment from "moment";
import * as XLSX from "xlsx";
import { Displays, Inputs, Tables } from "components";
import { toast } from "react-toastify";

class EWarrantyDelaerDepCodeContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(this.props.router.location.search);
  }

  fetchData = async (stringQuery) => {
    const { dealerCode } = this.props;
    let res = await portalService.GET_E_WARRANTY_OVERVIEW_DEALER_PRODUCT_DEP(
      stringQuery
        ? `${stringQuery}&dealer_code=${dealerCode}`
        : `?dealer_code=${dealerCode}&period=year&start_date=${moment()
            .startOf("year")
            .format("YYYY-MM-DD")}&end_date=${moment()
            .endOf("year")
            .format("YYYY-MM-DD")}`
    );
    if (res && res.status === 200) {
      this.setState({
        data: res.data.cate_group,
        isLoading: false,
      });
    }
  };

  handleExport = async (data) => {
    const { dealerCode } = this.props;
    this.setState({
      isBgLoading: true,
    });
    const stringQuery = this.props.router.location.search;
    let res = await portalService.GET_E_WARRANTY_OVERVIEW_DEALER_PRODUCT_DEP(
      stringQuery
        ? `${stringQuery}&dealer_code=${dealerCode}`
        : `?dealer_code=${dealerCode}&period=year&start_date=${moment()
            .startOf("year")
            .format("YYYY-MM-DD")}&end_date=${moment()
            .endOf("year")
            .format("YYYY-MM-DD")}`
    );
    if (res && res.status === 200) {
      let wb = XLSX.utils.book_new();
      let ws_name = "SheetJS";
      /* make worksheet */
      let ws_data = res.data.cate_group.reduce((result, e, i) => {
        result.push([
          i + 1,
          e.name,
          e._id,
          e.serial_total,
          e.register_count,
          e.waiting_count,
          e.approved_count,
          e.reject_count,
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        "No",
        "Dealer",
        "Product Dep.",
        "Serial Upload",
        "Warranty Register",
        "Waiting",
        "Approve",
        "Reject",
      ]);
      let ws = XLSX.utils.aoa_to_sheet(ws_data);
      let wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `dealer_product_dep-report-${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error("Export ไม่สำเร็จ");
    }
  };

  render() {
    const { authenRedux } = this.props;
    const { isBgLoading, isLoading, data } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "dashboard");
    return (
      <EWarrantyDelaerDepCodeContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="filter_layout">
          <Inputs.InputFilter
            theme_standard_input_filter
            // dataFilter={dataFilter}
            btnLabel="ค้นหา"
            onQueryFilter={this.handleFilterKeyword}
            onExport={
              permission &&
              (permission.is_export || permission.is_export_condition)
                ? () => this.handleExport(permission)
                : false
            }
          />
        </div>
        {isLoading ? (
          <div className="loading_wrap">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="body_table">
              <Tables.Standard columns={columns()} data={data} />
            </div>
          </>
        )}
      </EWarrantyDelaerDepCodeContainerStyled>
    );
  }
}

const columns = () => [
  {
    Header: <div style={{ textAlign: "left" }}>Dealer</div>,
    accessor: "name",
    Cell: (props) => props.value,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Product Dep.</div>,
    accessor: "_id",
    Cell: (props) => props.value,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Serial Upload</div>,
    accessor: "serial_total",
    Cell: (props) => props.value,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Warranty Register</div>,
    accessor: "register_count",
    Cell: (props) => props.value,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Waiting</div>,
    accessor: "waiting_count",
    Cell: (props) => props.value,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Approve</div>,
    accessor: "approved_count",
    Cell: (props) => props.value,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Reject</div>,
    accessor: "reject_count",
    Cell: (props) => props.value,
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EWarrantyDelaerDepCodeContainer));
