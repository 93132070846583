import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { CalendarDayItemStyled } from "./styled";
import { RENDER_BOOKING_STATUS } from "utils/functions/booking";

const CalendarDayItem = ({
  theme_standard,
  division,
  name,
  product,
  status,
  isHoliday,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const renderStatus = RENDER_BOOKING_STATUS(status);
  return (
    <CalendarDayItemStyled
      className={customClass}
      isHoliday={isHoliday}
      indicatorColor={renderStatus.bgColor}
    >
      <div className="status-indicator" />
      <div className="cld_row">
        <div className="cld_left_col">
          <div className="cld_name">{division}</div>
        </div>
        <div className="cld_right_col">
          <div className="cld_phone">{name}</div>
          <div className="cld_product">{product}</div>
        </div>
      </div>
    </CalendarDayItemStyled>
  );
};

CalendarDayItem.propTypes = {};

export default CalendarDayItem;
