import React from "react";
import { connect } from "react-redux";
import { BookingEmailSettingContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
// import moment from "moment";
import { AlertControl } from "components/Modals/Alert";
import { toast } from "react-toastify";
import { Displays, Forms } from "components";

class BookingEmailSettingContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(`?pagination=false`);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_BOOKING_EMAIL_CONFIG_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        initialValue: {
          emails1: res.data.docs.filter((e) => e.event_type === "1"),
          emails2: res.data.docs.filter((e) => e.event_type === "2"),
          emails3: res.data.docs.filter((e) => e.event_type === "3"),
        },
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleSubmit = async (values) => {
    const { initialValue } = this.state;
    let initialValueGroup1 = initialValue.emails1.map((e) => e.email);
    let inputDataGroup1 = values.emails1.map((e) => e.email);

    let initialValueGroup2 = initialValue.emails2.map((e) => e.email);
    let inputDataGroup2 = values.emails2.map((e) => e.email);

    let initialValueGroup3 = initialValue.emails3.map((e) => e.email);
    let inputDataGroup3 = values.emails3.map((e) => e.email);

    const coverFileTemp = [
      // CASE ADD
      ...values.emails1
        .filter((f) => !initialValueGroup1.includes(f.email))
        .map((e) => ({
          email: e.email,
          event_type: "1",
          isNewUpload: true,
        })),
      // CASE REMOVE
      ...(!initialValue?.emails1
        ? []
        : initialValue.emails1
            .filter((f) => !inputDataGroup1.includes(f.email))
            .map((e) => ({ ...e, event_type: "1", isDelete: true }))),

      // CASE ADD
      ...values.emails2
        .filter((f) => !initialValueGroup2.includes(f.email))
        .map((e) => ({
          email: e.email,
          event_type: "2",
          isNewUpload: true,
        })),
      // CASE REMOVE
      ...(!initialValue?.emails2
        ? []
        : initialValue.emails2
            .filter((f) => !inputDataGroup2.includes(f.email))
            .map((e) => ({ ...e, event_type: "2", isDelete: true }))),

      // CASE ADD
      ...values.emails3
        .filter((f) => !initialValueGroup3.includes(f.email))
        .map((e) => ({
          email: e.email,
          event_type: "3",
          isNewUpload: true,
        })),
      // CASE REMOVE
      ...(!initialValue?.emails3
        ? []
        : initialValue.emails3
            .filter((f) => !inputDataGroup3.includes(f.email))
            .map((e) => ({ ...e, event_type: "3", isDelete: true }))),
    ];
    if (coverFileTemp && coverFileTemp.length > 0) {
      this.setState({
        isBgLoading: true,
      });
      let resProcess = await Promise.all(
        coverFileTemp.map((e) => {
          return new Promise(async (resolve, reject) => {
            if (e.isNewUpload) {
              let params = {
                email: e.email,
                event_type: e.event_type,
              };
              let res = await portalService.POST_BOOKING_EMAIL_CONFIG_DETAIL(
                params
              );
              if (res && res.status === 200) {
                resolve(true);
              }
            }
            if (e.isDelete) {
              let res = await portalService.DELETE_BOOKING_EMAIL_CONFIG_DETAIL(
                e._id
              );
              if (res && res.status === 200) {
                resolve(true);
              }
            }
          });
        })
      );
      if (resProcess) {
        this.fetchData();
        toast.success("สร้างสำเร็จ");
      }
    }
  };

  handleClickDelete = async (e) => {
    AlertControl.show({
      title: "ยืนยันการลบ",
      description: "คุณต้องการจะลบใช่หรือไม่",
      btnLabel1: "ยกเลิก ",
      btnLabel2: "ยืนยัน",
      onEvent2: async () => {
        this.setState({
          isBgLoading: true,
        });
        let res = await portalService.DELETE_HOLIDAY_DETAIL(e);
        if (res && res.status === 200) {
          this.fetchData();
          toast.success("ลบสำเร็จ");
        } else {
          this.setState({
            isBgLoading: false,
          });
          toast.error("ลบไม่สำเร็จ");
        }
      },
    });
  };

  render() {
    const { authenRedux } = this.props;
    const { isBgLoading, isLoading, initialValue } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "booking");
    return (
      <BookingEmailSettingContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <>
            <Forms.EmailForm
              initialValue={initialValue}
              permission={permission}
              onSubmit={this.handleSubmit}
            />
          </>
        )}
      </BookingEmailSettingContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BookingEmailSettingContainer));
