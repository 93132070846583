import React from 'react';
import { SystemOverviewContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
import moment from 'moment';
import { GraphAreaWidget, PieDonutWidget } from 'widgets';
import { Cards, Displays, Inputs } from 'components';

class SystemOverviewContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData(
      `?pagination=false&period=year&start_date=${moment()
        .startOf('year')
        .format('YYYY-MM-DD')}&end_date=${moment()
        .endOf('year')
        .format('YYYY-MM-DD')}`
    );
  }

  fetchData = async (stringQuery) => {
    let resArr = await Promise.all([
      portalService.GET_SYSTEM_OVERVIEW(stringQuery),
      portalService.GET_SYSTEM_OVERVIEW_PERIOD(stringQuery),
    ]);
    if (resArr) {
      this.setState({
        data: resArr[0].data,
        pieDonutGraphData: {
          labels: ['MEMBERS', 'WARRANTY', 'ACTIVITY', 'POINT OF REDEMPTION'],
          series: [
            resArr[0].data.customerCount,
            resArr[0].data.productWarranteeCount,
            resArr[0].data.activityHistoryCount,
            resArr[0].data.rewardHistoryCount,
          ],
        },
        areaGraphData: {
          categories: resArr[1].data.pattern,
          series: resArr[1].data.dataList.map((e) => ({
            name: e.cate,
            data: e.data.map((f) => (f.userCount ? f.userCount : 0)),
          })),
        },
        isLoading: false,
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleJoinQuery = (query) => {
    let joinQuery = query ? `${query}&pagination=false` : `?pagination=false`;
    this.setState({
      isLoading: true,
    });
    this.fetchData(joinQuery);
  };

  render() {
    const { isLoading, data, pieDonutGraphData, areaGraphData } = this.state;
    return (
      <SystemOverviewContainerStyled>
        <div className="filter_layout">
          <Inputs.InputFilter
            theme_standard_input_filter
            dataFilter={dataFilter}
            btnLabel="ค้นหา"
            onQueryFilter={this.handleFilterKeyword}
          />
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <div className="body_layout">
              <div className="score_row">
                <Cards.ScoreBlock label="MEMBERS" value={data.customerCount} />
                <Cards.ScoreBlock
                  label="WARRANTY"
                  value={data.productWarranteeCount}
                />
                <Cards.ScoreBlock
                  label="ACTIVITY"
                  value={data.activityHistoryCount}
                />
                <Cards.ScoreBlock
                  label="POINT OF REDEMPTION"
                  value={data.rewardHistoryCount}
                />
              </div>
              <div className="bl_row">
                <PieDonutWidget data={pieDonutGraphData} graphType="overview" />
              </div>
              {/* <div className="bl_row"> */}
              <GraphAreaWidget data={areaGraphData} />
              {/* </div> */}
            </div>
          </>
        )}
      </SystemOverviewContainerStyled>
    );
  }
}

const dataFilter = [
  {
    accessor: 'dateBy',
    placeHolder: 'ระยะเวลา',
    filter: {
      initialValues: {
        level1: 'year',
        level2: new Date(moment().format('YYYY-MM-DD')),
      },
      fieldQuery: 'period',
      type: 'dateGroup',
      placeholder: 'เลือก',
      options: [
        { label: 'เดือน', value: 'month' },
        { label: 'ปี', value: 'year' },
      ],
    },
  },
];

export default withRouter(SystemOverviewContainer);
