import React from "react";

export default function renderIcon({
  width = 36,
  height = 19,
  color = "#4e4e4e",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 19"
    >
      <g
        id="Group_528"
        data-name="Group 528"
        transform="translate(-1607 -14280)"
      >
        <path
          id="Polygon_195"
          data-name="Polygon 195"
          d="M11,0,22,19H0Z"
          transform="translate(1621 14280)"
          fill={color}
        />
        <path
          id="Polygon_196"
          data-name="Polygon 196"
          d="M6.5,0,13,11H0Z"
          transform="translate(1607 14288)"
          fill={color}
        />
      </g>
    </svg>
  );
}
