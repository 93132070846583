export const GENDER_TYPE_OPTION = [
  {
    label: "ชาย",
    value: "1",
  },
  {
    label: "หญิง",
    value: "2",
  },
  {
    label: "ไม่ต้องการระบุ",
    value: "5",
  },
];

export const RENDER_GENDER_TYPE = (key) => {
  switch (key) {
    case "1":
      return {
        labelTh: "ชาย",
        labelEn: "Male",
      };
    case "2":
      return {
        labelTh: "หญิง",
        labelEn: "Female",
      };
    case "3":
      return {
        labelTh: "LGBTQIA+",
        labelEn: "LGBTQIA+",
      };
    case "4":
      return {
        labelTh: "อื่นๆ",
        labelEn: "Others",
      };
    case "5":
      return {
        labelTh: "ไม่ต้องการระบุ",
        labelEn: "Prefer not to respond",
      };
    default:
      return {
        labelTh: "ไม่ต้องการระบุ",
        labelEn: "Prefer not to respond",
      };
  }
};

export const MEMBER_TYPE_OPTION = [
  {
    label: "บุคคล",
    value: "1",
  },
  {
    label: "องค์กร",
    value: "2",
  },
];
