import React from "react";
import { connect } from "react-redux";
import { QuestionViewDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from "utils/functions/string";
import { Displays, Tables } from "components";

class QuestionViewDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (queryString) => {
    let resArr = await Promise.all([
      portalService.GET_QUESTION_DETAIL(this.props.router.params.id),
      portalService.GET_ASSESSMENT_BY_QUESTION_ADMIN_LIST(
        this.props.router.params.id,
        queryString
      ),
    ]);
    if (resArr) {
      this.setState({
        data: resArr[0].data,
        assessmentdata: resArr[1].data,
        pagination: {
          last_page: resArr[1].data.totalPages,
          current_page: resArr[1].data.page,
        },
        isLoading: false,
        isBgLoading: false,
        isLoadingTable: false,
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoadingTable: true,
    });
    this.fetchData(joinQuery);
  };

  render() {
    const { authenRedux } = this.props;
    const {
      isLoading,
      isLoadingTable,
      isBgLoading,
      data,
      assessmentdata,
      pagination,
    } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "question");
    return (
      <QuestionViewDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <div className="block_section">
              <div className="title">ข้อมูลฟอร์มคำถาม</div>
              <div className="body">
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"ชื่อแบบสอบถาม"}
                    value={`${data.title}`}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"รายละเอียด"}
                    value={data.description}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"คะแนน"}
                    value={`${data.point}`}
                  />
                </div>
              </div>
            </div>
            <div className="block_section">
              <div className="title">คำถาม</div>
              <div className="body pl_1">
                {data.question_list &&
                  data.question_list.map((e, i) => (
                    <div key={i} className="question_row">
                      <div className="question_label">
                        {`ข้อที่ ${i + 1} `}
                        {e.question}
                      </div>
                      <div className="question_ans_block">
                        {e.answer_list.map((f, j) => (
                          <div key={j} className="ans_label">
                            {`ตอบ ${j + 1} `}
                            {f}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {/* <div className="filter_wrap">
              <Inputs.InputFilter
                theme_standard_input_filter
                dataFilter={dataFilter}
                btnLabel="ค้นหา"
                onQueryFilter={this.handleFilterKeyword}
              />
            </div> */}
            {isLoadingTable ? (
              <Displays.Loading theme_standard_loading />
            ) : (
              <div className="body_table">
                <Tables.Standard
                  columns={columns({
                    permission: permission,
                    data: data,
                    // handleClickGifgPoint: this.handleClickGifgPoint,
                  })}
                  data={assessmentdata.docs}
                  showPagination
                  pagination={pagination}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            )}
          </>
        )}
      </QuestionViewDetailContainerStyled>
    );
  }
}

// const dataFilter = [
//   {
//     accessor: 'activity_code',
//     length: '311px',
//     filter: {
//       fieldQuery: 'activity_code',
//       type: 'text',
//       label: 'Activity Code',
//       placeholder: 'Activity Code',
//     },
//   },
// ];

const columns = ({ permission, data }) => {
  let params = [
    {
      Header: <div style={{ textAlign: "left" }}>Form name</div>,
      accessor: "question_title",
      Cell: (props) => <div>{`${props.value}`}</div>,
    },
    {
      Header: <div style={{ textAlign: "left" }}>Name</div>,
      accessor: "member_detail.first_name",
      Cell: (props) =>
        props.row.original.member_type === "2" ? (
          RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: props.row.original.organization_detail.name,
          })
        ) : (
          <div>
            {`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.value,
            })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.row.original.member_detail.last_name,
            })}`}
          </div>
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Phone</div>,
      accessor: "member_detail.phone_number",
      Cell: (props) =>
        props.row.original.member_type === "2" ? (
          RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: props.row.original.organization_detail.phone_number,
          })
        ) : (
          <div>{`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: props.value,
            type: "phone",
          })}`}</div>
        ),
    },
  ];
  if (data.question_list && data.question_list.length > 0) {
    data.question_list.forEach((e, i) => {
      params.push({
        Header: <div style={{ textAlign: "left" }}>{`Ans${i + 1}`}</div>,
        accessor: `question_list${i + 1}`,
        Cell: (props) => (
          <div>{props.row.original.question_list[i].answer}</div>
        ),
      });
    });
  }
  return params;
};

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuestionViewDetailContainer));
