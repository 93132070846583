export const ROUTE_PATH = {
  ROOT: "/",
  LOGIN: "/login",
  COMPONENTS: "/components",
  SYSTEM: "/system",
  DASHBOARD: "/system/dashboard",
  DASHBOARD_CUSTOMER: "/system/dashboard/customer",
  DASHBOARD_PRODUCT_WARRANTY: "/system/dashboard/product-warranty",
  DASHBOARD_E_WARRANTY: "/system/dashboard/e-warranty",
  DASHBOARD_E_WARRANTY_OVERVIEW: "/system/dashboard/e-warranty/overview",
  DASHBOARD_E_WARRANTY_DEALER: "/system/dashboard/e-warranty/dealer",
  DASHBOARD_E_WARRANTY_DEPCODE: "/system/dashboard/e-warranty/depcode",
  DASHBOARD_ACTIVITY_HISTORY: "/system/dashboard/activity-history",
  DASHBOARD_REWARD_HISTORY: "/system/dashboard/reward-history",
  DASHBOARD_RELINK_REPORT: "/system/dashboard/relink-report",
  ACTIVITY_FORM: "/system/activity-form",
  ACTIVITY_FORM_DETAIL: "/system/activity-form/:id",
  ACTIVITY_FORM_OFFLINE_DETAIL: "/system/activity-form/offline/:id",
  ACTIVITY: "/system/activity",
  ACTIVITY_DETAIL: "/system/activity/:id",
  ACTIVITY_OFFLINE_DETAIL: "/system/activity/offline/:id",
  PRODUCT_SET: "/system/product-warranty/set",
  PRODUCT_SET_DETAIL: "/system/product-warranty/set/:id",
  PRODUCT_WARRANTY: "/system/product-warranty",
  PRODUCT_WARRANTY_DETAIL: "/system/product-warranty/:id",
  PRODUCT_WARRANTY_IMPORT: "/system/product-warranty/upload",
  QUESTION: "/system/question",
  QUESTION_DETAIL: "/system/question/:id",
  QUESTION_VIEW_DETAIL: "/system/question/detail/:id",
  REWARD: "/system/reward",
  REWARD_DETAIL: "/system/reward/:id",
  REWARD_OFFLINE_DETAIL: "/system/reward/offline/:id",
  REWARD_REDEEM: "/system/reward/redeem",
  NEW: "/system/new",
  NEW_DETAIL: "/system/new/:id",
  CORPERATE: "/system/corperate",
  CORPERATE_DETAIL: "/system/corperate/:id",
  CORPERATE_INFO_DETAIL: "/system/corperate/info/:id",
  CUSTOMER: "/system/customer",
  CUSTOMER_DETAIL: "/system/customer/:id",
  BUSINESS_UNIT: "/system/business-unit",
  BUSINESS_UNIT_DETAIL: "/system/business-unit/:id",
  BRANCH: "/system/branch",
  BRANCH_DETAIL: "/system/branch/:id",
  LUCKY_DRAW: "/system/lucky-draw",
  LUCKY_DRAW_DETAIL: "/system/lucky-draw/:id",
  LUCKY_DRAW_OFFLINE_DETAIL: "/system/lucky-draw/offline/:id",
  LUCKY_DRAW_HISTORY: "/system/lucky-draw/history",
  PDPA: "/system/pdpa",
  PDPA_DETAIL: "/system/pdpa/:id",
  PDPA_PARTICIPATION_DETAIL: "/system/pdpa/participation/:id",
  USER_MANAGEMENT: "/system/user_management",
  USER_MANAGEMENT_DETAIL: "/system/user_management/:id",
  BOOKING: "/system/booking",
  BOOKING_LIST: "/system/booking/list",
  BOOKING_TABLE: "/system/booking/table",
  BOOKING_SETTING: "/system/booking/setting",
  BOOKING_EMAIL_SETTING: "/system/booking/email-setting",
  BOOKING_DETAIL: "/system/booking/:id",
  SHOW_ROOM: "/system/show-room",
  SHOW_ROOM_BOOKING_DETAIL: "/system/show-room/:id",
  SHOW_ROOM_REPORT: "/system/show-room/report",
  PERMISSION: "/system/permission",
  PERMISSION_DETAIL: "/system/permission/:id",
  CERTIFICATE: "/system/certificate",
  CERTIFICATE_DETAIL: "/system/certificate/:id",
  BANNER: "/system/banner",
  BANNER_DETAIL: "/system/banner/:id",
};
