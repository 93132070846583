import styled from 'styled-components';

export const QuestionViewDetailContainerStyled = styled.div`
  border-radius: 1rem;
  padding: 1.25rem;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .block_section {
    margin-bottom: 2rem;
    .title {
      margin-bottom: 0.25rem;
    }
    .body {
      .value_item_wrap {
      }
      &.pl_1 {
        padding-left: 1rem;
      }
      .question_row {
        margin-bottom: 1rem;
        .question_label {
          margin-bottom: 0.25rem;
        }
        .question_ans_block {
          padding-left: 1rem;
          .ans_label {
            color: ${({ theme }) => theme.COLORS.BLUE_2};
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
          }
        }
      }
    }
  }
  .filter_wrap {
    margin-bottom: 1rem;
  }
`;
