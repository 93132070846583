import styled from "styled-components";

export const PasswordFormStyled = styled.div`
  border-radius: 8px;
  padding: 1.75rem 1.5rem;
  width: 400px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .psf_title {
    margin-bottom: 1.25rem;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.BLACK_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .input_row {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    .f_left_col {
      width: 30%;
      display: flex;
      align-items: center;
    }
    .f_right_col {
      flex: 1;
    }
  }
  .action_row {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
  }
`;
