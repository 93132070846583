export const LUCKY_DRAW_SERVICE_RENDER_TYPE = (type) => {
  switch (type) {
    case '1':
      return 'ใช้ ออนไลน์ coupon code';
    case '2':
      return 'จัดส่ง';
    case '3':
      return 'แลกหน้างาน';
    default:
      break;
  }
};
