import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { LabelValueStyled } from "./styled";

const LabelValue = ({
  theme_standard,
  leftWidth,
  label,
  value,
  isHighligth,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <LabelValueStyled leftWidth={leftWidth} isHighligth={isHighligth}>
      <div className={customClass}>
        <div className="label_value_container">
          <div className="lvc_left_col">{label}</div>
          <div className="lvc_right_col">{value}</div>
        </div>
      </div>
    </LabelValueStyled>
  );
};

LabelValue.propTypes = {};

export default LabelValue;
