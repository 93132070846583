export const ADMIN_STATUS_OPTIONS = [
  {
    value: "1",
    label: "ปกติ",
  },
  {
    value: "2",
    label: "ถูกระงับชั่วคราว",
  },
  {
    value: "3",
    label: "ลาออก",
  },
];

export const RENDER_ADMIN_STATUS = (key) => {
  switch (key) {
    case "1":
      return {
        label: "ปกติ",
      };
    case "2":
      return {
        label: "ถูกระงับชั่วคราว",
      };
    case "3":
      return {
        label: "ลาออก",
      };
    default:
      return {
        label: "-",
      };
  }
};
