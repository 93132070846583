import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { CalendarWeekItemStyled } from "./styled";
import { RENDER_BOOKING_STATUS } from "utils/functions/booking";

const CalendarWeekItem = ({ theme_standard, data, status, isHoliday }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const renderStatus = RENDER_BOOKING_STATUS(status);
  return (
    <CalendarWeekItemStyled
      className={customClass}
      isHoliday={isHoliday}
      indicatorColor={renderStatus.bgColor}
    >
      <div className="status_indicator" />
      <div className="cdw_list">
        {data &&
          data.length > 0 &&
          data.map((e, i) => (
            <div key={i} className="cdw_item">
              {e.division} {e.customer_first_name}
            </div>
          ))}
      </div>
    </CalendarWeekItemStyled>
  );
};

CalendarWeekItem.propTypes = {};

export default CalendarWeekItem;
