import React, { useEffect } from "react";
import { ProductWarrantyFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Displays, Inputs } from "components";

const ProductWarrantyForm = ({
  isFormDisabled,
  onSubmit,
  initialValues,
  handleClickBack,
}) => {
  const schema = yup.object().shape({
    status: yup.string().required("Status is required"),
    rejected_reason: yup.string().when("status", (val, schema) => {
      if (val[0] === "5") {
        return yup.string().required("Rejected reason is required");
      } else {
        return yup.string().notRequired();
      }
    }),
    approved_warranty_date: yup.string().when("status", (val, schema) => {
      if (val[0] === "3") {
        return yup.string().required("Warranty date is required");
      } else {
        return yup.string().notRequired();
      }
    }),
  });

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchAllFields = watch();

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        approved_warranty_date:
          initialValues.approved_warranty_date &&
          new Date(initialValues.approved_warranty_date),
        // slip_img: initialValues.slip_img ? [{ media_uri: initialValues.slip_img }] : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProductWarrantyFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="box_reason center mt">
          <div className="blank">อัพโหลดสลิป</div>
          <div className="show_reason">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.UploadImage
                    {...field}
                    theme_upload_image
                    acceptType={"cover"}
                    scaleLabel="*** รูปขนาดกว้าง 197px สูง 148px"
                    disabled={isFormDisabled}
                    errors={errors.slip_img?.message}
                  />
                )}
                name="slip_img"
                defaultValue={[]}
              />
            </div>
          </div>
        </div>
        {(initialValues.status === "2" || initialValues.status === "5") && (
          <Displays.LabelValue
            label={"สถานะ"}
            value={
              <div className="input_wrap">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      // placeholder={t('test')}
                      options={[
                        {
                          label: "คงค้าง",
                          value: "2",
                        },
                        {
                          label: "อนุมัติ",
                          value: "3",
                        },
                        {
                          label: "ไม่อนุมัติ",
                          value: "5",
                        },
                      ]}
                      errors={errors.status?.message}
                    />
                  )}
                  name="status"
                />
              </div>
            }
          />
        )}
        <div className="box_reason center">
          <div className="blank">ขยายระยะเวลาประกัน</div>
          <div className="show_reason">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    type="number"
                    // placeholder={t('test')}
                    errors={errors.extension_period?.message}
                  />
                )}
                name="extension_period"
                // defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="box_reason center">
          <div className="blank">หน่วยระยะเวลาประกัน</div>
          <div className="show_reason">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    // placeholder={t('test')}
                    options={[
                      {
                        label: "วัน",
                        value: "D",
                      },
                      {
                        label: "สัปดาห์",
                        value: "W",
                      },
                      {
                        label: "เดือน",
                        value: "M",
                      },
                      {
                        label: "ปี",
                        value: "Y",
                      },
                    ]}
                    errors={errors.extension_unit?.message}
                  />
                )}
                name="extension_unit"
              />
            </div>
          </div>
        </div>

        {watchAllFields.status === "3" && (
          <div className="box_reason">
            <div className="blank">วันเริ่มประกัน</div>
            <div className="show_reason">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.DateTime
                      {...field}
                      // placeholder={t('test')}
                      errors={errors.approved_warranty_date?.message}
                    />
                  )}
                  name="approved_warranty_date"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
        )}

        {watchAllFields.status === "5" && (
          <div className="box_reason">
            <div className="blank">เหตุผลการยกเลิก</div>
            <div className="show_reason">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextArea
                      {...field}
                      // placeholder={t('test')}
                      // errors={
                      //   (watchAllFields.rejected_reason === '' && watchAllFields.status === '5') && 'Rejected reason is required'
                      // }
                      errors={errors.rejected_reason?.message}
                    />
                  )}
                  name="rejected_reason"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
        )}
        <div className="action_row">
          {!isFormDisabled && (
            <div className="w120">
              <Buttons.BgStandard
                type="submit"
                label="Save"
                disabled={isFormDisabled}
              />
            </div>
          )}
          <div className="w150">
            <Buttons.BgStandard
              theme_gray
              label="Back"
              onClick={handleClickBack}
            />
          </div>
        </div>
      </form>
    </ProductWarrantyFormStyled>
  );
};

ProductWarrantyForm.propTypes = {};

export default ProductWarrantyForm;
