import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TextFieldStyled } from "./styled";

const TextField = ({
  theme_standard,
  label,
  errors,
  defaultValue,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <TextFieldStyled disabled={props && props.disabled}>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <input {...props} defaultValue={defaultValue} />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </TextFieldStyled>
  );
};

TextField.propTypes = {};

export default TextField;
