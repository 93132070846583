import React from "react";
import { connect } from "react-redux";
import { RewardOfflineDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import {
  REWARD_RENDER_TYPE,
  REWARD_RENDER_STATUS,
} from "utils/functions/reward";
import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from "utils/functions/string";
import { RENDER_GENDER_TYPE } from "utils/functions/customer";
import * as XLSX from "xlsx";
import moment from "moment";
import momentTZ from "moment-timezone";
import { toast } from "react-toastify";
import { Displays, Inputs, Modals, Tables } from "components";

class RewardOfflineDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (queryString) => {
    let resArr = await Promise.all([
      portalService.GET_REWARD_DETAIL(this.props.router.params.id),
      portalService.GET_REWARD_HISTORY_BY_REWARD_LIST(
        this.props.router.params.id,
        queryString
      ),
    ]);
    if (resArr) {
      this.setState({
        data: resArr[0].data,
        rewardHistorydata: resArr[1].data,
        pagination: {
          last_page: resArr[1].data.totalPages,
          current_page: resArr[1].data.page,
        },
        isLoading: false,
        isBgLoading: false,
        isLoadingTable: false,
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoadingTable: true,
    });
    this.fetchData(joinQuery);
  };

  handleClickGifgPoint = async (e) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      customer_id: e.customer_ref_id,
      activity_id: e.activity_ref_id,
      activity_history_id: e._id,
    };
    let res = await portalService.POST_REWARD_HITORY_SCAN(params);
    if (res && res.status === 200) {
      this.fetchData();
    } else {
      toast.error("ไม่สำเร็จ");
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  handleExport = async (permission) => {
    const { data } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let res = await portalService.GET_REWARD_HISTORY_BY_REWARD_LIST(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      let wb = XLSX.utils.book_new();
      let ws_name = "SheetJS";
      if (data.type === "1") {
        /* make worksheet */
        let ws_data = res.data.docs.reduce((result, e, i) => {
          result.push([
            i + 1,
            momentTZ(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY"),
            !e?.member_detail
              ? "-"
              : `${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                  ...permission,
                  inputString: e?.member_detail?.first_name,
                  action: "export",
                })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                  ...permission,
                  inputString: e?.member_detail?.last_name,
                  action: "export",
                })}`,
            !e.member_detail
              ? "-"
              : RENDER_GENDER_TYPE(e.member_detail.gender).labelTh,
            !e.member_detail ? "-" : e.member_detail.province,
            e.reward_point,
            REWARD_RENDER_STATUS(e.status).label,
          ]);
          return result;
        }, []);
        ws_data.splice(0, 0, [
          "No",
          "Create Date",
          "Name",
          "Gender",
          "Province",
          "Point",
          "Status",
        ]);
        let ws = XLSX.utils.aoa_to_sheet(ws_data);
        let wscols = [
          { wch: 5 },
          { wch: 15 },
          { wch: 30 },
          { wch: 30 },
          { wch: 15 },
          { wch: 15 },
        ];
        ws["!cols"] = wscols;
        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        XLSX.writeFile(
          wb,
          `reward-report-${data.code}-${data.name}-${moment()
            .tz("Asia/Bangkok")
            .format("DD/MM/YYYY")}.xlsx`
        );
        this.setState({
          isBgLoading: false,
        });
      } else {
        /* make worksheet */
        let ws_data = res.data.docs.reduce((result, e, i) => {
          result.push([
            i + 1,
            momentTZ(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY"),
            !e?.member_detail
              ? "-"
              : `${e?.member_detail?.first_name} ${e?.member_detail?.last_name}`,
            !e.member_detail
              ? "-"
              : RENDER_GENDER_TYPE(e.member_detail.gender).labelTh,
            !e.member_detail ? "-" : e.member_detail.province,
            e.reward_point,
            e.address_shipping,
            REWARD_RENDER_STATUS(e.status).label,
          ]);
          return result;
        }, []);
        ws_data.splice(0, 0, [
          "No",
          "Create Date",
          "Name",
          "Gender",
          "Province",
          "Point",
          "Shipping",
          "Status",
        ]);
        let ws = XLSX.utils.aoa_to_sheet(ws_data);
        let wscols = [
          { wch: 5 },
          { wch: 15 },
          { wch: 30 },
          { wch: 30 },
          { wch: 15 },
          { wch: 45 },
          { wch: 15 },
        ];
        ws["!cols"] = wscols;
        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        XLSX.writeFile(
          wb,
          `reward-report-${data.code}-${data.name}-${moment()
            .tz("Asia/Bangkok")
            .format("DD/MM/YYYY")}.xlsx`
        );
        this.setState({
          isBgLoading: false,
        });
      }
    }
  };

  render() {
    const { authenRedux } = this.props;
    const {
      isLoading,
      isLoadingTable,
      isBgLoading,
      data,
      rewardHistorydata,
      pagination,
      renderModal,
    } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "reward");
    return (
      <RewardOfflineDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <div className="block_section">
              <div className="title">ข้อมูลของรางวัล</div>
              <div className="body">
                <div className="value_item_wrap">
                  <Displays.LabelValue label={"ชื่อ"} value={data.name} />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"ประเภท"}
                    value={REWARD_RENDER_TYPE(data.type)}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"คะแนน"}
                    value={`${data.redeem_point}`}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"จำนวนทั้งหมด"}
                    value={`${data.total_amount}`}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"จำนวนที่ใช้ไป"}
                    value={`${data.redeem_amount}`}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"ยอดคงเหลือ"}
                    value={`${data.total_amount - data.redeem_amount}`}
                  />
                </div>
              </div>
            </div>
            <div className="filter_wrap">
              <Inputs.InputFilter
                theme_standard_input_filter
                dataFilter={[]}
                btnLabel="ค้นหา"
                onQueryFilter={this.handleFilterKeyword}
                onExport={
                  permission &&
                  (permission.is_export || permission.is_export_condition)
                    ? () => this.handleExport(permission)
                    : false
                }
              />
            </div>
            {isLoadingTable ? (
              <Displays.Loading theme_standard_loading />
            ) : (
              <div className="body_table">
                <Tables.Standard
                  columns={
                    data.type === "1"
                      ? eCouponColumns({ permission: permission })
                      : productColumns({ permission: permission })
                  }
                  data={rewardHistorydata.docs}
                  showPagination
                  pagination={pagination}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            )}
          </>
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </RewardOfflineDetailContainerStyled>
    );
  }
}

// const dataFilter = [
//   {
//     accessor: 'activity_code',
//     length: '311px',
//     filter: {
//       fieldQuery: 'activity_code',
//       type: 'text',
//       label: 'Activity Code',
//       placeholder: 'Activity Code',
//     },
//   },
// ];

const eCouponColumns = ({ permission }) => [
  {
    Header: <div style={{ textAlign: "left" }}>Create Date</div>,
    accessor: "created_at",
    Cell: (props) => (
      <div>
        {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Name</div>,
    accessor: "member_detail.first_name",
    Cell: (props) => (
      <div>
        {!props.value
          ? "-"
          : `${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.value,
            })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.row.original?.member_detail?.last_name,
            })}`}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Gender</div>,
    accessor: "member_detail.gender",
    Cell: (props) => <div>{`${RENDER_GENDER_TYPE(props.value).labelTh}`}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Province</div>,
    accessor: "member_detail.province",
    Cell: (props) => <div>{!props.value ? "-" : `${props.value}`}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Point</div>,
    accessor: "reward_point",
    Cell: (props) => <div>{`${props.value}`}</div>,
  },
  {
    Header: <div style={{ textAlign: "center" }}>Status</div>,
    accessor: "status",
    Cell: (props) => {
      const renderStatus = REWARD_RENDER_STATUS(props.value);
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Displays.StatusLabel
            bg={renderStatus?.bg}
            label={renderStatus?.label}
          />
        </div>
      );
    },
  },
];

const productColumns = ({ permission }) => [
  {
    Header: <div style={{ textAlign: "left" }}>Create Date</div>,
    accessor: "created_at",
    Cell: (props) => (
      <div>
        {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Name</div>,
    accessor: "member_detail.first_name",
    Cell: (props) => (
      <div>
        {`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
          ...permission,
          inputString: props.value,
        })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
          ...permission,
          inputString: props.row.original.member_detail.last_name,
        })}`}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Gender</div>,
    accessor: "member_detail.gender",
    Cell: (props) => <div>{`${RENDER_GENDER_TYPE(props.value).labelTh}`}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Point</div>,
    accessor: "reward_point",
    Cell: (props) => <div>{`${props.value}`}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Shipping</div>,
    accessor: "address_shipping",
    Cell: (props) => <div>{`${props.value}`}</div>,
  },
  {
    Header: <div style={{ textAlign: "center" }}>Status</div>,
    accessor: "status",
    Cell: (props) => {
      const renderStatus = REWARD_RENDER_STATUS(props.value);
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Displays.StatusLabel
            bg={renderStatus?.bg}
            label={renderStatus?.label}
          />
        </div>
      );
    },
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RewardOfflineDetailContainer));
