import React from "react";
import { connect } from "react-redux";
import { PDPAContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import { MdAdd, MdAssignmentAdd } from "react-icons/md";
import momentTZ from "moment-timezone";
import queryString from "query-string";
import { Buttons, Inputs, Displays, Tables } from "components";

class PDPAContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    perPage: 10,
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    const query = this.props.router.location.search || `?limit=${perPage}`;
    this.fetchData(query);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_PDPA_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        isLoading: false,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleClickCreate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.PDPA}/${e}`);
  };

  handleClickPDPAParticiple = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.PDPA}/participation/${e}`);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoading: true,
    });
    this.props.router.navigate(`${ROUTE_PATH.PDPA}${joinQuery}`);
    this.fetchData(joinQuery);
  };

  render() {
    const { authenRedux, router } = this.props;
    const { isLoading, pagination, data } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "pdpa");
    return (
      <PDPAContainerStyled>
        {permission && permission.is_create && (
          <div className="btn_create">
            <div className="w120">
              <Buttons.BgIconPrefix
                label="New"
                icon={<MdAdd size={18} />}
                onClick={() => this.handleClickCreate("create")}
              />
            </div>
          </div>
        )}
        <Inputs.InputFilter
          theme_standard_input_filter
          dataFilter={dataFilter(router.location?.search)}
          btnLabel="ค้นหา"
          onQueryFilter={this.handleFilterKeyword}
        />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <>
            <div className="body_table">
              <Tables.Standard
                columns={columns({
                  handleClickDetail: this.handleClickCreate,
                  handleClickPDPAParticiple: this.handleClickPDPAParticiple,
                })}
                data={data}
                showPagination
                pagination={pagination}
                handlePageClick={this.handlePageClick}
              />
            </div>
          </>
        )}
      </PDPAContainerStyled>
    );
  }
}

const dataFilter = (query) => {
  let initialValues = queryString.parse(query);
  return [
    {
      accessor: "pdpa_code",
      length: "311px",
      filter: {
        fieldQuery: "pdpa_code",
        defaultValue: initialValues["pdpa_code"],
        type: "text",
        label: "PDPA code",
        placeholder: "PDPA code",
      },
    },
  ];
};

const columns = ({ handleClickDetail, handleClickPDPAParticiple }) => [
  {
    Header: <div style={{ textAlign: "left" }}>Create Date</div>,
    accessor: "created_at",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Public Date</div>,
    accessor: "release_date",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>PDPA Code</div>,
    accessor: "pdpa_code",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Type</div>,
    accessor: "type",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value === "1" ? "Application" : "Activity Form"}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Action</div>,
    accessor: "action",
    Cell: (props) => (
      <div onClick={() => handleClickPDPAParticiple(props.row.original._id)}>
        <MdAssignmentAdd size={22} color="#4b1e78" />
      </div>
    ),
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PDPAContainer));
