import React, { useEffect } from "react";
import { NewFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const NewForm = ({
  initialValues,
  permission,
  onCancel,
  onDelete,
  onSubmit,
  onSent,
}) => {
  const schema = yup.object().shape({
    title: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        start_date:
          initialValues.start_date && new Date(initialValues.start_date),
        end_date: initialValues.end_date && new Date(initialValues.end_date),
        front_img_file: initialValues.front_img
          ? [{ media_uri: initialValues.front_img }]
          : [],
        inside_img_file: initialValues.inside_img
          ? [{ media_uri: initialValues.inside_img }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NewFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_row">
          <div className="f_left_col">Name</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.title?.message}
                />
              )}
              name="title"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Description</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextArea
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.description?.message}
                />
              )}
              name="description"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Start Date</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  placeholder={"Start Date"}
                  errors={errors.start_date?.message}
                  showYearDropdown
                />
              )}
              name="start_date"
              defaultValue={new Date()}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">End Date</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  placeholder={"End Date"}
                  errors={errors.end_date?.message}
                  showYearDropdown
                />
              )}
              name="end_date"
              defaultValue={new Date()}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Front picture</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  {...field}
                  theme_upload_image
                  fieldName="front_img"
                  acceptType={"cover"}
                  scaleLabel="*** รูปขนาดกว้าง 720px สูง 720px"
                  disabled={!permission?.is_update}
                  errors={errors.front_img_file?.message}
                />
              )}
              name="front_img_file"
              defaultValue={[]}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Inside picture</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  {...field}
                  theme_upload_image
                  fieldName="inside_img"
                  acceptType={"cover"}
                  scaleLabel="*** รูปขนาดกว้าง 720px สูง 720px"
                  disabled={!permission?.is_update}
                  errors={errors.inside_img_file?.message}
                />
              )}
              name="inside_img_file"
              defaultValue={[]}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">One time popup</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  disabled={true}
                  errors={errors.is_popup_activate?.message}
                />
              )}
              name="is_popup_activate"
              defaultValue={false}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">One time Notification</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  disabled={true}
                  errors={errors.is_notification_activate?.message}
                />
              )}
              name="is_notification_activate"
              defaultValue={false}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Active</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  disabled={!permission?.is_update}
                  errors={errors.is_active?.message}
                />
              )}
              name="is_active"
              defaultValue={false}
            />
          </div>
        </div>
        {permission && (
          <div className="action_row">
            <div className="w120">
              <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
            </div>
            {initialValues && permission.is_delete && (
              <div className="w120">
                <Buttons.BgStandard
                  theme_red
                  label="Delete"
                  onClick={onDelete}
                />
              </div>
            )}
            {initialValues && permission.is_update ? (
              <>
                {!initialValues.is_notification_activate && (
                  <div className="w120">
                    <Buttons.BgStandard
                      theme_green
                      label="Sent noti"
                      // disabled={initialValues && initialValues.is_center}
                      onClick={onSent}
                    />
                  </div>
                )}
                <div className="w120">
                  <Buttons.BgStandard
                    type="submit"
                    label="Update"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              </>
            ) : (
              permission.is_create && (
                <div className="w120">
                  <Buttons.BgStandard
                    type="submit"
                    label="Save"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              )
            )}
          </div>
        )}
      </form>
    </NewFormStyled>
  );
};

NewForm.propTypes = {};

export default NewForm;
