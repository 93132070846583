import styled from "styled-components";

export const BookingTableContainerStyled = styled.div`
  padding: 22px;
  .header_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .title {
      flex: 1;
      text-align: left;
      font-weight: bold;
      font-size: 24px;
    }
    .btn_create {
      width: max-content;
      .w120 {
        width: 200px;
      }
    }
  }

  .table_container {
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    .scheduler_box {
      width: 100%;
      margin-bottom: 30px;
      .table {
        margin-bottom: 20px;
      }
    }
  }
`;
