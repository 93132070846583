import React from "react";
import { connect } from "react-redux";
import { EWarrantyContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import moment from "moment";
import { Outlet } from "react-router-dom";
import * as XLSX from "xlsx";
import { portalService } from "apiServices";
import { ROUTE_PATH } from "utils/constants/routePath";
import { EWarrantyFilterWidget } from "widgets";
import { Displays, Inputs } from "components";

class EWarrantyContainer extends React.Component {
  state = {};

  handleFilterKeyword = (e) => {
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e);
  };

  handleJoinQuery = (query) => {
    let joinQuery = query ? `${query}` : ``;
    this.setState({
      isLoadingTable: true,
    });
    this.props.router.navigate(this.props.router.location.pathname + joinQuery);
  };

  handleExport = async (permission) => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let filename = "";
    switch (this.props.router.location.pathname) {
      case "/system/dashboard/e-warranty":
      case ROUTE_PATH.DASHBOARD_E_WARRANTY_OVERVIEW:
        let res1 = await portalService.GET_E_WARRANTY_OVERVIEW_STATUS(
          filterQuery
            ? `${filterQuery}&cate=pd_cat03_desc`
            : `?cate=pd_cat03_desc&period=year&start_date=${moment()
                .startOf("year")
                .format("YYYY-MM-DD")}&end_date=${moment()
                .endOf("year")
                .format("YYYY-MM-DD")}`
        );
        filename = "e_warranty_overview";
        this.exportOverview(res1.data.cate_group, filename);
        break;
      case ROUTE_PATH.DASHBOARD_E_WARRANTY_DEALER:
        let res2 = await portalService.GET_E_WARRANTY_OVERVIEW_DEALER(
          filterQuery
            ? `${filterQuery}`
            : `?period=year&start_date=${moment()
                .startOf("year")
                .format("YYYY-MM-DD")}&end_date=${moment()
                .endOf("year")
                .format("YYYY-MM-DD")}`
        );
        filename = "e_warranty_dealer";
        this.export(res2.data.cate_group, filename, "name", [
          "No",
          "Dealer",
          "Serial Upload",
          "Serial Registed",
          "Total Registed",
          "Waiting",
          "Approve",
          "Reject",
        ]);
        break;
      case ROUTE_PATH.DASHBOARD_E_WARRANTY_DEPCODE:
        let res3 = await portalService.GET_E_WARRANTY_OVERVIEW_STATUS(
          filterQuery
            ? `${filterQuery}&cate=pd_dep_code`
            : `?cate=pd_dep_code&period=year&start_date=${moment()
                .startOf("year")
                .format("YYYY-MM-DD")}&end_date=${moment()
                .endOf("year")
                .format("YYYY-MM-DD")}`
        );
        filename = "e_warranty_cate";
        this.export(res3.data.cate_group, filename, false, [
          "No",
          "Product Dep.",
          "Serial Upload",
          "Serial Registed",
          "Total Registed",
          "Waiting",
          "Approve",
          "Reject",
        ]);
        break;
      default:
        break;
    }
  };

  exportOverview = (data, filename, fieldGroup) => {
    let wb = XLSX.utils.book_new();
    let ws_name = "SheetJS";
    /* make worksheet */
    let ws_data = data.reduce((result, e, i) => {
      result.push([
        i + 1,
        e.product_dep,
        e[fieldGroup] || e._id,
        e.serial_total,
        e.register_count,
        e.register_serial_count,
        e.waiting_count,
        e.approved_count,
        e.reject_count,
      ]);
      return result;
    }, []);
    ws_data.splice(0, 0, [
      "No",
      "Product Dep.",
      "Product Categories",
      "Serial Upload",
      "Serial Registed",
      "Total Registed",
      "Waiting",
      "Approve",
      "Reject",
    ]);
    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    let wscols = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    ws["!cols"] = wscols;
    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(
      wb,
      `${filename}-report-${moment()
        .tz("Asia/Bangkok")
        .format("DD/MM/YYYY")}.xlsx`
    );
    this.setState({
      isBgLoading: false,
    });
  };

  export = (data, filename, fieldGroup, columnsList) => {
    let wb = XLSX.utils.book_new();
    let ws_name = "SheetJS";
    /* make worksheet */
    let ws_data = data.reduce((result, e, i) => {
      result.push([
        i + 1,
        fieldGroup ? e[fieldGroup] : e._id,
        e.serial_total,
        e.register_count,
        e.register_serial_count,
        e.waiting_count,
        e.approved_count,
        e.reject_count,
      ]);
      return result;
    }, []);
    ws_data.splice(0, 0, columnsList);
    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    let wscols = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    ws["!cols"] = wscols;
    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(
      wb,
      `${filename}-report-${moment()
        .tz("Asia/Bangkok")
        .format("DD/MM/YYYY")}.xlsx`
    );
    this.setState({
      isBgLoading: false,
    });
  };

  render() {
    const { authenRedux } = this.props;
    const { isBgLoading } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "dashboard");
    return (
      <EWarrantyContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="filter_layout">
          <Inputs.InputFilter
            theme_standard_input_filter
            dataFilter={dataFilter}
            btnLabel="ค้นหา"
            onQueryFilter={this.handleFilterKeyword}
            onExport={
              permission &&
              (permission.is_export || permission.is_export_condition)
                ? () => this.handleExport(permission)
                : false
            }
          />
        </div>
        <EWarrantyFilterWidget />
        <Outlet />
      </EWarrantyContainerStyled>
    );
  }
}

const dataFilter = [
  {
    accessor: "dateBy",
    placeHolder: "ระยะเวลา",
    filter: {
      initialValues: {
        level1: "year",
        level2: moment(),
      },
      fieldQuery: "period",
      type: "dateGroup",
      placeholder: "เลือก",
      options: [
        { label: "เดือน", value: "month" },
        { label: "ปี", value: "year" },
      ],
    },
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EWarrantyContainer));
