import { GraphAreaWidgetStyled } from './styled';
import ReactApexChart from 'react-apexcharts';

const GraphAreaWidget = ({ data }) => {
  return (
    <GraphAreaWidgetStyled>
      {data && (
        <ReactApexChart
          options={{
            labels: data.categories,
            options: {
              chart: {
                height: 350,
                type: 'area',
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: 'smooth',
              },
              xaxis: {
                // type: 'datetime',
                // categories: [
                //   '2018-09-19T00:00:00.000Z',
                //   '2018-09-19T01:30:00.000Z',
                //   '2018-09-19T02:30:00.000Z',
                //   '2018-09-19T03:30:00.000Z',
                //   '2018-09-19T04:30:00.000Z',
                //   '2018-09-19T05:30:00.000Z',
                //   '2018-09-19T06:30:00.000Z',
                //   '2018-09-19T07:30:00.000Z',
                // ],
                // categories: moment.monthsShort(),
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm',
                },
              },
            },
          }}
          // series={[
          //   {
          //     name: 'series1',
          //     data: [31, 40, 28, 51, 42, 109, 100],
          //   },
          //   {
          //     name: 'series2',
          //     data: [11, 32, 45, 32, 34, 52, 41],
          //   },
          // ]}
          series={data.series}
          type="area"
          width="100%"
          height={420}
        />
      )}
    </GraphAreaWidgetStyled>
  );
};

export default GraphAreaWidget;
