import React from "react";
import { EWarrantyDealerContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import moment from "moment";
import { EWarrantyDelaerDepCodeContainer } from "./Modals";
import { Displays, Modals, Tables } from "components";

class EWarrantyDealerContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(this.props.router.location.search);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.router.location.search &&
      this.props.router.location.search !== prevProps.router.location.search
    ) {
      this.setState({
        isLoading: true,
      });
      this.fetchData(this.props.router.location.search);
    }
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_E_WARRANTY_OVERVIEW_DEALER(
      stringQuery
        ? `${stringQuery}`
        : `?period=year&start_date=${moment()
            .startOf("year")
            .format("YYYY-MM-DD")}&end_date=${moment()
            .endOf("year")
            .format("YYYY-MM-DD")}`
    );
    if (res && res.status === 200) {
      this.setState({
        data: res.data.cate_group,
        isLoading: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  handleClickDealer = (e) => {
    this.setState({
      renderModal: <EWarrantyDelaerDepCodeContainer dealerCode={e._id} />,
    });
  };

  render() {
    const { isLoading, data, renderModal } = this.state;
    return (
      <EWarrantyDealerContainerStyled>
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <Tables.Standard
              columns={columns({ handleClickDealer: this.handleClickDealer })}
              data={data}
            />
          </>
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </EWarrantyDealerContainerStyled>
    );
  }
}

const columns = ({ handleClickDealer }) => [
  {
    Header: <div style={{ textAlign: "left" }}>Dealer</div>,
    accessor: "name",
    Cell: (props) => (
      <div
        style={{ color: "#4b1e78", cursor: "pointer" }}
        onClick={() => handleClickDealer(props.row.original)}
      >
        {props.value}
      </div>
    ),
  },
  {
    Header: (
      <div style={{ textAlign: "left", color: "green" }}>Serial Upload</div>
    ),
    accessor: "serial_total",
    Cell: (props) => <div style={{ color: "green" }}> {props.value}</div>,
  },
  {
    Header: (
      <div style={{ textAlign: "left", color: "green" }}>Serial Registed</div>
    ),
    accessor: "register_count",
    Cell: (props) => <div style={{ color: "green" }}> {props.value}</div>,
  },
  {
    Header: (
      <div style={{ textAlign: "left", color: "blue" }}>Total Registed</div>
    ),
    accessor: "register_serial_count",
    Cell: (props) => <div style={{ color: "blue" }}> {props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left", color: "blue" }}>Waiting</div>,
    accessor: "waiting_count",
    Cell: (props) => <div style={{ color: "blue" }}> {props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left", color: "blue" }}>Approve</div>,
    accessor: "approved_count",
    Cell: (props) => <div style={{ color: "blue" }}> {props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left", color: "blue" }}>Reject</div>,
    accessor: "reject_count",
    Cell: (props) => <div style={{ color: "blue" }}> {props.value}</div>,
  },
];

export default withRouter(EWarrantyDealerContainer);
