import React from "react";
import { connect } from "react-redux";
import { CorperateDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import { AlertControl } from "components/Modals/Alert";
import { toast } from "react-toastify";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Displays, Forms } from "components";

class CorperateDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    if (this.props.router.params.id !== "create") {
      this.fetchData();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  fetchData = async () => {
    let res = await portalService.GET_ORGANIZATION_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      this.setState({
        initialValues: { ...res.data },
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  fetchCustomerDetail = async () => {
    let res = await portalService.GET_CUSTOMER_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };

  handleClickBack = (e) => {
    this.props.router.navigate(-1);
  };

  handleClickDelete = async () => {
    AlertControl.show({
      title: "ยืนยันการลบ",
      description: "คุณต้องการจะลบใช่หรือไม่",
      btnLabel1: "ยกเลิก ",
      btnLabel2: "ยืนยัน",
      onEvent2: async () => {
        this.setState({
          isBgLoading: true,
        });
        let res = await portalService.DELETE_ORGANIZATION_DETAIL(
          this.props.router.params.id
        );
        if (res && res.status === 200) {
          toast.success("ลบสำเร็จ");
          this.setState({
            isBgLoading: false,
          });
          this.props.router.navigate(-1);
        } else {
          toast.error("ลบไม่สำเร็จ");
        }
      },
    });
  };

  handleSubmit = async (values) => {
    const { router } = this.props;
    const { deleteTemp } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    if (values.member_list && values.member_list.length > 0) {
      params.member_list = values.member_list.map((e) => {
        return !e.member.isNew
          ? {
              name: e.member.name,
              member_ref_id: e.member.member_ref_id,
              isNew: e.member.isNew,
            }
          : {
              name: `${e.member.first_name} ${e.member.last_name}`,
              member_ref_id: e.member._id,
              isNew: e.member.isNew,
            };
      });
    }
    if (deleteTemp && deleteTemp.length > 0) {
      if (!params.member_list || params.member_list.length === 0) {
        params.member_list = deleteTemp.map((e) => ({
          name: e.member.name,
          member_ref_id: e.member.member_ref_id,
          isDelete: e.isDelete,
        }));
      } else {
        deleteTemp.forEach((e) => {
          params.member_list.push({
            name: e.member.name,
            member_ref_id: e.member.member_ref_id,
            isDelete: e.isDelete,
          });
        });
      }
    }
    let res =
      router.params.id === "create"
        ? await portalService.POST_ORGANIZATION_DETAIL(params)
        : await portalService.PUT_ORGANIZATION_UPDATE(router.params.id, params);
    if (res && res.status === 200) {
      toast.success("อัพเดตสำเร็จ");
      this.props.router.navigate(ROUTE_PATH.CORPERATE);
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error("อัพเดตไม่สำเร็จ");
    }
  };

  handleDeleteItem = (e) => {
    const { deleteTemp } = this.state;
    if (!e.member.isNew) {
      if (!deleteTemp) {
        this.setState({
          deleteTemp: [{ ...e, isDelete: true }],
        });
      } else {
        let temp = deleteTemp;
        temp.push({ ...e, isDelete: true });
        this.setState({
          deleteTemp: [...temp],
        });
      }
    }
  };

  render() {
    const { authenRedux } = this.props;
    const { isLoading, isBgLoading, initialValues } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "customer");
    return (
      <CorperateDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <div className="block_section">
              <Forms.CorperateForm
                initialValues={initialValues}
                permission={permission}
                onCancel={this.handleClickBack}
                onDelete={this.handleClickDelete}
                onDeleteItem={this.handleDeleteItem}
                onSubmit={this.handleSubmit}
              />
            </div>
          </>
        )}
      </CorperateDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CorperateDetailContainer));
