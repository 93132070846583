export default function AddRound({
  width = "22",
  height = "19",
  color = "#4e4e4e",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 19"
    >
      <path
        id="Polygon_193"
        data-name="Polygon 193"
        d="M11,0,22,19H0Z"
        fill={color}
      />
    </svg>
  );
}
