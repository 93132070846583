import React from "react";
import { RelinkReportContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import moment from "moment";
import * as XLSX from "xlsx";
import { Cards, Displays, Inputs } from "components";

class RelinkReportContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    this.fetchData(
      `?pagination=false&start_date=${moment()
        .startOf("year")
        .format("YYYY-MM-DD")}&end_date=${moment()
        .endOf("year")
        .format("YYYY-MM-DD")}`
    );
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_RELINK_OVERVIEW(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleJoinQuery = (query) => {
    let joinQuery = query ? `${query}&pagination=false` : `?pagination=false`;
    this.setState({
      isLoading: true,
    });
    this.fetchData(joinQuery);
  };

  handleExport = async () => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false&start_date=${moment()
          .startOf("year")
          .format("YYYY-MM-DD")}&end_date=${moment()
          .endOf("year")
          .format("YYYY-MM-DD")}`
      : `?pagination=false&start_date=${moment()
          .startOf("year")
          .format("YYYY-MM-DD")}&end_date=${moment()
          .endOf("year")
          .format("YYYY-MM-DD")}`;
    let res = await portalService.GET_RELINK_OVERVIEW(joinQuery);
    if (res && res.status === 200) {
      let wb = XLSX.utils.book_new();
      let ws_name = "SheetJS";
      /* make worksheet */
      let ws_data = [
        [
          res.data.memberOldTotal,
          res.data.memberOldRelinkCount,
          res.data.memberOldNotRelinkCount,
          res.data.memberNewNotRelinkCount,
          res.data.memberNewRelinkCount,
          res.data.memberTotal,
        ],
      ];
      ws_data.splice(0, 0, [
        "สมาชิกเก่าทั้งหมด",
        "สมาชิกเก่ารีลิงค์",
        "สมาชิกเก่าไม่รีลิงค์",
        "สมาชิกใหม่",
        "สมาชิกใหม่รีลิงค์",
        "สมาชิกทั้งหมด",
      ]);
      let ws = XLSX.utils.aoa_to_sheet(ws_data);
      let wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `customer-relink-${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    }
  };

  render() {
    const { isBgLoading, isLoading, data } = this.state;
    return (
      <RelinkReportContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="filter_layout">
          <Inputs.InputFilter
            theme_standard_input_filter
            dataFilter={[]}
            btnLabel="ค้นหา"
            onQueryFilter={this.handleFilterKeyword}
            onExport={this.handleExport}
          />
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <div className="body_layout">
            <div className="score_row">
              <Cards.ScoreBlock
                label="สมาชิกเก่าทั้งหมด"
                value={data.memberOldTotal}
              />
              <Cards.ScoreBlock
                label="สมาชิกเก่ารีลิงค์"
                value={data.memberOldRelinkCount}
              />
              <Cards.ScoreBlock
                label="สมาชิกเก่าไม่รีลิงค์"
                value={data.memberOldNotRelinkCount}
              />
              <Cards.ScoreBlock
                label="สมาชิกใหม่"
                value={data.memberNewNotRelinkCount}
              />
              <Cards.ScoreBlock
                label="สมาชิกใหม่รีลิงค์"
                value={data.memberNewRelinkCount}
              />
              <Cards.ScoreBlock
                label="สมาชิกทั้งหมด"
                value={data.memberTotal}
              />
            </div>
          </div>
        )}
      </RelinkReportContainerStyled>
    );
  }
}

// const dataFilter = [
//   {
//     accessor: "created_at",
//     length: "311px",
//     filter: {
//       defaultValue: {
//         startDate: new Date(moment().startOf("year").format("YYYY-MM-DD")),
//         endDate: new Date(moment().endOf("year").format("YYYY-MM-DD")),
//       },
//       fieldQuery: "created_at",
//       type: "dateRank",
//       placeholder: "เลือกวันที่",
//     },
//   },
// ];

export default withRouter(RelinkReportContainer);
