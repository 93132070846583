import React from "react";
import { CertificateGenerateFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const CertificateGenerateForm = ({ onCancel, onSubmit }) => {
  const schema = yup.object().shape({
    csv_file: yup.array().required("Required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <CertificateGenerateFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="cgf_title">Certificate Generate</div>
        <div className="input_wrap mw_400">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.UploadImage
                theme_upload_image
                {...field}
                label={"กรุณาอัพโหลดรายชื่อ"}
                acceptType="csv"
                errors={errors.csv_file?.message}
              />
            )}
            name="csv_file"
            // defaultValue=""
          />
        </div>
        <div className="action_row">
          <div className="w120">
            <Buttons.BgStandard
              type="submit"
              label="Save"
              // disabled={initialValues && initialValues.is_center}
            />
          </div>
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
        </div>
      </form>
    </CertificateGenerateFormStyled>
  );
};

CertificateGenerateForm.propTypes = {};

export default CertificateGenerateForm;
