import Avatar from './Avatar';
import BGLoading from './BGLoading';
import CofirmBox from './CofirmBox';
import LabelValue from './LabelValue';
import Loading from './Loading';
import PaginationCustom from './PaginationCustom';
import StatusLabel from './StatusLabel';

const EXPORT = {
  Avatar,
  BGLoading,
  CofirmBox,
  LabelValue,
  Loading,
  PaginationCustom,
  StatusLabel,
};

export default EXPORT;
