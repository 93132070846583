export const REWARD_RENDER_TYPE = (type) => {
  switch (type) {
    case '1':
      return 'E-coupon';
    case '2':
      return 'Voucher';
    case '3':
      return 'Gift';
    default:
      break;
  }
};

export const REWARD_RENDER_STATUS = (type) => {
  switch (type) {
    case '1':
      return {
        label: 'ดำเนินการ',
        bgColor: '#4B1E78',
      };
    case '2':
      return {
        label: 'กรอกที่อยู่',
        bgColor: '#4B1E78',
      };
    case '3':
      return {
        label: 'จัดส่ง',
        bgColor: '#4B1E78',
      };
    case '4':
      return {
        label: 'จัดส่ง',
        bgColor: '#B3B3B3',
      };
    case '5':
      return {
        label: 'สำเร็จ',
        bgColor: '#B3B3B3',
      };
    default:
      break;
  }
};

export const REWARD_HISTORY_RENDER_STATUS = (type) => {
  switch (type) {
    case '1':
      return {
        label: 'รอดำเนินการ',
        bg: '#4b1e78',
      };
    case '2':
      return {
        label: 'กรอกที่อยู่',
        bg: '#FFB341',
      };
    case '3':
      return {
        label: 'สำเร็จ',
        bg: '#008000',
      };
    default:
      break;
  }
};

export const REWARD_HISTORY_E_COUPON_RENDER_STATUS = (type) => {
  switch (type) {
    case '1':
      return {
        label: 'รอดำเนินการ',
        bg: '#4b1e78',
      };
    case '2':
      return {
        label: 'จอง',
        bg: '#FFB341',
      };
    case '3':
      return {
        label: 'ใช้แล้ว',
        bg: '#008000',
      };
    case '4':
      return {
        label: 'สำเร็จ',
        bg: '#008000',
      };
    default:
      break;
  }
};

export const REWARD_LUCKY_DRAW_RENDER_TYPE = (type) => {
  switch (type) {
    case '1':
      return 'E-coupon';
    case '2':
      return 'Gift';
    default:
      break;
  }
};
