import React, { useState, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { InputWysiwygStyled } from "./styled";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const InputWysiwyg = ({
  theme_standard,
  disabled,
  errors,
  label,
  value,
  onChange,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [isInitial, setIsInitial] = useState(false);
  const [_value, _setValue] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (value && !isInitial) {
      const blocksFromHtml = htmlToDraft(value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      _setValue(editorState);
      setIsInitial(true);
    }
  }, [value, isInitial]);

  const handleChange = (e) => {
    setIsInitial(true);
    _setValue(e);
    onChange && onChange(draftToHtml(convertToRaw(e.getCurrentContent())));
  };

  return (
    <InputWysiwygStyled disabled={disabled}>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <Editor
          editorClassName="editor_className"
          editorState={_value}
          toolbar={OPTION_LIST}
          wrapperClassName="editor_wrap"
          onEditorStateChange={handleChange}
        />
        {errors && <div className="error">{`* ${errors}`}</div>}
      </div>
    </InputWysiwygStyled>
  );
};

const OPTION_LIST = {
  options: [
    "inline",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "history",
  ],
  fontFamily: {
    options: ["Prompt", "Sarabun"],
  },
};

InputWysiwyg.propTypes = {};

export default InputWysiwyg;
