import ArrowDropdown from "./ArrowDropdown";
import CloseX from "./CloseX";
import AddRound from "./AddRound";
import ArrowLeftRight from "./ArrowLeftRight";
import Image from "./Image";
import Loading from "./Loading";
import MoveDown from "./MoveDown";
import MoveUp from "./MoveUp";

const EXPORT = {
  ArrowDropdown,
  AddRound,
  CloseX,
  ArrowLeftRight,
  Image,
  Loading,
  MoveDown,
  MoveUp
};

export default EXPORT;
