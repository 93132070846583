import React from "react";
import { connect } from "react-redux";
import { CertificateSendInfoContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import momentTZ from "moment-timezone";
import { portalService } from "apiServices";
import { Buttons, Displays, Tables } from "components";

class CertificateSendInfoContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(`?pagination=false`);
  }

  fetchData = async () => {
    let resArr = await Promise.all([
      portalService.GET_CERTIFICATE_DETAIL(this.props.router.params.id),
      portalService.GET_CERTIFICATE_GENERATE_HISTORY_BY_CERT_ID(
        `?cert_id=${this.props.router.params.id}`
      ),
    ]);
    if (resArr) {
      this.setState({
        certificateDetailData: resArr[0].data,
        certificateSendListData: resArr[1].data,
        isLoading: false,
      });
    }
  };

  handleClickBack = () => {
    this.props.router.navigate(-1);
  };

  render() {
    const { authenRedux } = this.props;
    const { isLoading, certificateDetailData, certificateSendListData } =
      this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "certificate");
    return (
      <CertificateSendInfoContainerStyled>
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <div className="block_section">
              <div className="title">ข้อมูล Certificate</div>
              <div className="body_row">
                <div className="b_left_col">
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={"รหัส"}
                      value={`${certificateDetailData.certificate_code}`}
                    />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={"ชื่อ"}
                      value={certificateDetailData.certificate_name}
                    />
                  </div>

                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={"Category"}
                      value={`${certificateDetailData.sub_category}`}
                    />
                  </div>
                </div>
                <div className="b_right_col"></div>
              </div>
            </div>
            <div className="body_table">
              <Tables.Standard
                columns={COLUMNS({ permission: permission })}
                data={certificateSendListData.docs || []}
              />
            </div>
            <div className="action_row">
              <div className="btn_wrap">
                <Buttons.BgStandard
                  theme_gray
                  label="Back"
                  onClick={this.handleClickBack}
                />
              </div>
            </div>
          </>
        )}
      </CertificateSendInfoContainerStyled>
    );
  }
}

const COLUMNS = ({ permission }) => [
  {
    Header: <div style={{ textAlign: "left" }}>Create</div>,
    accessor: "created_at",
    Cell: (props) =>
      momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm"),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Name</div>,
    accessor: "name",
    Cell: (props) =>
      `${props.row.original.first_name} ${props.row.original.last_name}`,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Email</div>,
    accessor: "email",
    Cell: (props) => props.value,
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CertificateSendInfoContainer));
