import React from "react";
import { HolidayFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";
import moment from "moment";

const HolidayForm = ({ permission, onSubmit }) => {
  const schema = yup.object().shape({
    date: yup.string().required("required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <HolidayFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="f_header">
          <div className="f_left_col">
            <div className="f_title">เพิ่มวันหยุด / Day off</div>
          </div>

          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  minDate={
                    new Date(moment().add(1, "day").format("YYYY-MM-DD"))
                  }
                  placeholder={"กรุณาเลือกวันหยุด"}
                  errors={errors.date?.message}
                />
              )}
              name="date"
              defaultValue=""
            />
            {permission.is_create && (
              <div className="btn_submit_wrap">
                <Buttons.BgStandard
                  type="submit"
                  label="ยืนยัน"
                  // disabled={initialValues && initialValues.is_center}
                />
              </div>
            )}
          </div>
        </div>
      </form>
    </HolidayFormStyled>
  );
};

HolidayForm.propTypes = {};

export default HolidayForm;
