import React from "react";
import { connect } from "react-redux";
import { ActivityOfflineDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import {
  ACTIVITY_RENDER_TYPE,
  ACTIVITY_RENDER_STATUS,
} from "utils/functions/activity";
import * as XLSX from "xlsx";
import { RENDER_GENDER_TYPE } from "utils/functions/customer";
import moment from "moment";
import momentTZ from "moment-timezone";
import { MdCheckCircle } from "react-icons/md";
import { toast } from "react-toastify";
import { _calculateAge } from "utils/functions/birthday";
import { ActivityParticipate, ActQuestionContainer } from "./Modals";
import { AlertControl } from "components/Modals/Alert";
import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from "utils/functions/string";
import Linkify from "react-linkify";
import { IMG_PATH } from "utils/constants/imgPath";
import { BtnApproveWidget } from "widgets";
import { Displays, Inputs, Modals, Tables } from "components";

class ActivityOfflineDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(`?pagination=false`);
  }

  fetchData = async (queryString) => {
    let resArr = await Promise.all([
      portalService.GET_ACTIVITY_DETAIL(this.props.router.params.id),
      portalService.GET_ACTIVITY_HISTORY_BY_ACTIVITY_LIST(
        this.props.router.params.id,
        queryString
      ),
    ]);
    if (resArr) {
      this.setState({
        data: resArr[0].data,
        activityHistorydata: {
          ...resArr[1],
          docs: resArr[1].data.docs.sort(
            (a, b) => statusSort[a.status] - statusSort[b.status]
          ),
        },
        selectedAmount: resArr[1].data.docs.filter((e) => e.is_selected).length,
        pagination: {
          last_page: resArr[1].data.totalPages,
          current_page: resArr[1].data.page,
        },
        isLoading: false,
        isBgLoading: false,
        isLoadingTable: false,
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoadingTable: true,
    });
    this.fetchData(joinQuery);
  };

  handleClickGifgPoint = async (permission, e) => {
    if (!permission || !permission.is_update) {
      toast.error("คุณไม่มีสิทธิ์ในการให้คะแนน");
    } else {
      this.setState({
        isBgLoading: true,
      });
      let params = {
        customer_id: e.customer_ref_id,
        activity_id: e.activity_ref_id,
        activity_history_id: e._id,
        member_type: e.member_type,
      };
      let res = await portalService.POST_ACTIVITY_HITORY_SCAN(params);
      if (res && res.status === 200) {
        this.fetchData();
      } else {
        toast.error("ไม่สำเร็จ");
      }
    }
  };

  handleExport = async (permission) => {
    const { data } = this.state;
    const { filterQuery } = this.state;
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false`
      : `?pagination=false`;

    this.setState({
      isBgLoading: true,
    });
    let res = await portalService.GET_ACTIVITY_HISTORY_BY_ACTIVITY_LIST(
      this.props.router.params.id,
      joinQuery
    );
    if (res && res.status === 200) {
      let wb = XLSX.utils.book_new();
      let ws_name = "SheetJS";
      switch (data.type) {
        case "1":
          /* make worksheet */
          let ws_data = res.data.docs
            .sort((a, b) => statusSort[a.status] - statusSort[b.status])
            .reduce((result, e, i) => {
              result.push([
                e._id,
                e.customer_ref_id,
                momentTZ(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY"),
                e.activity_name,
                e.member_type === "1"
                  ? `${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                      ...permission,
                      inputString: e.member_detail.first_name,
                      action: "export",
                    })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                      ...permission,
                      inputString: e.member_detail.last_name,
                      action: "export",
                    })}`
                  : RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                      ...permission,
                      inputString: e.organization_detail.name,
                      action: "export",
                    }),
                e.member_type === "1"
                  ? `${RENDER_GENDER_TYPE(e.member_detail.gender).labelTh}`
                  : "",
                e.member_type === "1"
                  ? `${_calculateAge(e.member_detail.birthday)}`
                  : "",
                e.member_type === "1"
                  ? e.member_detail.province
                  : e.organization_detail.province,
                e.member_type === "1"
                  ? e.member_detail.email
                  : e.organization_detail.email,
                e.activity_point,
                ACTIVITY_RENDER_STATUS(e.status)?.label,
                i + 1,
                0,
                e.answer,
              ]);
              return result;
            }, []);
          ws_data.splice(0, 0, [
            "ID",
            "Customer ID",
            "Create Date",
            "Activity code",
            "Name",
            "Gender",
            "Age",
            "Province",
            "Email",
            "Point",
            "Status",
            "No",
            "Select",
            "Answer",
          ]);
          let ws = XLSX.utils.aoa_to_sheet(ws_data);
          let wscols = [
            { wch: 15 },
            { wch: 15 },
            { wch: 30 },
            { wch: 30 },
            { wch: 15 },
            { wch: 15 },
            { wch: 30 },
            { wch: 15 },
            { wch: 15 },
          ];
          ws["!cols"] = wscols;
          /* Add the worksheet to the workbook */
          XLSX.utils.book_append_sheet(wb, ws, ws_name);
          XLSX.writeFile(
            wb,
            `act-report-${data.code}-${data.name}-${moment()
              .tz("Asia/Bangkok")
              .format("DD/MM/YYYY")}.xlsx`
          );
          this.setState({
            isBgLoading: false,
          });
          break;
        case "2":
          /* make worksheet */
          let ws_data2 = res.data.docs.reduce((result, e, i) => {
            result.push([
              e._id,
              e.customer_ref_id,
              momentTZ(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY"),
              e.activity_name,
              e.member_type === "1"
                ? `${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                    ...permission,
                    inputString: e.member_detail.first_name,
                    action: "export",
                  })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                    ...permission,
                    inputString: e.member_detail.last_name,
                    action: "export",
                  })}`
                : RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                    ...permission,
                    inputString: e.organization_detail.name,
                    action: "export",
                  }),
              e.member_type === "1"
                ? `${RENDER_GENDER_TYPE(e.member_detail.gender).labelTh}`
                : "",
              e.member_type === "1"
                ? `${_calculateAge(e.member_detail.birthday)}`
                : "",
              e.member_type === "1"
                ? e.member_detail.province
                : e.organization_detail.province,
              e.member_type === "1"
                ? e.member_detail.email
                : e.organization_detail.email,
              e.is_used_qrcode_special,
              e.activity_point,
              e.qrcode_special_point,
              e.activity_point + e.qrcode_special_point
                ? 0
                : e.qrcode_special_point,
              ACTIVITY_RENDER_STATUS(e.status)?.label,
              e.permission_no,
              e.is_selected ? 1 : 0,
              e.answer,
            ]);
            return result;
          }, []);
          ws_data2.splice(0, 0, [
            "ID",
            "Customer ID",
            "Create Date",
            "Activity code",
            "Name",
            "Gender",
            "Age",
            "Province",
            "Email",
            "use special point",
            "Point",
            "Special point",
            "Total",
            "Status",
            "No",
            "Select",
            "Answer",
          ]);
          let ws2 = XLSX.utils.aoa_to_sheet(ws_data2);
          let wscols2 = [
            { wch: 15 },
            { wch: 15 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];
          ws2["!cols"] = wscols2;
          /* Add the worksheet to the workbook */
          XLSX.utils.book_append_sheet(wb, ws2, ws_name);
          XLSX.writeFile(
            wb,
            `act-report-${data.code}-${data.name}-${moment()
              .tz("Asia/Bangkok")
              .format("DD/MM/YYYY")}.xlsx`
          );
          this.setState({
            isBgLoading: false,
          });
          break;
        case "3":
          /* make worksheet */
          let ws_data3 = res.data.docs.reduce((result, e, i) => {
            let params = [
              e._id,
              e.customer_ref_id,
              momentTZ(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY"),
              e.activity_name,
              e.member_type === "1"
                ? `${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                    ...permission,
                    inputString: e.member_detail.first_name,
                    action: "export",
                  })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                    ...permission,
                    inputString: e.member_detail.last_name,
                    action: "export",
                  })}`
                : RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                    ...permission,
                    inputString: e.organization_detail.name,
                    action: "export",
                  }),
              e.member_type === "1"
                ? `${RENDER_GENDER_TYPE(e.member_detail.gender).labelTh}`
                : "",
              e.member_type === "1"
                ? `${_calculateAge(e.member_detail.birthday)}`
                : "",
              e.member_type === "1"
                ? e.member_detail.province
                : e.organization_detail.province,
              e.member_type === "1"
                ? e.member_detail.email
                : e.organization_detail.email,
              e.activity_point,
              ACTIVITY_RENDER_STATUS(e.status)?.label,
              e.rejection_reason,
            ];
            if (e.question_list && e.question_list.length > 0) {
              e.question_list.forEach((f) => {
                params.push(f.answer);
              });
            }
            result.push(params);
            return result;
          }, []);
          ws_data3.splice(0, 0, [
            "ID",
            "Customer ID",
            "Create Date",
            "Activity code",
            "Name",
            "Gender",
            "Age",
            "Province",
            "Email",
            "Point",
            "Status",
            "Reject",
            ...(res.data.docs && res.data.docs.length > 0
              ? res.data.docs[0].question_list.map((e, i) => `Ans${i + 1}`)
              : []),
          ]);
          let ws3 = XLSX.utils.aoa_to_sheet(ws_data3);
          let wscols3 = [
            { wch: 15 },
            { wch: 15 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];
          ws3["!cols"] = wscols3;
          /* Add the worksheet to the workbook */
          XLSX.utils.book_append_sheet(wb, ws3, ws_name);
          XLSX.writeFile(
            wb,
            `act-report-${data.code}-${data.name}-${moment()
              .tz("Asia/Bangkok")
              .format("DD/MM/YYYY")}.xlsx`
          );
          this.setState({
            isBgLoading: false,
          });
          break;
        default:
          break;
      }
    }
  };

  handleImport = () => {
    const { perPage } = this.state;
    this.setState({
      isShowModal: true,
      renderModal: (
        <ActivityParticipate
          onImportSuccess={() => {
            const query =
              this.props.router.location.search || `?limit=${perPage}`;
            this.setState({
              isShowModal: false,
            });
            this.fetchData(query);
          }}
        />
      ),
    });
  };

  handleChangeSelect = (e, values) => {
    const { activityHistorydata } = this.state;
    let temp = activityHistorydata.docs;
    let findIndex = temp.findIndex((f) => f._id === values._id);
    if (findIndex === -1) {
    } else {
      temp[findIndex]["is_selected"] = e;
    }
    this.setState({
      activityHistorydata: { ...activityHistorydata, docs: temp },
    });
  };
  handleSave = () => {
    const { activityHistorydata, filterQuery } = this.state;
    AlertControl.show({
      title: "ยืนยันการอนุมัติ",
      description: "คุณต้องการจะอนุมัติใช่หรือไม่",
      btnLabel1: "ยกเลิก",
      btnLabel2: "ยืนยัน",
      onEvent2: async () => {
        let filterStatus = activityHistorydata.docs.filter(
          (e) => e.status === "3"
        );
        if (!filterStatus || filterStatus.length === 0) {
          toast.error("กรุณาเลือก activity");
        } else {
          this.setState({
            isBgLoading: true,
          });
          let err = [];
          let resArr = await Promise.all(
            filterStatus.map((e) => {
              return new Promise(async (resolve, reject) => {
                let params = {
                  is_selected: e.is_selected,
                };
                let res = await portalService.PUT_ACTIVITY_HITORY(
                  e._id,
                  params
                );
                if (res && res.status === 200) {
                  resolve(true);
                } else {
                  err.push(e);
                  resolve(false);
                }
              });
            })
          );
          if (resArr) {
            toast.success("บันทึกรายการสำเร็จ");
            this.setState({
              isLoading: true,
            });
            setTimeout(() => {
              this.fetchData(filterQuery);
            }, 400);
          }
        }
      },
    });
  };

  handleAnnounch = async () => {
    const { data } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      activity_id: this.props.router.params.id,
      announcement_type: data.announcement_type,
    };
    let res = await portalService.POST_ACTIVITY_ANNOUNCEMEN_DETAIL(params);
    if (res && res.status === 200) {
      this.fetchData(`?pagination=false`);
      toast.success("ประกาศผลสำเร็จ");
    } else {
      this.fetchData(`?pagination=false`);
      toast.error("ประกาศผลไม่สำเร็จ");
    }
  };

  handleApproveFormType3 = async (permission, e) => {
    if (!permission || !permission.is_update) {
      toast.error("คุณไม่มีสิทธิ์ในการให้คะแนน");
    } else {
      this.setState({
        isBgLoading: true,
      });
      let res = await portalService.PUT_ACTIVITY_NOMAL_FORM_APPROVE(e._id);
      if (res && res.status === 200) {
        this.fetchData(`?pagination=false`);
      } else {
        toast.error("อนุมัติไม่สำเร็จ กรุณาเช็คเงื่อนไข");
      }
    }
  };

  handleBgLoading = (e) => {
    this.setState({
      isBgLoading: e,
    });
  };

  handleQuestionEdit = (permission, e) => {
    const { filterQuery } = this.state;
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false`
      : `?pagination=false`;
    this.setState({
      isShowModal: true,
      renderModal: (
        <ActQuestionContainer
          initialValues={e}
          permission={permission}
          onQuestionUpdateFail={() => {
            this.setState({
              isShowModal: false,
              renderModal: false,
            });
          }}
          onQuestionUpdateSuccess={() => {
            this.setState({
              isShowModal: false,
              renderModal: false,
              isLoading: true,
            });
            this.fetchData(joinQuery);
          }}
        />
      ),
    });
  };

  renderColumn = (e, permission) => {
    switch (e.type) {
      case "1":
        return type1Columns({
          permission: permission,
          data: e,
          announcementType: e.announcement_type,
          handleClickGifgPoint: (f) => this.handleClickGifgPoint(permission, f),
          handleChangeSelect: this.handleChangeSelect,
        });
      case "2":
        return columns({
          permission: permission,
          data: e,
          announcementType: e.announcement_type,
          handleClickGifgPoint: (f) => this.handleClickGifgPoint(permission, f),
          handleChangeSelect: this.handleChangeSelect,
        });
      case "3":
        return type3Columns({
          permission: permission,
          data: e,
          announcementType: e.announcement_type,
          handleApproveeSuccess: () => this.fetchData(`?pagination=false`),
          handleBgLoading: this.handleBgLoading,
          handleChangeSelect: this.handleChangeSelect,
          handleQuestionEdit: (f) => this.handleQuestionEdit(permission, f),
        });
      default:
        break;
    }
  };

  render() {
    const { authenRedux } = this.props;
    const {
      isLoading,
      isLoadingTable,
      isBgLoading,
      data,
      activityHistorydata,
      pagination,
      // perPage,
      isShowModal,
      selectedAmount,
      renderModal,
    } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "activity");
    return (
      <ActivityOfflineDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <div className="block_section">
              <div className="title">ข้อมูลผู้เข้าร่วมกิจกรรม</div>
              <div className="body_row">
                <div className="b_left_col">
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={"รหัส"}
                      value={`${data.code}`}
                    />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={"ชนิด"}
                      value={ACTIVITY_RENDER_TYPE(data.type)}
                    />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue label={"ชื่อ"} value={data.name} />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={"คะแนน"}
                      value={`${data.point}`}
                    />
                  </div>
                  {data.question_list && data.question_list.length > 0 && (
                    <>
                      <div className="title">คำถาม</div>
                      <div className="body pl_1">
                        {data.question_list &&
                          data.question_list.map((e, i) => (
                            <div key={i} className="question_row">
                              <div
                                className="question_label"
                                dangerouslySetInnerHTML={{ __html: e.question }}
                              >
                                {/* {`ข้อที่ ${i + 1} `} */}
                                {/* {e.question} */}
                              </div>
                              <div className="question_ans_block">
                                {e.answer_list.map((f, j) => (
                                  <div key={j} className="ans_label">
                                    {`ตอบ ${j + 1} `}
                                    {f}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>
                {data.permission_type === "2" && (
                  <div className="b_right_col">
                    <div className="value_item_wrap">
                      <Displays.LabelValue
                        leftWidth="150px"
                        label={"จำนวนที่กำหนด"}
                        value={data.permission_main_quotar}
                      />
                    </div>
                    {data.announcement_type === "2" && (
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          leftWidth="150px"
                          label={"จำนวนสำรองที่กำหนด"}
                          value={data.permission_reserve_quotar}
                        />
                      </div>
                    )}
                    <div className="value_item_wrap">
                      <Displays.LabelValue
                        leftWidth="150px"
                        label={"วันสุดท้ายที่เข้าร่วม"}
                        value={
                          !data.last_date
                            ? ""
                            : moment(data.last_date).format("DD/MM/YYYY")
                        }
                      />
                    </div>
                    {data.announcement_type === "2" && (
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          leftWidth="150px"
                          label={"วันที่ประกาศ"}
                          value={
                            !data.announcement_date
                              ? ""
                              : moment(data.announcement_date).format(
                                  "DD/MM/YYYY"
                                )
                          }
                        />
                      </div>
                    )}
                    <div className="value_item_wrap">
                      <Displays.LabelValue
                        leftWidth="150px"
                        label={"จำนวนที่เหลือ"}
                        isHighligth
                        value={data.permission_main_quotar - selectedAmount}
                      />
                    </div>
                    <div className="value_item_wrap">
                      <Displays.LabelValue
                        leftWidth="150px"
                        label={"ชนิดการประกาศ"}
                        value={
                          data.announcement_type === "1"
                            ? "Run by Que"
                            : "Select by Admin"
                        }
                      />
                    </div>
                    {data.announcement_type === "2" && (
                      <div className="value_item_wrap">
                        <Displays.LabelValue
                          leftWidth="150px"
                          label={"สถานะประกาศ"}
                          value={
                            data.status === "2" ? "ประกาศ" : "ยังไม่ประกาศ"
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="filter_wrap">
              <Inputs.InputFilter
                theme_standard_input_filter
                dataFilter={[]}
                btnLabel="ค้นหา"
                onQueryFilter={this.handleFilterKeyword}
                onExport={
                  permission &&
                  (permission.is_export || permission.is_export_condition)
                    ? () => this.handleExport(permission)
                    : false
                }
                onImport={
                  data.status === "1" &&
                  data.permission_type === "2" &&
                  data.announcement_type === "2" &&
                  this.handleImport
                }
                onSave={
                  data.status === "1" &&
                  data.permission_type === "2" &&
                  data.announcement_type === "2"
                    ? this.handleSave
                    : false
                }
                onAnnounch={
                  data.status === "1" &&
                  data.permission_type === "2" &&
                  data.announcement_type === "2"
                    ? this.handleAnnounch
                    : false
                }
              />
            </div>
            {isLoadingTable ? (
              <Displays.Loading theme_standard_loading />
            ) : (
              <div className="body_table">
                <Tables.Standard
                  columns={
                    this.renderColumn(data, permission)
                    // data.type === "1"
                    //   ? type1Columns({
                    //       permission: permission,
                    //       data: data,
                    //       announcementType: data.announcement_type,
                    //       handleClickGifgPoint: (e) =>
                    //         this.handleClickGifgPoint(permission, e),
                    //       handleChangeSelect: this.handleChangeSelect,
                    //     })
                    //   : columns({
                    //       permission: permission,
                    //       data: data,
                    //       announcementType: data.announcement_type,
                    //       handleClickGifgPoint: (e) =>
                    //         this.handleClickGifgPoint(permission, e),
                    //       handleChangeSelect: this.handleChangeSelect,
                    //     })
                  }
                  data={activityHistorydata.docs}
                  showPagination
                  pagination={pagination}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            )}
          </>
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={() => {
            this.setState({ isShowModal: false });
          }}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </ActivityOfflineDetailContainerStyled>
    );
  }
}

const statusSort = { 2: 0, 1: 1, 3: 2, 4: 3 };

const type1Columns = ({
  data,
  permission,
  handleChangeSelect,
  announcementType,
}) => {
  let temp = [
    {
      Header: <div style={{ textAlign: "left" }}>Create Date</div>,
      accessor: "created_at",
      Cell: (props) => (
        <div>
          {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Name</div>,
      accessor: "member_detail.first_name",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>
            {`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.value,
            })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.row.original.member_detail?.last_name,
            })}`}
          </div>
        ) : (
          <div>
            {`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.row.original.organization_detail.name,
            })}`}
          </div>
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Gender</div>,
      accessor: "member_detail.gender",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>{`${RENDER_GENDER_TYPE(props.value).labelTh}`}</div>
        ) : (
          ""
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Age</div>,
      accessor: "member_detail.birthday",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>{`${_calculateAge(props.value)}`}</div>
        ) : (
          ""
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Province</div>,
      accessor: "member_detail.province",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>{`${props.value}`}</div>
        ) : (
          props.row.original.organization_detail.province
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Email</div>,
      accessor: "member_detail.email",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>{`${props.value || ""}`}</div>
        ) : (
          props.row.original.organization_detail.email
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Point</div>,
      accessor: "activity_point",
      Cell: (props) => <div>{`${props.value}`}</div>,
    },
    {
      Header: <div style={{ textAlign: "center" }}>Status</div>,
      accessor: "status",
      Cell: (props) => {
        const renderStatus = ACTIVITY_RENDER_STATUS(props.value);
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Displays.StatusLabel
              bg={renderStatus?.bg}
              label={renderStatus?.label}
            />
          </div>
        );
      },
    },
  ];
  if (announcementType === "2") {
    temp.splice(temp.length - 1, 0, {
      Header: <div style={{ textAlign: "left" }}>เลือก</div>,
      accessor: "is_selected",
      Cell: (props) => (
        <div>
          {props.row.original.status === "3" && (
            <Inputs.InputCheckBox
              checked={props.value}
              disabled={props.row.original.status !== "3"}
              onChange={(e) =>
                handleChangeSelect(e.target.checked, props.row.original)
              }
            />
          )}
        </div>
      ),
    });
  }
  if (data.question_list && data.question_list.length > 0) {
    data.question_list.forEach((e, i) => {
      temp.push({
        Header: <div style={{ textAlign: "left" }}>{`Ans${i + 1}`}</div>,
        accessor: `question_list${i + 1}`,
        Cell: (props) =>
          props.row.original.question_list[i].type === "3" ? (
            <a
              href={IMG_PATH + props.row.original.question_list[i].answer}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link
            </a>
          ) : (
            <div>{props.row.original.question_list[i].answer}</div>
          ),
      });
    });
  }
  return temp;
};

const columns = ({
  data,
  announcementType,
  permission,
  handleClickGifgPoint,
  handleChangeSelect,
}) => {
  let temp = [
    {
      Header: <div style={{ textAlign: "left" }}>Create Date</div>,
      accessor: "created_at",
      Cell: (props) => (
        <div>
          {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Activity code</div>,
      accessor: "activity_code",
      Cell: (props) => <div>{`${props.value}`}</div>,
    },
    {
      Header: <div style={{ textAlign: "left" }}>Name</div>,
      accessor: "member_detail.first_name",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>
            {`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.value,
            })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.row.original.member_detail?.last_name,
            })}`}
          </div>
        ) : (
          <div>
            {`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.row.original.organization_detail.name,
            })}`}
          </div>
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Gender</div>,
      accessor: "member_detail.gender",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>{`${RENDER_GENDER_TYPE(props.value).labelTh}`}</div>
        ) : (
          ""
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Age</div>,
      accessor: "member_detail.birthday",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>{`${_calculateAge(props.value)}`}</div>
        ) : (
          ""
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Province</div>,
      accessor: "member_detail.province",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>{`${props.value}`}</div>
        ) : (
          props.row.original.organization_detail.province
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Email</div>,
      accessor: "member_detail.email",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>{`${props.value || ""}`}</div>
        ) : (
          props.row.original.organization_detail.email
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Point</div>,
      accessor: "activity_point",
      Cell: (props) => <div>{`${props.value}`}</div>,
    },
    {
      Header: <div style={{ textAlign: "left" }}>Passed</div>,
      accessor: "action",
      Cell: (props) => {
        switch (props.row.original.permission_type) {
          case "1":
            switch (props.row.original.status) {
              case "1":
                return (
                  <div onClick={() => handleClickGifgPoint(props.row.original)}>
                    <MdCheckCircle size={22} color="#4b1e78" />
                  </div>
                );
              case "2":
                return <MdCheckCircle size={22} color="green" />;
              default:
                break;
            }
            break;
          case "2":
            switch (props.row.original.status) {
              case "1":
                return (
                  <div onClick={() => handleClickGifgPoint(props.row.original)}>
                    <MdCheckCircle size={22} color="#4b1e78" />
                  </div>
                );
              case "2":
                return <MdCheckCircle size={22} color="green" />;
              default:
                break;
            }
            break;
          default:
            break;
        }
      },
    },
    {
      Header: <div style={{ textAlign: "left" }}>ลำดับ</div>,
      accessor: "permission_no",
      Cell: (props) => <div>{props.value}</div>,
    },
    // {
    //   Header: <div style={{ textAlign: "left" }}>คำตอบ</div>,
    //   accessor: "answer",
    //   Cell: (props) => <div>{props.value}</div>,
    // },
  ];
  if (announcementType === "2") {
    temp.splice(temp.length - 1, 0, {
      Header: <div style={{ textAlign: "left" }}>เลือก</div>,
      accessor: "is_selected",
      Cell: (props) => (
        <div>
          {props.row.original.status === "3" && (
            <Inputs.InputCheckBox
              checked={props.value}
              disabled={props.row.original.status !== "3"}
              onChange={(e) =>
                handleChangeSelect(e.target.checked, props.row.original)
              }
            />
          )}
        </div>
      ),
    });
  }
  if (data.question_list && data.question_list.length > 0) {
    data.question_list.forEach((e, i) => {
      temp.push({
        Header: <div style={{ textAlign: "left" }}>{`Ans${i + 1}`}</div>,
        accessor: `question_list${i + 1}`,
        Cell: (props) =>
          props.row.original.question_list[i].type === "3" ? (
            <a
              href={IMG_PATH + props.row.original.question_list[i].answer}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link
            </a>
          ) : (
            <div>{props.row.original.question_list[i].answer}</div>
          ),
      });
    });
  }
  return temp;
};

const type3Columns = ({
  data,
  announcementType,
  permission,
  handleApproveeSuccess,
  handleBgLoading,
  handleChangeSelect,
  handleQuestionEdit,
}) => {
  let temp = [
    {
      Header: <div style={{ textAlign: "left" }}>Create Date</div>,
      accessor: "created_at",
      Cell: (props) => (
        <div>
          {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Activity code</div>,
      accessor: "activity_code",
      Cell: (props) => <div>{`${props.value}`}</div>,
    },
    {
      Header: <div style={{ textAlign: "left" }}>Name</div>,
      accessor: "member_detail.first_name",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>
            {`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.value,
            })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.row.original.member_detail?.last_name,
            })}`}
          </div>
        ) : (
          <div>
            {`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: props.row.original.organization_detail.name,
            })}`}
          </div>
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Gender</div>,
      accessor: "member_detail.gender",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>{`${RENDER_GENDER_TYPE(props.value).labelTh}`}</div>
        ) : (
          ""
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Age</div>,
      accessor: "member_detail.birthday",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>{`${_calculateAge(props.value)}`}</div>
        ) : (
          ""
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Province</div>,
      accessor: "member_detail.province",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>{`${props.value}`}</div>
        ) : (
          props.row.original.organization_detail.province
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Email</div>,
      accessor: "member_detail.email",
      Cell: (props) =>
        props.row.original.member_type === "1" ? (
          <div>{`${props.value || ""}`}</div>
        ) : (
          props.row.original.organization_detail.email
        ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Point</div>,
      accessor: "activity_point",
      Cell: (props) => <div>{`${props.value}`}</div>,
    },
    {
      Header: <div style={{ textAlign: "left" }}>Approved</div>,
      accessor: "action",
      Cell: (props) => {
        return (
          <BtnApproveWidget
            id={props.row.original._id}
            value={props.row.original.status}
            onBgLoading={handleBgLoading}
            onSuccess={handleApproveeSuccess}
          />
        );

        // switch (props.row.original.permission_type) {
        //   case "1":
        //     switch (props.row.original.status) {
        //       case "3":
        //         return (
        //           <div
        //             onClick={() => handleApproveFormType3(props.row.original)}
        //           >
        //             <MdCheckCircle size={22} color="#4b1e78" />
        //           </div>
        //         );
        //       case "2":
        //         return <MdCheckCircle size={22} color="green" />;
        //       default:
        //         break;
        //     }
        //     break;
        //   default:
        //     break;
        // }
      },
    },
    {
      Header: <div style={{ textAlign: "left" }}>Reject</div>,
      accessor: "rejection_reason",
      Cell: (props) => <div>{props.value || "-"}</div>,
    },
  ];
  if (announcementType === "2") {
    temp.splice(temp.length - 1, 0, {
      Header: <div style={{ textAlign: "left" }}>เลือก</div>,
      accessor: "is_selected",
      Cell: (props) => (
        <div>
          {props.row.original.status === "3" && (
            <Inputs.InputCheckBox
              checked={props.value}
              disabled={props.row.original.status !== "3"}
              onChange={(e) =>
                handleChangeSelect(e.target.checked, props.row.original)
              }
            />
          )}
        </div>
      ),
    });
  }
  if (data.question_list && data.question_list.length > 0) {
    data.question_list.forEach((e, i) => {
      temp.push({
        Header: <div style={{ textAlign: "left" }}>{`Ans${i + 1}`}</div>,
        accessor: `question_list${i + 1}`,
        Cell: (props) =>
          props.row.original.question_list[i].type === "3" ? (
            <a
              href={IMG_PATH + props.row.original.question_list[i].answer}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link
            </a>
          ) : (
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {props.row.original.question_list[i].answer}
            </Linkify>
          ),
      });
    });
  }
  temp.push({
    Header: <div style={{ textAlign: "left" }}>edit</div>,
    accessor: "activity_pointss",
    Cell: (props) => (
      <div onClick={() => handleQuestionEdit(props.row.original)}>
        <Displays.StatusLabel bg={"#FFB341CC"} label="แก้ไข" />
      </div>
    ),
  });
  return temp;
};

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivityOfflineDetailContainer));
