import React from 'react';
import { EWarrantyDepCodeContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { portalService } from 'apiServices';
import moment from 'moment';
import { Displays, Tables } from 'components';

class EWarrantyDepCodeContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(this.props.router.location.search);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.router.location.search &&
      this.props.router.location.search !== prevProps.router.location.search
    ) {
      this.setState({
        isLoading: true,
      });
      this.fetchData(this.props.router.location.search);
    }
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_E_WARRANTY_OVERVIEW_STATUS(
      stringQuery
        ? `${stringQuery}&cate=pd_dep_code`
        : `?cate=pd_dep_code&period=year&start_date=${moment()
            .startOf('year')
            .format('YYYY-MM-DD')}&end_date=${moment()
            .endOf('year')
            .format('YYYY-MM-DD')}`
    );
    if (res && res.status === 200) {
      this.setState({
        data: res.data.cate_group,
        isLoading: false,
      });
    }
  };

  render() {
    const { isLoading, data } = this.state;
    return (
      <EWarrantyDepCodeContainerStyled>
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <div className="body_table">
              <Tables.Standard columns={columns()} data={data} />
            </div>
          </>
        )}
      </EWarrantyDepCodeContainerStyled>
    );
  }
}

const columns = () => [
  {
    Header: <div style={{ textAlign: 'left' }}>Product Dep.</div>,
    accessor: '_id',
    Cell: (props) => props.value,
  },
  {
    Header: (
      <div style={{ textAlign: "left", color: "green" }}>Serial Upload</div>
    ),
    accessor: "serial_total",
    Cell: (props) => <div style={{ color: "green" }}> {props.value}</div>,
  },
  {
    Header: (
      <div style={{ textAlign: "left", color: "green" }}>Serial Registed</div>
    ),
    accessor: "register_count",
    Cell: (props) => <div style={{ color: "green" }}> {props.value}</div>,
  },
  {
    Header: (
      <div style={{ textAlign: "left", color: "blue" }}>Total Registed</div>
    ),
    accessor: "register_serial_count",
    Cell: (props) => <div style={{ color: "blue" }}> {props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left", color: "blue" }}>Waiting</div>,
    accessor: "waiting_count",
    Cell: (props) => <div style={{ color: "blue" }}> {props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left", color: "blue" }}>Approve</div>,
    accessor: "approved_count",
    Cell: (props) => <div style={{ color: "blue" }}> {props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left", color: "blue" }}>Reject</div>,
    accessor: "reject_count",
    Cell: (props) => <div style={{ color: "blue" }}> {props.value}</div>,
  },
];

export default withRouter(EWarrantyDepCodeContainer);
