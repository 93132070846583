import styled from "styled-components";

export const UploadImageStyled = styled.div`
  width: 100%;
  .label {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .errors {
    margin-top: 4px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  .theme_upload_image {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .show_img {
      height: ${({ heightBox }) => (heightBox ? heightBox : "231px")};
      border-radius: 12px;
      width: ${({ width }) => (width ? width : "100%")};
      margin: 10px 0px;
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      -webkit-box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      object-fit: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      .close {
        position: absolute;
        right: 7px;
        top: 8px;
        cursor: pointer;
        z-index: 2;
      }
      .block_more {
        border-radius: 12px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // opacity: 0.5;
        background: #ffffff80;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        cursor: pointer;
        .num_show {
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
          color: ${({ theme }) => theme.COLORS.BLUE_2};
          position: relative;
        }
      }
    }
    .container_other {
      height: ${({ heightBox }) => (heightBox ? heightBox : "231px")};
      border-radius: 12px;
      width: ${({ width }) => (width ? width : "calc(32.5% - 10px)")};
      margin: 10px;
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      -webkit-box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .show_other {
        border-radius: 12px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.5;
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S48};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLUE_2};
        position: relative;
      }
    }
    .upload_container {
      position: relative;
      width: 100%;
      .box_upload {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        &.disabled {
          .box_path {
            cursor: not-allowed;
            background: ${({ theme }) => theme.COLORS.GRAY_1};
          }
          .btn_upload {
            cursor: not-allowed;
          }
        }
        .box_path {
          border-top-left-radius: 1rem;
          border-bottom-left-radius: 1rem;
          flex: 1;
          width: 100%;
          .path_name {
            color: ${({ theme }) => theme.COLORS.BLUE_2};
            font-size: ${({ theme }) => theme.FONT.SIZE.S13};
            font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
            height: 2.75rem;
            padding: 0px 35px 0 15px;
            display: flex;
            align-items: center;
            border: 1px solid #f5f5f5;
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
            &:hover {
              border-color: ${({ theme }) => theme.COLORS.BLUE_3};
            }
          }
        }
        .btn_upload {
          background: #d7dae3;
          min-width: 72px;
          display: flex;
          justify-content: center;
          cursor: pointer;
          border: 0.0625rem solid transparent #f5f5f5;
          color: ${({ theme }) => theme.COLORS.BLUE_2};
          font-size: ${({ theme }) => theme.FONT.SIZE.S13};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-right-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }
      }
      .remove_image {
        position: absolute;
        top: 3px;
        right: 78px;
        padding-top: 10px;
        z-index: 22;
        cursor: pointer;
      }
    }

    .input_upload_scale_label {
      margin-top: 2px;
      padding-left: 8px;
      color: ${({ theme }) => theme.COLORS.GRAY_2}A6;
      font-size: ${({ theme }) => theme.FONT.SIZE.S13};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    }

    .btn_show {
      width: 100%;
      margin-top: 33px;
      padding: 0px 17%;
    }
  }
`;
