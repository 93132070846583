import React from "react";
import { QuestionFormStyled } from "./styled";
import { Controller, useFieldArray } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import AnswerForm from "../AnswerForm";
import { AlertControl } from "components/Modals/Alert";
import { Icons, Inputs } from "components";
import { IMG_PATH } from "utils/constants/imgPath";

const QuestionForm = ({
  nestIndex,
  pdpaOptions,
  control,
  errors,
  dataItem,
  watchQuestionList,
  onDeleteQuestion,
}) => {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `question_list[${nestIndex}].questions`,
  });

  return (
    <QuestionFormStyled>
      {fields.map((item, index) => (
        <div key={item.id} className="question_block">
          <div className="qt_header_block">
            <div className="qth_row">
              <div className="qth_no">{`คำถามข้อที่ ${index + 1}`}</div>
              <div className="move_block">
                เลื่อนคำถาม
                <div
                  className={`btn_move ${index !== 0 ? "" : "disabled"}`}
                  onClick={() => {
                    if (index !== 0) {
                      move(index, index - 1);
                    }
                  }}
                >
                  <Icons.MoveUp />
                </div>
                <div
                  className={`btn_move ${
                    index < fields.length - 1 ? "" : "disabled"
                  }`}
                  onClick={() => {
                    if (index < fields.length - 1) {
                      move(index, index + 1);
                    }
                  }}
                >
                  <Icons.MoveDown />
                </div>
              </div>
              <div className="upload_block">
                {/* เพิ่มรูปใต้คำถาม
                <div className="btn_add_img">
                  <Icons.Image />
                </div> */}
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImageQuestion
                      {...field}
                      theme_upload_image
                      fieldName="question"
                      acceptType={"cover"}
                      placeholder="Add picture (No file chosen yet)"
                      // scaleLabel="*** รูปขนาดกว้าง 720px สูง 720px"
                    />
                  )}
                  name={`question_list.${nestIndex}.questions.${index}.image`}
                  defaultValue={[]}
                />
              </div>
              <div className="active_block">
                <div className="require_label">Required*</div>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputSwitch
                      {...field}
                      errors={errors.is_condition?.message}
                    />
                  )}
                  name={`question_list.${nestIndex}.questions.${index}.is_require`}
                  defaultValue={false}
                />
              </div>
              <div
                className="btn_delete"
                onClick={() => {
                  AlertControl.show({
                    title: "ยืนยันการลบ",
                    description: "คุณต้องการจะลบใช่หรือไม่",
                    btnLabel1: "ยกเลิก ",
                    btnLabel2: "ยืนยัน",
                    onEvent2: async () => {
                      onDeleteQuestion &&
                        onDeleteQuestion("question", dataItem, item);
                      remove(index);
                    },
                  });
                }}
              >
                <MdDelete fontSize={25} color={"red"} />
              </div>
            </div>
            <div className="qrh_input_wrap">
              <Controller
                render={({ field }) => <Inputs.InputWysiwyg {...field} />}
                name={`question_list.${nestIndex}.questions.${index}.question`}
                control={control}
              />
            </div>
          </div>
          <div className="qt_lv3_block">
            <div className="qt_lv3_input_wrap">
              {watchQuestionList &&
                watchQuestionList[nestIndex].questions[index] &&
                watchQuestionList[nestIndex].questions[index].image &&
                watchQuestionList[nestIndex].questions[index].image.length >
                  0 && (
                  <img
                    className="upload_img"
                    alt="preview question"
                    src={
                      watchQuestionList[nestIndex].questions[index].image[0]
                        ?.preview ||
                      watchQuestionList[nestIndex].questions[index].image[1]
                        ?.preview ||
                      IMG_PATH +
                        watchQuestionList[nestIndex].questions[index].image[0]
                          ?.media_uri
                    }
                  />
                )}
            </div>
            {/* <div className="qt_lv3_input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.UploadImage
                    {...field}
                    theme_upload_image
                    fieldName="question"
                    acceptType={"cover"}
                    placeholder="Add picture (No file chosen yet)"
                    // scaleLabel="*** รูปขนาดกว้าง 720px สูง 720px"
                  />
                )}
                name={`question_list.${nestIndex}.questions.${index}.image`}
                defaultValue={[]}
              />
            </div> */}
            <div className="qt_lv3_input_wrap">
              <Controller
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={ANSWER_TYPE_LIST}
                    errors={errors.type?.message}
                  />
                )}
                name={`question_list.${nestIndex}.questions.${index}.type`}
                control={control}
              />
            </div>
            {watchQuestionList &&
              watchQuestionList[nestIndex].questions[index] &&
              watchQuestionList[nestIndex].questions[index].type === "1" && (
                <div className="answer_block">
                  <AnswerForm
                    nestIndex={nestIndex}
                    nestIndexLv2={index}
                    {...{ control, errors }}
                  />
                </div>
              )}
            {watchQuestionList &&
              watchQuestionList[nestIndex].questions[index] &&
              watchQuestionList[nestIndex].questions[index].type === "4" && (
                // <div className="answer_block">
                <Controller
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={pdpaOptions || []}
                      errors={errors.type?.message}
                    />
                  )}
                  name={`question_list.${nestIndex}.questions.${index}.pdpa_ref_id`}
                  control={control}
                />
                // </div>
              )}
          </div>
        </div>
      ))}
      <button type="button" className="btn_add_lv2" onClick={() => append()}>
        + Add question
      </button>
    </QuestionFormStyled>
  );
};

const ANSWER_TYPE_LIST = [
  {
    label: "Choice",
    value: "1",
  },
  {
    label: "Freetext",
    value: "2",
  },
  {
    label: "Upload",
    value: "3",
  },
  {
    label: "PDPA",
    value: "4",
  },
  {
    label: "Date",
    value: "5",
  },
];

QuestionForm.propTypes = {};

export default QuestionForm;
