import styled from 'styled-components';

export const BranchFormStyled = styled.div`
  .input_row {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    .f_left_col {
      width: 20%;
      display: flex;
      align-items: center;
    }
    .f_right_col {
      width: 40%;
    }
  }
  .action_row {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
  }
`;
