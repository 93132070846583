import React from "react";
import { connect } from "react-redux";
import { ProductWarranteeDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import { RENDER_UNIT } from "utils/functions/unit";
import {
  CAL_WARRANTY_EXPIRE,
  CHECK_WARRANTY,
  RENDER_WARRANTY_STATUS,
  RENDER_STATUS_PRODUCT_WARRANTY,
} from "utils/functions/productWarranty";
import { RENDER_GENDER_TYPE } from "utils/functions/customer";
import { IMG_PATH } from "utils/constants/imgPath";
import { toast } from "react-toastify";
import moment from "moment";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from "utils/functions/string";
import { MdAdd } from "react-icons/md";
import { ProductWarrantyRegisterContainer } from "./Modals";
import { Buttons, Displays, Forms, Modals } from "components";

class ProductWarranteeDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await portalService.GET_PRODUCT_WARRANTEE_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleSubmit = async (values) => {
    let checkValue = true;
    // เช็คกรณีเพิ่มระยะเวลาประกันต้องเลือกหน่วยด้วย
    if (values.extension_period || values.extension_unit) {
      if (values.extension_period && values.extension_unit) {
        checkValue = true;
      } else {
        checkValue = false;
      }
    }
    if (checkValue) {
      this.setState({
        isBgLoading: true,
      });
      let params = {
        product_warrantee_id: this.props.router.params.id,
        status: values.status,
      };
      if (values.status === "3") {
        params.approved_warranty_date = values.approved_warranty_date;
      }
      if (values.status === "5") {
        params.rejected_reason = values.rejected_reason;
      }
      if (values.extension_period) {
        params.extension_period = values.extension_period;
      }
      if (values.extension_unit) {
        params.extension_unit = values.extension_unit;
      }
      let res = await portalService.POST_PRODUCT_WARRANTEE_APPROVAL(params);
      if (res && res.status === 200) {
        let filesTemp = [];
        if (
          values?.slip_img &&
          typeof values.slip_img !== "string" &&
          values.slip_img.length > 0
        ) {
          filesTemp = [
            ...filesTemp,
            ...values.slip_img.filter((e) => e.isNewUpload),
          ];
        }
        if (filesTemp.length === 0) {
          toast.success("อัพเดตสำเร็จ");
          this.fetchData();
        } else {
          this.handleUploadContentMedia(values._id, filesTemp);
          this.fetchData();
        }
      }
    } else {
      toast.error("กรุณาเลือกใส่ข้อมูลระยะเวลาประกันและหน่วยระยะเวลาประกัน");
    }
  };

  handleUploadContentMedia = (product_warrantee_id, files) => {
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set("product_warrantee_id", product_warrantee_id);
            bodyFormData.set("type", e.fieldName);
            bodyFormData.append("image", e);
            let res = await portalService.POST_PRODUCT_WARRANTEE_UPLOAD_IMAGE(
              bodyFormData
            );
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      toast.success("อัพเดจสำเร็จ");
      this.fetchData();
    });
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: false,
    });
  };

  handleProductWarrantyRegis = () => {
    this.setState({
      renderModal: (
        <ProductWarrantyRegisterContainer
          onCancel={this.handleCloseModal}
          onRegistedSuccess={() => {
            this.handleCloseModal();
            this.setState({
              isLoading: true,
            });
            this.fetchData();
          }}
        />
      ),
    });
  };

  handleClickBack = (e) => {
    this.props.router.navigate(-1);
  };

  render() {
    const { authenRedux } = this.props;
    const { isLoading, isBgLoading, data, renderModal } = this.state;
    const renderUnit = RENDER_UNIT(data?.warranty_unit);
    const renderExtensionUnit = RENDER_UNIT(data?.extension_unit);
    const renderStatus = RENDER_STATUS_PRODUCT_WARRANTY(data?.status);
    const renderWarrantyStatus = RENDER_WARRANTY_STATUS(
      data?.status,
      CHECK_WARRANTY(
        data?.approved_warranty_date,
        data?.warranty_period,
        data?.warranty_unit,
        data?.extension_period,
        data?.extension_unit
      )
    );
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "product_warranty");
    return (
      <ProductWarranteeDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <div className="block_section">
              <div className="title">ข้อมูลการรับประกัน</div>
              <div className="body">
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"ชื่อสินค้า"}
                    value={`${data.description} ${data.description_line_2}`}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"หมายเลขสินค้า"}
                    value={data.item_no}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"ระยะเวลาการรับประกัน"}
                    value={
                      !data.warranty_period
                        ? "-"
                        : `${data.warranty_period} ${renderUnit.labelTH}`
                    }
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"ขยายระยะเวลาการรับประกัน"}
                    value={
                      !data.extension_period
                        ? "-"
                        : `${data.extension_period} ${renderExtensionUnit.labelTH}`
                    }
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"เงื่อนไข"}
                    value={data.warranty_description_th}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"Serial no"}
                    value={data.serial_no}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"Invoice no"}
                    value={data.invoice_no}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"Invoice type"}
                    value={data.invoice_type}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"Invoice date"}
                    value={
                      data.invoice_date &&
                      `${moment(data.invoice_date).format("DD/MM/YYYY")}`
                    }
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"Registed at"}
                    value={
                      !data.registed_at
                        ? "-"
                        : `${moment(data.registed_at).format("DD/MM/YYYY")}`
                    }
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"Approval at"}
                    value={
                      !data.approved_warranty_date
                        ? "-"
                        : `${moment(data.approved_warranty_date).format(
                            "DD/MM/YYYY"
                          )}`
                    }
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"Warranty expired"}
                    value={
                      !data.approved_warranty_date && data.status !== "3"
                        ? "-"
                        : CAL_WARRANTY_EXPIRE(
                            data.approved_warranty_date,
                            data.warranty_period,
                            data.warranty_unit,
                            data.extension_period,
                            data.extension_unit
                          ).format("DD/MM/YYYY")
                    }
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"Dealer code"}
                    value={data.dealer_code}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"Dealer name"}
                    value={data.dealer_name}
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"สถานะ"}
                    value={
                      <Displays.StatusLabel
                        bg={renderStatus?.bg}
                        label={renderStatus?.label}
                      />
                    }
                  />
                </div>
                <div className="value_item_wrap">
                  <Displays.LabelValue
                    label={"ประกัน"}
                    value={
                      <Displays.StatusLabel
                        bg={renderWarrantyStatus?.bg}
                        label={renderWarrantyStatus?.label}
                      />
                    }
                  />
                </div>
              </div>
            </div>
            {data.status === "1" && permission?.is_update && (
              <div className="btn_regis_wrap">
                <Buttons.BgIconPrefix
                  label="เพิ่มการลงทะเบียน"
                  icon={<MdAdd size={18} />}
                  onClick={this.handleProductWarrantyRegis}
                />
              </div>
            )}
            {data.status !== "1" ? (
              <>
                <div className="block_section">
                  <div className="title">ลงทะเบียนโดย</div>
                  <div className="body">
                    <div className="value_item_wrap">
                      <Displays.LabelValue
                        label={"ชื่อ"}
                        value={`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                          ...permission,
                          inputString: data.first_name,
                        })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                          ...permission,
                          inputString: data.last_name,
                        })}`}
                      />
                    </div>
                    <div className="value_item_wrap">
                      <Displays.LabelValue
                        label={"เบอร์โทร"}
                        value={RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                          ...permission,
                          inputString: data.phone_number,
                          type: "phone",
                        })}
                      />
                    </div>

                    <div className="value_item_wrap">
                      <Displays.LabelValue
                        label={"อีเมล"}
                        value={
                          !data.email
                            ? "-"
                            : RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                                ...permission,
                                inputString: data.email,
                              })
                        }
                      />
                    </div>
                    <div className="value_item_wrap">
                      <Displays.LabelValue
                        label={"เพศ"}
                        value={RENDER_GENDER_TYPE(data.gender)?.labelTh}
                      />
                    </div>
                    <div className="value_item_wrap">
                      <Displays.LabelValue
                        label={"จังหวัด"}
                        value={data.province}
                      />
                    </div>
                    <div className="value_item_wrap">
                      <Displays.LabelValue
                        label={"วันเกิด"}
                        value={moment(data.birthday).format("DD/MM/YYYY")}
                      />
                    </div>
                  </div>
                </div>
                {data.slip_img && (
                  <div className="block_section">
                    <div className="title">รูปใบเสร็จ</div>
                    <div className="body">
                      <Zoom>
                        <img
                          className="slip_img"
                          alt="slip"
                          src={IMG_PATH + data.slip_img}
                        />
                      </Zoom>
                    </div>
                  </div>
                )}
                <Forms.ProductWarrantyForm
                  initialValues={data}
                  isFormDisabled={
                    !permission || !permission?.is_update ? true : false
                  }
                  onSubmit={this.handleSubmit}
                  handleClickBack={this.handleClickBack}
                />
                {data.status !== "1" &&
                  data.status !== "2" &&
                  data.status !== "5" && (
                    <Displays.LabelValue
                      label={"สถานะ"}
                      value={RENDER_STATUS_PRODUCT_WARRANTY(data?.status).label}
                    />
                  )}
              </>
            ) : (
              <div className="button_wrap">
                <Buttons.BgStandard
                  theme_gray
                  label="Back"
                  onClick={this.handleClickBack}
                />
              </div>
            )}
          </>
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </ProductWarranteeDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductWarranteeDetailContainer));
