import React, { useEffect } from "react";
import { BookingFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  BOOKING_STATUS_OPTIONS,
  RENDER_AGE_RANGE,
  RENDER_BOOKING_PURPOSE,
  VISITOR_STATUS_OPTIONS,
} from "utils/functions/booking";
import { RENDER_GENDER_TYPE } from "utils/functions/customer";
import { DIVISION_OPTIONS } from "utils/functions/division";
import { Buttons, Inputs } from "components";
import moment from "moment";

const BookingForm = ({ initialValues, permission, onCancel, onSubmit }) => {
  const schema = yup.object().shape({
    status: yup.string().required("required"),
    division: yup.string().required("equired"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      let params = { ...initialValues };
      reset(params);
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BookingFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row_contain">
          <div className="row_right_contain">
            <div className="right_col">
              <div className="title">ข้อมูลการจอง</div>
              <div className="row_detail">
                <div className="left_detail">วันที่ทำการจอง</div>
                <div className="right_detail">
                  {moment(initialValues.date).format("DD/MM/YYYY")}
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">เวลาที่จอง</div>
                <div className="right_detail">
                  {initialValues?.start_time} - {initialValues.end_time}
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">จุดประสงค์ในการซื้อ</div>
                <div className="right_detail">
                  {RENDER_BOOKING_PURPOSE(initialValues.purpose).labelTh}
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">ระบุอายุของผู้เล่น</div>
                <div className="right_detail">
                  {RENDER_AGE_RANGE(initialValues.age_range).label} ปี
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">ประเภทเครื่องดนตรี</div>
                <div className="right_detail">
                  {initialValues.instrument_type_th}
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">สินค้า</div>
                <div className="right_detail">{initialValues.product_th}</div>
              </div>
              <div className="row_detail">
                <div className="left_detail">ช่วงราคาที่สนใจ</div>
                <div className="right_detail">
                  {initialValues.price_range_min} -{" "}
                  {initialValues.price_range_max}
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">รุ่น / ข้อมูลเพิ่มเติม</div>
                <div className="right_detail">{initialValues.model_info}</div>
              </div>
              <div className="linear"></div>
              <div className="title">ลงทะเบียนการจองโดย</div>
              <div className="row_detail">
                <div className="left_detail">ชื่อ</div>
                <div className="right_detail">
                  {initialValues.customer_first_name}{" "}
                  {initialValues.customer_last_name}
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">เบอร์โทร</div>
                <div className="right_detail">
                  {initialValues.customer_phone_number}
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">อีเมล</div>
                <div className="right_detail">
                  {initialValues.customer_email}
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">เพศ</div>
                <div className="right_detail">
                  {RENDER_GENDER_TYPE(initialValues.gender).labelTh}
                </div>
              </div>
              <div className="linear"></div>
              <div className="title">สถานะการจอง</div>
              <div className="row_detail">
                <div className="left_detail">สถานะ</div>
                <div className="right_detail">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Dropdown
                        {...field}
                        options={BOOKING_STATUS_OPTIONS}
                        placeholder=""
                        errors={errors.status?.message}
                      />
                    )}
                    name="status"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">เหตุผลการยกเลิก</div>
                <div className="right_detail">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextArea
                        {...field}
                        // placeholder={t('test')}
                        errors={errors.reason_reject?.message}
                      />
                    )}
                    name="reason_reject"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">ฝ่ายที่รับผิดชอบ</div>
                <div className="right_detail">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Dropdown
                        {...field}
                        options={DIVISION_OPTIONS}
                        placeholder=""
                        errors={errors.division?.message}
                      />
                    )}
                    name="division"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">Note ภายใน</div>
                <div className="right_detail">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextArea
                        {...field}
                        // placeholder={t('test')}
                        errors={errors.note?.message}
                      />
                    )}
                    name="note"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">Visit Status</div>
                <div className="right_detail">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Dropdown
                        {...field}
                        options={VISITOR_STATUS_OPTIONS}
                        placeholder=""
                        errors={errors.visit_status?.message}
                      />
                    )}
                    name="visit_status"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="linear"></div>
              <div className="title">Ban User</div>
              <div className="row_detail">
                <div className="left_detail">Ban this user</div>
                <div className="right_detail">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputSwitch
                        {...field}
                        errors={errors.is_ban_user?.message}
                      />
                    )}
                    name="is_ban_user"
                  />
                </div>
              </div>
              <div className="row_detail">
                <div className="left_detail">Ban this user until </div>
                <div className="right_detail2">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputSwitch
                        {...field}
                        errors={errors.is_ban_user_limit?.message}
                      />
                    )}
                    name="is_ban_user_limit"
                  />
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.DateTime
                        {...field}
                        errors={errors.ban_user_until_at?.message}
                      />
                    )}
                    name="ban_user_until_at"
                    // defaultValue={new Date()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="action_row">
          {permission.is_update && (
            <div className="w120">
              <Buttons.BgStandard
                type="submit"
                label="Save"
                // disabled={initialValues && initialValues.is_center}
              />
            </div>
          )}
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
        </div>
      </form>
    </BookingFormStyled>
  );
};

BookingForm.propTypes = {};

export default BookingForm;
