import React from "react";
import { ProductWarrantyRegisterFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { portalService } from "apiServices";
import { Buttons, Inputs } from "components";

const ProductWarrantyRegisterForm = ({
  permission,
  // initialValues,
  onCancel,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    member_type: yup.string().required("Member type is required"),
    member: yup.object().required("Member is required"),
    slip_img: yup.array().required("Slip is required"),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchMemberTypeField = watch("member_type");

  return (
    <ProductWarrantyRegisterFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_row">
          <div className="f_left_col">ลงทะเบียนสำหรับ</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  // placeholder={t('test')}
                  options={[
                    { label: "บุคคล", value: "1" },
                    { label: "องค์กร", value: "2" },
                  ]}
                  errors={errors.member_type?.message}
                />
              )}
              name="member_type"
              defaultValue=""
            />
          </div>
        </div>
        {watchMemberTypeField && (
          <>
            <div className="input_row">
              <div className="f_left_col">ค้นหา</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputCustomerAutoSuggestion
                      {...field}
                      apiService={
                        watchMemberTypeField === "1"
                          ? portalService.GET_CUSTOMER_LIST
                          : portalService.GET_ORGANIZATION_LIST
                      }
                      fieldRender={
                        watchMemberTypeField === "1" ? false : "name"
                      }
                      query={
                        watchMemberTypeField === "1" ? "?first_name" : "?name"
                      }
                      errors={errors.member?.message}
                    />
                  )}
                  name="member"
                  // defaultValue={[]}
                />
              </div>
            </div>
            <div className="input_row">
              <div className="f_left_col">อัพโหลดสลิป</div>
              <div className="f_right_col">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      acceptType={"cover"}
                      scaleLabel="*** รูปขนาดกว้าง 197px สูง 148px"
                      errors={errors.slip_img?.message}
                    />
                  )}
                  name="slip_img"
                  // defaultValue={[]}
                />
              </div>
            </div>
          </>
        )}
        <div className="action_row">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
          {permission.is_update && (
            <div className="w120">
              <Buttons.BgStandard
                type="submit"
                label="Register"
                // disabled={initialValues && initialValues.is_center}
              />
            </div>
          )}
        </div>
      </form>
    </ProductWarrantyRegisterFormStyled>
  );
};

ProductWarrantyRegisterForm.propTypes = {};

export default ProductWarrantyRegisterForm;
