import styled from "styled-components";

export const BookingEmailSettingContainerStyled = styled.div`
  padding: 20px;
  .body_table {
    max-width: 400px;
    padding: 20px;
    margin-bottom: 100px;
  }
`;
