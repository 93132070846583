import styled from "styled-components";

export const ActivitySpecialFormStyled = styled.div`
  .f_title {
    margin-bottom: 30px;
    color: ${({ theme }) => theme.COLORS.BLACK_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_BOLD};
  }
  .input_row {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    .f_left_col {
      width: 20%;
      display: flex;
      align-items: center;
    }
    .f_right_col {
      width: 40%;
    }
    &.mb_100 {
      margin-bottom: 100px;
    }
  }
  .qr_code_block {
    margin-bottom: 1rem;
    .f_title {
      margin-bottom: 0.5rem;
    }
    .qrcode_row {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2rem;
      row-gap: 2rem;
    }
  }
  .line_horizontal {
    margin-bottom: 20px;
    height: 3px;
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
  }
  .page_block_container {
    .pb_header_block {
      max-width: 646px;
      display: felx;
      justify-content: space-between;
      align-items: center;
      .pb_page_label {
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_8};
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;
        border-bottom: 0px;
        padding: 12px;
        min-width: 124px;
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        text-align: center;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      }
      .btn_delete_lv1 {
        cursor: pointer;
      }
    }
    .pb_body_lv1_block {
      margin-bottom: 32px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_8};
      padding: 22px;
      max-width: 600px;
      .pt_input_wrap {
        margin-bottom: 20px;
      }
    }
    .pb_question_lv2_block {
      padding-left: 100px;
    }
    .btn_add_lv1 {
      border-radius: 18px;
      padding: 8px 32px;
      background: ${({ theme }) => theme.COLORS.BLUE_6};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      cursor: pointer;
    }
  }
  .action_row {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
  }
`;
