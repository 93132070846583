import ActivityForm from "./ActivityForm";
import ApproveActivityForm from "./ApproveActivityForm";
import ActivityQuestionform from "./ActivityQuestionform";
import ActivitySpecialForm from "./ActivitySpecialForm";
import BusinessUnitForm from "./BusinessUnitForm";
import BranchForm from "./BranchForm";
import BookingForm from "./BookingForm";
import BannerForm from "./BannerForm";
import CertificateForm from "./CertificateForm";
import ChangeStatusForm from "./ChangeStatusForm";
import CorperateForm from "./CorperateForm";
import EditSendCompanyForm from "./EditSendCompanyForm";
import EmailForm from "./EmailForm";
import HolidayForm from "./HolidayForm";
import LoginForm from "./LoginForm";
import LuckyDrawForm from "./LuckyDrawForm";
import ProductWarrantyForm from "./ProductWarrantyForm";
import ProductWarrantyRegisterForm from "./ProductWarrantyRegisterForm";
import ProductWarrantySetForm from "./ProductWarrantySetForm";
import QuestionForm from "./QuestionForm";
import PasswordForm from "./PasswordForm";
import NewForm from "./NewForm";
import PDPAForm from "./PDPAForm";
import PermissionForm from "./PermissionForm";
import RewardForm from "./RewardForm";
import UserManagementForm from "./UserManagementForm";
import ShowRoomForm from "./ShowRoomForm";

const EXPORT = {
  ActivityForm,
  ApproveActivityForm,
  ActivityQuestionform,
  ActivitySpecialForm,
  BookingForm,
  BranchForm,
  BusinessUnitForm,
  BannerForm,
  EditSendCompanyForm,
  EmailForm,
  HolidayForm,
  CertificateForm,
  ChangeStatusForm,
  CorperateForm,
  LoginForm,
  LuckyDrawForm,
  ProductWarrantyForm,
  ProductWarrantyRegisterForm,
  ProductWarrantySetForm,
  QuestionForm,
  PasswordForm,
  NewForm,
  PDPAForm,
  PermissionForm,
  RewardForm,
  UserManagementForm,
  ShowRoomForm,
};

export default EXPORT;
