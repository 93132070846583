import React from "react";
import { connect } from "react-redux";
import { QuestionDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { AlertControl } from "components/Modals/Alert";
import { Displays, Forms, Modals } from "components";

class QuestionDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData();
  }

  fetchData = async () => {
    let resArr = await Promise.all([
      this.props.router.params.id === "create"
        ? true
        : portalService.GET_QUESTION_DETAIL(this.props.router.params.id),
      portalService.GET_ACTIVITY_LIST(`?pagination=false`),
    ]);
    if (resArr) {
      if (this.props.router.params.id === "create") {
        this.setState({
          activityOptions: resArr[1].data.docs.map((e) => ({
            label: e.name,
            value: e._id,
            is_join: e.is_join,
          })),
          isLoading: false,
          isBgLoading: false,
        });
      } else {
        this.setState({
          initialValues: resArr[0].data,
          activityOptions: resArr[1].data.docs.map((e) => ({
            label: e.name,
            value: e._id,
            is_join: e.is_join,
          })),
          isLoading: false,
          isBgLoading: false,
        });
      }
    }
  };

  // FLOW DELETE
  // =============================
  // =============================
  handleDelete = () => {
    this.setState({
      renderModal: (
        <Displays.CofirmBox
          label="คุณต้องการลบใช่หรือไม่ ?"
          labelBtn1="Confirm"
          labelBtn2="Cancel"
          onAction1={this.deleteAction}
          onAction2={this.handleCloseModal}
        />
      ),
    });
  };
  deleteAction = async () => {
    this.setState({
      isBgLoading: true,
    });
    let res = await portalService.DELETE_QUESTION_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      toast.success("ลบสำเร็จ");
      this.handleNextStep();
    } else {
      toast.error("ลบไม่สำรเร็จ");
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  // FLOW SUBMIT
  // =============================
  // =============================
  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    if (values.type === "2") {
    }
    let res =
      this.props.router.params.id === "create"
        ? await portalService.POST_QUESTION_DETAIL(params)
        : await portalService.PUT_QUESTION_UPDATE(
            this.props.router.params.id,
            params
          );
    if (res && res.status === 200) {
      let resActivity = !params.is_join
        ? true
        : await portalService.PUT_ACTIVITY_UPDATE(params.join_ref_id, {
            join_ref_id: res.data._id,
            is_join: true,
          });
      if (resActivity) {
        let filesTemp = [];
        if (values.front_img_file) {
          filesTemp = [
            ...filesTemp,
            ...values.front_img_file.filter((e) => e.isNewUpload),
          ];
        }
        if (filesTemp.length === 0) {
          toast.success("อัพเดตสำเร็จ");
          this.handleNextStep();
        } else {
          this.handleUploadContentMedia(res.data._id, filesTemp);
        }
      }
    } else {
      toast.error("อัพเดตไม่สำเร็จ");
    }
  };
  handleUploadContentMedia = (questionID, files) => {
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set("question_id", questionID);
            bodyFormData.append("image", e);
            let res = await portalService.POST_QUESTION_UPLOAD(bodyFormData);
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      this.setState({
        isBgLoading: false,
      });
      toast.success("อัพเดจสำเร็จ");
      this.handleNextStep();
    });
  };

  handleCancel = () => {
    this.props.router.navigate(-1);
  };

  handleSent = async () => {
    AlertControl.show({
      title: "ยืนยันการส่ง",
      description: "คุณต้องการจะส่งแจ้งเตือนใช่หรือไม่",
      btnLabel1: "ยกเลิก",
      btnLabel2: "ยืนยัน",
      onEvent2: async () => {
        const { initialValues } = this.state;
        this.setState({
          isBgLoading: true,
        });
        let paramsPopup = {
          feature_id: initialValues._id,
          img_path: initialValues.front_img,
          feature_type: "3",
        };
        let paramsNoti = {
          feature_id: initialValues._id,
          title: initialValues.title,
          message: initialValues.description,
          feature_type: "3",
        };
        let res = await Promise.all([
          portalService.POST_POPUP_SENT(paramsPopup),
          portalService.POST_NOTIFICATION_SENT(paramsNoti),
          portalService.PUT_QUESTION_UPDATE(this.props.router.params.id, {
            is_popup_activate: true,
            is_notification_activate: true,
          }),
        ]);
        if (res) {
          this.fetchData();
        }
      },
    });
  };

  handleNextStep = () => {
    this.props.router.navigate(ROUTE_PATH.QUESTION);
  };

  render() {
    const { authenRedux } = this.props;
    const {
      activityOptions,
      isLoading,
      initialValues,
      isBgLoading,
      renderModal,
    } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "question");
    return (
      <QuestionDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading theme_standard_loading />
          </div>
        ) : (
          <Forms.QuestionForm
            initialValues={initialValues}
            activityOptions={activityOptions}
            permission={permission}
            onCancel={this.handleCancel}
            onDelete={this.handleDelete}
            onSent={this.handleSent}
            onSubmit={this.handleSubmit}
          />
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </QuestionDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuestionDetailContainer));
