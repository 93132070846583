import { ROUTE_PATH } from "utils/constants/routePath";
import theme from "styles/theme.json";
import {
  MdDashboard,
  MdOutlineMenuBook,
  MdCardGiftcard,
  MdInsertDriveFile,
  MdAssessment,
  MdOutlineHowToVote,
  MdPeople,
  MdBusiness,
  MdAccountBalance,
  MdOutlineVideogameAsset,
  MdSupervisedUserCircle,
  MdReport,
  MdOutlineApartment,
} from "react-icons/md";
import TopTab from "components/Navigations/TopTab";
import ActivityFormContainer from "containers/ActivityFormSystem/ActivityFormContainer";
import ActivityFormDetailContainer from "containers/ActivityFormSystem/ActivityFormDetailContainer";
import ActivityFormOfflineDetailContainer from "containers/ActivityFormSystem/ActivityFormOfflineDetailContainer";
import ActivityContainer from "containers/ActivitySystem/ActivityContainer";
import ActivityDetailContainer from "containers/ActivitySystem/ActivityDetailContainer";
import ActivityOfflineDetailContainer from "containers/ActivitySystem/ActivityOfflineDetailContainer";
import CustomerContainer from "containers/CustomerSystem/CustomerContainer";
import CustomerDetailContainer from "containers/CustomerSystem/CustomerDetailContainer";
import CorperateContainer from "containers/CorperateSystem/CorperateContainer";
import CorperateDetailContainer from "containers/CorperateSystem/CorperateDetailContainer";
import CorperateInfoDetailContainer from "containers/CorperateSystem/CorperateInfoDetailContainer";
import ActivityHistoryOverviewContainer from "containers/DashboardSystem/ActivityHistoryOverviewContainer";
import ProductWarrantyOverviewContainer from "containers/DashboardSystem/ProductWarrantyOverviewContainer";
import EWarrantyContainer from "containers/DashboardSystem/EWarrantyContainer";
import EWarrantyOverviewContainer from "containers/DashboardSystem/EWarrantyContainer/Tabs/EWarrantyOverviewContainer";
import EWarrantyDepCodeContainer from "containers/DashboardSystem/EWarrantyContainer/Tabs/EWarrantyDepCodeContainer";
import EWarrantyDealerContainer from "containers/DashboardSystem/EWarrantyContainer/Tabs/EWarrantyDealerContainer";
import RewardHistoryOverviewContainer from "containers/DashboardSystem/RewardHistoryOverviewContainer";
import SystemOverviewContainer from "containers/DashboardSystem/SystemOverviewContainer";
import UserOverviewContainer from "containers/DashboardSystem/UserOverviewContainer";
import RelinkReportContainer from "containers/DashboardSystem/RelinkReportContainer";
import NewContainer from "containers/NewSystem/NewContainer";
import NewDetailContainer from "containers/NewSystem/NewDetailContainer";
import ProductSetContainer from "containers/ProductWarranteeSystem/ProductSetContainer";
import ProductSetDetailContainer from "containers/ProductWarranteeSystem/ProductSetDetailContainer";
import ProductWarranteeContainer from "containers/ProductWarranteeSystem/ProductWarranteeContainer";
import ProductWarranteeDetailContainer from "containers/ProductWarranteeSystem/ProductWarranteeDetailContainer";
import ProductWarranteeImportContainer from "containers/ProductWarranteeSystem/ProductWarranteeImportContainer";
import QuestionContainer from "containers/QuestionSystem/QuestionContainer";
import QuestionDetailContainer from "containers/QuestionSystem/QuestionDetailContainer";
import QuestionViewDetailContainer from "containers/QuestionSystem/QuestionViewDetailContainer";
import RewardContainer from "containers/RewardSystem/RewardContainer";
import RewardDetailContainer from "containers/RewardSystem/RewardDetailContainer";
import RewardOfflineDetailContainer from "containers/RewardSystem/RewardOfflineDetailContainer";
import RewardHistoryContainer from "containers/RewardSystem/RewardHistoryContainer";
import BusinessContainer from "containers/BusinessUnitSystem/BusinessContainer";
import BusinessDetailContainer from "containers/BusinessUnitSystem/BusinessDetailContainer";
import BranchContainer from "containers/BranchSystem/BranchContainer";
import BranchDetailContainer from "containers/BranchSystem/BranchDetailContainer";
import LuckyDrawContainer from "containers/LuckyDrawSystem/LuckyDrawContainer";
import LuckyDrawDetailContainer from "containers/LuckyDrawSystem/LuckyDrawDetailContainer";
import LuckyDrawOfflineDetailContainer from "containers/LuckyDrawSystem/LuckyDrawOfflineDetailContainer";
import LuckyDrawHistoryContainer from "containers/LuckyDrawSystem/LuckyDrawHistoryContainer";
import PDPAContainer from "containers/PDPASystem/PDPAContainer";
import PDPADetailContainer from "containers/PDPASystem/PDPADetailContainer";
import PDPAParticipationDetailContainer from "containers/PDPASystem/PDPAParticipationDetailContainer";
import UserManagementContainer from "containers/UserMangementSystem/UserManagementContainer";
import UserManagementDetailContainer from "containers/UserMangementSystem/UserManagementDetailContainer";
import BookingListContainer from "containers/BookingSystem/BookingListContainer";
import BookingTableContainer from "containers/BookingSystem/BookingTableContainer";
import BookingSettingContainer from "containers/BookingSystem/BookingSettingContainer";
import BookingDetailContainer from "containers/BookingSystem/BookingDetailContainer";
import VisitorContainer from "containers/ShowRoomSystem/VisitorContainer";
import VisitorBookingDetailContainer from "containers/ShowRoomSystem/VisitorBookingDetailContainer";
import ShowRoomReportContainer from "containers/ShowRoomSystem/ShowRoomReportContainer";
import PermissionContainer from "containers/PermissionSystem/PermissionContainer";
import PermissionDetailContainer from "containers/PermissionSystem/PermissionDetailContainer";
import CertificateContainer from "containers/CertificateSystem/CertificateContainer";
import CertificateDetailContainer from "containers/CertificateSystem/CertificateDetailContainer";
import CertificateSendInfoContainer from "containers/CertificateSystem/CertificateSendInfoContainer";
import BannerDetailContainer from "containers/BannerSystem/BannerDetailContainer";
import BannerContainer from "containers/BannerSystem/BannerContainer";
import BookingEmailSettingContainer from "containers/BookingSystem/BookingEmailSettingContainer";

const systemRoutes = [
  {
    key: "dashboard",
    name: "Dashboard",
    path: ROUTE_PATH.DASHBOARD,
    icon: (active) => (
      <MdDashboard
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: (
      <TopTab
        data={[
          {
            key: "overview",
            name: "Overview",
            path: ROUTE_PATH.DASHBOARD,
            element: <SystemOverviewContainer />,
          },
          {
            key: "customer",
            name: "Member",
            path: ROUTE_PATH.DASHBOARD_CUSTOMER,
            element: <UserOverviewContainer />,
          },
          {
            key: "product-warranty",
            name: "Warranty",
            path: ROUTE_PATH.DASHBOARD_PRODUCT_WARRANTY,
            element: <ProductWarrantyOverviewContainer />,
          },
          {
            key: "e-warranty",
            name: "E-Warranty",
            path: ROUTE_PATH.DASHBOARD_E_WARRANTY,
            element: <EWarrantyContainer />,
          },
          {
            key: "activity-history",
            name: "Activity",
            path: ROUTE_PATH.DASHBOARD_ACTIVITY_HISTORY,
            element: <ActivityHistoryOverviewContainer />,
          },
          {
            key: "reward-history",
            name: "Point of Redemption",
            path: ROUTE_PATH.DASHBOARD_REWARD_HISTORY,
            element: <RewardHistoryOverviewContainer />,
          },
          {
            key: "relink-report",
            name: "Relink Report",
            path: ROUTE_PATH.DASHBOARD_RELINK_REPORT,
            element: <RelinkReportContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: "overview",
        name: "Overview",
        path: ROUTE_PATH.DASHBOARD,
        element: <SystemOverviewContainer />,
      },
      {
        key: "customer",
        name: "User",
        path: ROUTE_PATH.DASHBOARD_CUSTOMER,
        element: <UserOverviewContainer />,
      },
      {
        key: "product-warranty",
        name: "Warranty",
        path: ROUTE_PATH.DASHBOARD_PRODUCT_WARRANTY,
        element: <ProductWarrantyOverviewContainer />,
      },
      {
        key: "e-warranty",
        name: "E-Warranty",
        path: ROUTE_PATH.DASHBOARD_E_WARRANTY,
        element: <EWarrantyContainer />,
        children: [
          {
            key: "e-warranty",
            name: "E-Warranty",
            path: ROUTE_PATH.DASHBOARD_E_WARRANTY,
            element: <EWarrantyOverviewContainer />,
          },
          {
            key: "e-warranty",
            name: "E-Warranty",
            path: ROUTE_PATH.DASHBOARD_E_WARRANTY_OVERVIEW,
            element: <EWarrantyOverviewContainer />,
          },
          {
            key: "e-warranty",
            name: "E-Warranty",
            path: ROUTE_PATH.DASHBOARD_E_WARRANTY_DEALER,
            element: <EWarrantyDealerContainer />,
          },
          {
            key: "e-warranty",
            name: "E-Warranty",
            path: ROUTE_PATH.DASHBOARD_E_WARRANTY_DEPCODE,
            element: <EWarrantyDepCodeContainer />,
          },
        ],
      },
      {
        key: "activity-history",
        name: "Activity",
        path: ROUTE_PATH.DASHBOARD_ACTIVITY_HISTORY,
        element: <ActivityHistoryOverviewContainer />,
      },
      {
        key: "reward-history",
        name: "Redemption",
        path: ROUTE_PATH.DASHBOARD_REWARD_HISTORY,
        element: <RewardHistoryOverviewContainer />,
      },
      {
        key: "relink-report",
        name: "Relink Report",
        path: ROUTE_PATH.DASHBOARD_RELINK_REPORT,
        element: <RelinkReportContainer />,
      },
    ],
  },
  {
    key: "product-warranty",
    name: "Product Warranty",
    path: ROUTE_PATH.PRODUCT_WARRANTY,
    icon: (active) => (
      <MdOutlineHowToVote
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: (
      <TopTab
        data={[
          {
            key: "product-warranty",
            name: "Product warranty",
            path: ROUTE_PATH.PRODUCT_WARRANTY,
            element: <ProductWarranteeContainer />,
          },
          {
            key: "upload",
            name: "Upload",
            path: ROUTE_PATH.PRODUCT_WARRANTY_IMPORT,
            element: <ProductWarranteeImportContainer />,
          },
          {
            key: "set",
            name: "Product Set",
            path: ROUTE_PATH.PRODUCT_SET,
            element: <ProductSetContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: "upload_data_cu_point",
        name: "Upload CU point",
        path: ROUTE_PATH.PRODUCT_WARRANTY,
        element: <ProductWarranteeContainer />,
      },
      {
        key: "upload_data_cu_point",
        name: "Upload CU point",
        path: ROUTE_PATH.PRODUCT_WARRANTY_DETAIL,
        element: <ProductWarranteeDetailContainer />,
      },
      {
        key: "upload_rewards",
        name: "Upload rewards",
        path: ROUTE_PATH.PRODUCT_WARRANTY_IMPORT,
        element: <ProductWarranteeImportContainer />,
      },
      {
        key: "product-set",
        name: "Product Set",
        path: ROUTE_PATH.PRODUCT_SET,
        element: <ProductSetContainer />,
      },
      {
        key: "product-set",
        name: "Product Set",
        path: ROUTE_PATH.PRODUCT_SET_DETAIL,
        element: <ProductSetDetailContainer />,
      },
    ],
  },
  {
    key: "business-unit",
    name: "Business Unit",
    path: ROUTE_PATH.BUSINESS_UNIT,
    icon: (active) => (
      <MdBusiness
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <BusinessContainer />,
  },
  {
    key: "business-unit",
    name: "Business Unit",
    path: ROUTE_PATH.BUSINESS_UNIT_DETAIL,
    notShowSidebar: true,
    element: <BusinessDetailContainer />,
  },
  {
    key: "branch",
    name: "Branch",
    path: ROUTE_PATH.BRANCH,
    icon: (active) => (
      <MdAccountBalance
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <BranchContainer />,
  },
  {
    key: "branch",
    name: "Branch",
    path: ROUTE_PATH.BRANCH_DETAIL,
    notShowSidebar: true,
    element: <BranchDetailContainer />,
  },
  {
    key: "corperate",
    name: "Corperate",
    path: ROUTE_PATH.CORPERATE,
    icon: (active) => (
      <MdOutlineApartment
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <CorperateContainer />,
  },
  {
    key: "corperate",
    name: "Corperate",
    path: ROUTE_PATH.CORPERATE_DETAIL,
    notShowSidebar: true,
    element: <CorperateDetailContainer />,
  },
  {
    key: "corperate",
    name: "Corperate",
    path: ROUTE_PATH.CORPERATE_INFO_DETAIL,
    notShowSidebar: true,
    element: <CorperateInfoDetailContainer />,
  },
  {
    key: "customer",
    name: "Customer",
    path: ROUTE_PATH.CUSTOMER,
    icon: (active) => (
      <MdPeople
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <CustomerContainer />,
  },
  {
    key: "customer",
    name: "Customer",
    path: ROUTE_PATH.CUSTOMER_DETAIL,
    notShowSidebar: true,
    element: <CustomerDetailContainer />,
  },
  {
    key: "new",
    name: "News",
    path: ROUTE_PATH.NEW,
    icon: (active) => (
      <MdOutlineMenuBook
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <NewContainer />,
  },
  {
    key: "new",
    name: "News",
    path: ROUTE_PATH.NEW_DETAIL,
    notShowSidebar: true,
    element: <NewDetailContainer />,
  },
  {
    key: "activity-form",
    name: "Online Form",
    path: ROUTE_PATH.ACTIVITY_FORM,
    icon: (active) => (
      <MdInsertDriveFile
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <ActivityFormContainer />,
  },
  {
    key: "activity-form",
    name: "Online Form",
    path: ROUTE_PATH.ACTIVITY_FORM_DETAIL,
    notShowSidebar: true,
    element: <ActivityFormDetailContainer />,
  },
  {
    key: "activity-form",
    name: "Online Form",
    path: ROUTE_PATH.ACTIVITY_FORM_OFFLINE_DETAIL,
    notShowSidebar: true,
    element: <ActivityFormOfflineDetailContainer />,
  },
  {
    key: "activity",
    name: "Activity",
    path: ROUTE_PATH.ACTIVITY,
    icon: (active) => (
      <MdInsertDriveFile
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <ActivityContainer />,
  },
  {
    key: "activity",
    name: "Activity",
    path: ROUTE_PATH.ACTIVITY_DETAIL,
    notShowSidebar: true,
    element: <ActivityDetailContainer />,
  },
  {
    key: "activity",
    name: "Activity",
    path: ROUTE_PATH.ACTIVITY_OFFLINE_DETAIL,
    notShowSidebar: true,
    element: <ActivityOfflineDetailContainer />,
  },
  {
    key: "booking",
    name: "Booking",
    path: ROUTE_PATH.BOOKING,
    icon: (active) => (
      <MdOutlineVideogameAsset
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: (
      <TopTab
        data={[
          {
            key: "list",
            name: "List",
            path: ROUTE_PATH.BOOKING_LIST,
            element: <BookingListContainer />,
          },
          {
            key: "table",
            name: "Table",
            path: ROUTE_PATH.BOOKING_TABLE,
            element: <BookingTableContainer />,
          },
          {
            key: "setting",
            name: "Day off Setting",
            path: ROUTE_PATH.BOOKING_SETTING,
            element: <BookingSettingContainer />,
          },
          {
            key: "email-setting",
            name: "Email Setting",
            path: ROUTE_PATH.BOOKING_EMAIL_SETTING,
            element: <BookingEmailSettingContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: "booking",
        name: "List",
        path: ROUTE_PATH.BOOKING,
        element: <BookingListContainer />,
      },
      {
        key: "booking",
        name: "List",
        path: ROUTE_PATH.BOOKING_LIST,
        element: <BookingListContainer />,
      },
      {
        key: "booking",
        name: "Table",
        path: ROUTE_PATH.BOOKING_TABLE,
        element: <BookingTableContainer />,
      },
      {
        key: "booking",
        name: "Setting",
        path: ROUTE_PATH.BOOKING_SETTING,
        element: <BookingSettingContainer />,
      },
      {
        key: "booking",
        name: "Settingd",
        path: ROUTE_PATH.BOOKING_DETAIL,
        element: <BookingDetailContainer />,
      },
      {
        key: "booking",
        name: "Email Setting",
        path: ROUTE_PATH.BOOKING_EMAIL_SETTING,
        element: <BookingEmailSettingContainer />,
      },
    ],
  },
  {
    key: "show-room",
    name: "Showroom",
    path: ROUTE_PATH.SHOW_ROOM,
    icon: (active) => (
      <MdOutlineVideogameAsset
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: (
      <TopTab
        data={[
          {
            key: "show-room",
            name: "Visitor List",
            path: ROUTE_PATH.SHOW_ROOM,
            element: <VisitorContainer />,
          },
          // {
          //   key: "report",
          //   name: "Report",
          //   path: ROUTE_PATH.SHOW_ROOM_REPORT,
          //   element: <ShowRoomReportContainer />,
          // },
        ]}
      />
    ),
    children: [
      {
        key: "show-room",
        name: "Visitor List",
        path: ROUTE_PATH.SHOW_ROOM,
        element: <VisitorContainer />,
      },
      {
        key: "show-room",
        name: "Visitor List",
        path: ROUTE_PATH.SHOW_ROOM_BOOKING_DETAIL,
        element: <VisitorBookingDetailContainer />,
      },
      {
        key: "report",
        name: "report",
        path: ROUTE_PATH.SHOW_ROOM_REPORT,
        element: <ShowRoomReportContainer />,
      },
    ],
  },
  {
    key: "question",
    name: "Question",
    path: ROUTE_PATH.QUESTION,
    icon: (active) => (
      <MdAssessment
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <QuestionContainer />,
  },
  {
    key: "question",
    name: "Question",
    path: ROUTE_PATH.QUESTION_DETAIL,
    notShowSidebar: true,
    element: <QuestionDetailContainer />,
  },
  {
    key: "question",
    name: "Question",
    path: ROUTE_PATH.QUESTION_VIEW_DETAIL,
    notShowSidebar: true,
    element: <QuestionViewDetailContainer />,
  },
  {
    key: "reward",
    name: "Reward",
    path: ROUTE_PATH.REWARD,
    icon: (active) => (
      <MdCardGiftcard
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: (
      <TopTab
        data={[
          {
            key: "reward",
            name: "Reward",
            path: ROUTE_PATH.REWARD,
            element: <RewardContainer />,
          },
          {
            key: "redeem",
            name: "Redeem",
            path: ROUTE_PATH.REWARD_REDEEM,
            element: <RewardHistoryContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: "reward",
        name: "Reward",
        path: ROUTE_PATH.REWARD,
        element: <RewardContainer />,
      },
      {
        key: "redeem",
        name: "Redeem",
        path: ROUTE_PATH.REWARD_REDEEM,
        element: <RewardHistoryContainer />,
      },
      {
        key: "reward",
        name: "Reward",
        path: ROUTE_PATH.REWARD_DETAIL,
        notShowSidebar: true,
        element: <RewardDetailContainer />,
      },
      {
        key: "reward",
        name: "Reward",
        path: ROUTE_PATH.REWARD_OFFLINE_DETAIL,
        notShowSidebar: true,
        element: <RewardOfflineDetailContainer />,
      },
    ],
  },
  {
    key: "lucky-draw",
    name: "Lucky Draw",
    path: ROUTE_PATH.LUCKY_DRAW,
    icon: (active) => (
      <MdOutlineVideogameAsset
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: (
      <TopTab
        data={[
          {
            key: "lucky-draw",
            name: "Lucky Draw",
            path: ROUTE_PATH.LUCKY_DRAW,
            element: <LuckyDrawContainer />,
          },
          {
            key: "history",
            name: "History",
            path: ROUTE_PATH.LUCKY_DRAW_HISTORY,
            element: <LuckyDrawHistoryContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: "lucky_draw",
        name: "Lucky Draw",
        path: ROUTE_PATH.LUCKY_DRAW,
        element: <LuckyDrawContainer />,
      },
      {
        key: "redeem",
        name: "Lucky Draw",
        path: ROUTE_PATH.LUCKY_DRAW_HISTORY,
        element: <LuckyDrawHistoryContainer />,
      },
      {
        key: "lucky_draw",
        name: "Lucky Draw",
        path: ROUTE_PATH.LUCKY_DRAW_DETAIL,
        notShowSidebar: true,
        element: <LuckyDrawDetailContainer />,
      },
      {
        key: "lucky_draw",
        name: "Lucky Draw",
        path: ROUTE_PATH.LUCKY_DRAW_OFFLINE_DETAIL,
        notShowSidebar: true,
        element: <LuckyDrawOfflineDetailContainer />,
      },
    ],
  },
  {
    key: "pdpa",
    name: "PDPA",
    path: ROUTE_PATH.PDPA,
    icon: (active) => (
      <MdReport
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <PDPAContainer />,
  },
  {
    key: "pdpa",
    name: "PDPA",
    path: ROUTE_PATH.PDPA_DETAIL,
    notShowSidebar: true,
    element: <PDPADetailContainer />,
  },
  {
    key: "pdpa",
    name: "PDPA",
    path: ROUTE_PATH.PDPA_PARTICIPATION_DETAIL,
    notShowSidebar: true,
    element: <PDPAParticipationDetailContainer />,
  },
  {
    key: "user_management",
    name: "User Management",
    path: ROUTE_PATH.USER_MANAGEMENT,
    icon: (active) => (
      <MdSupervisedUserCircle
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <UserManagementContainer />,
  },
  {
    key: "user_management",
    name: "User Management",
    path: ROUTE_PATH.USER_MANAGEMENT_DETAIL,
    notShowSidebar: true,
    element: <UserManagementDetailContainer />,
  },

  {
    key: "permission",
    name: "Permission",
    path: ROUTE_PATH.PERMISSION,
    icon: (active) => (
      <MdSupervisedUserCircle
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <PermissionContainer />,
  },
  {
    key: "certificate",
    name: "Certificate",
    path: ROUTE_PATH.CERTIFICATE,
    icon: (active) => (
      <MdOutlineVideogameAsset
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <CertificateContainer />,
  },
  {
    key: "certificate",
    name: "Certificate",
    path: ROUTE_PATH.CERTIFICATE_DETAIL,
    notShowSidebar: true,
    element: <CertificateDetailContainer />,
  },
  {
    key: "certificate",
    name: "Certificate",
    path: ROUTE_PATH.CERTIFICATE_SEND_INFO,
    notShowSidebar: true,
    element: <CertificateSendInfoContainer />,
  },
  {
    key: "banner",
    name: "Banner",
    path: ROUTE_PATH.BANNER,
    icon: (active) => (
      <MdOutlineVideogameAsset
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <BannerContainer />,
  },
  {
    key: "banner",
    name: "Banner",
    path: ROUTE_PATH.BANNER_DETAIL,
    notShowSidebar: true,
    element: <BannerDetailContainer />,
  },
  {
    key: "permission",
    name: "Permission",
    path: ROUTE_PATH.PERMISSION_DETAIL,
    notShowSidebar: true,
    element: <PermissionDetailContainer />,
  },
];

export default systemRoutes;
