export const RENDER_UNIT = (key) => {
  switch (key) {
    case "D":
      return {
        labelEN: "Day",
        labelTH: "วัน",
        momentVarAdd: "days",
      };
    case "W":
      return {
        labelEN: "Week",
        labelTH: "สัปดาห์",
        momentVarAdd: "weeks",
      };
    case "M":
      return {
        labelEN: "Month",
        labelTH: "เดือน",
        momentVarAdd: "months",
      };
    case "Y":
      return {
        labelEN: "Year",
        labelTH: "ปี",
        momentVarAdd: "years",
      };
    default:
      return {
        labelEN: "-",
        labelTH: "-",
        momentVarAdd: false,
      };
  }
};
