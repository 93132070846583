import styled from "styled-components";

export const CorperateDetailContainerStyled = styled.div`
  border-radius: 1rem;
  padding: 1.25rem;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .block_section {
    margin-bottom: 2rem;
    .title {
      margin-bottom: 0.25rem;
    }
    .cus_info_row {
      display: flex;
      .ci_left_col {
        flex: 1;
      }
      .ci_right_col {
        flex: 1;
      }
    }
    .value_item_wrap {
    }
  }
  .box_back {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
    .w150 {
      width: 150px;
    }
  }
`;
