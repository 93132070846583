import React from "react";
import { BannerFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const BannerForm = ({ onCancel, onSubmit }) => {
  const schema = yup.object().shape({
    file: yup.array().required("Required"),
  });

  const {
    control,
    handleSubmit,
    // watch,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const watchStatus = watch("status");

  const _onSubmit = (values) => {
    let isErr = false;
    if (values.status === "2" && !values.rejection_reason) {
      isErr = true;
      setError("rejection_reason", {
        type: "custom",
        message: "Required",
      });
    }
    if (!isErr) {
      onSubmit(values);
    }
  };

  return (
    <BannerFormStyled>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="form_title">Certificate</div>
        <div className="input_row">
          <div className="f_left_col">Certificate Picture</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  {...field}
                  theme_upload_image
                  fieldName="file"
                  errors={errors.file?.message}
                />
              )}
              name="file"
              defaultValue=""
            />
            <div>***รูป JPG or PNG ขนาดกว้าง 3508 px สูง 2480 px</div>
          </div>
        </div>
        <div className="cf_action_row">
          <div className="cf_action_item">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
          <div className="cf_action_item">
            <Buttons.BgStandard
              type="submit"
              label="Update"
              // disabled={initialValues && initialValues.is_center}
            />
          </div>
        </div>
      </form>
    </BannerFormStyled>
  );
};

BannerForm.propTypes = {};

export default BannerForm;
