import styled from 'styled-components';

export const RewardOfflineDetailContainerStyled = styled.div`
  border-radius: 1rem;
  padding: 1.25rem;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .block_section {
    margin-bottom: 2rem;
    .title {
      margin-bottom: 0.25rem;
    }
    .body {
      .value_item_wrap {
      }
    }
  }
  .filter_wrap {
    margin-bottom: 1rem;
  }
`;
