import React from "react";
import { connect } from "react-redux";
import { BookingTableContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { Tables } from "components";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import moment from "moment";
import { Displays } from "components";

class BookingTableContainer extends React.Component {
  state = {
    isLoading: true,
    filterValue: "day",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };

  componentDidMount() {
    const { startDate, endDate } = this.state;
    this.fetchData(
      `?pagination=false&start_date=${startDate}&end_date=${endDate}&date_type=booking_date`
    );
  }

  fetchData = async (stringQuery) => {
    let resArr = await Promise.all([
      portalService.GET_BOOKING_LIST(stringQuery),
      portalService.GET_HOLIDAY_LIST(stringQuery),
      portalService.GET_TIME_SLOT_LIST(stringQuery),
    ]);
    if (resArr) {
      this.setState({
        data: resArr[0].data.docs,
        holidayData: resArr[1].data.docs.map((e) =>
          moment(e.date).format("YYYY-MM-DD")
        ),
        timeSlotData: resArr[2].data.docs,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleChangeFilter = (e) => {
    const startDate =
      e === "day"
        ? moment().format("YYYY-MM-DD")
        : moment().startOf("week").format("YYYY-MM-DD");
    const endDate =
      e === "day"
        ? moment().format("YYYY-MM-DD")
        : moment().endOf("week").format("YYYY-MM-DD");
    this.setState({
      filterValue: e,
      startDate: startDate,
      endDate: endDate,
      isLoading: true,
    });
    this.fetchData(
      `?pagination=false&start_date=${startDate}&end_date=${endDate}&date_type=booking_date`
    );
  };

  handleDateFilter = (key, e) => {
    const { filterValue, startDate } = this.state;
    this.setState({
      isLoading: true,
    });
    switch (key) {
      case "next":
        const tempNextStartDate =
          filterValue === "day"
            ? moment(startDate, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD")
            : moment(startDate, "YYYY-MM-DD")
                .add(7, "day")
                .format("YYYY-MM-DD");
        const tempNextendDate =
          filterValue === "day"
            ? moment(startDate, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD")
            : moment(startDate, "YYYY-MM-DD")
                .add(13, "day")
                .format("YYYY-MM-DD");
        this.setState({
          startDate: tempNextStartDate,
          endDate: tempNextendDate,
        });
        this.fetchData(
          `?pagination=false&start_date=${tempNextStartDate}&end_date=${tempNextendDate}&date_type=booking_date`
        );
        break;
      case "prev":
        const tempPrevStartDate =
          filterValue === "day"
            ? moment(startDate, "YYYY-MM-DD")
                .subtract(1, "day")
                .format("YYYY-MM-DD")
            : moment(startDate, "YYYY-MM-DD")
                .subtract(7, "day")
                .format("YYYY-MM-DD");
        const tempPrevEndDate =
          filterValue === "day"
            ? moment(startDate, "YYYY-MM-DD")
                .subtract(1, "day")
                .format("YYYY-MM-DD")
            : moment(startDate, "YYYY-MM-DD")
                .subtract(1, "day")
                .format("YYYY-MM-DD");
        this.setState({
          startDate: tempPrevStartDate,
          endDate: tempPrevEndDate,
        });
        this.fetchData(
          `?pagination=false&start_date=${tempPrevStartDate}&end_date=${tempPrevEndDate}&date_type=booking_date`
        );
        break;
      case "select":
        const tempSelectStartDate =
          filterValue === "day"
            ? moment(e, "YYYY-MM-DD").format("YYYY-MM-DD")
            : moment(e, "YYYY-MM-DD").startOf("week").format("YYYY-MM-DD");
        const tempSelectEndDate =
          filterValue === "day"
            ? moment(e, "YYYY-MM-DD").format("YYYY-MM-DD")
            : moment(e, "YYYY-MM-DD").endOf("week").format("YYYY-MM-DD");
        this.setState({
          startDate: tempSelectStartDate,
          endDate: tempSelectEndDate,
        });
        this.fetchData(
          `?pagination=false&start_date=${tempSelectStartDate}&end_date=${tempSelectStartDate}&date_type=booking_date`
        );
        break;
      default:
        break;
    }
  };

  handleSubmit = async (values) => {
    const { startDate, endDate } = this.state;
    let dataCheck = Object.keys(values).reduce((result, data) => {
      let split = data.replaceAll("_", ".").split("-");
      if (values[data] === true) {
        result.push({ startTime: split[0], endTime: split[1], isClose: true });
      }
      if (values[data] === false) {
        result.push({ startTime: split[0], endTime: split[1], isClose: false });
      }
      return result;
    }, []);
    if (dataCheck && dataCheck.length > 0) {
      this.setState({
        isLoading: true,
      });
      let res = await Promise.all(
        dataCheck.map((e) => {
          return new Promise(async (resolve) => {
            let params = {
              date: startDate,
              start_time: e.startTime,
              end_time: e.endTime,
              is_close: e.isClose,
              action: "close",
            };
            let resUpdate = await portalService.POST_TIME_SLOT_BOOKING(params);
            if (resUpdate && resUpdate.status === 200) {
              resolve({
                status: 200,
              });
            } else {
              resolve({
                status: 400,
              });
            }
          });
        })
      );
      if (res) {
        toast.success("อัพเดตสำเร็จ");
        this.fetchData(
          `?pagination=false&start_date=${startDate}&end_date=${endDate}&date_type=booking_date`
        );
      }
    }
  };

  render() {
    const { authenRedux } = this.props;
    const {
      isLoading,
      data,
      filterValue,
      holidayData,
      startDate,
      timeSlotData,
    } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "booking");
    return (
      <BookingTableContainerStyled>
        {/* <div className="header_row">
          <div className="title">รายละเอียดการจอง</div>
          <div className="btn_create">
            <div className="w120">
              <Buttons.BgIconPrefix
                label="New Booking"
                icon={<MdAdd size={18} />}
                onClick={() => this.handleClickCreate("create")}
              />
            </div>
          </div>
        </div> */}
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <div className="table_container">
            <div className="scheduler_box">
              <div className="table">
                <Tables.Scheduler
                  columns={columns}
                  customerData={data}
                  dateFilterValue={startDate}
                  filterValue={filterValue}
                  holidayData={holidayData}
                  permission={permission}
                  timeSlotData={timeSlotData}
                  onChangeFilter={this.handleChangeFilter}
                  onDateFilter={this.handleDateFilter}
                  onSubmit={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </BookingTableContainerStyled>
    );
  }
}
const columns = [
  "Customer 1",
  "Customer 2",
  "Customer 3",
  "Customer 4",
  "Customer 5",
  "Customer 6",
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BookingTableContainer));
