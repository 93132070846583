import React, { useEffect } from "react";
import { ActivitySpecialFormStyled } from "./styled";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MdDelete } from "react-icons/md";
import QuestionForm from "./QuestionForm";
import { toast } from "react-toastify";
import { AlertControl } from "components/Modals/Alert";
import { Buttons, Inputs } from "components";

const ActivitySpecialForm = ({
  initialValues,
  pdpaOptions,
  permission,
  onCancel,
  onDelete,
  onDeleteQuestion,
  onSent,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    type: yup.string().required("Activity type is required"),
    code: yup.string().required("Activity Code is required"),
    name: yup.string().required("Activity name is required"),
    description: yup.string().required("Description is required"),
    // point: yup.string().required("Point name is required"),
    // permission_type: yup.string().required("Required"),
    last_date: yup.string().required("Required"),
    // announcement_date: yup.string().required("Required"),
    // qrcode_amount: yup.string().required('Qrcode amount is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    // setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "question_list",
  });

  const watchIsCondition = watch("is_condition");
  const watchQuestionList = watch("question_list");

  useEffect(() => {
    if (initialValues) {
      let params = {
        ...initialValues,
        start_date:
          initialValues.start_date && new Date(initialValues.start_date),
        end_date: initialValues.end_date && new Date(initialValues.end_date),
        img_file: initialValues.img ? [{ media_uri: initialValues.img }] : [],
        img_file2: initialValues.img2
          ? [{ media_uri: initialValues.img2 }]
          : [],
        document_file: initialValues.document
          ? [{ media_uri: initialValues.document }]
          : [],
      };
      if (initialValues.question_list) {
        params.question_list = initialValues.question_list.map((e) => ({
          ...e,
          questions:
            e?.questions &&
            e.questions.map((f) => ({
              ...f,
              image: f.img_path ? [{ media_uri: f.img_path }] : [],
            })),
        }));
      }
      reset({
        ...params,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const _onSubmit = (values) => {
    let isError = false;
    if (!isError) {
      onSubmit(values);
    } else {
      toast.error("กรุณากรอกเงื่อนไขให้ครบ");
    }
  };

  return (
    <ActivitySpecialFormStyled>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="f_title">Online Form</div>
        <div className="input_row">
          <div className="f_left_col">Activity Type</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={[
                    {
                      label: "ฟอร์มสมัครแข่งพิเศษ สมัครได้หลายคน",
                      value: "1",
                    },
                  ]}
                  errors={errors.type?.message}
                  disabled={initialValues}
                />
              )}
              name="type"
              // defaultValue={{
              //   label: "ฟอร์มสมัครแข่งพิเศษ สมัครได้หลายคน",
              //   // value: "1",
              // }}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Code</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField {...field} errors={errors.code?.message} />
              )}
              name="code"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Name</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField {...field} errors={errors.name?.message} />
              )}
              name="name"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Description</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputWysiwyg
                  {...field}
                  errors={errors.description?.message}
                />
              )}
              name="description"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Start Date</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  placeholder={"Start Date"}
                  errors={errors.start_date?.message}
                  showYearDropdown
                />
              )}
              name="start_date"
              defaultValue={new Date()}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">End Date</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  placeholder={"End Date"}
                  errors={errors.end_date?.message}
                  showYearDropdown
                />
              )}
              name="end_date"
              defaultValue={new Date()}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Picture</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  {...field}
                  theme_upload_image
                  isDelete
                  fieldName="img"
                  acceptType={"cover"}
                  scaleLabel="*** รูปขนาดกว้าง 720px สูง 720px"
                  errors={errors.img_file?.message}
                />
              )}
              name="img_file"
              defaultValue={[]}
            />
          </div>
        </div>

        <div className="input_row">
          <div className="f_left_col">Document Label</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  errors={errors.document_name?.message}
                />
              )}
              name="document_name"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Document upload</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  {...field}
                  theme_upload_image
                  isDelete
                  fieldName="document"
                  acceptType={"pdf"}
                  scaleLabel="*** PDF only"
                  errors={errors.document_file?.message}
                />
              )}
              name="document_file"
              defaultValue={[]}
            />
          </div>
        </div>

        <div className="input_row">
          <div className="f_left_col">Picture2</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  {...field}
                  isDelete
                  fieldName="img2"
                  theme_upload_image
                  acceptType={"cover"}
                  scaleLabel="*** รูปขนาดกว้าง 720px สูง 720px"
                  errors={errors.img_file?.message}
                />
              )}
              name="img_file2"
              defaultValue={[]}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Last date register</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  placeholder={"Last date"}
                  errors={errors.last_date?.message}
                  showYearDropdown
                />
              )}
              name="last_date"
              // defaultValue={new Date()}
            />
          </div>
        </div>
        <div className="line_horizontal" />
        <div className="input_row">
          <div className="f_left_col">Certificate system</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  errors={errors.is_certificate?.message}
                />
              )}
              name="is_certificate"
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Create ID QR code</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  errors={errors.is_qrcode?.message}
                />
              )}
              name="is_qrcode"
            />
          </div>
        </div>
        <div className="line_horizontal" />
        <div className="input_row">
          <div className="f_left_col">One time popup</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  disabled={true}
                  errors={errors.is_popup_activate?.message}
                />
              )}
              name="is_popup_activate"
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">One time Notification</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  disabled={true}
                  errors={errors.is_notification_activate?.message}
                />
              )}
              name="is_notification_activate"
              defaultValue={false}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Active</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  errors={errors.is_active?.message}
                />
              )}
              name="is_active"
              defaultValue={false}
            />
          </div>
        </div>
        <div className="line_horizontal" />
        <div className="input_row mb_100">
          <div className="f_left_col">Question Form</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  errors={errors.is_condition?.message}
                />
              )}
              name="is_condition"
              defaultValue={false}
            />
          </div>
        </div>
        {watchIsCondition === true && (
          <div className="page_block_container">
            {fields.map((item, index) => (
              <div key={item.id} className="part_block">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      hidden
                      options={[
                        {
                          label: "ฟอร์มสมัครแข่งพิเศษ สมัครได้หลายคน",
                          value: "1",
                        },
                      ]}
                      errors={errors.type?.message}
                      disabled={initialValues}
                    />
                  )}
                  name={`question_list.${index}.page`}
                  defaultValue={index + 1}
                />
                <div className="pb_header_block">
                  <div className="pb_page_label">Part {index + 1}</div>
                  <div
                    className="btn_delete_lv1"
                    onClick={() => {
                      AlertControl.show({
                        title: "ยืนยันการลบ",
                        description: "คุณต้องการจะลบใช่หรือไม่",
                        btnLabel1: "ยกเลิก ",
                        btnLabel2: "ยืนยัน",
                        onEvent2: async () => {
                          onDeleteQuestion && onDeleteQuestion("page", item);
                          remove(index);
                        },
                      });
                    }}
                  >
                    <MdDelete fontSize={25} color={"red"} />
                  </div>
                </div>
                <div className="pb_body_lv1_block">
                  <div className="pt_input_wrap">
                    <Controller
                      render={({ field }) => (
                        <Inputs.TextField
                          {...field}
                          label="Title"
                          errors={errors.type?.message}
                        />
                      )}
                      name={`question_list.${index}.title`}
                      control={control}
                    />
                  </div>
                  <div className="pt_input_wrap">
                    <Controller
                      render={({ field }) => (
                        <Inputs.TextField
                          {...field}
                          label="Description"
                          errors={errors.type?.message}
                        />
                      )}
                      name={`question_list.${index}.desc`}
                      control={control}
                    />
                  </div>
                </div>
                <div className="pb_question_lv2_block">
                  <QuestionForm
                    nestIndex={index}
                    pdpaOptions={pdpaOptions}
                    watchQuestionList={watchQuestionList}
                    dataItem={item}
                    onDeleteQuestion={onDeleteQuestion}
                    {...{ control, errors }}
                  />
                </div>
              </div>
            ))}
            <button
              type="button"
              className="btn_add_lv1"
              onClick={() => append()}
            >
              + Add more part
            </button>
          </div>
        )}
        {permission && (
          <div className="action_row">
            <div className="w120">
              <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
            </div>
            {initialValues && permission.is_delete && initialValues.is_edit && (
              <div className="w120">
                <Buttons.BgStandard
                  theme_red
                  label="Delete"
                  onClick={onDelete}
                />
              </div>
            )}
            {initialValues && permission.is_update ? (
              <>
                {!initialValues.is_popup_activate && (
                  <div className="w120">
                    <Buttons.BgStandard
                      theme_green
                      label="Sent popup"
                      onClick={onSent}
                    />
                  </div>
                )}
                {/* {initialValues.is_edit && ( */}
                <div className="w120">
                  <Buttons.BgStandard type="submit" label="Update" />
                </div>
                {/* )} */}
              </>
            ) : (
              permission.is_create && (
                <div className="w120">
                  <Buttons.BgStandard type="submit" label="Save" />
                </div>
              )
            )}
          </div>
        )}
      </form>
    </ActivitySpecialFormStyled>
  );
};

ActivitySpecialForm.propTypes = {};

export default ActivitySpecialForm;
