import styled from "styled-components";

export const HolidayFormStyled = styled.div`
  .f_header {
    display: flex;
    flex-direction: row;
    padding: 20px;
    column-gap: 60px;
    .f_left_col {
      .f_title {
      padding-top: 10px;
        color: ${({ theme }) => theme.COLORS.BLACK_3};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      }
    }
    .f_right_col {
      display: flex;

      column-gap: 12px;
      .btn_submit_wrap {
        min-width: 140px;
      }
    }
  }
`;
