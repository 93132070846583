import React, { useEffect } from "react";
import { BusinessUnitFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const BusinessUnitForm = ({
  initialValues,
  permission,
  onCancel,
  onDelete,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BusinessUnitFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_row">
          <div className="f_left_col">Name</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.name?.message}
                />
              )}
              name="name"
              defaultValue=""
            />
          </div>
        </div>
        {/* <div className="input_row">
          <div className="f_left_col">Is Center</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  errors={errors.is_center?.message}
                />
              )}
              name="is_center"
              defaultValue={false}
            />
          </div>
        </div> */}
        <div className="action_row">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
          {permission && (
            <>
              {initialValues && permission.is_delete && (
                <div className="w120">
                  <Buttons.BgStandard
                    theme_red
                    label="Delete"
                    disabled={initialValues && initialValues.is_center}
                    onClick={onDelete}
                  />
                </div>
              )}
              {initialValues && permission.is_update ? (
                <div className="w120">
                  <Buttons.BgStandard
                    type="submit"
                    label="Update"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              ) : (
                permission.is_create && (
                  <div className="w120">
                    <Buttons.BgStandard
                      type="submit"
                      label="Save"
                      // disabled={initialValues && initialValues.is_center}
                    />
                  </div>
                )
              )}
            </>
          )}
        </div>
      </form>
    </BusinessUnitFormStyled>
  );
};

BusinessUnitForm.propTypes = {};

export default BusinessUnitForm;
