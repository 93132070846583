import React, { useEffect } from "react";
import { CorperateFormStyled } from "./styled";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MdDelete } from "react-icons/md";
import address from "utils/jsons/thailandAddress/address.json";
import { portalService } from "apiServices";
import { Buttons, Inputs } from "components";

const CorperateForm = ({
  initialValues,
  permission,
  onCancel,
  onDelete,
  onDeleteItem,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    // corporate_id: yup.string().required("Corporate id is required"),
    province: yup.string().required("Province is required"),
    phone_number: yup.string().required("Phone number is required"),
    email: yup.string().email().required("Email is required"),
    member_list: yup.array().of(
      yup.object().shape({
        member: yup
          .object()
          .nullable()
          .required("Member is required")
          .shape({
            name: yup.string().required("Member is required"),
          }),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "member_list",
  });

  useEffect(() => {
    if (initialValues) {
      let params = initialValues;
      if (initialValues.member_list) {
        params.member_list = initialValues.member_list.map((e) => ({
          member: { name: e.name, member_ref_id: e.member_ref_id },
        }));
      }
      reset({
        ...params,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const _renderProvinceOptions = () => {
    return address.reduce((result, data, i) => {
      if (i === 0) {
        result.push({ label: data.province, value: data.province });
      } else {
        let find = result.find((e) => e.value === data.province);
        if (!find) {
          result.push({ label: data.province, value: data.province });
        }
      }
      return result;
    }, []);
  };

  return (
    <CorperateFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_row">
          <div className="f_left_col">ชื่อองค์กร</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField {...field} errors={errors.name?.message} />
              )}
              name="name"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">เลขนิติบุคคล(ถ้ามี)</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  errors={errors.corporate_id?.message}
                />
              )}
              name="corporate_id"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">จังหวัด</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={_renderProvinceOptions()}
                  errors={errors.province?.message}
                />
              )}
              name="province"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">เบอร์โทรศัพท์</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  errors={errors.phone_number?.message}
                />
              )}
              name="phone_number"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">อีเมล</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField {...field} errors={errors.email?.message} />
              )}
              name="email"
              defaultValue=""
            />
          </div>
        </div>
        {fields.map((item, index) => (
          <div key={item.id} className="input_row">
            <div className="f_left_col">รายชื่อผู้ติดต่อที่ {index + 1}</div>
            <div className="f_right_col">
              <div className="contact_item">
                <div className="ci_col">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputCustomerAutoSuggestion
                        {...field}
                        disabled={!item.member?.isNew}
                        apiService={portalService.GET_CUSTOMER_LIST}
                        query="?isNotOrganization=true&first_name"
                        errors={
                          errors.member_list &&
                          // errors?.member_list[index]?.member?.message &&
                          errors?.member_list[index]?.member?.name?.message
                        }
                      />
                    )}
                    name={`member_list.${index}.member`}
                    // defaultValue=""
                  />
                </div>
                <div
                  className="btn_delete"
                  onClick={() => {
                    onDeleteItem && onDeleteItem(item);
                    remove(index);
                  }}
                >
                  <MdDelete fontSize={25} color={"red"} />
                </div>
              </div>
            </div>
          </div>
        ))}
        <button
          type="button"
          className="btn_add"
          onClick={() =>
            append({
              member: {
                name: "",
                isNew: true,
              },
            })
          }
        >
          เพิ่มผู้ติดต่อ
        </button>
        <div className="action_row">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
          {permission && (
            <>
              {initialValues && permission.is_delete && (
                <div className="w120">
                  <Buttons.BgStandard
                    theme_red
                    label="Delete"
                    disabled={initialValues && initialValues.is_center}
                    onClick={onDelete}
                  />
                </div>
              )}
              {initialValues && permission.is_update ? (
                <div className="w120">
                  <Buttons.BgStandard
                    type="submit"
                    label="Update"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              ) : (
                permission.is_create && (
                  <div className="w120">
                    <Buttons.BgStandard
                      type="submit"
                      label="Save"
                      // disabled={initialValues && initialValues.is_center}
                    />
                  </div>
                )
              )}
            </>
          )}
        </div>
      </form>
    </CorperateFormStyled>
  );
};

CorperateForm.propTypes = {};

export default CorperateForm;
