import React from "react";
import { ReportContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";

class ReportContainer extends React.Component {
  render() {
    return <ReportContainerStyled>ReportContainer</ReportContainerStyled>;
  }
}

export default withRouter(ReportContainer);
