import React from "react";
import { connect } from "react-redux";
import { VisitorContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { MdAdd, MdDownload } from "react-icons/md";
import queryString from "query-string";
import { ROUTE_PATH } from "utils/constants/routePath";
import * as XLSX from "xlsx";
import moment from "moment";
import momentTZ from "moment-timezone";
import { portalService } from "apiServices";
import {
  CUSTOMER_TYPE,
  ENTRY_TYPE,
  PURPOSE_OPTION,
  RENDER_AGE_RANGE_OPTION,
  RENDER_BOOKIN_METHOD,
  RENDER_CUSTOMER_TYPE,
  RENDER_ENTRY_TYPE,
  RENDER_PURPOSE_OPTION,
  RENDER_RACE,
  RENDER_TIME_OPTION,
} from "utils/functions/showroom";
import { DIVISION_OPTIONS } from "utils/functions/division";
// import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from "utils/functions/string";
import { ShowroomQrCodeContainer } from "./Modals";
import { RENDER_GENDER_TYPE } from "utils/functions/customer";
import { Buttons, Displays, Inputs, Modals, Tables } from "components";

class VisitorContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    perPage: 10,
  };

  componentDidMount() {
    const { perPage } = this.state;
    const query = this.props.router.location.search || `?limit=${perPage}`;
    this.fetchData(query);
  }

  fetchData = async (query) => {
    let res = await portalService.GET_SHOWROOM_LIST(query);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,

        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
        isLoading: false,
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoading: true,
    });
    this.props.router.navigate(`${ROUTE_PATH.SHOW_ROOM}${joinQuery}`);
    this.fetchData(joinQuery);
  };

  handleClickCreate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.SHOW_ROOM}/${e}`);
  };

  handleExport = async (permission) => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false`
      : `?pagination=false`;
    let res = await portalService.GET_SHOWROOM_LIST(joinQuery);
    if (res && res.status === 200) {
      let wb = XLSX.utils.book_new();
      let ws_name = "SheetJS";
      /* make worksheet */
      let ws_data = res.data.docs.reduce((result, e, i) => {
        result.push([
          i + 1,
          RENDER_BOOKIN_METHOD(e.customer_type)?.label || "",
          momentTZ(e.created_at).tz("Asia/Bangkok").format("dddd") || "",
          momentTZ(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY"),
          RENDER_TIME_OPTION(e.start_time, e.end_time) || "",
          RENDER_CUSTOMER_TYPE(e.customer_type)?.label || "",
          RENDER_GENDER_TYPE(e.customer_gender)?.labelTh || "",
          RENDER_RACE(e.race)?.label || "",
          RENDER_AGE_RANGE_OPTION(e.age)?.label || "",
          RENDER_GENDER_TYPE(e.kid_gender_1)?.labelTh || "",
          RENDER_GENDER_TYPE(e.kid_gender_2)?.labelTh || "",
          e.division || "",
          e.product_info_name_th || "",
          `${e.product_price_range_min || ""}-${
            e.product_price_range_max || ""
          }` || "",
          e.product_name_th || "",
          e.product_amount || "",
          e.product_price || "",
          RENDER_PURPOSE_OPTION(e.purpose).label || "",
          RENDER_ENTRY_TYPE(e.entry_type)?.label || "",
          RENDER_BOOKIN_METHOD(e.booking_method)?.label || "",
          e.remark || "",
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        "No",
        "Customer Type 2",
        "Day",
        "Date",
        "Time",
        "Status",
        "Gender",
        "Race",
        "Age",
        "Kid 1",
        "Kid 2",
        "Division",
        "Product info",
        "Price info",
        "Product",
        "Amount",
        "Price",
        "Purpose",
        "Customer Types",
        "Customer Types 2",
        "Remark",
      ]);
      let ws = XLSX.utils.aoa_to_sheet(ws_data);
      let wscols = [
        { wch: 5 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 30 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `showroom-report-${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: false,
      isShowModal: false,
    });
  };

  handleShowQrCode = () => {
    this.setState({
      renderModal: (
        <ShowroomQrCodeContainer onCloseModal={this.handleCloseModal} />
      ),
      isShowModal: true,
    });
  };

  render() {
    const { authenRedux, router } = this.props;
    const { isLoading, isShowModal, renderModal, pagination, data } =
      this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "booking");
    return (
      <VisitorContainerStyled>
        <div className="header">
          <div className="input_body">
            <Inputs.InputFilter
              theme_standard_input_filter
              dataFilter={dataFilter(router.location?.search)}
              btnLabel="ค้นหา"
              onQueryFilter={this.handleFilterKeyword}
              onExport={
                permission &&
                (permission.is_export || permission.is_export_condition)
                  ? () => this.handleExport(permission)
                  : false
              }
            />
          </div>
          <div className="btn_right">
            <div className="btn_create">
              <div className="w120">
                <Buttons.BgIconPrefix
                  label="Register QR code  "
                  icon={<MdDownload size={18} />}
                  onClick={this.handleShowQrCode}
                />
              </div>
              <div className="w120">
                <Buttons.BgIconPrefix
                  label=" Create Visitor"
                  icon={<MdAdd size={18} />}
                  onClick={() => this.handleClickCreate("create")}
                />
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <div className="body_table">
            <Tables.Standard
              columns={columns({
                handleClickDetail: this.handleClickCreate,
              })}
              data={data}
              showPagination
              pagination={pagination}
              handlePageClick={this.handlePageClick}
            />
          </div>
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={() => {
            this.setState({ isShowModal: false });
          }}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </VisitorContainerStyled>
    );
  }
}

const dataFilter = (query) => {
  let initialValues = queryString.parse(query);
  return [
    {
      accessor: "customer_type",
      length: "300px",
      filter: {
        fieldQuery: "customer_type",
        defaultValue: initialValues["customer_type"],
        type: "dropdown",
        label: "",
        placeholder: "Status",
        options: CUSTOMER_TYPE,
      },
    },
    {
      accessor: "division",
      length: "300px",
      filter: {
        fieldQuery: "division",
        defaultValue: initialValues["division"],
        type: "dropdown",
        label: "",
        placeholder: "Division",
        options: DIVISION_OPTIONS,
      },
    },
    {
      accessor: "purpose",
      length: "300px",
      filter: {
        fieldQuery: "purpose",
        defaultValue: initialValues["purpose"],
        type: "dropdown",
        label: "",
        placeholder: "Purpose",
        options: PURPOSE_OPTION,
      },
    },
    {
      accessor: "entry_type",
      length: "300px",
      filter: {
        fieldQuery: "entry_type",
        defaultValue: initialValues["entry_type"],
        type: "dropdown",
        label: "",
        placeholder: "Customer types",
        options: ENTRY_TYPE,
      },
    },
    // {
    //   accessor: "day",
    //   length: "300px",
    //   filter: {
    //     fieldQuery: "day",
    //     defaultValue: initialValues["day"],
    //     type: "text",
    //     label: "",
    //     placeholder: "Today",
    //   },
    // },
    {
      accessor: "start_date",
      filter: {
        fieldQuery: "start_date",
        defaultValue: initialValues["start_date"],
        type: "date",
        label: "",
        placeholder: "Data start",
      },
    },
    {
      accessor: "end_date",
      length: "250px",
      filter: {
        fieldQuery: "end_date",
        defaultValue: initialValues["end_date"],
        type: "date",
        label: "",
        placeholder: "Date end",
      },
    },
  ];
};

const columns = ({ handleClickDetail }) => [
  {
    Header: <div style={{ textAlign: "left" }}>Create</div>,
    accessor: "created_at",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Status</div>,
    accessor: "customer_type",
    Cell: (props) => RENDER_CUSTOMER_TYPE(props.value).label,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Gender</div>,
    accessor: "gender",
    Cell: (props) => RENDER_GENDER_TYPE(props.value).labelTh,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Division</div>,
    accessor: "division",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Product</div>,
    accessor: "product_name_th",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Price</div>,
    accessor: "product_price",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Purpose</div>,
    accessor: "purpose",
    Cell: (props) => RENDER_PURPOSE_OPTION(props.value).label,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Customer</div>,
    accessor: "customer_first_name",
    Cell: (props) => props.value + " " + props.row.original.customer_last_name,
  },

  {
    Header: <div style={{ textAlign: "left" }}></div>,
    accessor: "actions_edite",
    Cell: (props) => (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          columnGap: "20px",
        }}
      >
        {/* <button
          style={{
            background: "none",
            border: "none",
            color: "#007bff",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => handleClickDetail(props.row.original._id)}
        >
          View
        </button> */}
        <button
          style={{
            background: "none",
            border: "none",
            color: "#007bff",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => handleClickDetail(props.row.original._id)}
        >
          Edit
        </button>
      </div>
    ),
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VisitorContainer));
