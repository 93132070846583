import { useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EWarrantyFilterWidgetStyled } from './styled';
import { ROUTE_PATH } from 'utils/constants/routePath';

const EWarrantyFilterWidget = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [_active, _setActive] = useState(
    ROUTE_PATH.DASHBOARD_E_WARRANTY_OVERVIEW
  );

  useMemo(() => {
    const split = location.pathname.split('/');
    if (split) {
      switch (split[split.length - 1]) {
        case 'overview':
          _setActive(ROUTE_PATH.DASHBOARD_E_WARRANTY_OVERVIEW);
          break;
        case 'dealer':
          _setActive(ROUTE_PATH.DASHBOARD_E_WARRANTY_DEALER);
          break;
        case 'depcode':
          _setActive(ROUTE_PATH.DASHBOARD_E_WARRANTY_DEPCODE);
          break;
        default:
          break;
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleClick = (e) => {
    _setActive(e);
    navigate(e + location.search);
  };

  return (
    <EWarrantyFilterWidgetStyled>
      <div
        className={`mf_item ${
          _active === ROUTE_PATH.DASHBOARD_E_WARRANTY_OVERVIEW ? 'active' : ''
        }`}
        onClick={() => _handleClick(ROUTE_PATH.DASHBOARD_E_WARRANTY_OVERVIEW)}
      >
        E-Warranty Overview
      </div>
      <div
        className={`mf_item ${
          _active === ROUTE_PATH.DASHBOARD_E_WARRANTY_DEALER ? 'active' : ''
        }`}
        onClick={() => _handleClick(ROUTE_PATH.DASHBOARD_E_WARRANTY_DEALER)}
      >
        E-Warranty by Dealer
      </div>
      <div
        className={`mf_item ${
          _active === ROUTE_PATH.DASHBOARD_E_WARRANTY_DEPCODE ? 'active' : ''
        }`}
        onClick={() => _handleClick(ROUTE_PATH.DASHBOARD_E_WARRANTY_DEPCODE)}
      >
        E-Warranty by Dep.
      </div>
    </EWarrantyFilterWidgetStyled>
  );
};

export default EWarrantyFilterWidget;
