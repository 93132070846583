import React from "react";
import { connect } from "react-redux";
import { LoginContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { setReduxAuthen } from "store/reducers/authenReducer";
import { RENDER_ROUTE_PERMISSION } from "utils/functions/config";
import { Displays, Forms, Modals } from "components";
import { AlertControl } from "components/Modals/Alert";

class LoginContainer extends React.Component {
  state = { isBgLoading: false };

  componentDidMount() {}

  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    let res = await portalService.POST_LOGIN(params);
    if (res && res.status === 200) {
      localStorage.setItem("token", res.token);
      let resArr = await Promise.all([
        portalService.GET_PERMISSION_DETAIL(res.data.permission_id),
        portalService.GET_MY_PROFILE(),
      ]);
      if (resArr) {
        toast.success("Login success");
        let routeList = RENDER_ROUTE_PERMISSION(
          resArr[0].data.permissions_list
        );
        if (!routeList || routeList.length === 0 || resArr[0].status !== 200) {
          toast.error("คุณไม่มีสิทธิ์ในการใช้งาน กรุณาติดต่อแอดมิน");
        } else {
          if (resArr[1].data.is_update_password) {
            this.setState({
              isShowModal: true,
              isBgLoading: false,
              data: resArr[1].data,
            });
          } else {
            this.props.setReduxAuthen({
              ...resArr[1].data,
              permission: resArr[0].data.permissions_list,
            });
            this.props.router.navigate(routeList[0].path);
          }
        }
      }
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error("Login failed");
    }
  };

  handlePwSubmit = async (values) => {
    const { data } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      password: values.password,
    };
    let res = await portalService.PUT_ADMIN_PASSWORD_UPDATE(data._id, params);
    if (res && res.status === 200) {
      this.setState({
        isBgLoading: false,
        isShowModal: false,
      });
      toast.success("อัพเดตสำเร็จ");
      AlertControl.show({
        title: "สำเร็จ",
        description: "อัพเดตสำเร็จ กรุณาล็อคอินใหม่",
        btnLabel1: "ตกลง",
      });
    } else {
      toast.error("อัพเดตไม่สำเร็จ");
    }
  };

  render() {
    const { isBgLoading, isShowModal } = this.state;
    return (
      <>
        <LoginContainerStyled>
          <div className="form_layout">
            <Displays.BGLoading visible={isBgLoading} />
            <Forms.LoginForm onSubmit={this.handleSubmit} />
          </div>
        </LoginContainerStyled>
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={() => {
            this.setState({ isShowModal: false });
          }}
        >
          <Forms.PasswordForm onSubmit={this.handlePwSubmit} />
        </Modals.BodyEmpty>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginContainer));
