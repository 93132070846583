import React from "react";
import { ApproveActivityFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const ApproveActivityForm = ({ onCancel, onSubmit }) => {
  const schema = yup.object().shape({
    status: yup.string().required("Required"),
  });

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchStatus = watch("status");

  const _onSubmit = (values) => {
    let isErr = false;
    if (values.status === "2" && !values.rejection_reason) {
      isErr = true;
      setError("rejection_reason", {
        type: "custom",
        message: "Required",
      });
    }
    if (!isErr) {
      onSubmit(values);
    }
  };

  return (
    <ApproveActivityFormStyled>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="form_title">แก้ไขสถานะ</div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Dropdown
                {...field}
                options={[
                  {
                    label: "Approved",
                    value: "4",
                  },
                  {
                    label: "Reject",
                    value: "2",
                  },
                ]}
                // placeholder={t('test')}
                errors={errors.status?.message}
              />
            )}
            name="status"
            defaultValue=""
          />
        </div>
        {watchStatus && watchStatus === "2" && (
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={"เหตุผลการยกเลิก"}
                  errors={errors.rejection_reason?.message}
                />
              )}
              name="rejection_reason"
              defaultValue=""
            />
          </div>
        )}
        <div className="cf_action_row">
          <div className="cf_action_item">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
          <div className="cf_action_item">
            <Buttons.BgStandard
              type="submit"
              label="Update"
              // disabled={initialValues && initialValues.is_center}
            />
          </div>
        </div>
      </form>
    </ApproveActivityFormStyled>
  );
};

ApproveActivityForm.propTypes = {};

export default ApproveActivityForm;
