import { useEffect, useState } from "react";
import { SystemLayoutStyled, LoadingStyled } from "./styled";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogoutThunk } from "store/reduxThunks/logoutThunk";
import { useNavigate } from "react-router-dom";
import { portalService } from "apiServices";
import { ROUTE_PATH } from "utils/constants/routePath";
import { setReduxAuthen } from "store/reducers/authenReducer";
import { NavbarWidget, SidebarWidget } from "widgets";
import { Displays } from "components";

const SystemLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [_isLoading, _setIsLoading] = useState(true);

  useEffect(() => {
    _checkToken();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _checkToken = async () => {
    let res = await portalService.POST_CHECK_TOKEN();
    if (res && res.status === 200) {
      let resArr = await Promise.all([
        portalService.GET_PERMISSION_DETAIL(res.data.permission_id),
        portalService.GET_MY_PROFILE(),
      ]);
      if (resArr) {
        if (resArr[1].data.is_update_password || resArr[0].status !== 200) {
          _destroyToken();
        } else {
          dispatch(
            setReduxAuthen({
              ...resArr[1].data,
              permission: resArr[0].data.permissions_list,
            })
          );
          _setIsLoading(false);
        }
      }
    } else {
      _destroyToken();
    }
  };

  const _destroyToken = async () => {
    let res = await dispatch(setLogoutThunk());
    if (res) {
      _setIsLoading(false);
      navigate(ROUTE_PATH.ROOT);
    }
  };

  return _isLoading ? (
    <LoadingStyled>
      <Displays.Loading />
    </LoadingStyled>
  ) : (
    <SystemLayoutStyled>
      <div className="left_mainlayout">
        <SidebarWidget />
      </div>
      <div className="right_mainlayout">
        <NavbarWidget />
        <div className="body_mainlayout">
          <Outlet />
        </div>
      </div>
    </SystemLayoutStyled>
  );
};

export default SystemLayout;
