import styled from 'styled-components';

export const SidebarWidgetStyled = styled.div`
  min-height: 100%;
  width: 238px;
  background: ${({ theme }) => theme.COLORS.BLUE_5};
  .s_logo_sec {
    padding: 1rem;
    text-align: center;
    .sidebar_logo {
      width: 170px;
      height: auto;
    }
  }
  .s_menu_sec {
    margin: 0px;
    padding: 0px;
    list-style: none;
    .s_menu_item {
      padding: 1rem 1.5rem;
      display: flex;
      align-items: center;
      column-gap: 0.75rem;
      cursor: pointer;
    }
  }
`;
