import React, { useRef, useEffect, useState } from "react";
import { CertificateFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";
import { portalService } from "apiServices";
import { IMG_PATH } from "utils/constants/imgPath";

const CertificateForm = ({
  initialValues,
  permission,
  activitiOption,
  onCancel,
  onDelete,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    certificate_code: yup.string().required("Required"),
    certificate_name: yup.string().required("Required"),
    activity_ref_code: yup.string().required("Required"),
    sub_category: yup.string().required("Required"),
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      if (initialValues.img_path) {
        setTemplate(IMG_PATH + initialValues.img_path);
      }
      setFontSize(initialValues.cus_name_font_size);
      setPosition({ ...position, y: initialValues.cus_name_position_y });
      reset({
        ...initialValues,
        img_file: initialValues.img_path
          ? [{ media_uri: initialValues.img_path }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const _onSubmit = (values) => {
    let isErr = false;
    if (values.status === "2" && !values.rejection_reason) {
      isErr = true;
      setError("rejection_reason", {
        type: "custom",
        message: "Required",
      });
    }
    if (!isErr) {
      onSubmit(values);
    }
  };

  const [template, setTemplate] = useState(null); // เก็บ URL ของ Template
  const [name, setName] = useState("Your Name");
  const [position, setPosition] = useState({ x: 200, y: 300 });
  const [fontSize, setFontSize] = useState(24);
  const canvasRef = useRef(null);

  // ฟังก์ชันอัปโหลดภาพ Template
  const handleTemplateUpload = (e) => {
    const file = e[e.length - 1];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setTemplate(e.target.result); // เก็บ Base64 URL ของภาพ
      reader.readAsDataURL(file);
    }
  };

  // เรนเดอร์ Canvas เมื่อ Template หรือข้อมูลอื่น ๆ เปลี่ยนแปลง
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // ล้าง Canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (template) {
      const img = new Image();
      img.crossOrigin = "anonymous"; // อนุญาต Cross-Origin
      img.src = template;

      // รอให้ภาพโหลดสำเร็จก่อน
      img.onload = () => {
        // ปรับขนาด canvas ให้เหมาะสมกับขนาดของรูป
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height); // วาด Background ให้เต็มพื้นที่
        ctx.font = `${fontSize}px Arial`;
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle"; // ตั้งค่าตำแหน่งข้อความกลาง
        // ctx.fillText(name, position.x, position.y); // วาดชื่อในตำแหน่งที่กำหนด
        ctx.fillText(name, img.width / 2, position.y); // วาดชื่อในตำแหน่งที่กำหนด
      };
    }
  }, [template, name, position, fontSize]);

  // ฟังก์ชันดาวน์โหลด Certificate
  const handleDownload = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.download = "certificate.png";
    link.href = canvas.toDataURL();

    link.click();
  };

  return (
    <CertificateFormStyled isShowCanva={template}>
      <button
        type="button"
        onClick={async () => {
          let res = await portalService.GET_CERTIFICATE_GENERATE(
            "67825250d31ca4b11d5a405f"
          );
          // if (res) {
          //   const link = document.createElement("a");
          //   link.href = res.data; // กำหนด URL ให้เป็น Base64 ของภาพ
          //   link.download = "certificate.png"; // กำหนดชื่อไฟล์ที่ผู้ใช้จะดาวน์โหลด
          //   link.click(); // เรียกการดาวน์โหลด
          // }
        }}
      >
        Download Test
      </button>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="form_section">
          <div className="form_title">Certificate Info</div>
          <div className="input_row">
            <div className="f_left_col">Certificate Code</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    // placeholder={t('test')}
                    errors={errors.certificate_code?.message}
                  />
                )}
                name="certificate_code"
                defaultValue=""
              />
            </div>
          </div>
          <div className="input_row">
            <div className="f_left_col">Certificate Name</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    // placeholder={t('test')}
                    errors={errors.certificate_name?.message}
                  />
                )}
                name="certificate_name"
                defaultValue=""
              />
            </div>
          </div>
          <div className="input_row">
            <div className="f_left_col">For Event/Activity</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={activitiOption || []}
                    errors={errors.activity_ref_code?.message}
                    // disabled={initialValues}
                  />
                )}
                name="activity_ref_code"
                // defaultValue={{ label: '1', value: '1' }}
              />
            </div>
          </div>
          <div className="input_row">
            <div className="f_left_col">Sub Category Name</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    // placeholder={t('test')}
                    errors={errors.sub_category?.message}
                  />
                )}
                name="sub_category"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="form_section">
          <div className="form_title">Certificate Generator</div>
          <div className="input_row">
            <div className="f_left_col">Certificate Picture</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.UploadImage
                    {...field}
                    theme_upload_image
                    fieldName="img_path"
                    errors={errors.img_file?.message}
                    onChangeCustom={handleTemplateUpload}
                  />
                )}
                name="img_file"
                defaultValue={[]}
              />
              <div>***รูป JPG or PNG ขนาดกว้าง 3508 px สูง 2480 px</div>
            </div>
          </div>
          <div className="input_row">
            <div className="f_left_col">Sample Name</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    // placeholder={t('test')}
                    errors={errors.name_exam?.message}
                    onChangeCustom={(e) => setName(e.target.value)}
                  />
                )}
                name="name_exam"
                defaultValue="Your Name"
              />
            </div>
          </div>
          <div className="input_row">
            <div className="f_left_col">Font Size</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    type="Number"
                    // placeholder={t('test')}
                    errors={errors.cus_name_font_size?.message}
                    onChangeCustom={(e) => setFontSize(Number(e.target.value))}
                  />
                )}
                name="cus_name_font_size"
                defaultValue={24}
              />
            </div>
          </div>
          <div className="input_row">
            <div className="f_left_col">Position Y</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    type="Number"
                    // placeholder={t('test')}
                    errors={errors.cus_name_position_y?.message}
                    onChangeCustom={(e) =>
                      setPosition({ ...position, y: Number(e.target.value) })
                    }
                  />
                )}
                name="cus_name_position_y"
                defaultValue={300}
              />
            </div>
          </div>
          {template && (
            <div className="input_row">
              <div className="f_left_col"></div>
              <div className="f_right_col">
                <div className="btn_download">
                  <Buttons.BgStandard
                    label={"Download Sample"}
                    onClick={handleDownload}
                  />
                </div>
              </div>
            </div>
          )}
          <canvas ref={canvasRef} className="certificate_canva" />
        </div>
        {permission && (
          <div className="cf_action_row">
            <div className="cf_action_item">
              <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
            </div>
            {initialValues && permission.is_delete && (
              <div className="cf_action_item">
                <Buttons.BgStandard
                  theme_red
                  label="Delete"
                  onClick={onDelete}
                />
              </div>
            )}
            {initialValues && permission.is_update ? (
              <>
                <div className="cf_action_item">
                  <Buttons.BgStandard
                    type="submit"
                    label="Update"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              </>
            ) : (
              permission.is_create && (
                <div className="cf_action_item">
                  <Buttons.BgStandard
                    type="submit"
                    label="Save"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              )
            )}
          </div>
        )}
      </form>
    </CertificateFormStyled>
  );
};

CertificateForm.propTypes = {};

export default CertificateForm;
