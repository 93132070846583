import styled from "styled-components";

export const ActivityFormStyled = styled.div`
  .input_row {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    .f_left_col {
      width: 20%;
      display: flex;
      align-items: center;
    }
    .f_right_col {
      width: 40%;
    }
  }
  .qr_code_block {
    margin-bottom: 1rem;
    .f_title {
      margin-bottom: 0.5rem;
    }
    .qrcode_row {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2rem;
      row-gap: 2rem;
    }
  }

  .action_row {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
  }
  .question_block {
    margin-bottom: 2rem;
    max-width: 60%;
    .question_row {
      margin-bottom: 0.5rem;
      display: flex;
      align-items: flex-end;
      column-gap: 1rem;
      .question_left_col {
        .question_input_item {
          margin-bottom: 1rem;
        }
        .question_upload {
          max-width: 400px;
        }
      }
    }
    .question_type_wrap {
      max-width: 400px;
      margin-bottom: 0.5rem;
    }
    .answer_block {
      padding-left: 2rem;
    }
  }
  .btn_add {
    border-radius: 1rem;
    cursor: pointer;
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
`;
