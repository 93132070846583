import React from "react";
import { connect } from "react-redux";
import { RewardDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { Displays, Forms, Modals } from "components";

class RewardDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    scrollToTopPageFN();
    if (this.props.router.params.id !== "create") {
      this.fetchData();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  fetchData = async () => {
    let res = await portalService.GET_REWARD_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  // FLOW DELETE
  // =============================
  // =============================
  handleDelete = () => {
    this.setState({
      renderModal: (
        <Displays.CofirmBox
          label="คุณต้องการลบใช่หรือไม่ ?"
          labelBtn1="Confirm"
          labelBtn2="Cancel"
          onAction1={this.deleteAction}
          onAction2={this.handleCloseModal}
        />
      ),
    });
  };
  deleteAction = async () => {
    this.setState({
      isBgLoading: true,
    });
    let res = await portalService.DELETE_REWARD_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      toast.success("ลบสำเร็จ");
      this.handleNextStep();
    } else {
      toast.error("ลบไม่สำรเร็จ");
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  // FLOW SUBMIT
  // =============================
  // =============================
  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    let res =
      this.props.router.params.id === "create"
        ? await portalService.POST_REWARD_DETAIL(params)
        : await portalService.PUT_REWARD_UPDATE(
            this.props.router.params.id,
            params
          );
    if (res && res.status === 200) {
      let filesTemp = [];
      if (values.img_file) {
        filesTemp = [
          ...filesTemp,
          ...values.img_file.filter((e) => e.isNewUpload),
        ];
      }
      if (filesTemp.length === 0) {
        toast.success("อัพเดตสำเร็จ");
        this.handleNextStep();
      } else {
        this.handleUploadContentMedia(res.data._id, filesTemp);
      }
    } else {
      toast.error("อัพเดตไม่สำเร็จ");
    }
  };
  handleUploadContentMedia = (rewardID, files) => {
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set("reward_id", rewardID);
            bodyFormData.append("image", e);
            let res = await portalService.POST_REWARD_UPLOAD(bodyFormData);
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      this.setState({
        isBgLoading: false,
      });
      toast.success("อัพเดจสำเร็จ");
      this.handleNextStep();
    });
  };

  handleCancel = () => {
    this.props.router.navigate(-1);
  };

  handleNextStep = () => {
    this.props.router.navigate(ROUTE_PATH.REWARD);
  };

  render() {
    const { authenRedux } = this.props;
    const { isLoading, initialValues, isBgLoading, renderModal } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "reward");
    return (
      <RewardDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading theme_standard_loading />
          </div>
        ) : (
          <Forms.RewardForm
            initialValues={initialValues}
            permission={permission}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            onDelete={this.handleDelete}
          />
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </RewardDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RewardDetailContainer));
