import styled from "styled-components";

export const ChangeStatusFormStyled = styled.div`
  border-radius: 1rem;
  padding: 1.5rem 1rem;
  min-width: 500px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .form_title {
    margin-bottom: 1.5rem;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .input_wrap {
    margin-bottom: 1rem;
  }
  .cf_action_row {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    .cf_action_item {
      width: 100%;
      max-width: 160px;
    }
  }
`;
