import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputAutoSuggestionStyled } from './styled';
import Autosuggest from 'react-autosuggest';

// Teach Autosuggest how to calculate suggestions for any given input value.
// const getSuggestions = (value) => {
//   const inputValue = value.trim().toLowerCase();
//   const inputLength = inputValue.length;

//   return inputLength === 0
//     ? []
//     : languages.filter(
//         (lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue
//       );
// };

const InputAutoSuggestion = ({
  theme_standard,
  disabled,
  desc,
  value,
  fieldRender,
  onChange,
  onChangeCustom,
  apiService,
  query,
  placeholder,
  label,
  errors,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const _renderField = (values) => {
    switch (fieldRender) {
      case 'serial_no':
        return `${values.serial_no}-${values.item_no}`;
      default:
        return values[fieldRender];
    }
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) =>
    fieldRender
      ? _renderField(suggestion)
      : `${suggestion.first_name} ${suggestion.last_name}`;

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => (
    <div>
      {fieldRender
        ? _renderField(suggestion)
        : `${suggestion.first_name} ${suggestion.last_name}`}
    </div>
  );

  let timeoutRef = useRef(null);

  const [suggestions, setSuggestions] = useState([]);
  const [_value, _setValue] = useState('');

  useEffect(() => {
    if (value && !_value) {
      _setValue(value?.name);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async ({ value }) => {
    let res = await apiService(`${query}=${value}`);
    if (res && res.status === 200) {
      setSuggestions(res.data.docs);
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    disabled: disabled,
    placeholder: placeholder,
    value: _value,
    onChange: async (event, { newValue }) => {
      _setValue(newValue);
      let find = suggestions.find((e) =>
        fieldRender
          ? _renderField(e) === newValue
          : `${e.first_name} ${e.last_name}` === newValue
      );
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (find) {
        const params = {
          ...find,
          name: fieldRender
            ? _renderField(find)
            : `${find.first_name} ${find.last_name}`,
          isNew: true,
        };
        _setValue(newValue);
        onChange(params);
        onChangeCustom && onChangeCustom(params);
      } else {
        let res = await apiService(`${query}=${value}`);
        if (res && res.status === 200) {
        } else {
          onChange(undefined);
          onChangeCustom && onChangeCustom(undefined);
        }
        // onChange(undefined);
        // onChangeCustom && onChangeCustom(undefined);
      }
    },
  };

  return (
    <InputAutoSuggestionStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        {desc && <div className="input_desc">*** {desc}</div>}
        {errors && <div className="errors">{errors}</div>}
      </div>
    </InputAutoSuggestionStyled>
  );
};

InputAutoSuggestion.propTypes = {};

export default InputAutoSuggestion;
