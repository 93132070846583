import React from 'react';
import { connect } from 'react-redux';
import { ProductSetContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
import { MdAdd } from 'react-icons/md';
import { RENDER_GENDER_TYPE } from 'utils/functions/customer';
import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from 'utils/functions/string';
import { RENDER_STATUS_PRODUCT_WARRANTY_SET } from 'utils/functions/productWarrantySet';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import * as XLSX from 'xlsx';
import { Buttons, Displays, Tables } from 'components';

class ProductSetContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    perPage: 10,
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    const query =
      this.props.router.location.search || `?limit=${perPage}&is_delete=false`;
    this.fetchData(query);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_PRODUCT_WARRANTY_SET_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        isLoading: false,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}&is_delete=false`
      : `?limit=${per_page}&page=${page}&is_delete=false`;
    this.setState({
      isLoading: true,
    });
    this.props.router.navigate(`${ROUTE_PATH.CUSTOMER}${joinQuery}`);
    this.fetchData(joinQuery);
  };

  handleExport = async (permission) => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false&is_delete=false`
      : `?pagination=false&is_delete=false`;
    let res = await portalService.GET_PRODUCT_WARRANTY_SET_LIST(joinQuery);
    if (res && res.status === 200) {
      let wb = XLSX.utils.book_new();
      let ws_name = 'SheetJS';
      /* make worksheet */
      let ws_data = res.data.docs.reduce((result, e, i) => {
        result.push([
          i + 1,
          momentTZ(e.created_at).tz('Asia/Bangkok').format('DD/MM/YYYY'),
          `${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: e.first_name,
            action: 'export',
          })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: e.last_name,
            action: 'export',
          })}`,
          RENDER_GENDER_TYPE(e.gender)?.labelTh,
          RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: e.phone_number,
            type: 'phone',
            action: 'export',
          }),
          e.province,
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        'No',
        'Create Date',
        'Name',
        'Gender',
        'Phone',
        'Province',
      ]);
      let ws = XLSX.utils.aoa_to_sheet(ws_data);
      let wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws['!cols'] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `customer-report-${moment()
          .tz('Asia/Bangkok')
          .format('DD/MM/YYYY')}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    }
  };

  handleClickCreate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.PRODUCT_SET}/${e}`);
  };

  render() {
    const { authenRedux } = this.props;
    const { isLoading, pagination, data } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === 'product_warranty');
    return (
      <ProductSetContainerStyled>
        {permission && permission.is_create && (
          <div className="btn_create">
            <div className="w120">
              <Buttons.BgIconPrefix
                label="Create Set"
                icon={<MdAdd size={18} />}
                onClick={() => this.handleClickCreate('create')}
              />
            </div>
          </div>
        )}
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <>
            <div className="body_table">
              <Tables.Standard
                columns={columns({
                  permission: permission,
                  handleClickDetail: this.handleClickCreate,
                  handleClickInfoDetail: this.handleClickInfoDetail,
                })}
                data={data}
                showPagination
                pagination={pagination}
                handlePageClick={this.handlePageClick}
              />
            </div>
          </>
        )}
      </ProductSetContainerStyled>
    );
  }
}

const columns = ({ permission, handleClickDetail, handleClickInfoDetail }) => {
  return [
    {
      Header: <div style={{ textAlign: 'left' }}>Create Date</div>,
      accessor: 'created_at',
      Cell: (props) => (
        <div onClick={() => handleClickDetail(props.row.original._id)}>
          {momentTZ(props.value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm')}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: 'left' }}>Setname</div>,
      accessor: 'name',
      Cell: (props) => (
        <div onClick={() => handleClickDetail(props.row.original._id)}>
          {props.value}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: 'left' }}>Setcode</div>,
      accessor: 'code',
      Cell: (props) => (
        <div onClick={() => handleClickDetail(props.row.original._id)}>
          {props.value}
        </div>
      ),
    },
    // {
    //   Header: <div style={{ textAlign: "left" }}>Item</div>,
    //   accessor: "item",
    //   Cell: (props) => (
    //     <div onClick={() => handleClickDetail(props.row.original._id)}>
    //       {props.value}
    //     </div>
    //   ),
    // },
    {
      Header: <div style={{ textAlign: 'left' }}>Organizer</div>,
      accessor: 'organization_name',
      Cell: (props) => (
        <div onClick={() => handleClickDetail(props.row.original._id)}>
          {props.value}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: 'left' }}>Status</div>,
      accessor: 'status',
      Cell: (props) => {
        const renderStatus = RENDER_STATUS_PRODUCT_WARRANTY_SET(props.value);
        return (
          <div onClick={() => handleClickDetail(props.row.original._id)}>
            <Displays.StatusLabel
              bg={renderStatus?.bg}
              label={renderStatus?.label}
            />
          </div>
        );
      },
    },
  ];
};

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductSetContainer));
