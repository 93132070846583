import styled from 'styled-components';

export const TableNormalStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard_table_small {
    border-radius: 1rem;
    background: ${({ theme }) => theme.COLORS.BLUE_1};
    padding: 12px;
    .title_table {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.WHITE_1};
      padding-bottom: 10px;
      text-align: left;
      padding-left: 10px;
    }
    .table_container {
      .table_header {
        display: none;
        .header_row {
          max-width: 1200px;
          width: 100%;
          margin: auto;
          display: flex;
          align-items: center;
          .header_col {
            width: 100%;
            padding: 15px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S15};
            font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
            &:first-child {
              border-left: none;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
        }
        .body_row_wrap {
          .body_row {
            max-width: 1200px;
            width: 100%;
            margin: auto;
            display: flex;
            align-items: center;

            cursor: pointer;
            padding: 12px 0;
            align-items: center;

            .body_col {
              font-size: ${({ theme }) => theme.FONT.SIZE.S16};
              font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
              padding: 0 15px;
              width: 100%;
            }
          }
          &:hover {
          }
        }
      }
    }
  }
`;
