import Scheduler from "./Scheduler";
import Standard from "./Standard";
import TableNormal from "./TableNormal";

const EXPORT = {
  Standard,
  TableNormal,
  Scheduler,
};

export default EXPORT;
