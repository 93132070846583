import Color from "./Color";
import DateTime from "./DateTime";
import DateGroup from "./DateGroup";
import Dropdown from "./Dropdown";
import InputAutoSuggestion from "./InputAutoSuggestion";
import InputCheckBox from "./InputCheckBox";
import InputCustomerAutoSuggestion from "./InputCustomerAutoSuggestion";
import InputDatePickerBetween from "./InputDatePickerBetween";
import InputFilter from "./InputFilter";
import InputMultiSelect from "./InputMultiSelect";
import InputMutiCheckbox from "./InputMutiCheckbox";
import InputSwitch from "./InputSwitch";
import InputWysiwyg from "./InputWysiwyg";
import TextArea from "./TextArea";
import TextField from "./TextField";
import Radio from "./Radio";
import UploadImage from "./UploadImage";
import UploadImageQuestion from "./UploadImageQuestion";
import UploadSetImage from "./UploadSetImage";

const EXPORT = {
  Color,
  DateGroup,
  DateTime,
  Dropdown,
  InputAutoSuggestion,
  InputCheckBox,
  InputCustomerAutoSuggestion,
  InputDatePickerBetween,
  InputFilter,
  InputMutiCheckbox,
  InputMultiSelect,
  TextField,
  InputSwitch,
  InputWysiwyg,
  TextArea,
  Radio,
  UploadImage,
  UploadImageQuestion,
  UploadSetImage,
};

export default EXPORT;
