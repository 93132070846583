import React from "react";
import { StandardStyled } from "./styled";
import cx from "classnames";
import { useTable, useSortBy, useRowSelect } from "react-table";
import { MdDelete } from "react-icons/md";
import { Displays } from "components";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export const Standard = ({
  columns,
  data,
  pagination,
  showPagination,
  handlePageClick,
  onDelete,
  isSelect,
}) => {
  const customClass = cx({});
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          // Header: ({ getToggleAllPageRowsSelectedProps }) => (
          //   <div>
          //     <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
          //   </div>
          // ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) =>
            !isSelect ? (
              <></>
            ) : (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
        },
        ...columns,
      ]);
    }
  );

  const _handleDelete = () => {
    onDelete && onDelete(selectedFlatRows.map((d) => d.original));
  };

  return (
    <StandardStyled>
      <div className={customClass}>
        {selectedFlatRows && selectedFlatRows.length > 0 && (
          <div className="table_action_row">
            <div className="ta_delet_btn" onClick={_handleDelete}>
              <MdDelete fontSize={18} color="gray" />
            </div>
          </div>
        )}
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                  >
                    {/* <div className="header_group"> */}
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span className="sort">
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                    {/* </div> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return cell.column.id === "no" ? (
                      <td {...cell.getCellProps()}>{i + 1}</td>
                    ) : (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {showPagination && pagination && data && data.length > 0 && (
          <div className="pagi_layout">
            <Displays.PaginationCustom
              theme_standard_pagination
              totalPages={pagination?.last_page}
              currentPageData={pagination?.current_page - 1}
              _handlePageClick={handlePageClick}
            />
          </div>
        )}
      </div>
    </StandardStyled>
  );
};

export default Standard;
