import styled from "styled-components";

export const EWarrantyDelaerDepCodeContainerStyled = styled.div`
  border-radius: 1rem;
  padding: 1.25rem;
  min-width: 619px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .loading_wrap {
    display: flex;
    justify-content: center;
  }
`;
