export const BOOKING_STATUS_OPTIONS = [
  {
    label: "Waiting",
    value: "1",
  },
  {
    label: "Approved",
    value: "2",
  },
  {
    label: "Rejected",
    value: "3",
  },
  {
    label: "Rejected by Customer",
    value: "4",
  },
];

export const RENDER_BOOKING_STATUS = (e) => {
  switch (e) {
    case "1":
      return {
        bgColor: "#D9C316",
        label: "Waiting",
      };
    case "2":
      return {
        bgColor: "#328826",
        label: "Approved",
      };
    case "3":
      return {
        bgColor: "#FF8900",
        label: "Rejected",
      };
    case "4":
      return {
        bgColor: "#D82F2F",
        label: "Rejected by Customer",
      };
    default:
      return {
        bgColor: "",
        label: "",
      };
  }
};

export const VISITOR_STATUS_OPTIONS = [
  {
    label: "Waiting",
    value: "1",
  },
  {
    label: "No Show",
    value: "2",
  },
  {
    label: "Check-in",
    value: "3",
  },
];

export const RENDER_VISITOR_STATUS = (e) => {
  switch (e) {
    case "1":
      return {
        bgColor: "#D9C317",
        label: "Waiting",
      };
    case "2":
      return {
        bgColor: "#FF8900",
        label: "No Show",
      };
    case "3":
      return {
        bgColor: "#4D1E79",
        label: "Check-in",
      };
    default:
      return {
        bgColor: "",
        label: "",
      };
  }
};

export const RENDER_AGE_RANGE = (e) => {
  switch (e) {
    case "1":
      return {
        label: "10 - 20",
      };
    case "2":
      return {
        label: "21 - 30",
      };
    case "3":
      return {
        label: "31 - 40",
      };
    case "4":
      return {
        label: "41 - 50",
      };
    case "5":
      return {
        label: "51 - 60",
      };
    case "6":
      return {
        label: "60 and more",
      };
    default:
      break;
  }
};

export const BOOKING_PURPOSE_OPTIONS = [
  {
    labelEn: "Personal Use",
    labelTh: "ใช้เอง",
    value: "1",
  },
  {
    labelEn: "For Children",
    labelTh: "เลือกซื้อให้ลูกหลาน",
    value: "2",
  },
  {
    labelEn: "For Student",
    labelTh: "เลือกซื้อให้นักเรียน",
    value: "3",
  },
];

export const RENDER_BOOKING_PURPOSE = (key) => {
  switch (key) {
    case "1":
      return {
        labelEn: "Personal Use",
        labelTh: "ใช้เอง",
      };
    case "2":
      return {
        labelEn: "For Children",
        labelTh: "เลือกซื้อให้ลูกหลาน",
      };
    case "3":
      return {
        labelEn: "For Student",
        labelTh: "เลือกซื้อให้นักเรียน",
      };
    default:
      break;
  }
};

export const RENDER_BOOKING_MEMBER_TYPE = (key) => {
  switch (key) {
    case "1":
      return {
        labelEn: "Individual",
        labelTh: "บุคคล",
      };
    case "2":
      return {
        labelEn: "Corperate",
        labelTh: "องค์กร",
      };

    default:
      break;
  }
};
