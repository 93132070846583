import React, { useEffect } from "react";
import { RewardFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Inputs, Buttons } from "components";

const RewardForm = ({
  initialValues,
  permission,
  onSubmit,
  onCancel,
  onDelete,
}) => {
  const schema = yup.object().shape({
    name: yup.string().required("Reward Name is required"),
    description: yup.string().required("Description Name is required"),
    type: yup.string().required("Reward Type is required"),
  });

  const {
    control,
    // watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const watchCampaignType = watch('type');

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        expired_at: new Date(initialValues.expired_at),
        start_date:
          initialValues.start_date && new Date(initialValues.start_date),
        end_date: initialValues.end_date && new Date(initialValues.end_date),
        img_file: initialValues.img ? [{ media_uri: initialValues.img }] : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RewardFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="body_form_campaign">
          <div className="input_type_wrap">
            <div className="itw_left_box">Reward type</div>
            <div className="itw_right_box">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={[
                      {
                        value: "1",
                        label: "E-Coupon",
                      },
                      {
                        value: "2",
                        label: "Voucher",
                      },
                      {
                        value: "3",
                        label: "Gift",
                      },
                    ]}
                    errors={errors.type?.message}
                  />
                )}
                name="type"
                // defaultValue={{ label: '1', value: '1' }}
              />
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Reward name:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      errors={errors.name?.message}
                    />
                  )}
                  name="name"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Reward description:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextArea
                      {...field}
                      errors={errors.description?.message}
                    />
                  )}
                  name="description"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Expired at:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.DateTime
                      {...field}
                      placeholder={"วันที่สิ้นสุด"}
                      errors={errors.expired_at?.message}
                      showYearDropdown
                    />
                  )}
                  name="expired_at"
                  defaultValue={new Date()}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Start Date:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.DateTime
                      {...field}
                      placeholder={"Start Date"}
                      errors={errors.start_date?.message}
                      showYearDropdown
                    />
                  )}
                  name="start_date"
                  defaultValue={new Date()}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">End Date:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.DateTime
                      {...field}
                      placeholder={"End Date"}
                      errors={errors.end_date?.message}
                      showYearDropdown
                    />
                  )}
                  name="end_date"
                  defaultValue={new Date()}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Total amount</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      type="number"
                      min={0}
                      errors={errors.total_amount?.message}
                    />
                  )}
                  name="total_amount"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Redeem point</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      type="number"
                      min={0}
                      errors={errors.redeem_point?.message}
                    />
                  )}
                  name="redeem_point"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Price</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      type="number"
                      min={0}
                      errors={errors.price?.message}
                    />
                  )}
                  name="price"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Image</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      acceptType={"cover"}
                      scaleLabel="*** รูปขนาดกว้าง 197px สูง 148px"
                      errors={errors.img_file?.message}
                    />
                  )}
                  name="img_file"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Active</div>
            <div className="right_box">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputSwitch
                    {...field}
                    errors={errors.is_active?.message}
                  />
                )}
                name="is_active"
                defaultValue={false}
              />
            </div>
          </div>
          <div className="btn_zone">
            <div className="w120">
              <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
            </div>
            {permission && (
              <>
                {initialValues && permission.is_delete && (
                  <div className="w120">
                    <Buttons.BgStandard
                      theme_red
                      label="Delete"
                      onClick={onDelete}
                      disabled={!initialValues.is_edit}
                    />
                  </div>
                )}
                {initialValues && permission.is_update ? (
                  <div className="w120">
                    <Buttons.BgStandard
                      type="submit"
                      label="Update"
                      // disabled={initialValues && initialValues.is_center}
                    />
                  </div>
                ) : (
                  permission.is_create && (
                    <div className="w120">
                      <Buttons.BgStandard
                        type="submit"
                        label="Save"
                        // disabled={initialValues && initialValues.is_center}
                      />
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </div>
      </form>
    </RewardFormStyled>
  );
};

RewardForm.propTypes = {};

export default RewardForm;
