import React from 'react';
import { connect } from 'react-redux';
import { CorperateDetailContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { portalService } from 'apiServices';
import momentTZ from 'moment-timezone';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import {
  ACTIVITY_RENDER_TYPE,
  ACTIVITY_RENDER_STATUS,
} from 'utils/functions/activity';
import { RENDER_STATUS_PRODUCT_WARRANTY } from 'utils/functions/productWarranty';
import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from 'utils/functions/string';
import { AlertControl } from 'components/Modals/Alert';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Displays, Tables } from 'components';

class CorperateDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let resArr = await Promise.all([
      this.fetchOrganizationDetail(),
      this.fetchActivityData(),
      this.fetchProductWarranteeData(),
      this.fetchRewardData(),
    ]);
    if (resArr) {
      this.setState({
        data: resArr[0],
        activityHistoryData: resArr[1],
        productWarranteeData: resArr[2],
        rewardHistoryData: resArr[3],
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  fetchOrganizationDetail = async () => {
    let res = await portalService.GET_ORGANIZATION_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };
  fetchProductWarranteeData = async () => {
    let res = await portalService.GET_PRODUCT_WARRANTEE_BY_CUSTOMER_LIST(
      this.props.router.params.id,
      `?pagination=false`
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };
  fetchActivityData = async () => {
    let res = await portalService.GET_ACTIVITY_BY_CUSTOMER_LIST(
      this.props.router.params.id,
      `?pagination=false`
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };
  fetchRewardData = async () => {
    let res = await portalService.GET_REWARD_HISTORY_BY_CUSTOMER_LIST(
      this.props.router.params.id,
      `?pagination=false`
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };

  handleClickBack = (e) => {
    this.props.router.navigate(-1);
  };

  handleClickDelete = async () => {
    AlertControl.show({
      title: 'ยืนยันการลบ',
      description: 'คุณต้องการจะลบใช่หรือไม่',
      btnLabel1: 'ยกเลิก ',
      btnLabel2: 'ยืนยัน',
      onEvent2: async () => {
        this.setState({
          isBgLoading: true,
        });
        let res = await portalService.DELETE_ORGANIZATION_DETAIL(
          this.props.router.params.id
        );
        if (res && res.status === 200) {
          toast.success('ลบสำเร็จ');
          this.setState({
            isBgLoading: false,
          });
          this.props.router.navigate(-1);
        } else {
          toast.error('ลบไม่สำเร็จ');
        }
      },
    });
  };

  handleClickProductWarrantyDetail = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.PRODUCT_WARRANTY}/${e._id}`);
  };

  render() {
    const { authenRedux } = this.props;
    const {
      isLoading,
      isBgLoading,
      data,
      activityHistoryData,
      productWarranteeData,
    } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === 'customer');
    return (
      <CorperateDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <div className="block_section">
              <div className="title">รายละเอียดองค์กร</div>
              <div className="cus_info_row">
                <div className="ci_left_col">
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={'ชื่อองค์กร'}
                      value={`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                        ...permission,
                        inputString: data.name,
                      })}`}
                    />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={'เบอร์โทร'}
                      value={RENDER_CHARACTER_REPLACE_BY_PERMISSION({
                        ...permission,
                        inputString: data.phone_number,
                        type: 'phone',
                      })}
                    />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue label={'อีเมล'} value={data.email} />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={'จังหวัด'}
                      value={`${data.province}`}
                    />
                  </div>
                  {data.member_list &&
                    data.member_list.map((e, i) => (
                      <div key={i} className="value_item_wrap">
                        <Displays.LabelValue
                          label={`ชื่อผู้ติดต่อที่ ${i + 1}`}
                          value={`${e.name}`}
                        />
                      </div>
                    ))}
                </div>
                <div className="ci_right_col"></div>
              </div>
            </div>
            <div className="block_section">
              <div className="title">การลงทะเบียนประกันสินค้า</div>
              <div className="body">
                <Tables.Standard
                  columns={productWaranteeColumns({
                    handleClickProductWarrantyDetail:
                      this.handleClickProductWarrantyDetail,
                  })}
                  data={productWarranteeData.docs}
                />
              </div>
            </div>
            <div className="block_section">
              <div className="title">การลงทะเบียนกิจกรรม</div>
              <div className="body">
                <Tables.Standard
                  columns={activityHistoryColumns()}
                  data={activityHistoryData.docs}
                />
              </div>
            </div>
          </>
        )}
      </CorperateDetailContainerStyled>
    );
  }
}

const productWaranteeColumns = ({ handleClickProductWarrantyDetail }) => [
  {
    Header: <div style={{ textAlign: 'left' }}>Create Date</div>,
    accessor: 'created_at',
    Cell: (props) => (
      <div onClick={() => handleClickProductWarrantyDetail(props.row.original)}>
        {momentTZ(props.value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm')}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Sn</div>,
    accessor: 'serial_no',
    Cell: (props) => (
      <div onClick={() => handleClickProductWarrantyDetail(props.row.original)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Item no</div>,
    accessor: 'item_no',
    Cell: (props) => (
      <div onClick={() => handleClickProductWarrantyDetail(props.row.original)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Dealer Code</div>,
    accessor: 'dealer_code',
    Cell: (props) => (
      <div onClick={() => handleClickProductWarrantyDetail(props.row.original)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Dealer Name</div>,
    accessor: 'dealer_name',
    Cell: (props) => (
      <div onClick={() => handleClickProductWarrantyDetail(props.row.original)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'center' }}>Status</div>,
    accessor: 'status',
    Cell: (props) => {
      const renderStatus = RENDER_STATUS_PRODUCT_WARRANTY(props.value);
      return (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => handleClickProductWarrantyDetail(props.row.original)}
        >
          <Displays.StatusLabel
            bg={renderStatus?.bg}
            label={renderStatus?.label}
          />
        </div>
      );
    },
  },
];

const activityHistoryColumns = () => [
  {
    Header: <div style={{ textAlign: 'left' }}>Create Date</div>,
    accessor: 'created_at',
    Cell: (props) => (
      <div>
        {momentTZ(props.value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm')}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Code</div>,
    accessor: 'activity_code',
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Name</div>,
    accessor: 'activity_name',
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: 'left' }}>ชนิด</div>,
    accessor: 'activity_type',
    Cell: (props) => <div>{ACTIVITY_RENDER_TYPE(props.value)}</div>,
  },
  {
    Header: <div style={{ textAlign: 'left' }}>คะแนน</div>,
    accessor: 'activity_point',
    Cell: (props) => <div>{RenderCommaMoney(props.value, true)}</div>,
  },
  {
    Header: <div style={{ textAlign: 'center' }}>Status</div>,
    accessor: 'status',
    Cell: (props) => {
      const renderStatus = ACTIVITY_RENDER_STATUS(props.value);
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Displays.StatusLabel
            bg={renderStatus?.bg}
            label={renderStatus?.label}
          />
        </div>
      );
    },
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CorperateDetailContainer));
