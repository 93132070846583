import styled from "styled-components";

export const CertificateContainerStyled = styled.div`
  .header {
    display: flex;
    flex-direction: column;
    .header_btn {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      .btn_create {
        display: flex;
        flex-direction: column;
        .w120 {
          width: 200px;
          margin-bottom: 10px;
        }
      }
    }
    .input_body {
      padding: 1.25rem;
    }
  }
  .body_table {
    margin-top: 20px;
    border-radius: 1rem;
    padding: 1.25rem;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
  }
`;

export const StatusButton = styled.button`
  background-color: ${({ bg }) => bg};
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 20px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
`;
