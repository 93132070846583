import systemRoutes from "../../navigations/systemRoutes";

export const RENDER_ROUTE_PERMISSION = (permissionList) => {
  if (!permissionList) {
    return permissionList;
  } else {
    let routeList = [];
    permissionList.forEach((e) => {
      switch (e.menu_key) {
        case "dashboard":
          if (e.is_read) {
            routeList.push(systemRoutes.find((f) => f.key === "dashboard"));
          }
          break;
        case "product_warranty":
          if (e.is_read) {
            routeList.push(
              systemRoutes.find((f) => f.key === "product-warranty")
            );
          }
          break;
        case "business_unit":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "business-unit")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "branch":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "branch")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "corperate":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "corperate")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "customer":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "customer")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "new":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "new")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "activity":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "activity")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "activity-form":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "activity-form")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "booking":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "booking")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "show-room":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "show-room")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "question":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "question")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "reward":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "reward")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "lucky_draw":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "lucky-draw")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "pdpa":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "pdpa")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "user_management":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "user_management")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;

        case "permisssion":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "permission")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "certificate":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "certificate")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        case "banner":
          if (e.is_read) {
            systemRoutes
              .filter((f) => f.key === "banner")
              .forEach((g) => {
                routeList.push(g);
              });
          }
          break;
        default:
          break;
      }
    });
    return routeList;
  }
};
