import React, { useEffect } from "react";
import { EmailFormStyled } from "./styled";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";
import { MdAdd, MdDelete } from "react-icons/md";

const EmailForm = ({ initialValue, permission, onSubmit }) => {
  const schema = yup.object().shape({
    // date: yup.string().required("required"),
    // emails1: yup
    //   .array()
    //   .of(yup.string().email("Invalid email").required("Email is required")),
    // emails2: yup
    //   .array()
    //   .of(yup.string().email("Invalid email").required("Email is required")),
    // emails3: yup
    //   .array()
    //   .of(yup.string().email("Invalid email").required("Email is required")),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    fields: fields1,
    append: append1,
    remove: remove1,
  } = useFieldArray({
    control,
    name: "emails1",
  });

  const {
    fields: fields2,
    append: append2,
    remove: remove2,
  } = useFieldArray({
    control,
    name: "emails2",
  });

  const {
    fields: fields3,
    append: append3,
    remove: remove3,
  } = useFieldArray({
    control,
    name: "emails3",
  });

  useEffect(() => {
    if (initialValue) {
      reset({
        ...initialValue,
      });
    }
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const addEmail1 = () => append1("");
  const addEmail2 = () => append2("");
  const addEmail3 = () => append3("");

  return (
    <EmailFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="title">การส่ง Email แจ้งเตือน</div>
        <div className="f_header">
          <div className="f_title">
            1. เมื่อ User ทำจองมาเพื่อให้ Admin Approve <br />
            ส่ง email เตือน admin เพื่อให้เข้ามาทำการ Approve
          </div>

          <div className="f_right_col">
            {fields1.map((item, index) => (
              <div key={item.id} className="email-field">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      errors={errors.emails1?.[index]?.message}
                      placeholder={`Email ${index + 1}`}
                    />
                  )}
                  name={`emails1[${index}].email`}
                  defaultValue={item.email || ""}
                />
                <button
                  className="btn_delete"
                  type="button"
                  onClick={() => remove1(index)}
                >
                  <MdDelete size={30} />
                </button>
              </div>
            ))}
            {permission.is_create && (
              <div className="btn_submit_wrap">
                <Buttons.BgIconPrefix
                  type="button"
                  icon={<MdAdd size={18} />}
                  label="Add Email"
                  onClick={addEmail1}
                />
              </div>
            )}
          </div>
        </div>

        <div className="f_header">
          <div className="f_title">
            2. เมื่อ Admin ทำการ Approved เสร็จสำเร็จ <br />
            ส่ง email เตือนไปยังทีม Showroom
          </div>
          <div className="f_right_col">
            {fields2.map((item, index) => (
              <div key={item.id} className="email-field">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      errors={errors.emails2?.[index]?.message}
                      placeholder={`Email ${index + 1}`}
                    />
                  )}
                  name={`emails2[${index}].email`}
                  defaultValue={item.email || ""}
                />
                <button
                  className="btn_delete"
                  type="button"
                  onClick={() => remove2(index)}
                >
                  <MdDelete size={30} />
                </button>
              </div>
            ))}
            {permission.is_create && (
              <div className="btn_submit_wrap">
                <Buttons.BgIconPrefix
                  type="button"
                  icon={<MdAdd size={18} />}
                  label="Add Email"
                  onClick={addEmail2}
                />
              </div>
            )}
          </div>
        </div>

        <div className="f_header">
          <div className="f_title">
            3. หาก User ทำการ Cancel การจอง <br />
            ส่ง email แจ้ง admin และ ทีม Showroom
          </div>
          <div className="f_right_col">
            {fields3.map((item, index) => (
              <div key={item.id} className="email-field">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      errors={errors.emails3?.[index]?.message}
                      placeholder={`Email ${index + 1}`}
                    />
                  )}
                  name={`emails3[${index}].email`}
                  defaultValue={item.email || ""}
                />
                <button
                  className="btn_delete"
                  type="button"
                  onClick={() => remove3(index)}
                >
                  <MdDelete size={30} />
                </button>
              </div>
            ))}
            {permission.is_create && (
              <div className="btn_submit_wrap">
                <Buttons.BgIconPrefix
                  type="button"
                  icon={<MdAdd size={18} />}
                  label="Add Email"
                  onClick={addEmail3}
                />
              </div>
            )}
          </div>
        </div>
        <div className="sec_btn_submit">
          <div className="btn_submit_wrap">
            <Buttons.BgIconPrefix type="submit" label="บันทึก" />
          </div>
        </div>
      </form>
    </EmailFormStyled>
  );
};

EmailForm.propTypes = {};

export default EmailForm;
