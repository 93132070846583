import React from "react";
import { connect } from "react-redux";
import { BannerListContainerStyled /* StatusButton */ } from "./styled";
import { withRouter } from "navigations/withRouter";
import queryString from "query-string";
import { ROUTE_PATH } from "utils/constants/routePath";
// import * as XLSX from "xlsx";
// import moment from "moment";
import momentTZ from "moment-timezone";
// import { portalService } from "apiServices";
// import {
//   BOOKING_STATUS_OPTIONS,
//   RENDER_BOOKING_STATUS,
//   RENDER_VISITOR_STATUS,
// } from "utils/functions/booking";
import { DIVISION_OPTIONS } from "utils/functions/division";
import { INSTUMENT_TYPE_OPTIONS } from "utils/functions/productBooking";
// import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from "utils/functions/string";
import { Buttons, /* Displays, */ Inputs, Tables } from "components";

class BannerListContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    perPage: 10,
  };

  // componentDidMount() {
  //   const { perPage } = this.state;
  //   const query = this.props.router.location.search || `?limit=${perPage}`;
  //   this.fetchData(query);
  // }

  // fetchData = async (query) => {
  //   let res = await portalService.GET_BOOKING_LIST(query);
  //   if (res && res.status === 200) {
  //     this.setState({
  //       data: res.data.docs,
  //       pagination: {
  //         last_page: res.data.totalPages,
  //         current_page: res.data.page,
  //       },
  //       isLoading: false,
  //     });
  //   }
  // };

  // handleFilterKeyword = (e) => {
  //   const { perPage } = this.state;
  //   this.setState({
  //     filterQuery: e,
  //   });
  //   this.handleJoinQuery(e, perPage, 1);
  // };

  // handlePageClick = (e) => {
  //   const { filterQuery, perPage } = this.state;
  //   this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  // };

  // handleJoinQuery = (query, per_page, page) => {
  //   let joinQuery = query
  //     ? `${query}&limit=${per_page}&page=${page}&date_type=booking_date`
  //     : `?limit=${per_page}&page=${page}&date_type=booking_date`;
  //   this.setState({
  //     isLoading: true,
  //   });
  //   this.props.router.navigate(`${ROUTE_PATH.BOOKING}${joinQuery}`);
  //   this.fetchData(joinQuery);
  // };

  // handleClickCreate = (e) => {
  //   this.props.router.navigate(`${ROUTE_PATH.BOOKING}/${e}`);
  // };

  // handleExport = async (permission) => {
  //   const { filterQuery } = this.state;
  //   this.setState({
  //     isBgLoading: true,
  //   });
  //   let joinQuery = filterQuery
  //     ? `${filterQuery}&pagination=false`
  //     : `?pagination=false`;
  //   let res = await portalService.GET_BOOKING_LIST(joinQuery);
  //   if (res && res.status === 200) {
  //     let wb = XLSX.utils.book_new();
  //     let ws_name = "SheetJS";
  //     /* make worksheet */
  //     let ws_data = res.data.docs.map((e, i) => [
  //       i + 1,
  //       momentTZ(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm"),
  //       RENDER_CHARACTER_REPLACE_BY_PERMISSION({
  //         ...permission,
  //         inputString: e.customer_first_name,
  //         action: "export",
  //       }),
  //       RENDER_CHARACTER_REPLACE_BY_PERMISSION({
  //         ...permission,
  //         inputString: e.customer_last_name,
  //         action: "export",
  //       }),
  //       RENDER_CHARACTER_REPLACE_BY_PERMISSION({
  //         ...permission,
  //         inputString: e.customer_phone_number,
  //         type: "phone",
  //       }),
  //       e.instrument_type_th || "",
  //       e.division || "",
  //       e.note || "",
  //       momentTZ(e.date).tz("Asia/Bangkok").format("DD/MM/YYYY") || "",
  //       `${e.start_time}-${e.end_time}` || "",
  //       RENDER_BOOKING_STATUS(e.status).label || "",
  //       RENDER_VISITOR_STATUS(e.visit_status).label || "",
  //     ]);

  //     ws_data.splice(0, 0, [
  //       "No",
  //       "วันที่สร้าง",
  //       "ชื่อ",
  //       "นามสกุล",
  //       "หมายเลขโทรศัพท์",
  //       "หมวดหมู่",
  //       "ฝ่าย",
  //       "หมายเหตุ",
  //       "วันที่จอง",
  //       "เวลา",
  //       "สถานะ",
  //       "สถานะเข้าชม",
  //     ]);
  //     let ws = XLSX.utils.aoa_to_sheet(ws_data);
  //     let wscols = [
  //       { wch: 5 },
  //       { wch: 15 },
  //       { wch: 20 },
  //       { wch: 20 },
  //       { wch: 20 },
  //       { wch: 15 },
  //       { wch: 15 },
  //       { wch: 20 },
  //       { wch: 20 },
  //       { wch: 15 },
  //       { wch: 15 },
  //     ];
  //     ws["!cols"] = wscols;
  //     /* Add the worksheet to the workbook */
  //     XLSX.utils.book_append_sheet(wb, ws, ws_name);
  //     XLSX.writeFile(
  //       wb,
  //       `booking-report-${moment()
  //         .tz("Asia/Bangkok")
  //         .format("DD/MM/YYYY")}.xlsx`
  //     );
  //     this.setState({
  //       isBgLoading: false,
  //     });
  //   }
  // };

  handleClickGenerate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.BANNER}/${e}`);
  };

  render() {
    const { authenRedux, router } = this.props;
    const { /* isLoading, */ pagination /*  data */ } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "banner");
    return (
      <BannerListContainerStyled>
        <div className="btn_create">
          <div className="w120">
            <Buttons.BgIconPrefix
              label="Add"
              onClick={() => this.handleClickGenerate("create")}
            />
          </div>
        </div>
        <div className="header">
          {/* <div className="header_btn">
            <div className="btn_create">
              <div className="w120">
                <Buttons.BgIconPrefix
                  label="New Booking"
                  icon={<MdAdd size={18} />}
                  onClick={() => this.handleClickCreate("create")}
                />
              </div>
            </div>
          </div> */}
          <div className="input_body">
            <Inputs.InputFilter
              theme_standard_input_filter
              dataFilter={dataFilter(router.location?.search)}
              btnLabel="ค้นหา"
              onQueryFilter={this.handleFilterKeyword}
              onExport={
                permission &&
                (permission.is_export || permission.is_export_condition)
                  ? () => this.handleExport(permission)
                  : false
              }
            />
          </div>
        </div>
        {/* {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : ( */}
        <div className="body_table">
          <Tables.Standard
            columns={columns({
              handleClickDetail: this.handleClickCreate,
            })}
            data={[]}
            showPagination
            pagination={pagination}
            handlePageClick={this.handlePageClick}
          />
        </div>
        {/* )} */}
      </BannerListContainerStyled>
    );
  }
}

const dataFilter = (query) => {
  let initialValues = queryString.parse(query);
  return [
    {
      accessor: "customer_first_name",
      // length: "300px",
      filter: {
        fieldQuery: "customer_first_name",
        defaultValue: initialValues["customer_first_name"],
        type: "text",
        label: "",
        placeholder: "Name",
      },
    },
    {
      accessor: "customer_phone_number",
      // length: "300px",
      filter: {
        fieldQuery: "customer_phone_number",
        defaultValue: initialValues["customer_phone_number"],
        type: "text",
        label: "",
        placeholder: "Phone number",
      },
    },
    {
      accessor: "instrument_type",
      length: "300px",
      filter: {
        fieldQuery: "instrument_type",
        defaultValue: initialValues["instrument_type"],
        type: "dropdown",
        label: "",
        placeholder: "Category",
        options: INSTUMENT_TYPE_OPTIONS.map((e) => ({
          label: e.labelTh,
          value: e.value,
        })),
      },
    },
    {
      accessor: "division",
      length: "300px",
      filter: {
        fieldQuery: "division",
        defaultValue: initialValues["division"],
        type: "dropdown",
        label: "",
        placeholder: "ฝ่าย",
        options: DIVISION_OPTIONS,
      },
    },
    {
      accessor: "status",
      length: "300px",
      filter: {
        fieldQuery: "status",
        defaultValue: initialValues["status"],
        type: "dropdown",
        label: "",
        placeholder: "Status",
        // options: BOOKING_STATUS_OPTIONS,
      },
    },
    {
      accessor: "start_date",
      filter: {
        fieldQuery: "start_date",
        defaultValue: initialValues["start_date"],
        type: "date",
        label: "",
        placeholder: "Data start",
      },
    },
    {
      accessor: "end_date",
      filter: {
        fieldQuery: "end_date",
        defaultValue: initialValues["end_date"],
        type: "date",
        label: "",
        placeholder: "Date end",
      },
    },
  ];
};

const columns = ({ handleClickDetail }) => [
  {
    Header: <div style={{ textAlign: "left" }}>Create</div>,
    accessor: "created_at",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Banner Image</div>,
    accessor: "customer_first_name",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
];

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
// });

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  // mapStateToProps,
  mapDispatchToProps
)(withRouter(BannerListContainer));
