import styled from "styled-components";

export const QuestionFormStyled = styled.div`
  .answer_row {
    margin-bottom: 0.5rem;
    display: flex;
    column-gap: 1rem;
  }
  .question_block {
    margin-bottom: 2rem;
    max-width: 744px;
    .qt_header_block {
      margin-bottom: 32px;
      .qth_row {
        margin-bottom: 14px;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        .qth_no {
          padding-right: 8px;
          color: ${({ theme }) => theme.COLORS.BLACK_2};
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
        }
        .move_block {
          padding: 0 8px;
          border-left: 1.5px solid ${({ theme }) => theme.COLORS.GRAY_10};
          border-right: 1.5px solid ${({ theme }) => theme.COLORS.GRAY_10};
          display: flex;
          align-items: center;
          column-gap: 10px;
          .btn_move {
            border: 1px solid ${({ theme }) => theme.COLORS.GRAY_9};
            width: 44px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.disabled {
              background: ${({ theme }) => theme.COLORS.GRAY_3};
              cursor: not-allowed;
            }
          }
        }
        .upload_block {
          border-right: 1.5px solid ${({ theme }) => theme.COLORS.GRAY_10};
          padding: 0 8px;
          display: flex;
          align-items: center;
          column-gap: 10px;
          .btn_add_img {
            border: 1px solid ${({ theme }) => theme.COLORS.GRAY_9};
            width: 44px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
        .active_block {
          border-right: 1.5px solid ${({ theme }) => theme.COLORS.GRAY_10};
          padding: 0 8px;
          display: flex;
          align-items: center;
          column-gap: 12px;
          .require_label {
          }
        }
        .btn_delete {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;
        }
      }
    }
    .qt_lv3_block {
      padding-left: 60px;
      .qt_lv3_input_wrap {
        margin-bottom: 20px;
        .upload_img {
          border-radius: 1rem;
          width: 180px;
          height: 180px;
          object-fit: cover;
        }
      }
      .answer_block {
        padding-left: 2rem;
      }
    }
  }
  .btn_add_lv2 {
    margin-bottom: 90px;
    border-radius: 18px;
    padding: 8px 32px;
    background: ${({ theme }) => theme.COLORS.BLUE_6};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    cursor: pointer;
  }
`;
