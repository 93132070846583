import React, { useEffect, useState } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { InputMutiCheckboxStyled } from "./styled";

const InputMutiCheckbox = ({
  theme_standard,
  theme_flex,
  options,
  value,
  onChange,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_flex: theme_flex,
  });

  const [_value, _setValue] = useState({});

  useEffect(() => {
    if (value && Object.keys(_value).length === 0) {
      _setValue({ ...value });
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleChange = (e) => {
    let temp = _value;
    let inputData = String(e.value);
    if (temp[inputData]) {
      delete temp[inputData];
      _setValue({ ...temp });
      onChange && onChange({ ...temp });
      // onChange && onChange(Object.keys(temp).map(key => key));
    } else {
      temp[inputData] = e;
      _setValue({ ...temp });
      onChange && onChange({ ...temp });
      // onChange && onChange(Object.keys(temp).map(key => key));
    }
  };

  return (
    <InputMutiCheckboxStyled>
      <div className={customClass}>
        <div className="input_checkbox_container">
          {options &&
            options.map((e, i) => (
              <div
                key={i}
                className="input_checkbox_item"
                onClick={() => _handleChange(e)}
              >
                {_value[e.value] ? (
                  <MdCheckBox fontSize={18} />
                ) : (
                  <MdCheckBoxOutlineBlank fontSize={18} />
                )}
                {e.label && <span className="lable_show">{e.label}</span>}
              </div>
            ))}
        </div>
      </div>
    </InputMutiCheckboxStyled>
  );
};

InputMutiCheckbox.propTypes = {};

export default InputMutiCheckbox;
