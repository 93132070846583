import styled from 'styled-components';

export const BusinessContainerStyled = styled.div`
  .btn_create {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .w120 {
      margin-left: 8px;
      width: 120px;
    }
  }
  .body_table {
    margin-top: 20px;
    border-radius: 1rem;
    padding: 1.25rem;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
  }
`;
