import styled from 'styled-components';

export const CustomerContainerStyled = styled.div`
  .body_table {
    margin-top: 20px;
    border-radius: 1rem;
    padding: 1.25rem;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
  }
`;
