import React, { useEffect } from "react";
import { QuestionFormStyled } from "./styled";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AnswerForm from "./AnswerForm";
import { MdDelete } from "react-icons/md";
import { Buttons, Inputs } from "components";
import { toast } from "react-toastify";

const QuestionForm = ({
  initialValues,
  activityOptions,
  permission,
  onCancel,
  onDelete,
  onSubmit,
  onSent
}) => {
  const schema = yup.object().shape({
    title: yup.string().required("required"),
    description: yup.string().required("required"),
    start_date: yup.string().required("required"),
    end_date: yup.string().required("required"),
    point: yup.string().required("required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "question_list",
  });

  const watchJoin = watch("is_join");
  const watchQuestionList = watch("question_list");

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        start_date:
          initialValues.start_date && new Date(initialValues.start_date),
        end_date: initialValues.end_date && new Date(initialValues.end_date),
        front_img_file: initialValues.front_img
          ? [{ media_uri: initialValues.front_img }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const _onSubmit = (values) => {
    let err = [];
    if (values.is_join && !values.join_ref_id) {
      err.push("กรุณาเลือกกิจกรรมที่เชื่อมโยง");
      setError("join_ref_id", {
        type: "custom",
        message: "required",
      });
    }
    if (err.length > 0) {
      toast.error(err.join(", "));
    } else {
      onSubmit(values);
    }
  };

  return (
    <QuestionFormStyled>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="input_row">
          <div className="f_left_col">Name</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.title?.message}
                />
              )}
              name="title"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Description</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextArea
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.description?.message}
                />
              )}
              name="description"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Start Date</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  placeholder={"Start Date"}
                  errors={errors.start_date?.message}
                  showYearDropdown
                />
              )}
              name="start_date"
              defaultValue={new Date()}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">End Date</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  placeholder={"End Date"}
                  errors={errors.end_date?.message}
                  showYearDropdown
                />
              )}
              name="end_date"
              defaultValue={new Date()}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Picture</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  {...field}
                  theme_upload_image
                  acceptType={"cover"}
                  scaleLabel="*** รูปขนาดกว้าง 720px สูง 720px"
                  errors={errors.front_img_file?.message}
                />
              )}
              name="front_img_file"
              defaultValue={[]}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Point</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  type="number"
                  // placeholder={t('test')}
                  errors={errors.point?.message}
                />
              )}
              name="point"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Only activity participants</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  disabled={initialValues}
                  errors={errors.is_join?.message}
                />
              )}
              name="is_join"
              defaultValue={false}
            />
          </div>
        </div>
        {watchJoin && (
          <div className="input_row">
            <div className="f_left_col">Activity</div>
            <div className="f_right_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={
                      initialValues
                        ? activityOptions
                        : activityOptions.filter((e) => !e.is_join)
                    }
                    errors={errors.join_ref_id?.message}
                    disabled={initialValues}
                  />
                )}
                name="join_ref_id"
                // defaultValue={{ label: '1', value: '1' }}
              />
            </div>
          </div>
        )}
        <div className="input_row">
          <div className="f_left_col">One time popup</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  disabled={true}
                  errors={errors.is_popup_activate?.message}
                />
              )}
              name="is_popup_activate"
              defaultValue={false}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">One time Notification</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  disabled={true}
                  errors={errors.is_notification_activate?.message}
                />
              )}
              name="is_notification_activate"
              defaultValue={false}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Active</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  errors={errors.is_active?.message}
                />
              )}
              name="is_active"
              defaultValue={false}
            />
          </div>
        </div>
        {fields.map((item, index) => (
          <div key={item.id} className="question_block">
            <div className="question_row">
              <Controller
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    label={`คำถามข้อที่ ${index + 1}`}
                    // placeholder={t('test')}
                    // errors={errors.title?.message}
                  />
                )}
                name={`question_list.${index}.question`}
                control={control}
              />
              <div className="btn_delete" onClick={() => remove(index)}>
                <MdDelete fontSize={25} color={"red"} />
              </div>
            </div>
            <div className="question_type_wrap">
              <Controller
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={[
                      {
                        label: "Choice",
                        value: "1",
                      },
                      {
                        label: "Freetext",
                        value: "2",
                      },
                    ]}
                    errors={errors.type?.message}
                  />
                )}
                name={`question_list.${index}.type`}
                control={control}
              />
            </div>
            {watchQuestionList &&
              watchQuestionList[index] &&
              watchQuestionList[index].type === "1" && (
                <div className="answer_block">
                  <AnswerForm nestIndex={index} {...{ control, errors }} />
                </div>
              )}
          </div>
        ))}
        <button type="button" className="btn_add" onClick={() => append()}>
          เพิ่มคำถาม
        </button>
        {permission && (
          <div className="action_row">
            <div className="w120">
              <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
            </div>
            {initialValues && permission.is_delete && (
              <div className="w120">
                <Buttons.BgStandard
                  theme_red
                  label="Delete"
                  onClick={onDelete}
                />
              </div>
            )}
            {initialValues && permission.is_update ? (
              <>
                {!initialValues.is_notification_activate && (
                  <div className="w120">
                    <Buttons.BgStandard
                      theme_green
                      label="Sent noti"
                      // disabled={initialValues && initialValues.is_center}
                      onClick={onSent}
                    />
                  </div>
                )}
                <div className="w120">
                  <Buttons.BgStandard
                    type="submit"
                    label="Update"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              </>
            ) : (
              permission.is_create && (
                <div className="w120">
                  <Buttons.BgStandard
                    type="submit"
                    label="Save"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              )
            )}
          </div>
        )}
      </form>
    </QuestionFormStyled>
  );
};

QuestionForm.propTypes = {};

export default QuestionForm;
