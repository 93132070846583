import styled from "styled-components";

export const PDPAFormStyled = styled.div`
  .input_group_container {
    margin-bottom: 1.5rem;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_1};
    padding: 1rem;
    .input_group_title {
      margin-bottom: 1rem;
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
    }
  }
  .input_row {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    .f_left_col {
      width: 20%;
      display: flex;
      align-items: center;
    }
    .f_right_col {
      width: 40%;
    }
  }
  .action_row {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
  }
`;
