import styled from "styled-components";

export const EmailFormStyled = styled.div`
  padding: 20px;
  .title {
    color: ${({ theme }) => theme.COLORS.BLACK_3};
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_BOLD};
  }
  .f_header {
    display: flex;
    flex-direction: column;
    padding: 20px;
    column-gap: 60px;

    .f_title {
      margin-bottom: 20px;
      padding-top: 10px;
      color: ${({ theme }) => theme.COLORS.BLACK_3};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    }

    .f_right_col {
      margin-left: 50px;
      width: 350px;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      .email-field {
        display: flex;
        column-gap: 10px;
        .btn_delete {
          background: none;
          color: ${({ theme }) => theme.COLORS.RED_1};
        }
      }
      .btn_submit_wrap {
        width: 150px;
        min-width: 140px;
      }
    }
  }
  .sec_btn_submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .btn_submit_wrap {
      width: 150px;
      min-width: 140px;
    }
  }
`;
