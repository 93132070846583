import React from "react";
import { PasswordFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^])[a-zA-Z\d!@#$%^]{8,}$/;

const PasswordForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    password: yup
      .string()
      .matches(
        passwordRegex,
        "8 ตัวอักษร ตัวอักษรพิมพ์ใหญ่เล็ก (a-z, A-Z) มีตัวเลขอย่างน้อย 1 ตัว (0-9) พร้อมอักขระพิเศษ (!@#$%^)"
      )
      .required("Password is required"),
    confirm_password: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password")], "password not match"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <PasswordFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="psf_title">Password Update</div>
        <div className="input_row">
          <div className="f_left_col">Password</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  // type="password"
                  minLength={8}
                  // placeholder={t('test')}
                  errors={errors.password?.message}
                />
              )}
              name="password"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Confirm Password</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  minLength={8}
                  // type="password"
                  // placeholder={t('test')}
                  errors={errors.confirm_password?.message}
                />
              )}
              name="confirm_password"
              defaultValue=""
            />
          </div>
        </div>
        <div className="w120">
          <Buttons.BgStandard
            type="submit"
            label="Update"
            // disabled={initialValues && initialValues.is_center}
          />
        </div>
      </form>
    </PasswordFormStyled>
  );
};

PasswordForm.propTypes = {};

export default PasswordForm;
