import { useState } from "react";
import { BtnUploadCerificateWidgetStyled } from "./styled";
import { MdCheckCircle } from "react-icons/md";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { Forms, Modals } from "components";

const BtnUploadCerificateWidget = ({
  acvityFormHistoryID,
  value,
  onBgLoading,
  onSuccess,
}) => {
  const [_renderModal, _setRenderModal] = useState(false);

  const _handleChange = () => {
    _setRenderModal(true);
  };
  const _handleCloseModal = () => {
    _setRenderModal(false);
  };

  const _handleSubmit = async (values) => {
    onBgLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append("activity_form_history_id", acvityFormHistoryID);
    bodyFormData.append("image", values.file[0]);

    let res = await portalService.POST_ACTIVITY_FORM_CERTIFICATE_UPLOAD(
      bodyFormData
    );
    if (res && res.status === 200) {
      onBgLoading(false);
      toast.success("ดำเนินการสำเร็จ");
      onSuccess && onSuccess();
    } else {
      toast.error("เปลี่ยนไม่สำเร็จ");
    }
  };

  return (
    <>
      <BtnUploadCerificateWidgetStyled onClick={_handleChange}>
        {!value ? "-" : <MdCheckCircle size={20} color="green" />}
      </BtnUploadCerificateWidgetStyled>
      <Modals.BodyEmpty
        isShowModal={_renderModal}
        onCloseModal={_handleCloseModal}
      >
        <Forms.CertificateForm
          onCancel={_handleCloseModal}
          onSubmit={_handleSubmit}
        />
      </Modals.BodyEmpty>
    </>
  );
};

export default BtnUploadCerificateWidget;
