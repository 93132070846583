import styled from "styled-components";

export const CalendarWeekItemStyled = styled.div`
  position: relative;
  border: 1px solid
    ${({ theme, isHoliday }) => (isHoliday ? theme.COLORS.GRAY_2 : "#ccc")};
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 60px;
  background-color: ${({ isHoliday, theme }) =>
    isHoliday ? theme.COLORS.GRAY_2 : theme.COLORS.WHITE_1};
  .status_indicator {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 8px;
    background-color: ${({ indicatorColor }) =>
      indicatorColor ? indicatorColor : "transparent"};
  }
  .cdw_list {
    padding: 4px 12px;
    .cdw_item {
      display: flex;
    }
  }

  .theme_standard {
  }
`;
