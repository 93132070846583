import React, { useEffect } from "react";
import { PermissionFormStyled } from "./styled";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const PermissionForm = ({
  initialValues,
  permission,
  onCancel,
  onDelete,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { permissions_list: PERMISSION_LIST },
  });

  const { fields } = useFieldArray({
    control,
    name: "permissions_list",
  });

  useEffect(() => {
    if (initialValues) {
      let params = initialValues;
      params.permissions_list =
        !initialValues.permissions_list ||
        initialValues.permissions_list.length === 0
          ? PERMISSION_LIST
          : initialValues.permissions_list;
      reset(params);
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PermissionFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_row">
          <div className="f_left_col">Name</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.name?.message}
                />
              )}
              name="name"
              defaultValue=""
            />
          </div>
        </div>
        <table className="f_table">
          <thead>
            <tr>
              <td></td>
              <td>Create</td>
              <td>Edit</td>
              <td>View</td>
              <td>View Condition</td>
              <td>Delete</td>
              <td>Export</td>
              <td>Export Condition</td>
            </tr>
          </thead>
          <tbody>
            {fields &&
              fields.map((item, index) => {
                return (
                  <tr>
                    <td>{item.menu_name}</td>
                    <td>
                      <Controller
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Inputs.InputCheckBox
                            checked={value}
                            onChange={onChange}
                            theme_flex
                          />
                        )}
                        name={`permissions_list.${index}.is_create`}
                        defaultValue=""
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Inputs.InputCheckBox
                            theme_flex
                            checked={value}
                            onChange={onChange}
                          />
                        )}
                        name={`permissions_list.${index}.is_update`}
                        defaultValue=""
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Inputs.InputCheckBox
                            theme_flex
                            checked={value}
                            onChange={onChange}
                          />
                        )}
                        name={`permissions_list.${index}.is_read`}
                        defaultValue=""
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Inputs.InputCheckBox
                            theme_flex
                            checked={value}
                            onChange={onChange}
                          />
                        )}
                        name={`permissions_list.${index}.is_read_condition`}
                        defaultValue=""
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Inputs.InputCheckBox
                            theme_flex
                            checked={value}
                            onChange={onChange}
                          />
                        )}
                        name={`permissions_list.${index}.is_delete`}
                        defaultValue=""
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Inputs.InputCheckBox
                            theme_flex
                            checked={value}
                            onChange={onChange}
                          />
                        )}
                        name={`permissions_list.${index}.is_export`}
                        defaultValue=""
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Inputs.InputCheckBox
                            theme_flex
                            checked={value}
                            onChange={onChange}
                          />
                        )}
                        name={`permissions_list.${index}.is_export_condition`}
                        defaultValue=""
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="action_row">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
          {permission && (
            <>
              {initialValues && permission.is_delete && (
                <div className="w120">
                  <Buttons.BgStandard
                    theme_red
                    label="Delete"
                    disabled={initialValues && initialValues.is_center}
                    onClick={onDelete}
                  />
                </div>
              )}
              {initialValues && permission.is_update ? (
                <div className="w120">
                  <Buttons.BgStandard
                    type="submit"
                    label="Update"
                    // disabled={initialValues && initialValues.is_center}
                  />
                </div>
              ) : (
                permission.is_create && (
                  <div className="w120">
                    <Buttons.BgStandard
                      type="submit"
                      label="Save"
                      // disabled={initialValues && initialValues.is_center}
                    />
                  </div>
                )
              )}
            </>
          )}
        </div>
      </form>
    </PermissionFormStyled>
  );
};

const PERMISSION_LIST = [
  {
    menu_name: "Dashboard",
    menu_key: "dashboard",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 1,
  },
  {
    menu_name: "Product Warranty",
    menu_key: "product_warranty",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 2,
  },
  {
    menu_name: "Business Unit",
    menu_key: "business_unit",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 3,
  },
  {
    menu_name: "Branch",
    menu_key: "branch",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 4,
  },
  {
    menu_name: "Corperate",
    menu_key: "corperate",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 5,
  },
  {
    menu_name: "Customer",
    menu_key: "customer",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 6,
  },
  {
    menu_name: "New",
    menu_key: "new",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 7,
  },
  {
    menu_name: "Activity",
    menu_key: "activity",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 8,
  },
  {
    menu_name: "Booking",
    menu_key: "booking",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 9,
  },
  {
    menu_name: "Show Room",
    menu_key: "show_room",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 10,
  },
  {
    menu_name: "Question",
    menu_key: "question",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 11,
  },
  {
    menu_name: "Reward",
    menu_key: "reward",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 12,
  },
  {
    menu_name: "Lucky Draw",
    menu_key: "lucky_draw",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 13,
  },
  {
    menu_name: "PDPA",
    menu_key: "pdpa",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 14,
  },
  {
    menu_name: "User Management",
    menu_key: "user_management",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 15,
  },

  {
    menu_name: "Permission",
    menu_key: "permisssion",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 16,
  },
  {
    menu_name: "Certificate",
    menu_key: "certificate",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 17,
  },
  {
    menu_name: "Banner",
    menu_key: "banner",
    is_create: false,
    is_read: false,
    is_read_condition: false,
    is_update: false,
    is_delete: false,
    is_export: false,
    is_export_condition: false,
    sort: 18,
  },
];

PermissionForm.propTypes = {};

export default PermissionForm;
