import React, { useEffect } from "react";
import { UserManagementFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ADMIN_STATUS_OPTIONS } from "utils/functions/admin";
import { Buttons, Inputs } from "components";

const UserManagementForm = ({
  initialValues,
  permission,
  businessOptions,
  permissionOptions,
  onCancel,
  onDelete,
  onResetPw,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    username: yup.string().required("Username is required"),
    email: yup.string().required("Email is required"),
    permission_ref_id: yup.string().required("Role is required"),
    status: yup.string().required("Status is required"),
    // business_unit: yup.string().required("Bu is required"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      let params = initialValues;
      if (
        initialValues.business_unit &&
        initialValues.business_unit.length > 0
      ) {
        let temp = {};
        initialValues.business_unit.forEach((e) => {
          temp[String(e)] = e;
        });
        params.business_unit = temp;
      }
      reset(params);
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <UserManagementFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_row">
          <div className="f_left_col">Username</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.username?.message}
                />
              )}
              name="username"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Email</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  // placeholder={t('test')}
                  errors={errors.email?.message}
                />
              )}
              name="email"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Division</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputMutiCheckbox
                  {...field}
                  options={businessOptions}
                  // placeholder={t('test')}
                  errors={errors.business_unit?.message}
                />
              )}
              name="business_unit"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Role</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={permissionOptions}
                  errors={errors.permission_ref_id?.message}
                />
              )}
              name="permission_ref_id"
              // defaultValue={{ label: '1', value: '1' }}
            />
          </div>
        </div>
        <div className="input_row">
          <div className="f_left_col">Status</div>
          <div className="f_right_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={ADMIN_STATUS_OPTIONS}
                  errors={errors.status?.message}
                />
              )}
              name="status"
              // defaultValue={{ label: '1', value: '1' }}
            />
          </div>
        </div>
        <div className="action_row">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
          {permission && (
            <>
              {initialValues && permission.is_delete && (
                <div className="w120">
                  <Buttons.BgStandard
                    theme_red
                    label="Delete"
                    disabled={initialValues && initialValues.is_center}
                    onClick={onDelete}
                  />
                </div>
              )}
              {initialValues && permission.is_update ? (
                <>
                  <div className="w120">
                    <Buttons.BgStandard
                      type="submit"
                      label="Update"
                      // disabled={initialValues && initialValues.is_center}
                    />
                  </div>
                  <div className="w120">
                    <Buttons.BgStandard
                      theme_green
                      label="Reset Pw"
                      // disabled={initialValues && initialValues.is_center}
                      onClick={onResetPw}
                    />
                  </div>
                </>
              ) : (
                permission.is_create && (
                  <div className="w120">
                    <Buttons.BgStandard
                      type="submit"
                      label="Save"
                      // disabled={initialValues && initialValues.is_center}
                    />
                  </div>
                )
              )}
            </>
          )}
        </div>
      </form>
    </UserManagementFormStyled>
  );
};

UserManagementForm.propTypes = {};

export default UserManagementForm;
