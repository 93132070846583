import React from "react";
import { connect } from "react-redux";
import { ActivityFormDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { AlertControl } from "components/Modals/Alert";
import { Displays, Forms, Modals } from "components";

class ActivityFormDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    scrollToTopPageFN();
    if (this.props.router.params.id !== "create") {
      this.fetchPDPA();
      this.fetchData();
    } else {
      this.fetchPDPA();
      this.setState({
        isLoading: false,
        isBgLoading: false,
      });
    }
  }

  fetchData = async () => {
    let res = await Promise.all([
      portalService.GET_ACTIVITY_FORM_DETAIL(this.props.router.params.id),
      this.fetchPDPA(),
    ]);
    if (res && res[0].status === 200) {
      this.setState({
        initialValues: res[0].data,
        pdpaOptions: res[1],
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  fetchPDPA = async () => {
    let res = await portalService.GET_PDPA_LIST(`?type=2&pagination=false`);
    if (res && res.status === 200) {
      const optionsData = res.data.docs.map((e) => ({
        label: e.pdpa_code,
        value: e._id,
      }));
      if (this.props.router.params.id === "create") {
        this.setState({
          pdpaOptions: optionsData,
        });
      }
      return optionsData;
    }
  };

  // FLOW DELETE
  // =============================
  // =============================
  handleDelete = () => {
    this.setState({
      renderModal: (
        <Displays.CofirmBox
          label="คุณต้องการลบใช่หรือไม่ ?"
          labelBtn1="Confirm"
          labelBtn2="Cancel"
          onAction1={this.deleteAction}
          onAction2={this.handleCloseModal}
        />
      ),
    });
  };
  deleteAction = async () => {
    this.setState({
      isBgLoading: true,
    });
    let res = await portalService.DELETE_ACTIVITY_FORM_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      toast.success("ลบสำเร็จ");
      this.handleNextStep();
    } else {
      toast.error("ลบไม่สำรเร็จ");
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  // FLOW SUBMIT
  // =============================
  // =============================
  handleSubmit = async (values) => {
    const { questionDelete } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    let res =
      this.props.router.params.id === "create"
        ? await portalService.POST_ACTIVITY_FORM_DETAIL(params)
        : await portalService.PUT_ACTIVITY_FORM_UPDATE(
            this.props.router.params.id,
            params
          );
    if (res && res.status === 200) {
      let filesTemp = [];
      const mediaCondition = (file) => {
        const reduce = file.reduce(
          (result, data) => {
            if (data.isNewUpload) {
              result.isNewUpload = true;
              result.fileNewUpload = data;
            }
            if (data.isDelete) {
              result.isDelete = true;
              result.fileDeleteUpload = data;
            }
            return result;
          },
          {
            isNewUpload: false,
            isDelete: false,
            fileNewUpload: false,
            fileDeleteUpload: false,
          }
        );
        if (reduce.isNewUpload && reduce.isDelete) {
          return [reduce.fileNewUpload];
        } else {
          if (reduce.isNewUpload) {
            return [reduce.fileNewUpload];
          } else {
            if (reduce.isDelete) {
              return [reduce.fileDeleteUpload];
            } else {
              return [];
            }
          }
        }
      };
      if (values.img_file || values.img_file2 || values.document_file) {
        filesTemp = [
          ...filesTemp,
          ...mediaCondition(values.img_file),
          ...mediaCondition(values.img_file2),
          ...mediaCondition(values.document_file),
        ];
      }
      if (filesTemp.length === 0) {
        let renderParamMedia = [];
        if (values.question_list) {
          renderParamMedia = values.question_list.reduce((result, data, i) => {
            data.questions.forEach((e, k) => {
              if (e.image && e.image.length > 0) {
                if (e.image.length === 1) {
                  if (e.image[0].isNewUpload) {
                    result.push({
                      ...e,
                      page_id: res.data.question_list[i]._id,
                      question_id: res.data.question_list[i].questions[k]._id,
                    });
                  }
                } else {
                  e.image.forEach((f) => {
                    if (f.isNewUpload) {
                      result.push({
                        ...e,
                        image: [f],
                        page_id: res.data.question_list[i]._id,
                        question_id: res.data.question_list[i].questions[k]._id,
                      });
                    }
                    if (f.media_uri) {
                      result.push({
                        img_path: f.media_uri,
                        isDelete: true,
                        page_id: res.data.question_list[i]._id,
                        question_id: res.data.question_list[i].questions[k]._id,
                      });
                    }
                  });
                }
              }
            });
            return result;
          }, []);
        }
        if (questionDelete) {
          renderParamMedia = [...renderParamMedia, ...questionDelete];
        }
        if (renderParamMedia && renderParamMedia.length > 0) {
          this.handleUploadContentMediaQuestion(res.data._id, renderParamMedia);
        } else {
          toast.success("อัพเดตสำเร็จ");
          this.handleNextStep();
        }
      } else {
        let renderParamMedia = [];
        if (values.question_list) {
          renderParamMedia = values.question_list.reduce((result, data, i) => {
            data.questions.forEach((e, k) => {
              if (e.image && e.image.length > 0) {
                if (e.image.length === 1) {
                  if (e.image[0].isNewUpload) {
                    result.push({
                      ...e,
                      page_id: res.data.question_list[i]._id,
                      question_id: res.data.question_list[i].questions[k]._id,
                    });
                  }
                } else {
                  e.image.forEach((f) => {
                    if (f.isNewUpload) {
                      result.push({
                        ...e,
                        image: [f],
                        page_id: res.data.question_list[i]._id,
                        question_id: res.data.question_list[i].questions[k]._id,
                      });
                    }
                    if (f.media_uri) {
                      result.push({
                        img_path: f.media_uri,
                        isDelete: true,
                        page_id: res.data.question_list[i]._id,
                        question_id: res.data.question_list[i].questions[k]._id,
                      });
                    }
                  });
                }
              }
            });

            return result;
          }, []);
        }
        if (questionDelete) {
          renderParamMedia = [...renderParamMedia, ...questionDelete];
        }
        this.handleUploadContentMedia(
          res.data._id,
          filesTemp,
          renderParamMedia
        );
      }
    } else {
      toast.error("อัพเดตไม่สำเร็จ");
    }
  };
  handleUploadContentMedia = (activityID, files, questionList) => {
    let isErr = false;
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set("activity_id", activityID);
            bodyFormData.append("image", e);
            bodyFormData.append("type", e.fieldName);
            let res = await portalService.POST_ACTIVITY_FORM_UPLOAD(
              bodyFormData
            );
            if (res && res.status === 200) {
              resolve();
            } else {
              isErr = true;
              resolve();
            }
          }
          if (e.isDelete) {
            let res = await portalService.POST_ACTIVITY_FORM_UPLOAD_DELETE({
              activity_id: activityID,
              field_name: e.fieldName,
              path_name: e.media_uri,
            });
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      if (isErr) {
        this.setState({
          isBgLoading: false,
        });
        toast.error("อัพโหลดไฟล์ไม่สำเร็จ");
        this.handleNextStep();
      } else {
        if (questionList && questionList.length > 0) {
          this.handleUploadContentMediaQuestion(activityID, questionList);
        } else {
          this.setState({
            isBgLoading: false,
          });
          toast.success("อัพเดจสำเร็จ");
          this.handleNextStep();
        }
      }
    });
  };

  handleUploadContentMediaQuestion = (activityID, files) => {
    let isErr = false;
    Promise.all(
      files.map((e, i) => {
        return new Promise(async (resolve) => {
          const bodyFormData = new FormData();
          bodyFormData.set("activity_id", activityID);
          bodyFormData.set("question", e.question);
          bodyFormData.set("type", e.type);
          if (e.page_id) {
            bodyFormData.append("page_id", e.page_id);
          }
          if (e.question_id) {
            bodyFormData.append("question_id", e.question_id);
          }
          if (e.answer_list) {
            bodyFormData.append("answer_list", e.answer_list);
          }
          if (e.image && e.image.length > 0) {
            bodyFormData.append("image", e.image[0]);
          }
          let res = !e.isDelete
            ? await portalService.POST_ACTIVITY_FORM_QUESTION_UPLOAD(
                bodyFormData
              )
            : await portalService.POST_ACTIVITY_FORM_QUESTION_MEDIA_DELETE({
                img_path: e.img_path,
              });
          if (res && res.status === 200) {
            resolve();
          } else {
            isErr = true;
            resolve();
          }
        });
      })
    ).then(() => {
      if (isErr) {
        this.setState({
          isBgLoading: false,
        });
        toast.success("อัพโหลดไฟล์สำเร็จ");
        this.handleNextStep();
      } else {
        this.setState({
          isBgLoading: false,
        });
        toast.success("อัพเดจสำเร็จ");
        this.handleNextStep();
      }
    });
  };

  handleCancel = () => {
    this.props.router.navigate(-1);
  };

  handleSent = async () => {
    AlertControl.show({
      title: "ยืนยันการส่ง",
      description: "คุณต้องการจะส่งแจ้งเตือนใช่หรือไม่",
      btnLabel1: "ยกเลิก",
      btnLabel2: "ยืนยัน",
      onEvent2: async () => {
        const { initialValues } = this.state;
        this.setState({
          isBgLoading: true,
        });
        let paramsPopup = {
          feature_id: initialValues._id,
          img_path: initialValues.img,
          feature_type: "2",
        };
        let paramsNoti = {
          feature_id: initialValues._id,
          title: initialValues.name,
          message: initialValues.description,
          feature_type: "2",
        };
        let res = await Promise.all([
          portalService.POST_POPUP_SENT(paramsPopup),
          portalService.POST_NOTIFICATION_SENT(paramsNoti),
          portalService.PUT_ACTIVITY_FORM_UPDATE(this.props.router.params.id, {
            is_popup_activate: true,
            is_notification_activate: true,
          }),
        ]);
        if (res) {
          this.fetchData();
        }
      },
    });
  };

  handleDeleteQuestion = (key, eLv1, eLv2) => {
    const { initialValues, questionDelete } = this.state;
    switch (key) {
      case "page":
        if (this.props.router.params.id !== "create") {
        }
        break;
      case "question":
        if (
          this.props.router.params.id !== "create" &&
          eLv2.image &&
          eLv2.image.length > 0 &&
          eLv2.image[0].media_uri
        ) {
          let find = initialValues.question_list.find(
            (f) => f._id === eLv1._id
          );
          if (find) {
            if (!questionDelete) {
              this.setState({
                questionDelete: [{ ...eLv2, isDelete: true }],
              });
            } else {
              let temp = questionDelete;
              temp.push({ ...eLv2, isDelete: true });
              this.setState({
                questionDelete: [{ ...temp }],
              });
            }
          }
        }
        break;

      default:
        break;
    }
  };

  handleNextStep = () => {
    this.props.router.navigate(ROUTE_PATH.ACTIVITY_FORM);
  };

  render() {
    const { authenRedux } = this.props;
    const { isLoading, initialValues, isBgLoading, renderModal, pdpaOptions } =
      this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "activity-form");
    return (
      <ActivityFormDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading theme_standard_loading />
          </div>
        ) : (
          <Forms.ActivitySpecialForm
            initialValues={initialValues && initialValues}
            pdpaOptions={pdpaOptions}
            permission={permission}
            onCancel={this.handleCancel}
            onDelete={this.handleDelete}
            onDeleteQuestion={this.handleDeleteQuestion}
            onSent={this.handleSent}
            onSubmit={this.handleSubmit}
          />
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </ActivityFormDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivityFormDetailContainer));
