import { useState } from "react";
import { BtnApproveFormWidgetStyled } from "./styled";
import { ACTIVITY_FORM_RENDER_STATUS } from "utils/functions/activityForm";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { Displays, Forms, Modals } from "components";

const BtnApproveFormWidget = ({ id, value, onBgLoading, onSuccess }) => {
  const [_renderModal, _setRenderModal] = useState(false);

  const _handleChange = () => {
    _setRenderModal(true);
  };
  const _handleCloseModal = () => {
    _setRenderModal(false);
  };

  const _handleSubmit = async (values) => {
    onBgLoading(true);
    let params = {
      ...values,
    };
    let res = await portalService.PUT_ACTIVITY_FORM_APPROVE_(id, params);
    if (res && res.status === 200) {
      onBgLoading(false);
      toast.success("ดำเนินการสำเร็จ");
      onSuccess && onSuccess();
    } else {
      toast.error("เปลี่ยนไม่สำเร็จ");
    }
  };

  const renderStatus = ACTIVITY_FORM_RENDER_STATUS(value);
  return (
    <>
      <BtnApproveFormWidgetStyled>
        <div onClick={_handleChange}>
          <Displays.StatusLabel
            canEdit
            bg={renderStatus?.bg}
            label={renderStatus?.label}
          />
        </div>
      </BtnApproveFormWidgetStyled>
      <Modals.BodyEmpty
        isShowModal={_renderModal}
        onCloseModal={_handleCloseModal}
      >
        <Forms.ApproveActivityForm
          onCancel={_handleCloseModal}
          onSubmit={_handleSubmit}
        />
      </Modals.BodyEmpty>
    </>
  );
};

export default BtnApproveFormWidget;
