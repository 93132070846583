import React from "react";
import { connect } from "react-redux";
import { UserManagementDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { Displays, Forms, Modals } from "components";
import { AlertControl } from "components/Modals/Alert";

class UserManagementDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData();
    if (this.props.router.params.id !== "create") {
      this.fetchData();
    } else {
      this.fetchData();
    }
  }

  fetchData = async () => {
    let res = await Promise.all([
      this.props.router.params.id === "create"
        ? false
        : portalService.GET_ADMIN_DETAIL(this.props.router.params.id),
      portalService.GET_BUSINESS_UNIT_LIST(`?pagination=false`),
      portalService.GET_PERMISSION_LIST(),
    ]);
    if (res) {
      this.setState({
        initialValues:
          this.props.router.params.id === "create" ? false : res[0].data,
        businessOptions: res[1].data.docs.map((e) => ({
          label: e.name,
          value: e._id,
        })),
        permissionOptions: res[2].data.docs.map((e) => ({
          label: e.name,
          value: e._id,
        })),
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  // FLOW DELETE
  // =============================
  // =============================
  handleDelete = () => {
    this.setState({
      renderModal: (
        <Displays.CofirmBox
          label="คุณต้องการลบใช่หรือไม่ ?"
          labelBtn1="Confirm"
          labelBtn2="Cancel"
          onAction1={this.deleteAction}
          onAction2={this.handleCloseModal}
        />
      ),
    });
  };
  deleteAction = async () => {
    this.setState({
      isBgLoading: true,
    });
    let res = await portalService.DELETE_ADMIN_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      toast.success("ลบสำเร็จ");
      this.handleNextStep();
    } else {
      toast.error("ลบไม่สำรเร็จ");
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  // FLOW SUBMIT
  // =============================
  // =============================
  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    if (values.business_unit) {
      params.business_unit = Object.keys(values.business_unit).map((e) => e);
    }
    let res =
      this.props.router.params.id === "create"
        ? await portalService.POST_ADMIN_DETAIL(params)
        : await portalService.PUT_ADMIN_UPDATE(
            this.props.router.params.id,
            params
          );
    if (res && res.status === 200) {
      toast.success("อัพเดตสำเร็จ");
      if (this.props.router.params.id === "create") {
        this.fetchData();
        AlertControl.show({
          title: "แจ้งเตือน",
          description: `รหัสของท่านคือ ${res.data.password} ท่านจะสามารถเห็นรหัสได้ครั้งแรกเท่านั้น กรุณาบันทึกรหัส`,
          btnLabel1: "ตกลง",
          onEvent1: () => {
            this.handleNextStep();
          },
        });
      } else {
        this.handleNextStep();
      }
    } else {
      toast.error("อัพเดตไม่สำเร็จ");
    }
  };

  handleResetPw = async () => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      admin_id: this.props.router.params.id,
    };
    let res = await portalService.POST_RESET_PASSWORD(params);
    if (res && res.status === 200) {
      toast.success("อัพเดตสำเร็จ");
      this.fetchData();
      AlertControl.show({
        title: "แจ้งเตือน",
        description: (
          <>
            รหัสของท่านคือ{" "}
            <span style={{ color: "red" }}>{res.data.new_password}</span>{" "}
            ท่านจะสามารถเห็นรหัสได้ครั้งแรกเท่านั้น กรุณาบันทึกรหัส
          </>
        ),
        btnLabel1: "ตกลง",
        onEvent1: () => {
          this.handleNextStep();
        },
      });
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error("อัพเดตไม่สำเร็จ");
    }
  };

  handleCancel = () => {
    this.props.router.navigate(-1);
  };

  handleNextStep = () => {
    this.props.router.navigate(ROUTE_PATH.USER_MANAGEMENT);
  };

  render() {
    const { authenRedux } = this.props;
    const {
      isLoading,
      initialValues,
      isBgLoading,
      renderModal,
      businessOptions,
      permissionOptions,
    } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "user_management");
    return (
      <UserManagementDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading theme_standard_loading />
          </div>
        ) : (
          <Forms.UserManagementForm
            initialValues={initialValues}
            permission={permission}
            type="user_management"
            businessOptions={businessOptions}
            permissionOptions={permissionOptions}
            onCancel={this.handleCancel}
            onResetPw={this.handleResetPw}
            onSubmit={this.handleSubmit}
            onDelete={this.handleDelete}
          />
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </UserManagementDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserManagementDetailContainer));
