import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "utils/constants/routePath";
import { useSelector } from "react-redux";
import { Mainlayouts } from "components";
import LoginContainer from "containers/LoginContainer";
import ComponentContainer from "containers/ComponentContainer";
import { RENDER_ROUTE_PERMISSION } from "utils/functions/config";

const Navigations = () => {
  const navigate = useNavigate();
  const authenRedux = useSelector((state) => state.authenRedux);

  useEffect(() => {
    if (!authenRedux || !authenRedux?.permission) {
      navigate("/");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Routes>
      <Route element={<Mainlayouts.PageLayout />}>
        <Route path={ROUTE_PATH.LOGIN} element={<LoginContainer />} />
        <Route path={ROUTE_PATH.COMPONENTS} element={<ComponentContainer />} />
        <Route path="*" element={<LoginContainer />} />
      </Route>
      <Route path={ROUTE_PATH.SYSTEM} element={<Mainlayouts.SystemLayout />}>
        {authenRedux &&
          authenRedux.permission &&
          RENDER_ROUTE_PERMISSION(authenRedux?.permission).map((e, i) => {
            return !e.children ? (
              <Route
                key={i}
                index={e.index}
                path={e.path}
                element={e.element}
              />
            ) : (
              <Route key={i} path={e.path} element={e.element}>
                {e.children &&
                  e.children.map((f, j) =>
                    !f.children ? (
                      <Route
                        key={j}
                        // index={f.index}
                        index
                        path={f.path}
                        element={f.element}
                      />
                    ) : (
                      <Route key={j} path={f.path} element={f.element}>
                        {f.children.map((g, k) => (
                          <Route
                            key={k}
                            // index={f.index}
                            index
                            path={g.path}
                            element={g.element}
                          />
                        ))}
                      </Route>
                    )
                  )}
              </Route>
            );
          })}
      </Route>
    </Routes>
  );
};

export default Navigations;
