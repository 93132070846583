import React from "react";
import { connect } from "react-redux";
import { CertificateDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
// import { portalService } from "apiServices";
// import { toast } from "react-toastify";
// import { ROUTE_PATH } from "utils/constants/routePath";
import { /*  Displays, */ Forms } from "components";

class CertificateDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  // componentDidMount() {
  //   if (this.props.router.params.id !== "create") {
  //     this.fetchData();
  //   } else {
  //     this.setState({
  //       isLoading: false,
  //       isBgLoading: false,
  //     });
  //   }
  // }

  // fetchData = async () => {
  //   let res = await portalService.GET_BOOKING_DETAIL(
  //     this.props.router.params.id
  //   );
  //   if (res && res.status === 200) {
  //     this.setState({
  //       initialValues: res.data,
  //       isLoading: false,
  //       isBgLoading: false,
  //     });
  //   }
  // };

  // // FLOW SUBMIT
  // // =============================
  // // =============================
  // handleSubmit = async (values) => {
  //   const { initialValues } = this.state;
  //   this.setState({
  //     isBgLoading: true,
  //   });
  //   let params = {
  //     status: values.status,
  //     division: values.division,
  //     customer_id: initialValues.customer_ref_id,
  //   };
  //   if (values.reason_reject) {
  //     params.reason_reject = values.reason_reject;
  //   }
  //   if (values.note) {
  //     params.note = values.note;
  //   }
  //   if (values.is_ban_user || values.is_ban_user === false) {
  //     params.is_ban_user = values.is_ban_user;
  //   }
  //   if (values.is_ban_user_limit|| values.is_ban_user_limit === false ) {
  //     params.is_ban_user_limit = values.is_ban_user_limit;
  //   }
  //   if (values.reason_reject) {
  //     params.reason_reject = values.reason_reject;
  //   }
  //   if (values.ban_user_until_at) {
  //     params.ban_user_until_at = values.ban_user_until_at;
  //   }
  //   if (values.visit_status) {
  //     params.visit_status = values.visit_status;
  //   }
  //   let res =
  //     this.props.router.params.id === "create"
  //       ? await portalService.POST_BOOKING_DETAIL(params)
  //       : await portalService.PUT_BOOKING_UPDATE(
  //           this.props.router.params.id,
  //           params
  //         );
  //   if (res && res.status === 200) {
  //     toast.success("อัพเดตสำเร็จ");
  //     this.handleNextStep();
  //   } else {
  //     toast.error("อัพเดตไม่สำเร็จ");
  //   }
  // };

  // handleNextStep = () => {
  //   this.props.router.navigate(ROUTE_PATH.BOOKING);
  // };

  render() {
    // const { authenRedux } = this.props;
    // const { isLoading, initialValues, isBgLoading } = this.state;
    // const permission = !authenRedux
    //   ? false
    //   : authenRedux?.permission.find((e) => e.menu_key === "new");
    return (
      <CertificateDetailContainerStyled>
        {/* <Displays.BGLoading visible={isBgLoading} /> */}
        {/* {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : ( */}
        <Forms.CertificateForm />
        {/* )} */}
      </CertificateDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CertificateDetailContainer));
