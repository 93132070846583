import React from "react";
import { connect } from "react-redux";
import { CertificateContainerStyled /* StatusButton */ } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import momentTZ from "moment-timezone";
import { MdAdd, MdAssignmentAdd } from "react-icons/md";
import { portalService } from "apiServices";
import { BtnCertificateGenerateWidget } from "widgets";
import { Buttons, Displays, Tables } from "components";

class CertificateListContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    perPage: 10,
  };

  componentDidMount() {
    const { perPage } = this.state;
    const query =
      this.props.router.location.search || `?limit=${perPage}&is_delete=false`;
    this.fetchData(query);
  }

  fetchData = async (query) => {
    let res = await portalService.GET_CERTIFICATE_LIST(query);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
        isLoading: false,
      });
    }
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}&is_delete=false`
      : `?limit=${per_page}&page=${page}&is_delete=false`;
    this.setState({
      isLoading: true,
    });
    this.props.router.navigate(`${ROUTE_PATH.BUSINESS_UNIT}${joinQuery}`);
    this.fetchData(joinQuery);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleClickCreate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.CERTIFICATE}/${e}`);
  };

  handleClickCertificateSendInfo = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.CERTIFICATE}/${e}/send`);
  };

  render() {
    const { authenRedux } = this.props;
    const { isLoading, pagination, data } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "certificate");
    return (
      <CertificateContainerStyled>
        <div className="header">
          <div className="header_btn">
            {permission && permission.is_create && (
              <div className="btn_create">
                <div className="w120">
                  <Buttons.BgIconPrefix
                    label="New Certificate"
                    icon={<MdAdd size={18} />}
                    onClick={() => this.handleClickCreate("create")}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <div className="body_table">
            <Tables.Standard
              columns={columns({
                handleClickDetail: this.handleClickCreate,
                onClickCertificateSendInfo: this.handleClickCertificateSendInfo,
              })}
              data={data}
              showPagination
              pagination={pagination}
              handlePageClick={this.handlePageClick}
            />
          </div>
        )}
      </CertificateContainerStyled>
    );
  }
}

const columns = ({ handleClickDetail, onClickCertificateSendInfo }) => [
  {
    Header: <div style={{ textAlign: "left" }}>Create</div>,
    accessor: "created_at",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Certificate Code</div>,
    accessor: "certificate_code",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Certificate Name</div>,
    accessor: "certificate_name",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>For Event/Activity</div>,
    accessor: "activity_ref_code",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Sub Category Name</div>,
    accessor: "sub_category",
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}></div>,
    accessor: "action",
    Cell: (props) => (
      <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
        <BtnCertificateGenerateWidget certificateID={props.row.original._id} />
        <div onClick={() => onClickCertificateSendInfo(props.row.original._id)}>
          <MdAssignmentAdd size={22} color="#4b1e78" />
        </div>
      </div>
    ),
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CertificateListContainer));
